/****************************************************/

// This files contains the scss imports that are required for the inputs

/****************************************************/

.checkBox {
  position: relative;
  padding-top: #{pxToRem(10)};
  input {
    display: none;
  }
  label {
    cursor: pointer;
    position: absolute;
    left: 0;
    width: 100%;
    &:before {
      font: normal normal normal 18px/18px 'Nalco-additional-icons';
      content: '\E929';
      color: $secondary-gray;
      vertical-align: bottom;
      margin-right: 0.625rem;
    }
    &:hover {
      &:before {
        color: $secondary-dark-gray;
      }
    }
  }
  input:checked+label {
    &:before {
      content: '\E92A';
      color: $primary-blue;
    }
  }
  input:disabled+label {
    color: $secondary-dark-gray;
    &:before {
      color: $secondary-light-gray;
    }
  }
}

.radioBox {
  .inline {
    display: inline-block;
  }
  .inline+.inline {
    margin-left: #{pxToRem(10)};
  }
  .radio {
    color: $primary-gray;
    @include rhythm(13, 14);
    position: relative;
  }
  .radio span {
    position: relative;
    padding-left: 1.5rem;
    padding-top: 0.125rem;
  }
  .radio span:after {
    content: '';
    width: 1.125rem;
    height: 1.125rem;
    background-color: $primary-white;
    border: 2px solid $secondary-gray;
    position: absolute;
    left: 0;
    top: 1px;
    border-radius: 100%;
    -ms-border-radius: 100%;
    -moz-border-radius: 100%;
    -webkit-border-radius: 100%;
    box-sizing: border-box;
    -ms-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
  }
  .radio input[type="radio"] {
    cursor: pointer!important;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
    filter: alpha(opacity=0);
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)"
  }
  .radio input[type="radio"]:checked+span {
    color: $primary-gray;
  }
  .radio input[type="radio"]:checked+span:before {
    content: '';
    width: #{pxToRem(10)};
    height: #{pxToRem(10)};
    position: absolute;
    background: $primary-blue;
    left: 0.25rem;
    top: #{pxToRem(5)};
    border-radius: 100%;
    -ms-border-radius: 100%;
    -moz-border-radius: 100%;
    -webkit-border-radius: 100%;
    z-index: 1;
  }
}

input[type="number"] {
  -moz-appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

input,
button,
textarea {
  outline: none !important;
  box-shadow: none !important;
}

input {
  cursor: auto !important;
  &:disabled {
    border: 1px solid $secondary-dark-gray;
  }
}

.required-field {
  position: relative;
  &:after {
    content: '*';
    position: absolute;
    right: #{pxToRem(-10)};
    color: $primary-blue;
  }
}

button {
  color: $primary-gray;
}

fieldset {
  &:not(:last-of-type) {
    border-bottom: 1px solid $secondary-light-gray;
    padding-bottom: 1rem;
    margin-bottom: 1.5rem;
  }
  legend {
    @include rhythm(13, 14);
    font-family: $interstate-bold;
    text-align: left;
    margin-bottom: 1.5rem;
    color: $primary-gray;
  }
  .row {
    margin-bottom: 1rem;
    .fieldset-field-title {
      @include rhythm(13, 14);
      text-transform: uppercase;
      float: left;
      &.disabled {
        color: $secondary-gray;
      }
    }
    textarea,
    .dropdown,
    input,
    .radioBox,
    .k-datepicker,
    .k-combobox {
      float: left;
      width: 100%;
    }
    textarea,
    .dropdown,
    input {
      border-radius: 0;
      padding: #{pxToRem(11)} 1rem;
    }
    input {
      &:disabled {
        background-color: transparent;
      }
      &::placeholder {
        color: $secondary-gray;
      }
    }
    .checkBox {
      margin-top: 1rem;
    }
    .radioBox {
      text-align: left;
      margin-top: 0.25rem;
    }
  }
  .required-field {
    +input,
    +textarea {
      &.ng-touched.ng-invalid {
        border: 1px solid $utilitarian-red;
      }
    }
    +.ng-touched.ng-invalid {
      .dropdown {
        border: 1px solid $utilitarian-red;
      }
    }
  }
  &.no-txt-transform {
    .row {
      .fieldset-field-title {
        text-transform: none;
      }
    }
  }
}
