@import "../../../../../styles/variables.scss";

.k-pager-numbers .k-link, .k-pager-numbers .k-link:link {
  color: #007ac9;
}

.k-state-selected{
  background-color:#007ac9;
}

.k-pager-wrap .k-state-selected:hover{
  background-color:#007ac9
}

.k-pager-wrap {
  .k-pager-sizes {
      position: absolute;
      right: 0;
      bottom: 11px;
  }
}
.temperature-tile {
  padding: 16px 0 16px 10px;
  font-size: 14px;
  color: #616365;
  border-bottom: 1px solid #E2E2E2;

  input[type="radio"] {
    display: none;
  }

  label {
    padding-left: 16px;
    margin-bottom: 0px;
  }
}

.temperature-radio-width {
  width: 150px;
}

.checkmark {
  position: absolute;
  padding-right: 8px;
  top: 0;
  left: 0;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  border: 1px solid $primary-gray;

  &:after {
    content: "";
    position: absolute;
    display: none;
    top: 3px;
    left: 3px;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: $primary-blue;
  }
}
/* When the radio button is checked, add a blue background */
.temperature-tile input:checked ~ .checkmark {
  background-color: $primary-white;
  border: 1px solid $primary-blue;
}

.temperature-tile input:checked ~ .checkmark:after {
  display: block;
}

.temperature-uom {
  // @include font-size(14);
  // font-family: $interstate-bold
  font-size: 14px !important;
  margin-bottom: #{pxToRem(-4)};
  float: left;
  line-height: #{pxToRem(12)};
  padding-right: #{pxToRem(12)};
}

#siteLegionellaRiskList{
  .first-col {
        overflow: hidden;
        text-overflow: ellipsis;
        &::before {
            content: '';
            display: block;
        }
    }
        th{
          word-break: normal;
        }
      
    
}

.water-safety-grid-view {
  .k-grid-aria-root {
    overflow-x: auto !important;
    tbody tr.k-alt{
      background-color: #fff;
    }
  }
}

@media only screen and (max-width: 768px) {
  .k-grid {
    max-height:350px;
  }
  .k-grid-aria-root {
    overflow-y: scroll !important;
  }
}


@media only screen and (max-width:550px){
  .k-pager-wrap {
    .k-pager-sizes {
      bottom: 2px;
        select{
          width: auto;
        }
    }
  }
}
