/****************************************************/

// This files contains the favorite-toggle component styles

/****************************************************/

.favorite-toggle {
  display: flex;
  padding-bottom: 0.125rem;
  float: right;
  width: 7.5rem;
  label {
    cursor: pointer;
  }
  .ico-toggle {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin: 0 0.5rem;
    @include rhythm(30, 17);
    position: relative;
    height:#{pxToRem(17)};
    background-color: $primary-white;
    border-radius: 50%;
  }
  input {
    visibility: hidden;
    &:checked+.ico-toggle {
      color: $primary-white;
      &:before {
        content: '\e912';
        color: $primary-gray;
      }
      &:after {
        content: '\e903';
        @include rhythm(11, 11);
        color: $primary-blue;
        position: absolute;
        top: 2px;
        right: 0.25rem;
      }
    }
  }
  &.disabled {
    .ico-toggle {
      &:before{ 
      color: $primary-gray;
    }
    &:after{ 
      color: $primary-gray;
    }
  }
    input , label {
      cursor: auto;
    }
  }
}

.fav-toggle-wrap {
  .favorite-toggle {
    width: 14rem;
    input {
      &~.ico-toggle {
        color: $primary-white;
        &:before {
          content: '\e912';
          color: $primary-blue;
        }
        &:after {
          content: '\e903';
          @include rhythm(11, 11);
          color: $primary-blue;
          position: absolute;
          top: 2px;
          right: 0.25rem;
        }
      }
    }
    input {
      &:checked {
        &~.ico-toggle {
          &:after {
            left: 0.25rem;
            right: auto;
          }
        }
      }
    }
  }
}
