@import "../../styles/variables.scss";

.notifications-admin {
  ul {
    float: left;

    li {
      list-style-type: none;
    }
  }

  table {
    width: auto !important;
  }

  .searchtypeSite {
    .mandatory-field {
      color: red;
      font-size: 20px;
      position: absolute;
      right: 4px;
    }
  }

  .nalco-asset-parameters {
    .notificationPage {
      flex-direction: column;
      min-width: 0;
      word-wrap: break-word;
      background-color: #fff;
      background-clip: border-box;
      border: 0 solid rgba(0, 0, 0, 0.125);
      border-radius: 0;
      position: relative;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;

      .filterSpace {
        border-bottom: 1px solid #E2E2E2;
        align-items: center;
        padding: 6px 0px 10px 24px;

        .paddingRight {
          padding-right: 16px;

          .nalco-filter-by-service,
          .filter-by-type-of-user {

            .dropdown {
              min-width: 13.8rem;
              min-height: 30px;

              .btn-outline-primary.drop-btn.dropdown-toggle {
                margin-top: 1px;
              }

              .label {
                display: block;
              }
            }

            .dropdown.show {
              .dropdown-menu.show {
                margin-top: 9px !important;
              }
            }
          }

          .filter-by-account {

            .admin-search {
              min-width: 13.8rem;
              width: auto;
              border: 1px solid #BBBCBE;
              border-radius: 0.25rem;
                ::placeholder{
                  color: $secondary-gray;
              }
            }
                    
          }

          .search-text-filter {

            .k-autocomplete {
              min-width: 13.8rem;
              border: 1px solid #BBBCBE;
              padding: 0.125rem 1rem 0.125rem 2rem;
              border-radius: 0.25rem;
            }
          }
        }

        @media screen and (max-width: 1120px) {
          .position-relative.paddingRight {
            margin-top: 1rem;
          }
        }

        .padding-12 {
          padding-right: 12px;
        }

      }

      .kendoSpace {
        min-height: 100px;

        .admin-button-spacing {
          position: absolute;
          top: -65px;
          right: 0px;

          .btn {
            width: 170px;
            margin-right: 10px;
            min-height: 32px;
          }
        }
      }

      .parameters-tile {
        padding: 16px;
        font-size: 14px;
        color: #616365;
        border-bottom: 1px solid #E2E2E2;

        .admin-radio-width {
          &:first-child {
            width: 231px;
          }
        }

        input[type="radio"] {
          margin-left: 25px;
        }

        label {
          padding-left: 16px;
          margin-bottom: 0px;
        }

        .checkmark {
          position: absolute;
          padding-right: 8px;
          top: 0;
          left: 0;
          height: 15px;
          width: 15px;
          border-radius: 50%;
          border: 1px solid $primary-gray;

          &:after {
            content: "";
            position: absolute;
            display: none;
            top: 3px;
            left: 3px;
            width: 7px;
            height: 7px;
            border-radius: 50%;
            background: $primary-blue;
          }
        }

        /* When the radio button is checked, add a blue background */
        .input:checked~.checkmark {
          background-color: $primary-white;
          border: 1px solid $primary-gray;
        }

        input:checked~.checkmark:after {
          display: block;
        }

        .fontFamily {
          font-size: 1rem;
        }

        @media screen and (max-width: 767px) {
          div {
            margin: 8px 0 8px 8px;
          }

        }
      }
    }
  }

  // .margin17{
  //   margin-right: 19px;
  // }
  // .margin45{
  //   margin-right: 48px;
  // }

  .search-user {
    .search-icon {
      left: 17px;
      top: 9px;
      background-image: url(/assets/images/search-icon.svg);
      width: 1rem;
      height: 1rem;
      background-repeat: no-repeat;
      position: absolute;
      z-index: 10;
    }

    .k-autocomplete {
      min-width: 13.8rem;
      border: 1px solid #BBBCBE;
      padding: 0.125rem 1rem 0.125rem 2rem;
      border-radius: 0.25rem;
    }
  }

  .accordion.mainClass {
    padding-top: 26px;
    float: left;
    width: 100%;
  }

  ngb-accordion .card .card-header[role=tab] button.btn-link {

    &:after,
    &.collapsed:after {
      top: 42px
    }
  }
  .custom-control-label {
    .tooltip{
      display: contents;
      line-height: 1em ;
    }
  }
  .custom-control-label::before {
    margin-left: 1.5rem;
  }
  .select-view-type .nalco-tree-column .custom-control .custom-control-label {
    padding: 5px 4px 4px 25px;
    max-width: 200px;
  }
  .custom-control-label:after {
    left: 0.01rem;
  }
  .select-view-type .nalco-simple-column .checkBoxStyle .custom-control {
    display: flex;
  }
  .userClass{
    left:-10px !important;
  }
  .select-view-type .nalco-tree-column ngb-accordion .card .card-header {
    padding: 25px 10px 15px;
  }
}


.filter-by-type-of-user,
.nalco-filter-by-service {
  .selected-text {
    color: #007ac9 !important;
    font-family: "Interstate-Regular", sans-serif;
    top: 2px;
    position: relative;
  }
}

.custom-control-label::before {
  background: #e2e2e2;
  border: none;
}