@import "../../../../../styles/variables.scss";
nalco-sub-system-chart{
    .DonutChart{
        span.iconwater-LowRiskGreen, span.iconwater-MediumRiskYellow, span.iconwater-HighRiskRed{
            vertical-align: top;
        }
        span.icon-trend-NoTest{
            margin-right: 4px;
        }
    }

    .offlineDaysClass{
        bottom: 3.6rem;
        text-align: center;
    }

    .viewMoreClass1{
        bottom: 3.7rem;
        text-align: center;
    }

    .viewMoreClass2{
        bottom: 2.15rem;
        text-align: center;
    }

    .navigationLink{
        color:dodgerblue;
        text-decoration: underline; 
        background-color: none;
        cursor: pointer;
    }

    .donutContainer{
        display: block;
    }
}
.wq-sub-system-chart{
    .title-row {
        display: flex;
        .asset-title-info.info-dashboard{
            flex:12;
            display: flex;
            gap: 5px;
            justify-content: center;
            max-width: unset;
            .donut-asset-title{
                max-width: #{pxToRem(110)};
                .donut-asset-substring{
                    max-width: 9rem !important;
                    // width:min-content;
        
                    @media screen and (max-width:992px) {
                        max-width: 15rem !important;
                    } 
                }
            }
            
            .popup-info-icon{
                font-size: 1rem !important;
                line-height: 1.2rem !important;
                padding: 0;
                height: 1.2rem; 
            }
        }
        .ai-icon{
            position: absolute;
            top:0;
            right: 0.3rem;
            background-image: url(../../../../../assets/images/ai-icon.svg);
            background-repeat: no-repeat;
            height: #{pxToRem(18)};
            width: #{pxToRem(27)};
            background-position: center;
            background-size: contain;
        }
    }
}

.poweredBy-tooltip.tooltip.show {
    opacity: 1 !important;

    .tooltip-inner {
        background-color: #39b54a !important;
    }

    .arrow {
        left: 50% !important;
        top: 80% !important;
        transform: translateX(-50%);
    }

    .arrow::before {
        border-top-color: #39b54a !important;
    }
}

.donutAssetName.donutassetcomponet{
    overflow: hidden;
    line-height: 1.5em;
    max-height: 3em;
    position:absolute; 
    text-align:center;
    width:200px;
    margin-top: 7px;
    z-index: 10;
    margin-left: 0;
    @media screen and (max-width:990px){
        width: 100%;
    }

 }

 
