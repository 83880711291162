@import "../../../../styles/variables.scss";
::ng-deep {
  .asset-details-modal{
    padding-top: 4rem;
      .modal-dialog {
        min-width: 50rem !important;
          .modal-content {
            .modal-body {
              min-height: 27rem;
              max-height: 92em;
            }
            .modal-footer {
              border-top: 1px solid white;
            }
          }
      }
      
      .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
        color: #495057;
        background-color: #ffffff;
        border-color: #ffffff #ffffff #ffffff;
      }
      .nav-tabs {
        border-bottom: 1px solid #ffffff;
      }
      .nav-item{
        margin-left: -12px;
        margin-bottom: 14px;
        font-size: 16px;
      }
      .nav-tabs .nav-item .nav-link.active{
        color: black ;
      }
      .nav-tabs .nav-item .nav-link{
          color: #1794e5;
        
      }
      .loader-wrapper {
        width: 100%;
        max-height: 87%;
        background: rgba(255, 255, 255, 0.8);
        position: fixed;
        top:0px;
        left: 0px;
        z-index: 100;
        margin-top:8%;
        display:flex;
        flex-wrap:wrap;
        align-items: center;
        justify-content: center;
      }
    
      .loader {
        position: absolute;
        left:  #{pxToRem(0)};
        right:  #{pxToRem(0)};
        width:  #{pxToRem(80)};
        height:  #{pxToRem(80)};
        bottom: 20%;
        top: 2rem;
      }
      .tile {
        border: 6px solid white;
        padding: 0rem;
        //border: 3px solid white;
      }
      
      .tile-thumbnail {
        // aspect ratio box
        position: relative;
        height: 50px;
        background-color: white;
        border: solid 1px #1693e4 !important;
        word-wrap: break-word;
        padding-top: 1rem;
      }
      .assets-div{
        overflow-y: auto;
        overflow-x: hidden;
        min-height: 5rem;
        max-height: 19rem;
        display: flex;
        padding-right: 10px;
        margin-top: 1rem;
      } 
      .asset-selected{
        background-color: #e6e6e6;
      }
      .select-text{
        font-size: 13px;
        margin-left: -13px;
        margin-bottom: 22px;
    }
    .assetWidth100 {
      width: 100%;
    }
    .back-btn{
      margin-right: 499px;
    }
    .set-footer{
      margin-top: -15px;
    }
    .noteClass{
      margin-top: 54px;
    }
    
    .dd-container.select-dropdown .dd-list-container .list-items .dd-items-container{
          overflow-x: hidden;
    }
    .assetNameOverflow {
      text-align: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: $primary-gray;
    }
    
    @media only screen and (max-width: 390px) {
      .back-btn{
        margin-right: 21px !important;
      }
      .assets-div{
        max-height: 11rem;
      }
    }
    @media (min-width:391px) and (max-width: 450px) {
      .back-btn{
        margin-right: 65px !important;
      }
      .assets-div{
        max-height: 13rem;
      }
    }
    @media (min-width:451px) and (max-width: 780px) {
      .back-btn{
        margin-right: 201px !important;
      }
      .assets-div{
        max-height: 13rem;
      }
    }
    @media only screen and (max-width: 800px) {
      .modal-dialog {
        min-width: inherit !important;
      }   
      .showPopUpLoader{
        .loader {
          top: 9rem;
        }
      } 
    }
    @media (min-width: 256px) and (max-width: 320px) {
      .modal-footer {
        .setWidth {
          min-width: 5rem;
        }
      }
    }
  }
}
