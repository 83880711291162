@import "../../../../../styles/variables.scss";

.edata-mapping-customize-modal {
    .tile-content-wrapper {
        min-height: #{pxToRem(240)};
        background-color: #FFF;
    }
    .modal-dialog {
        min-width: 60rem !important;
    }
    .modal-body {
        min-height: #{pxToRem(240)};
        padding: 0 !important;
        max-height: fit-content !important;
    }
    .modal-footer {
        display: flex;
        justify-content: flex-end;
    }
    ngb-accordion.edata-accordion.custom-accordion .card .card-body {
        background-color: #fff;
        min-height: auto;
    }
    .row {
      display: flex;
      align-items: center;
    }
    .float-right {
      margin-left: 2rem;
    }
    .single-arrow-icons {
        height: 20px;
        margin-left: 3px;
    }
    .single-arrow-up-icons {
        height: 20px;
        margin-left: 3px;
        transform: rotate(-90deg);
    }
    .single-arrow-down-icons {
        height: 20px;
        margin-left: 5px;
        transform: rotate(90deg);
    }
    .icons {
        height: 20px;
    }
    .margin-10 {
        margin: 10px 0px;
    }
    .panel-title {
        background:$primary-blue;
        color: #fff;
        padding: 10px;
        font-size: 12px;
    }
    .panel-content-body {
        height: 250px;
        overflow: auto;
        width: 100%;
        border: 1px solid #E2E2E2;
    }
    .list-items-edata {
        cursor: pointer;
        background: #e2e2e2;
        padding: 5px;
        margin: 5px 10px;
        border-radius: inherit;
    }
    .color-blue-edata {
        color:$primary-blue;
        cursor: pointer;
    }
    .active-list-edata {
        border: 2px solid $primary-blue;
        border-style: dashed;
    }
    .loader {
        position: absolute;
        left: 0px;
        right: 0px;
        width: 50px;
        height: 50px;
        top: 35%;
    }
    .error {
        color: red;
    }

.inventory-modal-checkbox {
  margin: 0 0 0 32px;
  input {
    display: none
  }
  .checkmark {
    position: relative;
    left: 0;
    width: #{pxToRem(18)};
    height: #{pxToRem(18)};
    border: 1px solid $light-gray;
    display: inline-block;
    @include margin-padding(right , #{pxToRem(5)} , all, null);
    vertical-align: top;
    background-color: $light-gray;
    border-radius: #{pxToRem(4)};
  }
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  input:checked~.checkmark:after {
    display: block;
  }
  input:checked~.checkmark {
    background-color: $primary-blue;
    border: 1px solid $primary-blue;
  }
  .checkmark:after {
    left: #{pxToRem(5)};
    top: #{pxToRem(2)};
    width: #{pxToRem(5)};
    height: #{pxToRem(10)};
    border: solid $primary-white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    border-radius: #{pxToRem(0)};
  }
  input:disabled~.checkmark {
      background-color:$primary-gray;
      border: 1px solid #949494 !important;
  }
  input:disabled~.checkmark:after{
      background: none;
  }
}
#tooltip {
margin-left: 2px;
}
}
.tooltip-msg {
max-width: 12rem !important;
}

.calculated-icon-image {
  width: 60px;
  height: 18px;
}
.calculated-icon-image-selected {
  width: 30px;
  height: 18px;
}
