.nalco-map-measurement-view {
    .no-header {
        .card.tile {
            .card-header {
                display: none;
            }
        }
    }

    .borderBottom {
        border-bottom: 1px solid #e2e2e2;
        min-height: 6rem;

        .tile-content-wrapper {
            .state-wrapper.loading {
                position: unset;
            }
        }
    }
    .searchBox {
        width: 40%;
        // margin: auto;
    }
    .ledendHyperlink{
        font-size: 0.875rem;
        color: #007AC9;
        cursor: pointer;
        text-decoration: underline;
        margin-bottom: 0;
        padding-top: 5px;
        position: absolute;
        top: 0;
        right: 0;
        padding-right: 4px;
        border: 0;
        background-color: transparent;

        @media screen and (max-width:990px){
            margin-top: 1.5rem;
        }
    }
    ngb-accordion{
        .card{
            //position: unset;
            .card-header{
                a {
                    padding-left: 25px;

                    &:after {
                        position: absolute;
                        left: 0;
                        border-top-color: #007ac9;
                        top: 40%;
                        transform: rotate(0deg);
                    }
                }

                &.active {
                    a:after {
                        transform: rotate(180deg);
                    }
                }
            
                .headingAccordian{
                    color: #616365;
                    // font-size: 1.25rem;
                    // padding: 0.3rem;

                    .categorytitle {
                        color: #007ac9;
                    }

                    .imageClass{
                        height: 1.5rem;
                    }
                    .emptyImage{
                        height: 1rem;
                        width: 2rem;
                        display: inline-flex;
                    }
                }
            }
            // .card-body{
            //     overflow-y: visible;
            // }
        }
    } 
    .map-measurement-header{
        display: flex;
        justify-content: flex-start;
        margin: 0px;
        padding-left: 0;
    }
}

.table-row-header{
    display: table-row;
    font-weight: bold;
    
    .table-cell{
        display: table-cell;
    }
}
.table-row-body{
    display: table-row;

    .table-cell{
        display: table-cell;
    }
}
