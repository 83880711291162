.assetPropComponent{
    ngb-tabset{
        padding: 0;
        display: inline-block;
    }
    .admin-button-spacing{
        z-index: 10;
        margin-bottom: 0;
    }
    .tab-content{
        margin-top: 2rem;
        nalco-limits-configuration {
            .modal-body {
                padding: 0px;
            }
            .modal-content {
                border: none;
            }
            .pl-3{
                padding-left: 0px !important;
            }
        }
    }
    .invalid-tab {
        color: red;
    }
    .disable-tab {
        color:#ADAEB0;
    }
}

.site-nav ul .nav-item .nav-link span{
    padding: 0.625rem;
}