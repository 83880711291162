// Variables
$primary-white: #fff;
$primary-black: #000;
$primary-brown: #964b00;
$primary-blue: #007ac9;
$primary-gray: #ddd;
$secondary-dark-blue: #004d71;
$secondary-blue: #5eb6e4;
$primary-gray: #616365;
$dark-gray: #4d4f53;
$secondary-dark-gray: #949494;
$light-gray: #dee2e6;
$secondary-gray: #bbbcbe;
$secondary-light-gray: #e2e2e2;
$utilitarian-green: #6fb43f;
$secondary-green: #275e37;
$utilitarian-yellow: #eeaf00;
$utilitarian-orange: #f57e20;
$color-orange: #ee7700;
$utilitarian-red: #e00034;
$utilitarian-purple: #9c13bb;
$utilitarian-java-green: #13c0b7;
$utilitarian-grey: #e9ecef;
$aqua-blue: #2ad2c9;
$linear-gradient-starting-blue: #1c9aec;
$linear-gradient-starting-green: #81ce4b;
$new-unit-button-starting-blue: #53ade0;
$new-unit-button-ending-blue: #3d95c8;
$linear-gradient-end-red: #e00069;
$linear-gradient-start-green: #3fb47b;
$linear-gradient-end-green: #66b44a;
$linear-gradient-start-yellow: #ffd82b;
$linear-gradient-end-yellow: #ffb82b;
$linear-gradient-start-grey: #aeaeae;
$linear-gradient-end-grey: #5e5e5e;
$modal-body-bg: #f7f7f7;
$loader-bg: #f5f5f5;
$loader-border: #ccc;
$loader-border-top: #3498db;
$nav-link-active-bg: #e1effa;
$inline-edit-border: #f4721e;
$inline-edit-cell-ending-orange: rgba(244, 114, 30, 0.5);
$semi-transparent-blue: rgba(9, 54, 117, 0.48);
$territory-midnight-blue: rgba(13, 13, 77, 0.856);
$opportunity-graph-line-starting-blue: #147dc5;
$opportunity-graph-line-ending-blue: #12b6c5;
$opportunity-graph-line-bg-gray: #eaebeb;
$bar-graph-legend-ending-blue: #7dc1e8;
$btn-group-bg: #f6f6f6;
$btn-group-disabled-txt: #adaeb0;
$btn-group-active-start-color: #178fdd;
$btn-group-active-end-color: #007ccc;
$legend-estimated-saving: #42b8e6;
$legend-estimated-lite-red: #f00032;
$legend-estimated-dark-red: #89001d;
$remove-btn-start-color: #f5515f;
$remove-btn-end-color: #9f041b;
$customer-area-legend: #5eb6e4;
$customer-area-gradient-legend: #147dc5;
$subcustomer-area-legend: #5eb6e4;
$asset-legend: #0081d7;
$asset-gradient-legend: #005b99;
$subasset-legend: #0086df;
$port-legend: #24a8ff;
$system-legend: #3fb47b;
$system-gradient-legend: #66b44a;
$parameter: #ffffff;
$parameter-gradient: #e2e2e2;
$deg-system: 90deg;
$asset-tree-icon: #868686;
$d-inline: #727272;
$data-source: #f3f3f3;
$count: #24a8ff;
$custom-info: #707070;
$info: #c3c3c3;
$d-inline: #727272;
$alt-table: #f7f7f7;
$validation-red: #c72946;
$accordian-bg: #fbfbfb;
$utilitarian-green-e-data: #e5f5da;
$transition: 0.3s cubic-bezier(0.95, 0.05, 0.795, 0.035);
$ecolab-purple: #813b9e;
$text-color-dark: #093675;
$tooltip-background: #d9d9d9;
$brand-light-blue: #d9eaf9;
$brand-medium-blue: #80b5e9;
$light-blue: #f5fafd;
$light-sky-blue: #e5f1f9;
$light-blue-half: #ecf5fc;
$dim-gray: #6a6a6a26;
$dim-dropdown-grey: #808080;
$notes-popup-border-shadow: 0px -1px 40px 0px rgba(0, 107, 211, 0.2),
  20px 4px 40px 0px rgba(8, 35, 68, 0.12);

//value icons color
$saving-water: #007ac9;
$saving-energy: #e17000;
$saving-productivity: #eeaf00;
$not-designated-legend: #e2e2e2;
$saving-cost: #005172;
$saving-waste: #5b8f22;
$saving-co2: rgb(94, 182, 228);
$saving-chemistry: rgb(39, 94, 55);
$saving-assetpreservation: rgb(77, 79, 83);
$default-cancel: #7a7a7a;
$saving-food: #ee3642;
$saving-profitability: #54b048;
$saving-quality: #3f4450;
$subtag: #adafaf;
//horizontal bar chart
$light-grayish-blue: #cce4f4;
$progress-soft-blue: #66afdf;
$progress-dark-blue: #004979;
$progress-dark-gray: #848282;
$regression-line: #58a618;

//dashboard
$value-green: #008000;
$unmapped-blue: aliceblue;
$card-background-blue: #e5f1fa;

//Font Varibles
$font11: 11px;
$font12: 12px;
$font14: 14px;
$font13: 13px;
$font15: 15px;
$font16: 16px;
$font18: 18px;
$font20: 20px;
$font22: 22px;
$font24: 24px;
$font30: 30px;
$font32: 32px;

@mixin gradient-directional($start-color, $end-color, $deg) {
  background: linear-gradient($deg, $start-color 0%, $end-color 100%);
  background-repeat: repeat-x;
}

@mixin font-size($sizeValue: 13) {
  font-size: $sizeValue + px; //fallback for old browsers
  font-size: calc($sizeValue / 16) + rem;
}

// Font icons
@mixin fonts() {
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// @mixin margin-padding($m-direction, $m-amount: 13, $p-direction, $p-amount: 13) {
//     @if $m-direction == all {
//       margin: $m-amount + px;
//       margin: ($m-amount / 16) + rem;;
//     } @else {
//       margin-#{$m-direction}: $m-amount + px;
//       margin-#{$m-direction}: ($m-amount / 16) + rem;;
//     }
//     @if $p-direction == all {
//       padding: $p-amount + px;
//       padding: ($p-amount / 16) + rem;;
//     } @else {
//       padding-#{$p-direction}: $p-amount  + px;
//       padding-#{$p-direction}: ($p-amount / 16) + rem;;
//     }
//   }

@mixin margin-padding($m-direction, $m-amount, $p-direction, $p-amount) {
  @if $m-direction==all {
    margin: $m-amount;
    //   margin: ($m-amount / 16) + rem;;
  }

  @else {
    margin-#{$m-direction}: $m-amount;
    //   margin-#{$m-direction}: ($m-amount / 16) + rem;
  }

  @if $p-direction==all {
    padding: $p-amount;
    //  padding: ($p-amount / 16) + rem;;
  }

  @else {
    padding-#{$p-direction}: $p-amount;
    //  padding-#{$p-direction}: ($p-amount / 16) + rem;
  }
}

// Font icons with gradient color
@mixin gradient-icons($start-color, $end-color) {
  //   background: -webkit-gradient(linear, left top, left bottom, from($start-color), to($end-color));
  // -webkit-background-clip: text;
  //   background-clip: text;
  //   -webkit-text-fill-color: transparent;
  display: initial;
  color: $end-color;
  @include font-size(20);
}

@mixin line-height($heightValue: 16) {
  line-height: $heightValue + px; //fallback for old browsers
  line-height: calc($heightValue / 16) + rem;
}

@mixin rhythm($sizeValue: 13, $heightValue: 16) {
  @include font-size($sizeValue);
  @include line-height($heightValue);
}

@mixin triangle($direction, $sizeH, $sizeV, $color) {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  -moz-transform: scale(0.9999);

  @if $direction==top {
    border-width: 0 $sizeV $sizeH $sizeV;
    border-color: transparent transparent $color transparent;
  }

  @if $direction==bottom {
    border-width: $sizeH $sizeV 0 $sizeV;
    border-color: $color transparent transparent transparent;
  }

  @if $direction==left {
    border-width: $sizeV $sizeH $sizeV 0;
    border-color: transparent $color transparent transparent;
  }

  @if $direction==right {
    border-width: $sizeV 0 $sizeV $sizeH;
    border-color: transparent transparent transparent $color;
  }
}

@mixin placeholder {
  ::-webkit-input-placeholder {
    @content;
  }

  :-moz-placeholder {
    @content;
  }

  ::-moz-placeholder {
    @content;
  }

  :-ms-input-placeholder {
    @content;
  }
}

@function pxToRem($val) {
  @return calc($val/16)+rem;
}

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1440px,
);
$body-bg: #eae9e8;
$body-color: $primary-gray;
$grid-gutter-width: 1rem;
$font-family-base: "Interstate-Regular", sans-serif;
$font-family-zh-lang: "Arial";
$interstate-regular: "Interstate-Regular", sans-serif;
$interstate-bold: "Interstate-Bold", sans-serif;
$interstate-light: "Interstate-Light", sans-serif;
$interstate-extra-light: "Interstate-Extra-Light", sans-serif;
$input-btn-font-size: #{pxToRem(14)};
$input-btn-line-height: 1.143;
$input-color: $primary-gray;
$input-border-color: $primary-gray;
$input-placeholder-color: $secondary-dark-gray;
$input-focus-color: $primary-gray;
$dropdown-item-padding-y: 0.75rem;
$dropdown-item-padding-x: 1.5rem;
$navbar-padding-y: #{pxToRem(7)};
$navbar-brand-padding-y: #{pxToRem(5)};
$nav-link-padding-y: 0.5rem;
$nav-link-padding-x: 0.75rem;
$card-spacer-y: 0.75rem;
$card-spacer-x: 1rem;
$card-border-width: 0;
$card-border-radius: 0;
$card-columns-count: 4;
$card-columns-gap: 1rem;
$card-columns-margin: 1rem;
$form-check-margin-bottom: #{pxToRem(17)};
$form-check-input-gutter: #{pxToRem(18)};
$link-color: $primary-blue;
$link-hover-color: $primary-blue;
$link-hover-decoration: none;
$ui-radius: 8px;
/*Nalco defined Variables*/

$search-icon-url: url(/assets/images/search-icon.svg);
$checkmark-blue-icon-url: url(../assets/images/checkmark-blue-icon.svg);
$round-close-icon-url: url(../assets/images/round-close-icon.svg);
$round-close-hover-url: url(../assets/images/round-close-hover.svg);
$round-close-white-url: url(../assets/images/round-close-white.svg);
$round-more-icon-url: url(../assets/images/more-icon.svg);
$star-colored-icon-url: url(../assets/images/star-color-icon.svg);
$star-uncolored-icon-url: url(../assets/images/star-uncolor-icon.svg);
$favorite-toggled-icon-url: url(../assets/images/toggled-icon.svg);
$favorite-untoggled-icon-url: url(../assets/images/unToggled-icon.svg);
$checkbox-default-icon-url: url(../assets/images/checkbox-default-icon.svg);
$checkbox-pressed-icon-url: url(../assets/images/checkbox-pressed-icon.svg);
$checkbox-hover-icon-url: url(../assets/images/checkbox-hover-icon.svg);
$checkbox-disabled-icon-url: url(../assets/images/checkbox-disabled-icon.svg);
$checkbox-utilitarian-selected-url: url(../assets/images/checkbox-utilitarian-selected.svg);
$checkbox-utilitarian-purple-selected-url: url(../assets/images/checkbox-utilitarian-purple-selected.svg);
$grip-icon-url: url(../assets/images/grip-icon.svg);
$grip-black-icon-url: url(../assets/images/grip-black-icon.svg);
$scrollbar-thumb-icon-url: url(../assets/images/scrollbar-thumb-icon.svg);
$inactive-icon-url: url(../assets/images/inactive-icon.svg);
$inactive-selected-icon-url: url(../assets/images/inactive-selected-icon.svg);
$temp-suspended-icon-url: url(../assets/images/temp-suspended-icon.svg);
$temp-suspended-selected-icon-url: url(../assets/images/temp-suspended-selected-icon.svg);
$delete-exclamation-icon-url: url(../assets/images/delete-exclamation-icon.svg);
$checkbox-utilitarian-orange-selected-url: url(../assets/images/checkbox-utilitarian-orange-selected.png);
$dashed-dotted-graph-line-url: url(../assets/images/dashed-dotted-graph-line.svg);
$add-button-blue-url: url(../assets/images/add-button-blue.png);
$pill-add-hover-url: url(../assets/images/pill-add-hover.svg);
$pill-add-disabled-url: url(../assets/images/pill-add-disabled.svg);
$edit-icon-black-url: url(../assets/images/edit-icon.svg);
$checkmark-green-icon-url: url(../assets/images/checkmark-green-icon.svg);
$autocomplete-account-icon-url: url(/assets/images/nalco-building.png);
$autocomplete-site-icon-url: url(/assets/images/nalco-site.png);
$omni-logo-icon-url: url(/assets/images/omni-logo.svg);
$page-not-found: url(/assets/images/error404.svg);
$access-denied: url(/assets/images/AccessDenied.svg);
$page-access-denied: url(/assets/images/401.svg);
$condenser-url: url(/assets/images/condenser.png);
$heatExchanger-url: url(/assets/images/heat-exchanger.png);
$application-downtime: url(/assets/images/application-downtime.png);
$cassady-controller-url: url(/assets/images/cassady_icon.png);
$new-search-icon-url: url("/assets/images/filter-icons/search-icon-newui.svg");
$new-search-bar-url: url("/assets/images/global-search/search-bar-icon.svg");

/* Responsive SCSS */
$break-med-large: 1200px;
$break-med-small: 992px;
$break-sml-large: 500px;
$default-header-zindex: 1030;

/* mixin for multiline  http://hackingui.com/front-end/a-pure-css-solution-for-multiline-text-truncation/ */

@mixin multiLineEllipsis($lineHeight: 1.2em, $lineCount: 1, $bgColor: white) {
  /* As specified *          /* As specified */
  overflow: hidden;
  position: relative;
  line-height: $lineHeight;
  max-height: $lineHeight * $lineCount;
  text-align: left;
  margin-right: -1em;
  padding-right: 1em;

  &:before {
    content: "...";
    position: absolute;
    right: 0;
    bottom: 0;
  }

  &:after {
    content: "";
    position: absolute;
    right: 0;
    width: 1em;
    height: 1em;
    margin-top: 0.2em;
    background: $bgColor;
  }
}

// New Ecolab 3d Design Css //

// Colors //
$primary-color: #006bd3;
$primary-blue-color: #034e9f;
$secondary-color: #093675;
$background-button: #a5cef3;
$default-white: #ffffff;
$default-grey: #adafaf;
$default-light-gray: #efefef;
$default-dark-gray: #616365;
$default-secondary-gray: #bac7d8;
$border-light-blue: #d9eaf9;
$secondary-light-pink: #aa336a;
$secondary-light-brown: #964b00ba;
$border-light-blue-half: #9fd1fd;
$text-medium-blue: #18498f;
$cancel-text-color: #0984d4;
$background-white: #edf5fc;
$light-border: #e3e3e3;
$text-color-light: #082344;
$button-color-red: #de3835;
$button-color-green: #4fc2b0;
$button-color-yellow: #ebc42f;
$button-color-orange: #f07a22;
$button-color-blue: #7fb5e9;
$background-light-blue: #ecf5fc;
$error-red: #df3935;
$success-green: #06cf06;
$success-band-action: #663399;
$text-red: #DC1846;
$text-orange: #E1803B;
$text-yellow: #E5C550;
$text-blue: #308AE2;
$font-black: #1d1b20;
$default-header-gray: #525252;
$light-ecolab-blue: #e5f0fb;
$dark-text-blue: #044ea0;
$box-shadow-black: #00000040;
$form-elements-color: #006bd0;
$check-mark-color: #2196f3;
$box-shadow-blue : #0936751A;
$inactive-site: #0936758F;
$light-blue-background: #ECF5FC80;
$medium-blue-background : #006bd31a;
$functional-yellow : rgba(236, 196, 47, 0.35);
$scrollbar-thumb: #89c4fc;
$scrollbar-track: #e9eaeb;

// Font Family Base  //
$base-font-family: "roboto";

.multistep-modal {
  .modal-content {
    height: 90%;
    width: 42%;
    border-radius: 24px 0px 0px 24px;
    box-shadow: 0px 4px 8px 3px rgba(8, 35, 68, 0.05),
      0px 1px 12px 0px rgba(8, 35, 68, 0.08);
    right: 0;
    position: fixed;
    background-color: white;
    z-index: 999;
    //overflow-y: auto;
  }

  .modal-dialog {
    border-radius: 24px 0px 0px 24px;
  }

  .modal .modal-dialog .modal-content .modal-header {
    border-radius: 1.5rem 0.25rem 0 0;
  }
}
