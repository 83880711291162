/****************************************************/
// This files contains the asset tree component styles
/****************************************************/
@import "../../styles/variables.scss"; 
.site-search-results {
    overflow: auto;
    margin-right: 0px;
    width: 100%;
    nalco-asset-tree-gridview
    {
        width:100%;
    }
    .k-grid-content{
        padding-bottom: 70px;
    }
    .k-grid .k-grid-header{
        width: 100%;
    }
    .k-widget{
        background-color: transparent !important;
    }
    .k-grid td {
    height: 3rem;
    border-width: 0px 0 1px 1px !important;
    cursor: pointer;

}
.nomatcheserror
{
    font-size: 14px;
    padding: 0.5rem 1rem;
    color: $primary-blue;
}  

.nalco-asset-tree-gridview
{
    width:100%;
}
.k-grid-header .k-header:first-child{
    border-left-width: 0 !important;
}
.k-grid-table{
 border-collapse: collapse;
 table-layout: fixed;

}
.k-grid td{
    text-align: left;
    width:100px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    &:nth-child(1){
    width: 222px;
    border-left-width: 0 !important;
    color: $primary-blue;
    text-transform: uppercase;
    }
    &:nth-child(2){
        color: $primary-blue;
    }
    &:nth-child(6){
        width: 240px;
    }
    &:nth-child(7){
        width: 70px;
    }
    &:nth-child(8){
        padding-bottom: 25px;
        text-align: left;
        width:78px;
    }
    nalco-more-option .more-option .dropdown-content .dropdown-menu.show {
        min-width: 0;
        margin: 0 0 0 -9.125rem;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
        padding: 0.75rem 0;
        width: 9.75rem;
        top: -13px !important;
        left: -11px !important;
    }
    .d-inline-block.more-option
{
    display: block !important;
    margin-top:-8px;
}
}
.k-grid-norecords{
    td{
        &:nth-child(1){
            text-transform: none;
            }  
    }
}
 .k-grid th{
     white-space: normal;
    width:100px;
     &:nth-child(1){
        width: 222px;
 }
 &:nth-child(6){
    width: 240px;
}
    &:nth-child(7){
        width: 70px;
 }
 &:nth-child(8){
    width:78px;
}
}
.more-option {
    position: absolute;
}

.d-inline-block.more-option
{
    display: block !important;
}
 .k-grid th{
    
    font-size: 12px;
    }
    .k-grid-header-wrap{
        border-width:0 !important;
        position: static;
    }

    .k-grid-header{
        border-style:none;
        border-top: 2px solid $secondary-light-gray;
        border-bottom: 1px solid $secondary-light-gray;
         padding-right: 0px !important;
    }
    ::-webkit-scrollbar-track{
       background-color: transparent;
       border-left:none ;
    }
    .k-grid-header .k-header{
        position: static;
    } 
}
.content{
    .content-tree{
        .btn{
            p + .bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"]{
                white-space: pre-wrap;
                padding:0px !important;
              }
        }
    }
}
.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"]{
//   white-space: pre-wrap;
  padding:0px !important;
}
.card-right .ico-info-thin{
    font-size:12px;
}
.k-grid-content
{
    overflow: hidden !important;
}
.btn-continue{
    max-width: 11rem;
    @include gradient-directional($linear-gradient-starting-blue, $primary-blue, 180deg);
    margin-left: 0.75rem !important;
    border: 0 none;
    border-radius: 0.25rem;
    box-shadow: none;
    width: 100%;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
    text-transform: capitalize;
    &:hover {
      @include gradient-directional($secondary-blue, $primary-blue, 180deg);
    }
}
.time-message
{
    padding-left: 24px;
    padding-bottom: 16px;
}