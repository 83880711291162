@import "../../../../../../../styles/variables.scss";
.nalco-legionella-test-list-enterprise{
  .asset-name {
      @include rhythm(14, 16);

      span{
        &:nth-child(1){
          margin-right: 40px;
        }
      }
  }
  .k-pager-numbers .k-link, .k-pager-numbers .k-link:link {
    color: #007ac9;
  }
  .k-pager-numbers .k-link.k-state-selected, .k-pager-numbers .k-link:link.k-state-selected{
    color: #ffffff;
  }
  .k-state-selected{
    background-color:#007ac9;
  }
  .k-pager-wrap .k-state-selected:hover{
    background-color:#007ac9
  }
  .k-pager-wrap {
    .k-pager-sizes {
        position: absolute;
        right: 0;
        bottom: 11px;
    }
  }
  .first-col {
        overflow: hidden;
        text-overflow: ellipsis;
        &::before {
            content: '';
            display: block;
        }
    }

    .water-safety-grid-view{
      height: 334px;

      table{
        overflow: unset;
      }

      .k-grid-aria-root{
        overflow: scroll;
      }
    }

    
}

.water-safety-grid-view {
  .k-grid-aria-root {
    overflow-x: auto !important;
    tbody tr.k-alt{
      background-color: #fff;
    }
  }
}

@media only screen and (max-width: 768px) {
  .k-grid {
    max-height:350px;
  }
  .k-grid-aria-root {
    overflow-y: scroll !important;
  }
}

@media only screen and (max-width:550px){
  .k-pager-wrap {
    .k-pager-sizes {
        bottom: 2px;
        select{
          width: auto;
        }
    }
  }
}