@import "../../../../styles/variables.scss";

nalco-legionella-distribution-group {


  .group-container {
    font-size: 12px;
    margin-left: 8px;

    .group-header {
      font-family: Interstate-Bold;

      .group-title {
        padding: 15px 10px 14px 16px;

        &:first-child {
          border-right: 1px solid #E2E2E2;
        }
      }

      .add-group-button {
        padding: 9px 16px 9px 55px;
      }
    }
  }

  .dropdown .drop-btn.dropdown-toggle .label {
    display: none !important;
  }

  .group-body .group-title .admin-value {
    height: 35px !important;
  }

  .group-body {
    .group-title {
      padding: 16px 14px 16px 16px;

      .admin-value {
        height: 27px;
        width: 71px;
        border: 1px solid $loader-border;
        color: #616365;
        padding: 6px;
      }

      &:first-child {
        border-right: 1px solid #E2E2E2;
      }

      .admin-dropdown {
        padding: 0 10px 0 10px;
      }
    }
  }

  .validate-text {
    color: $validation-red;
  }

  .btn {
    padding: 8px !important;
  }
}

.drag-delete:not([href]):not([tabindex]),
 .drag-delete:not([href]):not([tabindex]):focus,
  .drag-delete:not([href]):not([tabindex]):hover{
  color: inherit;
  text-decoration: none;
}

.drag-edit:not([href]):not([tabindex]),
 .drag-edit:not([href]):not([tabindex]):focus,
  .drag-edit:not([href]):not([tabindex]):hover{
  color: inherit;
  text-decoration: none;
}