  /****************************************************/

  // This files contains the styles that are required for the kendo.
  /****************************************************/

  body {

      .k-grid-header,
      .k-grid-toolbar,
      .k-grouping-header,
      .k-grid-add-row,
      .k-grid-footer,
      .k-drag-clue,
      .k-grid-table {
          background-color: $primary-white;
          color: $primary-gray;
      }

      .k-grid {
          & .k-grid-header {
              & .k-sort-order {
                  display: none !important;
              }

              & .k-sort-icon {

                  & .k-svg-i-sort-asc-small,
                  & .k-svg-i-sort-desc-small {
                      color: #616365;
                  }
              }
          }

          th,
          td {
              word-break: break-word;
              text-overflow: unset;
              padding: #{pxToRem(7)} 1rem;
              vertical-align: middle;
              @include rhythm(13, 16);
              height: 3rem;
          }

          th {
              white-space: inherit;
              font-family: $interstate-bold;
              @include rhythm(12, 14);

              & .k-cell-inner {
                  & .k-link {
                      & .k-column-title {
                          white-space: normal !important;
                      }
                  }

              }
          }

          td {
              border-width: 1px 0 0 0 !important;

              a {
                  color: $primary-blue !important;

                  & .k-icon-wrapper-host {
                      .k-icon {
                          color: $primary-blue !important;
                      }
                  }

              }

              div {
                  & .k-icon {
                      color: $primary-blue !important;
                  }
              }

              .ico-delete {
                  @include rhythm(19, 19);
                  cursor: pointer;
              }
          }

          .k-grid-header {

              //padding-right: 23px!important;
              .k-header {
                  vertical-align: middle;
                  white-space: normal;

                  .k-i-sort-asc-sm,
                  .k-i-sort-desc-sm {
                      color: $primary-gray;
                  }

                  .k-i-sort-asc-sm {
                      &:before {
                          content: "\E004";
                      }
                  }

                  .k-i-sort-desc-sm {
                      &:before {
                          content: "\E006";
                      }
                  }
              }
          }

          .k-grid-container {
              .k-grid-content {
                  .k-grid-table {
                      tr {
                          .checkBox {
                              padding-top: 0;
                              max-width: 1.125rem;
                              margin: 0 auto;

                              label {
                                  width: 100%;
                              }
                          }

                          &.k-alt {
                              background: transparent;
                          }

                          &.k-state-selected {
                              background-color: transparent;

                          }

                          &.k-selected {
                              >.k-table-td {
                                  background-color: #E5F1F9;
                                  color: #333;
                              }
                          }

                          &.k-grid-edit-row {
                              td {
                                  border-top: 2px solid $inline-edit-border;
                                  border-bottom: 2px solid $inline-edit-border;

                                  &:first-child {
                                      border-left: 2px solid $inline-edit-border;
                                  }

                                  &:last-child {
                                      border-right: 2px solid $inline-edit-border;
                                  }

                                  &.k-grid-edit-cell {
                                      background: linear-gradient(180deg, rgba(244, 114, 30, 0.1) 0%, rgba(244, 114, 30, 0.1) 100%);
                                      border-left: 2px solid $inline-edit-border;
                                      border-right: 2px solid $inline-edit-border;

                                      .k-textbox {
                                          border: 0 none;
                                          background: transparent;
                                          margin: 0;
                                          padding: 0;

                                          &.ng-invalid {
                                              color: $utilitarian-red;
                                          }
                                      }
                                  }
                              }
                          }
                      }
                  }
              }
          }

          .k-plus::before {
              content: "\e11e";
          }

          .k-minus::before {
              content: "\e121";
          }

          & .k-grid-content-locked {
              border-color: rgba(0, 0, 0, 0);

              & td {
                  border-color: rgba(0, 0, 0, 0);
              }

          }

          & .k-master-row.k-table-alt-row .k-grid-content-sticky {
              background: $light-blue
          }
      }

      .k-pager-sizes {
          margin-left: auto !important;
      }

      .k-pager-numbers {
          & .k-selected {
              & .k-button-text {
                  color: $primary-white !important;
              }
          }

          & .k-button-text {
              color: $primary-blue !important;
          }

      }

      // .k-pager-numbers .k-link.k-state-selected {
      //     color: white;
      // }




      .k-block,
      .k-widget {
          ::selection {
              background-color: $primary-blue;
          }
      }

      .k-animation-container {
          .k-list {
              .k-item {
                  &.k-state-selected {
                      color: $primary-blue;
                      background: transparent;
                  }

                  &.k-state-focused {
                      box-shadow: none;
                  }
              }
          }

          & .k-list-item {
              &.k-selected {
                  background-color: #007ac9;
                  color: white;

                  & .k-list-item-text {
                      color: white !important;
                  }
              }
          }
      }

      .k-upload {
          background: transparent;
          border: 0 none;

          .k-upload-button {
              @include gradient-directional($linear-gradient-starting-blue, $primary-blue, 180deg);
              color: $primary-white;
              padding-bottom: 0.5rem;
              padding-top: 0.5rem;
              margin: 0;
              width: 13rem;
              text-transform: capitalize;
              @include rhythm(14, 16);
              border: 0 none;

              input {
                  cursor: pointer !important;
              }
          }

          .k-dropzone-hint {
              display: none;
          }
      }

      .k-datepicker,
      .k-timepicker {
          border-color: $secondary-dark-gray;

          &.ng-pristine.ng-invalid {
              border-color: rgba(0, 0, 0, .623);
          }

          & kendo-dateinput {
              margin: 0 !important;
              width: 100%;
              border-width: 0;
              box-shadow: none !important;
              flex: 1 1 auto;
          }

          & .k-svg-i-calendar {
              font: normal normal normal 18px/18px 'Nalco-additional-icons';
              color: $primary-blue;

              // &:before {
              //     content: '\E928';
              // }
          }

          .k-input-inner {
              border: none;
              //   padding: 0.375rem 1rem;
              color: $primary-gray;

              .k-input {
                  padding: 0;

                  &::placeholder {
                      color: $secondary-gray;
                  }
              }
          }

          .k-picker-wrap {
              @include rhythm(14, 16);
              border-radius: 0;
              border-color: $secondary-dark-gray;
              background-color: $primary-white;
              color: $primary-gray;

              .k-select {
                  background-color: transparent;
                  padding: 11px 4px;
                  width: 3.125rem;

                  //   .k-svg-i-calendar {
                  //       font: normal normal normal 18px/18px 'Nalco-additional-icons';
                  //       color: $primary-blue;

                  //       &:before {
                  //           content: '\E928';
                  //       }
                  //   }

                  .k-i-clock {
                      @include rhythm(18, 18);
                  }
              }
          }
      }

      //ends datepicker
      .k-combobox {
          max-height: none;

          .k-dropdown-wrap {
              border: 1px solid $secondary-gray;
              border-radius: 0;
              color: $primary-gray !important;

              .k-input {
                  padding: 0.75rem 1rem;
                  height: auto;
                  @include rhythm(14, 16);
              }

              .k-i-close {
                  padding-top: 0.625rem;
                  right: 1rem;
              }

              .k-select {
                  background-color: transparent;

                  [class^="k-i-arrow-"],
                  [class*=" k-i-arrow-"] {
                      display: none;
                  }

                  &:after {
                      position: absolute;
                      right: 1rem;
                      top: 45%;
                      border-top-color: $primary-blue;
                  }
              }
          }
      }

      //ends combobox
      .k-multiselect {
          .k-multiselect-wrap {
              border: 1px solid $secondary-dark-gray;
              border-radius: 0;

              .k-searchbar {
                  .k-input {
                      @include rhythm(14, 16);
                      padding: 0.625rem 1rem;
                      height: auto;
                  }
              }

              .k-i-close {
                  padding-top: 0.625rem;
              }
          }
      }

      //ends multiselect
      .custom-kendo-grouping {
          .k-grouping-header {
              display: none;
          }

      }

      .data-source {
          border-radius: 12px;
          color: white;
          width: 25px;
          height: 25px;
          display: inline-block;
          text-align: center;
          padding: 5px 0 0 0;
          top: 1px;
          position: relative;
          background-color: #1C9AEC;
          vertical-align: bottom;
          left: 5px;
          margin-right: 2px;
      }

      .data-source-blank {
          background-color: $secondary-gray !important;
          display: block;
          padding: inherit;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          color: $secondary-gray;
      }

      .add-entry-datepicker {
          .k-button-group .k-button.k-state-active {
              border-color: $saving-water !important;
              background: linear-gradient(180deg, $linear-gradient-starting-blue, $saving-water) !important;
          }

          .k-calendar .k-state-selected .k-link {
              background: linear-gradient(180deg, $linear-gradient-starting-blue, $saving-water) !important;

          }

          .k-state-selected {
              background-color: transparent !important;
          }

          .k-calendar .k-content .k-today {
              color: $saving-water !important;
          }

          .k-button {
              &.k-group-start {
                  &.k-active {
                      border-color: $saving-water !important;
                      background: linear-gradient(180deg, $linear-gradient-starting-blue, $saving-water) !important;
                  }
              }

              &.k-time-accept {
                  border-color: $saving-water !important;
                  background: linear-gradient(180deg, $linear-gradient-starting-blue, $saving-water) !important;
              }
          }

          .k-button.k-primary,
          .k-slider .k-draghandle {
              border-color: $saving-water !important;
              background: linear-gradient(180deg, $linear-gradient-starting-blue, $saving-water) !important;
          }

          .k-button-group .k-button:active {
              border-color: $saving-water !important;
              background: linear-gradient(180deg, $linear-gradient-starting-blue, $saving-water) !important;
          }

          .k-action-buttons .k-button {
              border-radius: 0;
              padding: 12px 16px;
              border-width: 0;
              border-color: inherit;
              color: inherit;
              background: none;
              -webkit-box-flex: 1;
              flex: 1 1 auto;
              background-color: #f5f5f5;
          }

          .k-actions,
          .k-edit-buttons,
          .k-action-buttons,
          .k-columnmenu-actions,
          .k-form-buttons {
              gap: 0px !important;
              padding: 0px 0px !important;
          }

          .k-calendar .k-calendar-view .k-today {
              background-color: $primary-white;
          }

          .k-calendar-infinite .k-calendar-header {
              margin-right: 0px !important;
          }

          .k-calendar th {
              width: 34px !important;
          }

          .k-calendar-infinite .k-calendar-view {
              padding: 0px 0px;
          }

        //   .k-time-list-wrapper {
        //       margin-top: -15px;
        //   }
      }

      .k-datepicker {
          .k-dateinput {
              & .k-input-inner {
                  color: $primary-color;
              }
          }

          .k-textbox::selection,
          .k-input::selection,
          .k-textarea::selection {
              color: $primary-white !important;
          }

          .k-input-button {
              border: none;
              background: none;
          }
      }

      // global calendar picker scrollbar position fixes
      .k-calendar .k-calendar-td {
          &.k-selected {
              & .k-link {
                  border-color: $primary-blue;
                  color: $primary-white !important;
                  background-color: $primary-blue !important;
              }

          }

          & .k-link {
              &:hover {
                  border-color: rgba(0, 0, 0, 0.08);
                  color: #424242;
                  background-color: #f0f0f0;
              }
          }
      }

      .k-calendar .k-content.k-scrollable {
          padding-left: 94px;
      }

      .k-calendar th {
          text-transform: uppercase;
          opacity: 0.6;
          width: 16% !important;
      }

      .k-calendar td.k-state-focused:not(.k-state-selected) .k-link {
          box-shadow: inset 0 0 2px 1px lightgray;
      }

      .k-calendar .k-state-selected .k-link {
          border-color: #ff6358;
          color: #ffffff;
          background-color: #ff6358;
      }

      .k-state-selected {
          background-color: #F44336;
          border-radius: 0.25rem;
      }

      .kendo-dropdownbutton-ri {
          .k-button {
              color: $primary-blue;
              font-weight: 700;
              border: none;
              font-size: 16px;
          }
      }

      .overview_active {
          .k-button {
              color: $primary-white;
              background-color: $primary-blue;
          }
      }

      .time-range-datepicker {
          .k-calendar .k-link {
              color: $secondary-color !important;
          }

          .k-calendar-header .k-title {
              color: $secondary-color !important;
          }

          .k-calendar th {
              color: rgba(9, 54, 117, 0.70)
          }

          .k-calendar .k-calendar-navigation {
              color: rgba(9, 54, 117, 0.70) !important;
              background: rgba(236, 245, 252, 0.50)
          }

          .k-calendar-navigation .k-content li {
              color: $secondary-color !important;
          }

          .k-calendar .k-state-selected .k-link {
              border-color: $primary-blue;
              color: $primary-white !important;
              background-color: $primary-blue;
          }

          .k-calendar .k-calendar-td .k-selected .k-link {
              border-color: $primary-blue;
              color: $primary-white !important;
              background-color: $primary-blue !important;
          }

          .k-calendar .k-calendar-td.k-selected .k-calendar-cell-inner,
          .k-calendar .k-calendar-td.k-selected .k-link {
              border-color: $primary-blue;
              color: $primary-white !important;
              background-color: $primary-blue !important;
          }

          .k-state-selected {
              background-color: black
          }

          .k-calendar-yearview .k-state-selected .k-link {
              background-color: $primary-blue;
          }

          .k-calendar .k-state-selected .k-link {
              background-color: $primary-blue !important;
              color: $primary-white !important;
          }

          .k-calendar .k-calendar-view .k-today {
              color: $secondary-color !important;
          }
      }

      & .k-filter-row {
          & kendo-grid-string-filter-cell.k-filtercell {
              & .k-button {
                  &:has(.k-svg-i-filter) {
                      display: none;
                  }
                  & .k-svg-i-filter-clear {
                      color:inherit;
                  }
              }
          }
      }
  }

  body .k-grid td {
      text-transform: none !important;
  }


  // Custom multiview calendar scss changes

  .k-calendar.k-calendar-range.k-calendar-infinite table {
      padding: 0;
      display: -webkit-box;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      flex-direction: row;
  }

  .k-calendar.k-calendar-range.k-calendar-infinite table>tbody {
      display: inline-block;
      vertical-align: top;
  }

  .k-calendar.k-calendar-range.k-calendar-infinite:not(.k-calendar-minimal)>.k-calendar-view {
      border-width: 0 1px;
      border-style: solid;
      border-color: inherit;
  }

  .k-calendar.k-calendar-range .k-calendar-view {
      height: auto;
      min-height: 17em;
      //   width: auto;
      white-space: nowrap;
  }

  .k-calendar.k-calendar-range.k-calendar-infinite table>tbody+tbody {
      margin-left: 32px;
  }

  .k-calendar .k-range-mid {
      background-color: #D9EAF9 !important;
  }

  .k-calendar .k-range-split-end::after {
      background-image: none;
  }

  .k-calendar .k-range-split-start::after {
      background-image: none;
  }

  // Set button color for time picker
  .k-animation-container {
    .k-child-animation-container {
        .k-timepicker-popup {
            .k-timeselector {
                .k-time-footer {
                    .k-time-accept {
                        border-color: var(--kendo-color-border, rgba(0, 0, 0, 0.08));
                        background-color: var(--kendo-color-base, #f5f5f5);
                    }
                }
            }
        }
    }
  }

  //ends body