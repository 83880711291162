$dot-size: 100px;
$dot-grow-size: 7.5px;
$dot-color: #007ac9;
$dot-step: 1.2;

$three-dots-loader-dot-size: 7.5px;
$three-dots-loader-step: 1.2;
$three-dots-loader-color: #007ac9;
$three-dots-loader-duration: 2s;
$three-dots-loader-delay: -0.16s;

.c-three-dots-loader {
  position: relative;
  display: inline-block;
  width: $three-dots-loader-dot-size;
  height: $three-dots-loader-dot-size;
  border-radius: 50%;
  margin: -$three-dots-loader-dot-size ($three-dots-loader-dot-size * $three-dots-loader-step) 0 ($three-dots-loader-dot-size + 16.5px);
  animation-fill-mode: both;
  animation: three-dots-loader-animation $three-dots-loader-duration infinite ease-in-out;
  animation-delay: $three-dots-loader-delay;
  color: $three-dots-loader-color;
  background-color: $dot-color;
  
  
  &:before,
  &:after {
    content: '';
    position: absolute;
    width: $three-dots-loader-dot-size;
    height: $three-dots-loader-dot-size;
    bottom: 0;
    animation: three-dots-loader-animation $three-dots-loader-duration infinite ease-in-out;
    border-radius: 50%;
    background-color: $dot-color;
  }
  
  &:before {
    left: -$three-dots-loader-dot-size * $three-dots-loader-step;
    animation-delay: $three-dots-loader-delay * 2;
  }
  
  &:after {
    left: $three-dots-loader-dot-size * $three-dots-loader-step;
  }
}

@keyframes three-dots-loader-animation {
  0%,
  80%,
  100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}