@import "../../../styles/variables.scss";
.dataSourceDetailsGrid{
  .card.tile {
    box-shadow: none;
  }
    .tile-content-wrapper{
      min-height: 215px;

      .state-wrapper{
        top: 0;
        position: unset;
      }
    }
  }
  .modal-content nalco-cancel-popup .modal-footer {
    padding: 0.5rem!important;
    min-width: 250px;
  }
  .datasource-grid_StaticHeight { // Static Grid Height 
    height: 400px;
  }
  .datasource-grid_AutoHeight { // Auto Grid Height
    height: auto;
  }
  .grid-over-flow { // Enable Scroll Bar
    .k-grid-content { 
    overflow-y: auto !important;
   }
  }