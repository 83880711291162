    .assetCount{
        font-size: 0.875rem;
        cursor: pointer;
        border: 0;
        background-color: transparent;
        padding-top: 10px;
        width:325px;
        @media screen and (max-width:990px){
            margin-top: 1.5rem;
        }
    }
    .required{ 
        color: #E00034;
        margin-right: 2px;
    } 
    .disabled{
        cursor: not-allowed;
    }
    .editIcon{
        width: 20px;
        height: 20px;
        color: #007AC9 !important;
    }
    .asset-container{
        display: flex;
        justify-content: flex-start;
        margin-right: 40px;
        padding-left: 0;
    }

    .asset-count-modal {
        display: flex !important;
        align-items: center;
        justify-content: center;
            .modal-dialog{
                min-width:30rem !important;
        }
    }

    .asset-modal-body{
        min-height:8rem !important;
    }
    .text-box{
        width: 150px !important;
    }

    .asset-count-color{
        color: #007AC9;
    }