@import "../../../../../styles/variables.scss";
.scheduleComp {
    .radio-items {
        font-size: 13px;
        padding: 8px 16px;
        input {
            display: none;
        }
        span {
            content: " ";
            position: relative;
            top: #{pxToRem(2)};
            margin: 0 5px 0 0;
            width: #{pxToRem(13)};
            height: #{pxToRem(13)};
            border-radius: 50%;
            border: 1px solid $primary-gray;
            display: inline-block;
        }
        [type="checkbox"]:checked + span:before {
            content: " ";
            width: #{pxToRem(7)};
            height: #{pxToRem(7)};
            border-radius: 50%;
            position: absolute;
            background-color: $primary-blue;
            top: #{pxToRem(2)};
            left: #{pxToRem(2)};
        }
    }
    kendo-timepicker .k-picker-wrap {
        border-radius: 0.3rem !important;
        background-color: #fff !important;
        height: 2.05rem;
        border-color: rgba(0, 0, 0, 0.623);
        padding: 2px 5px;
        width: 100%;
    }
    kendo-dateinput span.k-dateinput-wrap {
        &:before {
            content: none !important;
        }
        padding: 0px 5px !important;
        border-radius: 0.3rem !important;
    }
    kendo-datepicker .k-picker-wrap {
        border-radius: 0.3rem !important;
        background-color: #fff !important;
        height: 2.05rem;
        border-color: rgba(0, 0, 0, 0.623);
        padding: 2px 5px;
    }
    .card.tile {
        min-height: 13rem;
    }
    .ud-select {
        display: inline;
        text-overflow: ellipsis;
        overflow: hidden !important;
        white-space: nowrap;
    }
    .form-control {
        font-size: 12px;
        height: 2.05rem;
    }
    .required {
        color: $utilitarian-red;
    }
    .ud-left-allignment {
        margin-left: 23px;
    }
    .invalid .k-picker-wrap {
        border-color: $utilitarian-red;
    }
    option:disabled {
        background-color: $secondary-gray;
    }
    input[type="radio"] {
        appearance: none;
        display: inline-block;
        position: absolute;
        padding: 0.17rem;
        background-clip: content-box;
        border: 1px solid #616365;
        border-radius: 50%;
        height: 0.9125rem;
        width: 0.9125rem;
    }
    input[type="radio"]:checked {
        background-color: #007ac9;
    }
    .transform{
        text-transform: initial;
    } 
}
