@import "../../../styles/variables.scss";

.wrapper {
  display: inline-flex;
  width: 300px;
  margin-left: 50px;
}

.option {
  background: #fff;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px;
  border-radius: 25px;
  cursor: pointer;
  padding: 3px;
  border: 2px solid #d1cece;
  transition: all 0.3s ease;
}

.mysites {
  margin-left: 7px;
}

.myteamsite {
  margin-left: 7px;
}

.favoritesite {
  margin-left: 6px;
  margin-right: 17px;
}

.displayRadio {
  display: none;
}

#option-1:checked:checked~.option-1,
#option-2:checked:checked~.option-2 {
  border-color: #1C9AEC;
  background: #1C9AEC;
}

.wrapper .option span {
  font-size: 14px;
  color: #808080;
  text-align: centre;
  margin-right: 14px;
  margin-left: 14px;
}

#option-1:checked:checked~.option-1 span,
#option-2:checked:checked~.option-2 span {
  color: #fff;
}

.grid-widget {
  margin-left: auto;
}

.mysites_size {
  width: 118px;
}

.myteamsites_size {
  width: 132px
}

.custom-toast-container {
  width: 230px !important;
  position: relative;
  top: 60px !important;
  font-size: 0.8rem !important;
}

.disabledExport {
  cursor: not-allowed;
}

.optIn-baner {
  display: flex;
  width: 100%;
  padding: 0.5rem 1.5rem;
  align-items: center;
  gap: 24px;
  border-radius: 8px;
  border: 1px solid $border-light-blue;
  background: $primary-white;
  /* Elevations/Elevation 3 */
  box-shadow: 0px 4px 8px 3px rgba(8, 35, 68, 0.05), 0px 1px 12px 0px rgba(8, 35, 68, 0.08);
  margin-bottom: 24px;

  .optIn-baner_text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
    flex: 1 0 0;
    color: $secondary-color;
    font-family: Roboto;
    font-style: normal;
    line-height: normal;

    .optIn_header_section {
      margin: auto;

      .optIn_headertext {
        font-size: 18px;
        font-weight: 500;
      }
  
      .optIn_subtext {
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: -0.04px;
        margin-bottom: 0rem !important;
      }
    }
  }

  .optIn-baner_buttons {

    display: flex;
    justify-content: end;

    .btn {
      min-width: 3rem !important;
    }

    .primary-btn {
      height: 52px;
      padding: 8px 16px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 8px;
      background-color: $primary-color;
      color: $primary-white;
    }

    .cancel-btn {
      height: 52px;
      padding: 8px 16px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 8px;
      border: 1px solid $primary-color;
      ;
      margin-left: 16px;
      color: $primary-color;
      ;
    }
  }
  .disclaimer-dialog {
    background-color: $default-white;
    top: 0px;
    position: relative;
    width: max-content;
    padding: 0;
    right: 7rem;

    ul {
      margin-bottom: 0;
      li {
        list-style-type: disc;
      }
    }
  }
}

body:has(.nalco-asset-dashboar, .confirm-item-modal) {
  .confirm-item-modal {
    top: 85% !important;
  }

  .modal .modal-dialog {
    min-width: 40rem !important;
  }
}

.opt-in-toggle {
  display: inline-flex;
  height: 33px;
  padding: 8px 16px;
  align-items: center;
  gap: 16px;
  flex-shrink: 0;
  border-radius: 8px;
  background: $functional-yellow;

  span {
    color: $secondary-color;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  img{
    cursor: pointer;
  }
}