@import "../../../../styles/variables.scss";

.admin-button-spacing {
  margin-bottom: 7px;
  .btn {
    width: 10%;
    margin-right: 10px;
    min-height: 32px;
  }
}
.admin-button-padding {
  padding: 0px !important;
  padding-bottom: 16px !important;
}
.data-driven-kpi-spacing{
  margin-right: 0px !important;
}
.headingClass{
  white-space: normal;
  text-transform: capitalize;

  .required {
    color: $utilitarian-red;
  }
}

.actionLogGridDetails{

  .siteName{
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .redClassForNote{
    color: red;
  }

  .actionLogNote,.eroiCategory{
    text-transform: none;
  }
  .k-icon.ng-star-inserted {
    &.k-clear-value.k-i-close, &.k-i-loading{
      color: #656565;
    }
  }
  .row{
    .first-column{
      label.col-sm-4 {
        padding-left: 0px;
      }
    }
  }
  .search-user input{
    font-size: 12px!important;
  }

  .checkBoxPosition{
    top:	0.625rem;
    height: 1.2rem;
    width: 1.2rem;
  }

  .attchmentListGrid{
    height: 79px;
    overflow: auto;
    border: 1px solid;
    margin-left: 2px;
    border-radius: 5px;
    width: 96%;
    overflow-x: hidden;

    ::-webkit-scrollbar-thumb{
      background: grey;
      border-radius: 10px;
    }

    ul{
      overflow-x: hidden;
      li{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .borderClass{
    border: 1px solid $primary-gray;
  }

  .heightClass{
    height: 42px;

    input{
      height: auto;
    }
  }

  .first-col{
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .container{
    max-width: unset !important;
  }

  .divClass{
    display: grid;
    padding: 5px;

    .headingGrid{
      font-family: "Interstate-Bold", sans-serif;
    }

    .k-datepicker{
        padding: 1px;
    }
  }

  .buttonClass{
    display: flex;
    justify-content: flex-end;

    .btnDisabledClass{
      border-radius: 5px;
      font-size: 14px;
      width: 100px;

      &:disabled {
        background: $parameter-gradient;
        opacity: 0.65;
        border: 1px solid;
      }

    }

    .cancelClass{
      width: 100px;
      background-image: linear-gradient(180deg, #1C9AEC, #007ac9);
      background-repeat: repeat-x;
      font-size: 1rem;
      line-height: 16px;
      line-height: 1rem;
      border: 0 none;
      border-radius: 0.25rem;
      box-shadow: none;
      padding-bottom: 0.5rem;
      padding-top: 0.5rem;
      text-transform: capitalize;
      color: $primary-white;
    }
  }

  .assetClass{
    .dd-display-item{
      min-height: 30px;
    }
  }

  .actionTakenSection{
    .kendoWidth {
      height: 250px;

      .k-grid-content.k-virtual-content{
        overflow: auto !important;
      }
    }
  }

  body .actionTakenSection{
   & .k-datepicker {
        border-width: inherit;
        color: #656565;

      & .k-dateinput {
          padding: 0rem 1rem;
        }
      }
   }
   .k-action-buttons{
     display: none;
   }
   .k-actions-end{
    display: none;
  }
   .k-upload {

    .k-upload-button{
    width: 6rem;
    margin-bottom: 1rem;
  }

  .k-file-validation-message{
    text-transform: none;
  }
}
  .k-multiselect .k-multiselect-wrap {
    &.k-floatwrap {
      height: 60px;
      overflow: auto;
      display:flex;
      padding-right: 0;
    }
    .k-i-close {
      padding-top: 0;
    }
    .k-button {
      font-size: 12px;
    }
  }
  .k-file-name-size-wrapper{
    margin-right: 1.5rem !important;
  }

  .dateSelectionError {
    color: red;
    padding-left: 8px;
  }

  .customdatepicker {
    & .k-dateinput {
        border-radius: 5px;
      }

      & .k-input-inner {
        font-size: 12px;
        color: #656565 !important;
        padding: .375rem .5rem;
      }

    .k-picker-wrap {
      border-radius: 5px;
    }
  }
  .site-nav.transparent ul.nav-tabs {
    padding-top: 0px !important;
  }
}

.pillClass{
  background: none;
  border: none;
  padding: 0;
  width: 26px;
  position: relative;
  top: -6px;

  .pills.add-pills{
    border: 0 none;
    background-color: transparent;
    padding: 0;
    color: $primary-blue;

    &::before {
      content: '\E900';
      font: normal normal normal 2rem/2rem 'nalco-additional-icons';
    }
  }

  .minus-pills{
    color: $primary-blue !important;
    font-size: 16px;
    padding: 7px;
    border: 2px solid $primary-blue;
    &::before {
      content: "\2212";
      font-size: 23px;
      // font: normal normal normal 2rem/2rem 'nalco-additional-icons';
    }

    &:hover{
      background-color: $primary-white !important;
    }

  }
}

.disable-background-color {
  background-color: $primary-white !important;
}
.disable-text-color {
  color: #656565 !important;
}
.disable-eroi-fields {
  pointer-events:none;
}

.remove-saving-icon {
  filter: invert(20%) sepia(92%) saturate(7394%) hue-rotate(5deg) brightness(111%) contrast(125%) !important;
}

.industry-norms {
  color: $primary-blue;
  font-size: 1rem !important;
  margin: 1rem 0.5rem !important;
  display: inline-block;
  cursor: pointer;
}

table.monetization-table {
  border: 1px solid black !important;
  text-align: center;
  border-collapse: collapse!important;
  & tr {
    &:hover{
      background-color: white;
    }
  }
  & td, & th {
    border: 1px solid black !important;
    padding: 0.25rem !important;
    height: auto !important;
    text-transform: none;
    font-size: 0.8125rem !important;
  }
}

table.assumptions-table {
  border: 1px solid white;
  padding: 0.25rem !important;
  margin: 0.25rem !important;
  text-align: center;
  td, th {
    border: 1px solid white;
    padding: 0.25rem!important;
    margin: 0.25rem !important;
    text-transform: none;
  }
}

.actionValueTabDetails{
  .container {
    min-height: 20rem;
    padding: 20px 0px;
  }

  .ico-info-thin {
    padding-left: 4px;
  }

  .add-remove-eroi-button {
    color: $primary-blue;
    border: none;
    background: transparent !important;
    font-size: 13px;
    padding-left: 0;
    margin-bottom: 0.5rem;
    min-width: auto;
  }

  .eroifields {
    height: calc(1.893rem + 2px);
    background-color: $primary-white;

    &:disabled {
      opacity: 0.65;
      border: 1px solid;
      background: #dddddd;
    }

    option:disabled {
      color: $secondary-dark-gray;
    }

  }

  select.eroifields {
    padding-left: 0.3rem;
    padding-right: 0;
  }

  .admin-button-spacing {
    position: absolute;
    right: 25px;
    z-index: 10;
  }
}

.iconwater-AiGenerated {
  background: url( '/assets/images/cooling-system-AI.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 130px;
  margin-top: 5px;
}
.actionLog-CreatedBy{
  margin-top: 5px;
  padding-top: 2px;
  position: absolute;
}

.adjust{
  .col-sm-6{
  width:300px;
  }
}

.adj{
  width:120vw;
}
