@import "../../../../styles/variables.scss";
  .pdf-layout-fixed {
    table-layout: fixed;
  }
  .hide-table {
    display: none;
  }

.actionLogGrid {

  .first-col {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  
}

.navigationLink {
  .primary-label {
    color: $primary-color !important;
    font-weight: 500;
  }
  .error-label {
    color: $error-red !important;
    font-weight: 500;
  }
}

.action-log-grid-view {
  .action-log-loader {
    position: relative !important;
    margin: 8.25rem auto !important;
    top: 0;
  }
  tr.k-detail-row:hover {
    background-color: transparent;
  }
  td a.k-icon.k-plus::before, td a.k-icon.k-minus::before {
    vertical-align: -webkit-baseline-middle;
  }
  .no-capitalize-text {
      text-transform: none !important;
  }

  .first-col {
    overflow: hidden;
    text-overflow: ellipsis;

    &::before {
      content: '';
      display: block;
    }
  }

  .k-icon.k-plus,
  .k-icon.k-minus {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  td {
    text-overflow: unset !important;
    white-space: unset !important;
    position: relative;
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }

  .spacing {
    padding-left: 0.5rem;
    display: flex;
  }

  .k-grid-aria-root {
    tbody tr.k-alt {
      background-color: #fff;
    }
  }

  th {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }

  .k-grid-content {
    overflow-x: auto !important;
  }
}

.green-action-log {
  border-radius: 50%;
  width: 15px;
  height: 15px;
  background: #6fb43f;
  display: inline-block;
  margin-right: 2px;
}

.yellow-action-log {
  width: 14px;
  height: 14px;
  background: #eeaf00;
  display: inline-block;
  margin-right: 2px;
}

.grey-action-log {
  background: #949494;
  transform: rotate(45deg);
  width: 12.8px;
  height: 12.8px;
  margin-left: 2px;
  display: inline-block;
  margin-right: 2px;
}

.yellow-action-log,
.grey-action-log,
.green-action-log {
  margin-right: 0.3rem !important;
  margin-top: 0.176rem;
  float: left;
  
  &:after {
    content: '';
    height: 40%;
    width: 40%;
    display: block;
    position: relative;
    margin-left: 20%;
    margin-top: 20%;
  }
}

.td-actionicon {
  margin-right: 0.3rem !important;
  margin-top: 0.3rem;
  float: left;
}

.td-sitename {
  word-wrap: break-word;
  overflow: hidden;
  display: flex;
  padding-top: 0.3rem;
}

.td-detail {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  .bold-caption {
    font-weight: bold;
  }
}

@media screen and (max-width: 768px) {
  .grid-display {
     display: unset !important;
     overflow:scroll !important;
  }
}

.column-pdf-width{
  width: 10% !important;
}

.column-pdf-width-tvd{
  width: 12% !important;
}

.text-center-pdf{
  text-align: center;
}

.disable-site-name-link {
  color: #656565 !important;
  cursor: default;
}

.action-log-pdf-wrapper {
  margin-top: -1.25%;
  .action-log-pdf-row{
    margin: 4px 0px;
  }
}

  
.red-action-log {
  position: relative;
  width: 17.2px;
  height: 9.55px;
  background-color: $utilitarian-red;
  margin: 5.77px 0;
  transform: rotate(30deg);
  float: left !important;
  margin-right: 0.2rem;
}

.red-action-log:before,
.red-action-log:after {
  content: "";
  position: absolute;
  width: 0;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
}

.red-action-log:before {
  bottom: 100%;
  border-bottom: 5.77px solid $utilitarian-red;
}

.red-action-log:after {
  top: 100%;
  width: 0;
  border-top: 5.77px solid $utilitarian-red;
}
.Eroi-Value-Texts{
  white-space: nowrap;
  text-overflow: ellipsis !important;  
  overflow: hidden;
}

.project-grid kendo-pager-next-buttons .k-link.k-pager-nav{
  left:0 !important;
}

.iconwater-AiGenerated {
  background: url( '/assets/images/cooling-system-AI.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 112px;
}

