@import "../../../../styles/variables.scss";
div.row {
    div.donutDetails {
        border-right: 1px solid #e2e2e2;
        padding-top: 1rem;
      
    }
    div.assetDetails {
        // padding-top: 8px;
        padding-left: 8%;
        // padding-top: 20px;
        padding-bottom: 1.25rem;
        h3 {
            font-size: 0.875rem;
            margin-bottom: 4px;
            margin-left: 1rem;
            color: #616365;
            font-weight: bold;
        }
        .numberOfAssets{
            margin: 0;
            font-weight: bold;
            font-size: 0.75rem;
            position: relative;
            left: -6px;
        }
    }
   
}

.notFound {
    margin: auto;
    padding: 8px;
    top: 30px;
    position: relative;
    text-align: center;
    font-size: 14px;
}

.tileHeight {
    .card.tile {
        margin-bottom: 0.9rem;
        .tile-content-wrapper {
            min-height: 8.875rem;
            .loading {
                top: 10px;
            }
        }
    }

    @media only screen and (max-width:1200px) and (min-width:992px) {
        max-width: 100% !important ;
        flex: auto !important;
    }

    // @media only screen and (min-width: 768px)
}


.hide-left{
    .carousel-control-prev{
       display: none !important;
   }
}
.hide-right{
     .carousel-control-next{
        display: none !important;
    }
}

ngb-carousel .carousel-control-next-icon{
    margin-right: -12px;
    margin-bottom: 13px;
    margin-left: -4px;
    width: 36px;
    height: 15px;
    border-color: #094bd4;
    border-bottom: 4px solid #094bd4;
    border-left: 4px solid #094bd4;
    transform: rotate(-135deg);
    background-image: none;
}
  
  
  ngb-carousel .carousel-control-prev-icon{
    margin-left: -11px;
    margin-right: -4px;
    width: 36px;
    height: 15px;
    border-color: #094bd4;
    border-bottom: 4px solid #094bd4;
    border-left: 4px solid #094bd4;
    transform: rotate(45deg);
    background-image: none;
  }
  
  ngb-carousel .carousel-control-prev, .carousel-control-next{
    width: 0%;
  }

@media only screen and (max-width:1050px){
    .highcharts-container{
        left:-10px;
    } 
}

@media only screen and (max-width:990px){
    .highcharts-container{
        left:0;
    } 
}

.nalco-quality-assets{
    @media screen and (min-width:992px) {
        max-height: 55rem;
        overflow-y: visible;
    }
    
    [class*='iconwater-'] {
      display: inline-block;
      margin-right:2px;
      background-repeat: no-repeat;
      width:15px;
      height:15px;
      margin-top: 4px;
    }
    .iconwater-MediumRiskYellow {
        margin-right: 1px !important;
      } 
     .iconwater-LowRiskGreen {
        margin-right: 1px !important;
        margin-top: 0.25rem !important;
      }
      .iconwater-HighRiskRed {
        margin-right: 1px !important;
        margin-top: 4px !important;
      }
      .iconwater-OfflineGray {
        margin-right: 0px !important;
        margin-top: 4px !important;
      }

}

