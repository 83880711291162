.left-sub-nav {
    position: fixed;
    background-color: $primary-blue-color;
    color: $default-white;
    top: 7rem;
    border-radius: 0px 8px 8px 0px;
    transition: all 0.3s ease;
    display: none;
    width: auto;
    height: auto;

    li {
        padding: 0.5rem 2.5rem;

        a {
            color: $default-white;
        }
    }
 
}

.left-navigation-panel{
    &:hover {
        .show-sub-nav-bar{
            display: none;
        }
        .left-sub-nav {
          display: block;
        }
      }
}

.custom-tooltips {
    left:11px !important;
    opacity: 11 !important;
    .tooltip-inner {
      background-color: $default-white;
      color: $primary-blue-color;
      font-weight: bold;
      border-radius: 7px;
      min-width: 104.158px;
      min-height: 34px;
      padding: 7px 5px 6px 5px;
      box-shadow: 0px 0px 4px 1px #6498C6;         
    }
    .arrow::before {
        border-right-color:$default-white;
    }
    .arrow::after {
        content: "";
        position: absolute;
        left: 100%;
        z-index: -1;
        border: 5px solid #fff;
        transform-origin: 0 0;
        transform: rotate(45deg);
        box-shadow: -2px 2px 4px 0px #6498C6
    }
}

.nav-item {
    .tooltip {
        left:11px !important;
        opacity: 11 !important;
        .tooltip-inner {
          background-color: $default-white;
          color: $primary-blue-color;
          font-weight: bold;
          border-radius: 7px;
          min-width: 104.158px;
          min-height: 34px;
          padding: 7px 5px 6px 5px;
          box-shadow: 0px 0px 4px 1px #6498C6;         
        }
        .arrow::before {
            border-right-color:$default-white;
        }
        .arrow::after {
            content: "";
            position: absolute;
            left: 100%;
            z-index: -1;
            border: 5px solid #fff;
            transform-origin: 0 0;
            transform: rotate(45deg);
            box-shadow: -2px 2px 4px 0px #6498C6
          }
    }

  }



.cPRTL_SideNav {
    .slds-has-submenu {
        .slds-icon_container {
            &:after {
                left: 4.125rem;
            }
        }
    }
}

.icon-css {
    height: 19px;
    width: 19px;
}

.nav-bar-text {
    color: $default-white;
}

.nav-padding {
    padding-top: 9px;
}

.title-small {
    font-size: #{pxToRem(13)};
}

.img-icon-mr {
    margin-top: -9px;
    margin-left: 2px;
}

.span-mr {
    margin-top: 7px;
    margin-left: 15px;
}

.submenu-ul {
    padding: 10px 25px 25px 25px;
}

.left-sub-nav hr {
    margin: 0px 25px 0px 25px;
}

.middle-nav .settings-nav {
    top: 24rem;
}

.middle-nav .tools-nav{
    top: 18rem; 
}

.top-nav-item{
    top: 4.5rem;
    bottom: auto;
}

.bottom-nav .bottom-leftsub-nav {
    top:auto;
    bottom: 0;
}
@mixin left-nav-mobile-view{
    .left-sub-nav{
        position: initial;
        background-color: initial;
        .submenu-ul-hide{
            display: none;
        }
    }
    .title-small {
       display: none;
    }
}

.show-sub-nav-bar{
    display: block;
}

@include media-breakpoint-down(sm) {
    @include left-nav-mobile-view; 
}
@include media-breakpoint-down(md) {
    @include left-nav-mobile-view; 
}

@media (min-width: 992px) and (max-width: 1500px) {
    .middle-nav .settings-nav {
        top: 20rem;
    }
    .middle-nav .tools-nav{
        top: auto; 
        bottom: 0;
    }
    .bottom-nav .bottom-leftsub-nav {
        top: auto;
        bottom: 0;
    }
}
