@import "../../../../styles/variables.scss"; 
.asset-library h3{
  @include rhythm(16, 20);
  font-family: $interstate-bold;
}
.asset-header.search-header
{
  display: flex;
  padding: 0.1rem 1rem;
  margin: 6px 0px;
  .float-left
  {
    width: 84%;

    span{
    color: $secondary-dark-gray;
    font-family: $interstate-regular;
    @include rhythm(14);
    }
  }

  .float-right
  {
    @include rhythm(10, 20);
    cursor: pointer;
    color: $secondary-dark-gray;
    font-family: $interstate-regular;
    margin-top: 0.1rem;

    button{
      margin-left: 10px;
      color: $secondary-dark-gray;

      img {
    vertical-align: top;
    margin-top: 5px;
    height: 10px;
    width: 10px;
      }
    }
  
  }
}
.searchlabel
{
  width: 29%;
  float: right;
  text-align: right;
  margin-right: 10px;
  @include rhythm(10,15);
  font-family: $interstate-regular;
  color: $secondary-dark-gray;

  @media (min-width: 1686px) {
    margin-top: 8px;
    width: 30%;
  }
}


.search-result-content
{
  height: 100%;
  overflow-y: scroll;
  height: 100%;
  position: relative;
  overflow-y: auto;
  height: 43rem;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
}

.asset-content {
  .asset-content-title {
    .btn-system{
      background-image: linear-gradient( 180deg, $system-legend, $system-gradient-legend );
      margin-right: 0;
      margin-left: -0.18rem;
      text-align: left;
    }
  }
}