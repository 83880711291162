@import "../../../../styles/variables.scss";

.fi-date.nalco-date-range-dropdown{
    .dropdown{
        min-width: 13.5rem;
        min-height: 2.78rem;
        // border-top-left-radius: 0px;
        // border-bottom-left-radius: 0px;
        // border-left: 0px;
        &.show{
            border-bottom-right-radius: 0rem;
            
        }
        .drop-btn {
         
            &.dropdown-toggle {
                .selected-text {
                    color: $primary-blue;
                    position: relative;
                    left: #{pxToRem(32)};
                    overflow: unset;
                    text-overflow: unset;
                    @include font-size(14);
                    font-family: $font-family-base;
                    display: table;
                    top: -5px;

                }
            
                .icon-calendar-icon{
                    &:before{
                        top: 9px;
                        @include margin-padding(right, #{pxToRem(10)} , all , null);
                    }
                }
                .label{
                    position: relative;
                    left: #{pxToRem(32)};
                    @include font-size(8);
                    line-height: #{pxToRem(2)};
                    top: -3px;
                    display: inline-block;
                }
            }
        }

        .dropdown-menu{
                &.show{
                    min-width: 100.8% !important;
                    margin-top: 10px !important;
                }
            button{
                &.dropdown-item{
                    @include font-size(14);
                }
            }
        }
    } 

    
        .k-datepicker{
          min-width: 100%;
          width: 100%;
          height: 2.78rem;
          border-radius: #{pxToRem(2)};
          margin-bottom: 0px ;
          .k-picker-wrap.k-state-default{
            border: 1px solid $secondary-gray;
            border-radius: #{pxToRem(2)};
          }
          .k-input{
            @include margin-padding(top,  #{pxToRem(10)}, all , null);
          }
          .selected-text{
            color: $primary-blue;
            top: 0px; 
            padding-top: 0.25rem;
          }
          .label{
            display: block !important;
            top: 0px;
            line-height: normal !important;
            padding-top: 0.25rem;
          }
        }
        .start-label{
          color: $primary-gray;
          @include font-size(9);
          left: #{pxToRem(16)};
          display: block;
          z-index: 1;
          position: absolute;
          text-transform: uppercase;
          font-family: $font-family-base;
          color: $primary-gray;
          margin-top: 0.375rem;
          @include margin-padding(bottom,  #{pxToRem(0)}, all , null);
          width: #{pxToRem(100)};
          width: 7rem;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        .k-dateinput-wrap{
          position: relative;
          background: transparent;
          .k-input{
      
            color: $primary-blue;
            &::placeholder{
              @include font-size(14);
              margin-top: #{pxToRem(6)};
              color: $primary-blue !important;
            }
            &::-moz-placeholder {
              @include font-size(14);
              margin-top: #{pxToRem(3)};
              color: $primary-blue !important;
              opacity: 1;
            }
          }
        }

        .k-animation-container-shown{
          z-index: 1002;
        }
   
      
    .page-datepicker {
        .k-picker-wrap.k-state-default {
          border-color: $secondary-gray !important;
        }
        .start-label {
          color: $primary-gray;
          @include font-size(9);
          left: #{pxToRem(16)};
          display: block;
          z-index: 1;
          position: absolute;
          text-transform: uppercase;
          @include margin-padding(top,  #{pxToRem(6)}, all , null);
        }
        kendo-datepicker {
          .k-dateinput-wrap {
            .k-input {
              @include margin-padding(top,  #{pxToRem(4)}, all , null);
            }
          }
          .k-icon.k-i-calendar {
            color: $secondary-gray;
          }
        }
      }
}

.error-msg{
	position: absolute;
	top: 7rem;
	color: red;
	@media screen  and (max-width: 991px) {
		position: initial;
	}
}

.inline-block-display-moz{
  display: inline-block;
}

@media screen and (max-width:992px) {
  .visit-by-site-dropdown{
      flex: 0 0 100%;
      max-width: 100%;
      padding-right: 0px;
      padding-left: 8px;
  }

  .date-section {
    padding-right: 0px;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .date-field {
    padding: 10px 0;
  }

  .date-field-span {
    width: 100%;
  }
}

@media screen and (min-width:992px) and (max-width:1280px) {
  .visit-by-site-dropdown{
      flex: 0 0 50%;
      max-width: 50%;
  }

  .date-section {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media screen and (min-width:1281px) and (max-width:1600px) {
  .visit-by-site-dropdown{
      flex: 0 0 41.66%;
      max-width: 41.66%;
  }

  .date-section {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media screen and (min-width:1601px) {
  .visit-by-site-dropdown{
      flex: 0 0 33.33%;
      max-width: 33.33%;
  }
}

.pd-0 {
  padding-left: 0;
  padding-right: 0;
}