/****************************************************/

// This files contains the scss imports that are required for the SSD PDF Table Alignments

/****************************************************/
@import "variables.scss";

.ssd-pdf-table-alignment {
  th {
      text-align:left;
      font-size: $font12;
    }
    td {
      font-size: $font12;
      color: $primary-gray;
    }
}
//Text adjustment in donut 
.fi-donut-small-size {
  font-size: $font22 !important;
}
.fi-donut-medium-size {
  font-size: $font14 !important;
}
.fi-donut-large-size {
  font-size: $font11 !important;
}
  