/****************************************************/

// This files contains the styles that are required for the breadcrumb.

/****************************************************/

@mixin breadcrumb-items{
  text-shadow: 0px 5px 16.667px rgba(106, 106, 106, 0.15);
  font-family: $base-font-family;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 1.167px;
}

@mixin contents{
  padding-top: 6px;
  margin-left: 9px;
  margin-right: 9px;
  color: $semi-transparent-blue;
  font-size: 16px;
}
.breadcrumb {
    float: left;
    background: transparent;
    margin-bottom: 0;
    padding-bottom: #{pxToRem(10)};
    padding-top: #{pxToRem(10)};
    @include media-breakpoint-down(sm){
      min-height: 98px;
      width:100%;
    }
    @media only screen and (min-width: 960px) and (max-width:1070px) {
      width:90%;
    }
  
    .breadcrumb-item {
      a{
        color: $semi-transparent-blue;
        @include breadcrumb-items;
      }
      @include rhythm(16, 20);
      @include media-breakpoint-down(sm) {
        @include rhythm(12, 20);
      }
      &+.breadcrumb-item {
        &:before {
          content: '>';
          color: $semi-transparent-blue;
          font-size: 16px;
        }
      }
    }

    .breadcrumb-dots {
      a{
        color: $semi-transparent-blue;
        @include breadcrumb-items;
      }
      @include rhythm(16, 20);
      @include media-breakpoint-down(sm) {
        @include rhythm(12, 20);
      }
      &:before{
          content: '>';
          @include contents;
      }
      &+.breadcrumb-item {
        &:before{
          content: '>';
          @include contents;
        }
      }
    }
    
    .no-print {
      color: $secondary-color;
      @include breadcrumb-items;
    }
    
  @include media-breakpoint-down(sm) {
    padding-left: #{pxToRem(0)};
    margin-left: .5rem;
  }
}
  
  @media print{
    .no-print{
      display: none;
    }
  }
  