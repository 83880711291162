@import "styles/variables.scss";
.nalco-assets-details-view{

    .no-header{
        .card.tile{
            .card-header{
                display: none;
            }
        }
    }

    .no-header{
    .tile-content-wrapper{
        table.limitalltable{
            .card.tile{
                .card-header{
                    display: block !important;
                }
            }
        }
    }
    }

    .borderBottom{
        border-bottom: 1px solid #e2e2e2;
        min-height: 9rem;

        .tile-content-wrapper{
            .state-wrapper.loading{
                position: unset;
                height: 60vh;
            }
        }
    }
    .searchBox{
        width: 40%;
        // margin: auto;
        //margin-bottom: 3.75rem;
        // #keyword{
        //     border-radius: 0;
        @media screen and (max-width:990px){
            padding-top: 3rem;
        }

        .ico-nav-search.search-icon{
            @media screen and (max-width:540px){
                left: -2rem !important;
            }
        }
        // }
    }

    .searchBoxNew{
        width: 100%;
        // margin: auto;
        //margin-bottom: 3.75rem;
        // #keyword{
        //     border-radius: 0;
        @media screen and (max-width:990px){
            padding-top: 3rem;
        }

        .ico-nav-search.search-icon{
            @media screen and (max-width:540px){
                left: -2rem !important;
            }
        }
        // }
    }
    .accountDetails{
        font-size: 0.875rem;
        color: #007AC9;
        cursor: pointer;
        text-decoration: underline;
        border: 0;
        background-color: transparent;

        @media screen and (max-width:990px){
            margin-top: 1.5rem;
        }
    }
    .ledendHyperlink{
        font-size: 0.875rem;
        color: #007AC9;
        cursor: pointer;
        text-decoration: underline;
        margin-bottom: 0;
        padding-top: 5px;
        position: absolute;
        top: 0;
        right: 0;
        border: 0;
        background-color: transparent;

        @media screen and (max-width:990px){
            margin-top: 1.5rem;
        }
    }

    ngb-accordion{
        .card{
            .card-header{
                a {
                    padding-left: 25px;

                    &:after {
                        position: absolute;
                        left: 0;
                        border-top-color: #007ac9;
                        top: 40%;
                        transform: rotate(0deg);
                    }
                }

                &.active {
                    a:after {
                        transform: rotate(180deg);
                    }
                }
            
                .headingAccordian{
                    color: #616365;
                    // font-size: 1.25rem;
                    // padding: 0.3rem;

                    .categorytitle {
                        color: #007ac9;
                    }

                    .imageClass{
                        height: 1.5rem;
                    }
                    .emptyImage{
                        height: 1rem;
                        width: 2rem;
                        display: inline-flex;
                    }

                    // .icon-dataSource{
                    //     background: url('../../.. /assets/images/dataSourcesIcon.svg');
                    //     }
                }
                .align-content{
                    align-self: flex-end;
                    .sideheadingAccordian{
                        color: #616365;
                        font-size: 0.8rem;
                    }
                }
                .mappedUnmappedIcon {
                    position: absolute;
                    right: 50px;
                    top: 14px;
                }
            }
        }
    } 

    .headerRow{
        width: 100%;
        .col-1{
            max-width: 11%;
        }
    }
}
.sppedDialIcon{
    height: 4.0625rem;
    margin-right: 3.125rem;
}

.table-row-header{
    display: table-row;
    font-weight: bold;
    
    .table-cell{
        display: table-cell;
    }
}
.table-row-body{
    display: table-row;

    .table-cell{
        display: table-cell;
    }
}
.accordiontabletd{
 padding: 0 !important;   
}

.nalco-assets-details-view {
    .no-header{
        .tile-content-wrapper{
            .accordiontable{
                .card{
                    .card-header{
                        background: #ededed !important;
                        display: block !important;
                    }
                }
                .btn-link{
                    color:#656565;
                    font-size: 0.875rem;
                    font-weight: 700;
                }
            }
          .limitalltable  {
            td,th{
                border: 1px solid rgba(0, 0, 0, 0.08);
                padding: 0;
                border-width: 1px 1px 0 1px !important;
                
            }
            td{
                vertical-align: top;
            }
            }
        }
    }
    .assets-details-header{
        display: flex;
        justify-content: flex-start;
        margin: 0px;
        padding-left: 0;
    }

    .assets-details-headerNew{
        display: block;
        justify-content: flex-start;
        margin: 0px;
        padding-left: 0;
    }
}

.system-card
{
   .card{
       margin-bottom: 5px !important;
   }

   #system {
    .card-body{
        padding: 0rem 0rem !important;
    }
   }
}

.asset-page-site-name{
    margin: 0;
    float: left;
}

.ah-to-ssd {
    font-size: 0.875rem;
    width: 100%;
    padding: 0;
    color: #007AC9;
    cursor: pointer;
    text-decoration: underline;
    border: 0;
    background-color: transparent;

    @media screen and (max-width:990px) {
        margin-top: 1.5rem;
    }
}

.header-second-row {
    display: flex;
    flex-direction: row;
}



