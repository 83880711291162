@import "../../../../styles/variables.scss";
.controller-Management {
  @include margin-padding(all, null, all, #{pxToRem(20)} #{pxToRem(10)});
.toggleButton{
  left: -20px;
  float: right;
  top: -16px;
  position: relative;
  display: inline-flex;
  p {
    font-size: 14px;
  }
  .ico-toggle{
    &:after {
      color: $primary-gray;
    }
  }
  .favorite-toggle input:checked + .ico-toggle:after {
    color: $primary-blue !important;
  }
}
  
custom-date-range-dropdown .customDateRange .selected-text {
      padding: 3px 0 0 0!important;    
}

.controller-repo-container {
      padding-left: 0px;
      margin-top: -1.5rem;

      ul .nav-item .nav-link {
        font-size: 14px!important;
        padding: 0 0 0.325rem 0;
      }

      ul .nav-item .nav-link.active
      {
        border-bottom: 0.15rem solid #616365;
      }
    }

    .loader-position{
      margin: -0.5% auto !important;
      position: absolute !important;
      width: 50px !important;
      height: 50px !important; 
    }
    .margine-unp{
      margin-left: 8.5rem !important;
    }
    .margine-p{
      margin-left: 0.5rem !important;
    }

    .filter-accordion {
      .select-Dvheading {
          @include margin-padding(all, null, all, #{pxToRem(16)});
          @include font-size(16);
          background-color: $primary-white;
          position: relative;
          cursor: pointer;
  
       }
      .selectFilterContent {
          nalco-sites-filter .filterButton {
              padding: 0.50rem 0.875rem 0.50rem 0.875rem;
              margin-bottom: 0;
              
              &.activeButton {
                  background-color: $primary-blue;
                  border: 1px solid $primary-blue;
                }
          }
          .serial-search {
            margin-top:1.2rem;
          }
          input[type="search"]{
            border-radius: 0.3rem;
            line-height: 2rem;
            border: 1px solid #bdbec0;
          }
          input[type="search"].secondary-search{
            width:100%;
          }
          input[type="search"] + .ico-nav-search::before {
            top: 0.9rem !important;
          }
    }
      }

   .set-margine{
    margin-top: 23px;
   }
      .ico-nav-file-icon {
        &:after {
          content: "\e922"; 
          font-size: smaller;
       }
      }
      .label-align{
        display: inline-block;
        margin-bottom: .5rem;
      }
      .required{
        color: #E00034;
     }

      form{
        .col-lg ,
        .col-lg-3{
          @include margin-padding(all, null , left , #{pxToRem(0)});
        }
        .col-lg-3{
          position: relative;
          .k-autocomplete .k-input{
          padding-left: 38px !important; 
          min-height: 2.78rem;
        }
        
        kendo-autocomplete.admin-search {
          width: 100%;
          min-width: 100%;
          border: 1px solid $secondary-gray;
          border-radius: #{pxToRem(2)};
          height: 2.78rem;
          margin-bottom: 0 ;
        }
    
        div.admin-icon {
          width: #{pxToRem(15)};
          height: #{pxToRem(19)};
          position: absolute;
          top: 2.20rem;
          z-index: 10;
          left: #{pxToRem(16)};
          background-image: $autocomplete-account-icon-url;
          background-position-x: no-repeat;
        }
        .dd-display-item{
          height: 2.78rem;
          padding-left: 36px;
          padding-top: 0.7rem;
          border-radius: 0.3rem !important;
        }
      }
    }
    
    .range-content {
        border-top: 1px solid $secondary-gray;
        background-color: $primary-white;
    }

    .showMe {
        left : 0px;
    }

    @media screen and (max-width: $break-sml-large) {
      .units {
        flex-wrap: wrap;
      }
     
    }
    hr{
        border-top: 1px solid $light-gray;
    }  
    .disabled {
        pointer-events: none;
        opacity: 0.4;
        background-color: transparent; 
    }    
  }
