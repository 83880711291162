/****************************************************/

// This files contains the favorite component styles

/****************************************************/

.favorite {
  position: relative;
  margin-right: 0.75rem;
  label {
    transition: all .5s ease;
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    color: $secondary-light-gray;
    font: normal normal normal 15px/15px 'Nalco-additional-icons';
    &:before {
      content: '\e918';
    }
  }
  input {
    visibility: hidden;
    &:checked+label {
      color: $primary-blue;
      &:before {
        content: '\e917';
      }
    }
  }
}
