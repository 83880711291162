@import "../../../../../styles/variables.scss";
.inventoryCalibrateComponent{
    .form-control{
        font-size: 12px;
        overflow: hidden;
        min-height: 2rem;
    }
    .unitDimension{
        text-transform: none;
        margin-top: 1.9rem;
    }
    // .calibrate-date {
    //   .k-input {
    //     pointer-events: none;
    //   }
    // }
    kendo-dateinput span.k-dateinput-wrap {
        &:before {
            content: none !important;
        }
        padding: 0px 5px !important;
        border-radius: 0.3rem !important;
    }
    kendo-datepicker .k-picker-wrap {
        border-radius: 0.3rem !important;
        background-color: #fff !important;
        height: 2.05rem;
        border-color: rgba(0, 0, 0, 0.623);
        padding: 2px 5px;
    }
    kendo-timepicker .k-picker-wrap {
        border-radius: 0.3rem !important;
        background-color: #fff !important;
        height: 2.05rem;
        border-color: rgba(0, 0, 0, 0.623);
        padding: 2px 5px;
        width: 100%;
    }
    .invalid .k-picker-wrap {
        border-color: $utilitarian-red;
    }
    .calibrate-btn{
        width:10%;
        margin-top: 1.5rem;
        margin-left: 3.1rem;
    }
    .probe-label{
        font-size: 12px;
        font-weight: bold;
    }
    .probe-value,.no-reading{
        margin-top: 0.38rem;
    }

    .validation-message{
        color: $utilitarian-red;
        margin-top: 1rem;
        text-transform: none;
    }
    .no-reading-msg{
        color: $utilitarian-red;
        text-transform: none;
        margin-top: 0.38rem;
    }
}
