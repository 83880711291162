
kendo-grid.types-unit{
  tr.k-grid-edit-row {
    input.k-textbox{
      text-align: right !important;
    }
  } 
  tr th.k-header{
      border-width: 1px 1px 0px 0px !important;
    }
    tr td{
      input.k-textbox{
          border: 1px solid #616365;
      }
    }
    tr td{
      vertical-align: middle;
      border-width: 1px 1px 0px 0px !important;
    }
    kendo-dropdownlist.wfi-unit-type {
      .k-state-default,
      .k-input,
      .k-select {
          &:before {
      content: none !important;
      } 
    }
  }
}
