@import "../../../../styles/variables.scss";
.select-filter {
    .filter-accordion{
      .select-Dvheading {
        @include margin-padding(all, null, all, #{pxToRem(16)});
        @include font-size(16);
        background-color: $primary-white;
        position: relative;
        cursor: pointer;

        p.upArrow {
          &:after {
            display: inline-block;
            vertical-align: #{pxToRem(4)};
            content: "";
            border-top: 0.3em solid;
            border-right: 0.3em solid transparent;
            border-bottom: 0;
            border-left: 0.3em solid transparent;
            position: absolute;
            right: #{pxToRem(16)};
            border-top-color: $primary-blue;
            @include font-size(20);
            top: 45%;
            border-width: 8px 7px 0 7px;
          }
        }
        p.downArrow {
          &:after {
            transform: scaleY(-1);
            display: inline-block;
            vertical-align: #{pxToRem(4)};
            content: "";
            border-top: 0.3em solid;
            border-right: 0.3em solid transparent;
            border-bottom: 0;
            border-left: 0.3em solid transparent;
            position: absolute;
            right: #{pxToRem(16)};
            border-top-color: $primary-blue;
            @include font-size(20);
            top: 45%;
            border-width: 8px 7px 0 7px;
          }
        }
        .toggleButton{
          left: -20px;
          float: right;
          top: -16px;
          position: relative;
          display: inline-flex;
          p {
            font-size: 14px;;
          }
          .ico-toggle{
            &:after {
              color: $primary-gray;
            }
          }
          .favorite-toggle input:checked + .ico-toggle:after {
            color: $primary-blue !important;
          }
        }
      }
      .range-content {
        border-top: 1px solid $secondary-gray;
        background-color: $primary-white;
      }
      .disabled {
        .customDateRange{
          pointer-events: none;
          opacity: 0.4;
          background-color: transparent;
        }    
      }
//         .card .card-header[role="tab"]{
//           @include margin-padding(all, null , all , #{pxToRem(16)});
//           a{
//             &:after{
//               top: 5px !important;
//             }
//           }
//           h4{
//             margin: 0;
//             @include rhythm(20 ,18);
//           }
//         }
//         .card .card-body[role="tabpanel"]{
//           background-color: transparent;
//           min-height: auto;
//           @include margin-padding(all, null , all , #{pxToRem(0)});
//         }
//       }
      form{
        .col-lg , .col-lg-3{
          @include margin-padding(all, null , left , #{pxToRem(0)});
        }
        .col-lg-3{
         position: relative;

         kendo-autocomplete.admin-search {
          width: 100%;
          min-width: 100%;
          border: 1px solid $secondary-gray;
          border-radius: #{pxToRem(2)};
          height: 2.78rem;
          margin-bottom: 0 ;

          & .k-searchbar{
            padding-left: 38px !important; 
            min-height: 2.78rem;
           }
        }
   
      div.admin-icon {
        width: #{pxToRem(15)};
        height: #{pxToRem(19)};
        position: absolute;
        top: 0.69rem;
        z-index: 10;
        left: #{pxToRem(16)};
        background-image: $autocomplete-account-icon-url;
        background-position-x: no-repeat;
      }
      .dd-display-item{
        height: 2.78rem;
        padding-left: 36px;
        padding-top: 0.7rem;
      }
        }
        div.custom{
          .admin-search , nalco-dropdown .dropdown , .k-datepicker , 
          .data-visual.nalco-date-range-dropdown , kendo-autocomplete.general-currency{
            min-width: 100%;
            width: 100%;
            height: 2.78rem;
            border-radius: #{pxToRem(2)};
            margin-bottom: 0px ;
            .k-picker-wrap.k-state-default{
              border: 1px solid $secondary-gray;
              border-radius: #{pxToRem(2)};
            }
            .k-input{
              @include margin-padding(top,  #{pxToRem(10)}, all , null);
            }
            .selected-text{
              color: $primary-blue;
              top: 0px; 
            }
            .label{
              display: block !important;
              top: 0px;
              line-height: normal !important;
            }
          }
          .start-label{
            color: $primary-gray;
            @include font-size(9);
            left: #{pxToRem(16)};
            display: block;
            z-index: 1;
            position: absolute;
            text-transform: uppercase;
            font-family: $font-family-base;
            color: $primary-gray;
            margin-top: 0.375rem;
            @include margin-padding(bottom,  #{pxToRem(0)}, all , null);
            width: #{pxToRem(100)};
            width: 7rem;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
          .k-dateinput{
            position: relative;
            background: transparent;
            padding: 1.2em 0 0 0 !important;
            .k-input-inner{
              padding: 0.375rem 1rem;
              color: $primary-blue;
              &::placeholder{
                @include font-size(14);
                margin-top: #{pxToRem(6)};
                color: $primary-blue !important;
              }
              &::-moz-placeholder {
                @include font-size(14);
                margin-top: #{pxToRem(3)};
                color: $primary-blue !important;
                opacity: 1;
              }
            }
          }
     
        }
  
        .page-datepicker {
          .k-picker-wrap.k-state-default {
            border-color: $secondary-gray !important;
          }
          .start-label {
            color: $primary-gray;
            @include font-size(9);
            left: #{pxToRem(16)};
            display: block;
            z-index: 1;
            position: absolute;
            text-transform: uppercase;
            @include margin-padding(top,  #{pxToRem(6)}, all , null);
          }
          kendo-datepicker {
            .k-dateinput {
                @include margin-padding(top,  #{pxToRem(4)}, all , null);
            }
            .k-icon.k-i-calendar {
              color: $secondary-gray;
            }
          }
        }
        .units{
          display: flex;
        }
        @media screen and (max-width: $break-sml-large) {
          .units {
            flex-wrap: wrap;
          }
          .custom {
            padding-left: 0.5rem !important;
            padding-right: 0.5rem !important;
          }
        }
        hr{
            border-top: 1px solid $light-gray;
        }
      }
      .selectParameter{
        h4{
          @include font-size(20);
          margin-bottom: 0px;
        }
        [class^="icon-"]{           
          display: inline-block;
          width: #{pxToRem(17)};
          height: #{pxToRem(17)};
          margin-right:5px;
          background-repeat: no-repeat;
        
        }
      
      
      
        .row.selectParamContent{
          .required {
            color: $utilitarian-red;
          }
          nalco-dropdown{
            .dropdown{
              margin-bottom: 0px;
              border-radius: 0.125rem;
              height: 2.78rem;
            }
          }
        }
      }
      .accfooter{
        button{
          width: #{pxToRem(160)};
          background-repeat: repeat-x;
          text-align: center !important;
          &:after{
            display: none;
          }
        }

        .k-button{
          background: $primary-blue;
          color: white;
          width: 2.1rem;
          border-radius: 1rem;
          padding: 0.4rem 0.5rem;
        }
     }
     .modal-footer{
      >:last-child{
        margin-left: 8px;
        justify-content: flex-end;
      }
  }
//   .setting {
//     margin-top: 16px;
//     margin-bottom: 16px;
//     padding-left: 8px;
//     padding-right: 8px;
//     .card.card-body{
//       border: 1px solid #dee2e6;
//       padding-top: 0px;
//       min-height: 242px;
//     }
//     .row {
//       padding-top: 16px;
//     }
//   }
    
  }}