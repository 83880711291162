@import "../../../../styles/variables.scss";
.siteListViewWidget {
    .card-header {
        div {
            display: initial;
          }
        .ico-info-thin{
            margin-left: 10px;
        }
        .tooltip{
            font-size: 11px;
            padding: 10px;
            padding-top: 0px;
            .tooltip-inner{
                display: block !important;
            }
        }
        .right-aligned {
            display: none;
        }
        .card-title {
            font-size: 1rem;
        }
    }

.waterquality-listview {
    tbody tr.k-alt {
        background-color: #fff;
    }
    tbody tr td {
        position: relative;
        border-width: 1px 0px 0px 0px;
    }

    thead tr th.k-header {
        border-width: 0px 0px 1px 0px;
    }

    .first-col {
        overflow: hidden;
        text-overflow: ellipsis;
    }

    ::-webkit-scrollbar-thumb {
        background: #BBBCBE !important;
      }

      ::-webkit-scrollbar {
        border: 0px !important;
        border-radius: 0.75rem !important;
        background-clip: content-box !important;
        min-height: 8px !important;
        max-height: 8px !important;
        max-width: 8px !important;
      }
}

.waterquality-listview {
    .k-grid-container{
        .k-grid-content-locked{
            overflow: unset;
            height: auto !important;
        }
    }
    .k-grid-header {
        .k-header>.k-link {
            padding-right: 12px !important;
        }
        .k-header {
            padding-right: 12px;
        }
        .k-header>.k-link>.k-icon.k-i-sort-asc-sm {
            margin-left: calc( 0px - 1px);
        }
        .k-header>.k-link>.k-icon.k-i-sort-desc-sm {
            margin-left: calc( 0px - 1px);
        }
        .k-i-sort-desc-sm::before,
        .k-i-sort-asc-sm::before {
            color: #cccccc;
        }
        a.k-link:focus .k-i-sort-desc-sm::before,
        a.k-link:focus .k-i-sort-asc-sm::before {
            color: #4e4f50;
        }
    }
    .k-pager-wrap {
        .k-pager-sizes {
            position: absolute;
            right: 0;
        }
    }
    .k-sort-order {
        display: none;
    }
    .k-grid-aria-root {
        overflow-x: auto !important;
        &::-webkit-scrollbar-thumb {
         //   box-shadow: 0px 0px 4px black;
        }
    }
}

.quality-tabs {
    padding-left: 9px;
    padding-right: 16px;
    .site-nav {
        border: 1px solid #e2e2e2;
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
        padding-right: 0;
        padding-left: 0;
        .nav-tabs {
            .nav-item {
                .nav-link {
                    padding: 0 0 0.813rem 0;
                }
            }
        }
    }
    .k-header span.k-icon {
        &::before {
            color: #616365
        }
    }
    .k-header span.k-icon {
        &::before {
            color: #616365
        }
    }
    .k-header span:not(.k-icon) {
        &::before {
            content: "\E006";
            color: #cccccc;
            vertical-align: baseline;
            box-sizing: border-box;
            width: 1em;
            height: 1em;
            font-size: 16px;
            font-family: 'WebComponentsIcons';
            font-style: normal;
            font-variant: normal;
            font-weight: normal;
            line-height: 1;
            text-transform: none;
            text-decoration: none;
            display: inline-block;
        }
    }
}

kendo-grid {
    .k-grid-container {
        .k-grid-content {
            overflow: auto !important;
        }
    }
    .iconPosition {
        position: relative;
        top: 4px;
        display: inline;
    }
}

@media only screen and (max-width:540px){
    .k-pager-wrap {
        .k-pager-sizes {
            width: 41%;
            font-size: 10px;
            position: relative !important;
}
}}
.defaultMsg {
    margin-top: 20px;
    span {
        display: flex;
        justify-content: center;
        color: $primary-gray;
    }
}

.hide-table{
    display: none;
}

.site-list-pdf-avoid-break {
    pageX-breakX-inside: avoid;
}


}

.fixed-table-layout{
  table-layout: fixed;
}
