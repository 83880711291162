@import "../../../styles/variables.scss";

.AH-limit-modal {
  .nalco-limits-configuration {
    min-width: 50rem;
  }
}

.disbaleGreyArea {
  height: 55px;
}

.header-frozen {
  thead th {
    top: 0;
    position: sticky;
    z-index: 1;
    background-color: #fff;
  }
}

.nalco-limits-configuration {
  .modal-body {
    min-height: 27rem;
    overflow: auto;

    .subHeading {
      text-align: left;
      font-weight: lighter;
      font-size: 0.75rem;

      .ico-info-thin {
        font-size: 1rem;
      }

      .wqCheckbox {
        vertical-align: middle;
        margin-left: 20px;
      }
    }

    .k-i-collapse.k-icon {
      margin-left: 0;
    }

    .k-i-expand.k-icon {
      margin-left: 0;
    }

    kendo-grid-list {
      .k-grid-content {
        overflow: auto !important;
      }
    }
  }

  .modal-header {
    border-bottom: none;
    padding-top: 1.25rem;

    .modal-title {
      padding-top: 0.5rem;
    }
  }

  .modal-content {
    display: inline-block;

    .filter-wrapper,
    .keyword-wrapper {
      display: flex;
      justify-content: center;
    }

    .filter-wrapper {
      min-height: 100%;
      flex-flow: column wrap;
      position: relative;
      float: left;
    }

    .keyword-wrapper {
      width: 100%;
      position: relative;
      max-height: 32px;
      margin-top: 3px;
      margin-bottom: 5px;

      .search-icon {
        position: absolute;
        top: 8px;
        left: 1rem;
        font-size: 1rem;
        line-height: 1rem;
        color: #616365;
      }
    }

    #keyword {
      border: 1px solid #ccc;
      color: #616365;
      width: 100%;
      outline: none;
      transition: border 0.5s ease-in-out;
      border-radius: 2rem;
      padding-right: 1rem;
      padding-left: 40px;
      min-width: 230px;
      font-size: 14px;
      height: 32px;
    }

    .laMoreOptions {
      right: 2rem;
      top: 0.3rem;
      position: absolute;
    }
  }

  .whole-cell {
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    height: 1rem;
    font-size: 0.875rem;
  }

  .whole-cell-rest {
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    height: 1rem;
    font-size: 0.875rem;
    text-align: center;
  }

  .whole-cell-indent {
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    height: 1rem;
    margin-left: 1rem;
    font-size: 0.75rem;
  }

  .whole-cell-indent-rest {
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    height: 1rem;
    text-align: center;
    font-size: 0.75rem;
  }

  .favorite-toggle {
    width: 6.5rem;

    .ico-toggle {
      left: 0.05rem;
      @include rhythm(45, 17);

      &:after {
        @include rhythm(20, 10);
        right: 0.15rem;
      }
    }

    input {
      &:checked+.ico-toggle {
        &:after {
          @include rhythm(20, 12);
          right: 1.45rem;
        }
      }
    }
  }

  .ico-toggle:after {
    @include rhythm(20, 10);
  }

  div.search-icon {
    width: 15px;
    height: 15px;
    position: relative;
    top: 23px;
    z-index: 10;
    left: 10px;
    background-image: $search-icon-url;
  }

  ::-webkit-scrollbar-thumb {
    background: #bbbcbe !important;
  }

  ::-webkit-scrollbar {
    border: 0px !important;
    border-radius: 0.75rem !important;
    background-clip: content-box !important;
    min-height: 8px !important;
    max-height: 8px !important;
    max-width: 8px !important;
  }

  .limit-search {
    border-radius: 1em;
  }

  // .limit-dropdown {
  //   top: 15px;
  // }
  .limit-toggle {
    top: -39px;
  }

  .first-col {
    overflow: hidden;
    text-overflow: ellipsis;
    border-bottom: inherit;
    padding: 1rem;

    .control-range-value {
      margin-left: 1rem;
      font-weight: bold;
    }
  }

  .edit-col {
    padding: 0.5rem;
  }

  .display-first-col {
    display: flex;
    padding: 0.5rem;
  }

  .close {
    position: absolute;
    top: 0.25rem;
    right: 2%;
    font-size: 1.4rem !important;
  }

  .limit-Grid {
    table.k-grid-table tbody tr td {
      border-width: 0px 1px 1px 1px;
      position: relative;
      // padding: 0.4375rem 0.5rem;
      padding: 0;
      text-transform: none;
    }

    thead tr th.k-header {
      border-width: 0px 1px 0px 1px;
      padding: 0;
      text-align: center;
    }

    .k-grid-header-wrap {
      border-width: 1px 0px 0px 1px;
    }

    .k-grid-aria-root .k-grid-header {
      margin: 0px 8px 0px 0px;
      border-right: 1px;
      border-right-color: rgba(0, 0, 0, 0.08);
    }
  }

  .error-col {
    background: #ff00008f;
    color: black;
  }

  // .audit-modal .modal-dialog, .audit-modal .modal-content{
  //   min-width: 70rem!important;
  // }
  // .switch {
  //   position: absolute;
  //   width: 50px;
  //   height: 27px;
  //   right: 0px;
  //   bottom: #{pxToRem(-13)};
  //   margin-right: 20%;
  //   input {
  //     opacity: 0;
  //     &:checked + .slider {
  //       background-color: #28a745;
  //       &:before {
  //         -webkit-transform: translateX(26px);
  //         -ms-transform: translateX(26px);
  //         transform: translateX(26px);
  //       }
  //     }
  //   }
  //   .slider {
  //     position: absolute;
  //     cursor: pointer;
  //     top: 0;
  //     left: 0;
  //     right: 0;
  //     bottom: 0;
  //     background-color: #ccc;
  //     -webkit-transition: 0.4s;
  //     border-radius: 34px;
  //     transition: 0.4s;
  //     &:before {
  //       position: absolute;
  //       content: "";
  //       height: 23px;
  //       width: 22px;
  //       left: 1px;
  //       bottom: 2px;
  //       background-color: white;
  //       border-radius: 50%;
  //       -webkit-transition: 0.4s;
  //       transition: 0.4s;
  //     }
  //   }
  // }

  kendo-dropdownlist {
    width: 100%;
  }

  kendo-numerictextbox {
    width: calc(100% - 45px);
    float: left;
    min-width: 45px;

    input {
      padding-right: 0;
    }
  }

  .full-width {
    kendo-numerictextbox {
      width: 100%;

      .k-input {
        height: 1.9rem;
        text-align: center;
      }

      border: 1px solid #616365;
    }
  }

  // Added for measurement's LED calendar tooltip
  table {
    tr td {
      .led-datepicker {
        .tooltip.bs-tooltip-bottom {
          top: 0 !important;
        }
      }
    }
  }

  .led-datepicker {
    overflow: unset;

    .k-datepicker {
      width: 80%;
      margin-right: 0.20rem;

      .k-picker-wrap {
        height: 2.1rem;
        border: 1px solid #616365;

        .k-select {
          width: 2rem;

          .k-i-calendar {
            color: unset;
          }
        }
      }

      .k-dateinput-wrap {
        padding: 0.2rem 0.3rem;

        input:disabled {
          border: none;
        }
      }

      .k-i-calendar {
        color: unset;
      }

      &.invalid-calendar>.k-picker-wrap {
        border: 1px solid #f31700 !important;
      }

      &.full-width {
        width: 100%;
        margin-right: 0;
      }
    }
  }

  .k-numerictextbox {
    width: 50px;
    font-size: 14px;

    .k-numeric-wrap {
      color: #656565;
      background-color: #fff;
      border-color: rgba(0, 0, 0, 0.08);
    }
  }


  .plusminusBox {
    background-color: #ececec;
    border-radius: 2px;
    float: left;
    padding: 7px;
    font-size: 19px;
    width: 21px;
    height: 28px;
  }

  .percentageBox {
    padding: 6px;
    font-size: 12px;
  }

  .Selected td .k-numerictextbox {
    border: none !important;
  }

  .Highlight {
    border: 1px solid #f31700 !important;
  }

  .acc-title-container {
    .k-icon.calculator_icon {
      font-size: 1.25rem;
      margin-left: 45px;
      margin-top: -3px;
    }

    .k-icon.calculator_icon,
    .k-icon.k-i-expand,
    .k-icon.k-i-collapse {
      color: $primary-blue;
    }

    .msr-name-container {
      max-width: 24%;
      min-width: 12%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .msr-name-container-inventoryCurrVol {
      width: 60%;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .tooltip {
      top: 65px !important;
      left: 30px !important;
    }

  }

  .ico-info-thin {
    font-size: 0.8rem !important;
  }

  .label-size {
    font-size: 12px !important;
  }
}


.no-header table .accordion-table .card.tile .card-header {
  display: block !important;
}

.uom-indicator-dd {
  .k-list .uom-indicator-dd-item {
    white-space: nowrap;
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.nalco-limits-configuration {

  .limitalltable {
    .first-col {
      border: none;
    }

    .IndicatorType {
      width: 100%;
      border: 1px solid;
      height: 34px;
      text-transform: none;

      .k-dropdown-wrap {
        background-color: #fff;
      }

      .k-input {
        padding: 0.375rem 0.25rem;
        white-space: nowrap;
        display: block;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .k-select {
        width: auto;
      }
    }
  }

  .limits-more-option {
    .dropdown .dropdown-menu.show {
      top: 0px !important;
    }
  }

}

.limits-reconfiguration {

  ngb-accordion {
    .card {
      .card-header {
        button {
          width: 99%
        }
      }
    }
  }

  .date-tooltip-icon {
    .ico-info-thin {
      margin-left: 8.25rem;
      position: absolute;
      top: 1rem;
    }
  }

  .full-date-width {
    position: absolute;
    width: 8rem !important;
  }

  .measurement-name {
    position: relative;
    right: 2.5rem;
  }

  .tooltip-info {
    transform: translate(687px, 1px) !important;
  }

  .limits-title {
    td {
      border: 0 !important;
    }
  }

  .limits-header {
    td {
      border: 0 !important;
    }

    .k-numerictextbox {
      border: 1px solid $primary-black;
    }
  }

  .treatment-limit-undo {
    float: right;
    position: absolute;
    left: 15rem;
    top: 1rem;
  }

  .hide-perf-limits {
    display: none;
  }
}

nalco-manage-asset-measurements {
  .modal-content {
    background-color: $card-background-blue;
  }
}

.wq-enabled-text {
  color: #007ac9;
  font-weight: bold;
  margin-left: 3px;
  position: relative;
  top: 0.5rem;
}

kendo-numerictextbox {

  .k-state-disabled,
  .k-widget[disabled] {
    input {
      background-color: #ddd !important;
    }
  }
}

kendo-dropdownlist {

  .k-state-disabled,
  .k-widget[disabled] {
    background-color: #ddd !important;
  }

}

.disableUOMLimitsRow {
  kendo-datepicker {

    .k-state-disabled,
    .k-widget[disabled] {
      .k-dateinput-wrap {
        background-color: #ddd !important;

      }

      .k-select {
        background-color: #ddd !important;
      }
    }

  }
}


.disableUOMLimitsRow {
  background-color: #ededed !important;
}

.k-calendar-view {
  // width: auto;
  .k-calendar td {
      width: calc(25% - 1px);
  text-align: center;
}
}

.invalid-calendar{
  border: 1px solid #f31700 !important;
}