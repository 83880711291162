@import "../../../../../styles/variables.scss"; 

.delete-content{
    word-wrap: break-word;
}
.stay-btn{
    margin-right: 0rem;
    margin-left: 2.25rem;
}

.allData {
    display: block;
    position: relative;
    padding-left: 18px;
    cursor: pointer;
    font-size: 0.8125rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  >input{
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
}


.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    border: 1px solid $primary-gray;
    &:after{
        content: "";
        position: absolute;
        display: none;
        top: 3px;
        left: 3px;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background: $primary-blue;
    }
}

/* When the radio button is checked, add a blue background */
.allData input:checked ~ .checkmark {
    background-color: $primary-white;
    border: 1px solid $primary-gray;
}

.allData input:checked ~ .checkmark:after {
    display: block;
}

.delete-measurement-modal .modal-dialog{
    top: 33%;
}