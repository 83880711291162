@import "../../../../styles/variables.scss";

.global-error-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  background-color: $background-light-blue;
  text-align: center;

  .error-code {
    font-weight: bold;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    font-family: "Roboto";
    font-size: 400px;
    font-style: normal;
    font-weight: 1000;
    line-height: normal;
    letter-spacing: 20px;
    background: linear-gradient(180deg, rgba(186, 217, 242, 0.60) 0%, rgba(255, 255, 255, 0.60) 134.89%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .error-main-msg {
    font-size: 1.5rem;
    margin: 1rem 0;
    color: #093675;
    font-family: Roboto;
    font-size: 45px;
    font-style: normal;
    font-weight: 600;
    margin-top: -8rem;
  }

  .details-msg {
    margin-bottom: 1.5rem;
    color: rgba(9, 54, 117, 0.75);
    text-align: center;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    margin-top: 2rem;
  }
  .cursor-pointer{
    cursor: pointer;
  }
  .top-margine-msg{
    margin-top: -1rem;
  }
  .action-buttons .btn {
    margin: 0.5rem;
  }

  .shared-btn.secondary {
    background-color: $background-light-blue;
  }
}

@media only screen and (max-width: 768px) {
  .global-error-page {
    position: relative;
    text-align: initial;

    .action-buttons {
      gap: 20px;
    }

    .details-msg {
      margin-top: -1.5rem;
    }

    .error-main-msg {
      font-size: 22px;
      padding: 4rem 1rem 1rem 0.5rem;
      text-align: center;
      position: relative;
    }

    .error-code {
      font-size: 160px;
      text-align: center;
    }
  }
}