@import "../../../../styles/variables.scss";

.nalco-asset-inventory-fields{
    .inventoryQuestionColumn{
        display: flex;
        flex-flow: column wrap;
        .row.mb-2{
          height: 2.689rem;
        }
        .inputFieldCollection{
            display: flex;
            .inputFeild{
                margin-right: 1rem;
                border: 1px solid #616365;
                border-radius: 0.25rem;
                transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            .inputFeildNoMargin{
                margin-right: 0rem;
                border: 1px solid #616365;
                border-radius: 0.25rem;
                transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            .wd-60{
                width:60%;
            }
            .wd-40{
                width:40%;
            }
            .wd-100{
                width: 100%;
            }            
            .nalcoProductDropdown{
                .dd-container.select-dropdown 
                {
                    .dd-control-container .dd-display-item{
                        padding: 0.35rem 1rem;
                        border: 1px solid transparent;
                        border-radius: 0.25rem;
                        color: #616365;
                        font-size: 12px;
                    }
                    .ah-asset-specific{
                        bottom:auto !important;
                    }
                    .dd-list-container{
                        height: 10rem;
                        .list-items .dd-select-list{
                            white-space: normal;
                            padding:0.6rem;
                        }
                        .list-items .loader{
                            width: 25px;
                            height: 25px;
                            border: 5px solid #007AC9;
                            border-top: 5px solid #FFF;
                        }
                    }
                }                
            }
        }
    }
    .inventoryType{
        height: 1.71rem;
    }
    .box-height{
        height: 1.8rem;
    }
    .inventoryFieldSecondRow{
        .row.mb-rem{
            margin-bottom: 0.5rem;
        }
        .tankIcon{
            height: 15.0625rem;
            width: 14rem;
            margin-top: -12rem;
        }
        .tankIconName{
            color:#007ac9;
        }
        .valueDimension{
            display: inline-flex;
        }
        .fieldValue{
            margin-left: -1.0rem;
            width: 97%!important;
        }
        .fieldValueWithGal{
            margin-left: -1.256rem;
        }
        .fieldValueWithUnit{
            margin-left: -1.3rem;
        }
        .nalcoSelectDropdown{
            min-height: 1.625rem;
            .dd-container.select-dropdown{
                .dd-control-container .dd-display-item{
                    padding: 0.35rem 1rem;
                    border: 1px solid #616365;
                    border-radius: 0.25rem;
                    color: #616365;
                    font-size: 12px;
                }
                &.disable{
                    opacity:1;
                    .dd-control-container .dd-display-item{
                        background-color: #dddddd;
                        color: #616365 !important;
                    }                   
                }
                &:after {
                    border:transparent;
                }
                .dd-list-container{
                    height: 10rem;
                    bottom: 70px;
                    .search-box{
                        border: 1px solid #BBBCBE;
                        border-top: 1px solid #BBBCBE;
                        border-bottom: none;
                    }
                    .list-items .dd-items-container{
                        height: 8.7rem;
                        border-top: 1px solid #BBBCBE;
                    }
                }
            }
        }      
    }
    .form-control:disabled, .disabledField{
        background: #dddddd;
    }
    .display-flex{
        display: inline-flex;
    }
    .unitGal{
        text-transform: none;
        margin-top: 0.4rem;
        margin-left: -0.8rem;;
    }
    .colValueUnit{
        display: inline-flex;
        margin-left: 1rem;
    }
    .col-unit-value{
        display: inline-flex;
        margin-left: 1.3rem;
    }
    .col-unit-valueName{
        display: inline-flex;
        .unitDimension{
            word-break: initial;
        }
    }
    .unitDimension{
        text-transform: none;
        margin-left: 0.2rem;
        margin-top: 0.4rem;
    }
    .labelHeading{
        white-space: nowrap;
        text-transform: capitalize;
    }
    input{
        height: 2rem;
    } 
    .rightDimensions{
        .dd-container.select-dropdown{
            width: 100%;
        }
    }
    .showNote span{
        color: red;
        font-weight: bold;  
    }

    .longHeading{
        width: 90%;
        white-space: break-spaces !important;
    }
    .longHeadingAsteric{
        bottom: 15px;
        position: inherit;
    }

    .productState_dropdown{
        appearance: none;
    }

    i {
        width: 2px;
        position: relative;
        right: 30px;
        font-size: 16px;
        line-height: 40px;
        z-index: 1;
        color: $primary-blue;
        bottom: 5px;
      }
}
