@import "../../../../../styles/variables.scss";

.waterqualitymap {
    div.map-container-inner {
        div.MicrosoftMap {
            width: 100% !important;
            position: relative !important;
            min-height: 100VH;
            height: 100% !important;
            /* using important to override plugin's inline styles*/

            .letterbox {
                display: none !important;
            }

            @media only screen and (max-width:550px) {
                .ms-composite {
                    top: -27px !important;

                    #tileCanvasId {
                        height: 618px !important;
                    }
                }
            }

        }
    }
}

.infoBox {
    top: 0;
    left: 0;
}

.waterqualitymap {

    div.MicrosoftMap {
        height: 43.98rem !important;
    }

    .carousel-control-prev-icon,
    .carousel-control-next-icon {
        width: 20px;
        height: 20px;
        border-color: #007ac9;
        border-bottom: 3px solid #007ac9;
        border-left: 3px solid #007ac9;
        transform: rotate(-135deg);
        background-image: none;
    }

    .carousel-control-prev-icon {
        transform: rotate(45deg);
    }

    .carousel-control-prev-hide,
    .carousel-control-next-hide {
        display: none;
    }

    .tooltip .tooltip-inner {
        max-width: 100% !important;
    }

    .custom-infobox {

        .bs-tooltip-bottom,
        .bs-tooltip-auto[x-placement^="bottom"] {
            left: 16px !important;
            width: auto;
            right: 17px !important;
            margin-left: 0 !important;
            margin-right: 0 !important;
        }

    }
}

.custom-infobox {
    background: $primary-white;
    box-shadow: 2px 2px 15px 0px #707070;
    width: 375px;
    position: relative;
    top: 58px;
    left: 0;
    z-index: 1000;

    @media screen and (max-width: 1114px) {
        top: 63px;
    }

    @media screen and (max-width: 991px) {
        top: 100px;
    }

    @media screen and (max-width: 617px) {
        top: 142px;
    }

    @media screen and (max-width: 380px) {
        top: 192px;
    }

    @media screen and (max-width: 338px) {
        top: 232px;
    }

    @media screen and (max-width:550px) {
        width: 330px;

        .infobox-inner {
            white-space: normal !important;
        }
    }

    &::after {
        top: 100%;
        left: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(136, 183, 213, 0);
        border-top-color: $primary-white;
        border-width: 10px;
        margin-left: -10px;
    }

    .infobox-inner {
        padding: 15px 18px;
        @include font-size(16);
        white-space: nowrap;
        overflow: hidden;
        border-bottom: 1px solid #c3c3c3;
        margin: 0;
    }

    h2 {
        @include font-size(32);
        margin-top: 5px;
        margin-bottom: 2px;
        text-transform: capitalize;
    }

    .compliance-infobox {
        @include font-size(14);
        margin-bottom: 12px;
    }

    .infobox-middle {
        text-align: left;
        border-bottom: 1px solid #c3c3c3;
        padding: 0 11px;
        margin-left: 0px;
        position: relative;
        padding-top: 8px !important;
        min-height: 146px;
        height: auto;

        .active {
            position: absolute;
            top: 0;
            width: 100% !important;
            left: 0;
            right: 0;
            text-align: center;

            h4 {
                @include font-size(28); // margin-top: 8px;
            }

            p {
                @include font-size(14);
                color: #616365;
            }
        }

        .position-relative {
            h4 {
                font-family: Interstate-Regular;
                @include font-size(28);
                text-transform: capitalize;
                margin-bottom: 0;
                margin: 0;
                position: absolute;
                top: 50%;
                left: 50%;
                margin-right: -50%;
                transform: translate(-50%, -50%);
            }

            span {
                padding-right: 2px;
            }
        }

        .kpi-item {
            border-bottom: 1px solid #e2e2e2;
            padding-top: 7px;
            padding-bottom: 6px;
            position: relative;

            &:first-child {
                padding-top: 0;
            }

            &:last-child {
                border-bottom: none;
            }

            span:nth-child(1) {
                position: absolute;
                top: -4px;
                left: 0;
            }

            span:nth-child(2) {
                position: absolute;
                top: 0;
                left: 24px;
            }
        }

        .headIng {
            font-weight: bold;
            padding-left: 0 !important;
        }

        p {
            @include font-size(12); //   margin-top: -8px;
            //   margin-bottom: 10px;
            line-height: 1.2;
            margin-bottom: 0;
            padding-left: 0; //   &:nth-child(2){
            //     //   padding-top: 2px;
            //   }
            //   &:nth-child(3){
            //     // padding-top: 2px;
            // }
            // &:nth-child(4){
            //     // padding-top: 2px;
            // }
        }

        .assetType {
            width: 60% !important;
            float: left;
            width: 118px;
            float: left;

        }

        .perforMance {
            float: left;
            margin: 0px;
            padding: 0px;

        }
    }

    .infobox-link {
        @include font-size(16);
        padding: 15px;
    }

    .actionLogdetails {
        border-bottom: 1px solid #c3c3c3;

        .card.tile {
            margin-bottom: 0;

            .siteTile {
                padding: 6px;

                .nameClass {
                    font-size: 14px;
                }
            }
        }
    }

    .textErrorMsg {
        display: block;
        text-align: center;
        position: relative;
        top: 45px;
        left: 97px;
    }
}