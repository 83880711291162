﻿/****************************************************/

// This files contains the dropdown component styles
/****************************************************/

.dropdown {
    background-color: $primary-white;
    padding: 0.5rem 1rem;
    border: 1px solid $secondary-gray;
    border-radius: $border-radius;
    min-width: #{pxToRem(208)};
    margin-bottom: #{pxToRem(-4)};
    &.disabled {
        pointer-events: none;
        background-color: $secondary-light-gray !important;
        .drop-btn{
            &.dropdown-toggle {
                &:after {
                    border-top-color: gray;
                }
            }
        }
    }
    .dropdown-item:disabled {
        background-color: #e3e0e0;
        padding-right: 0;
        width: 100%;
        max-width: 100% !important;
    }
    .dropdown .dropdown-menu.show .dropdown-item:disabled:hover,
    .dropdown .dropdown-menu.show .dropdown-item:disabled:active,
    .dropdown .dropdown-menu.show .dropdown-item:disabled:focus {
        background-color: #e3e0e0 !important;
        padding-right: 0;
        width: 100%;
        max-width: 100% !important;
    }
    &.align-to-right {
        .dropdown-menu {
            right: 0;
            left: auto !important;
        }
    }
    .drop-btn{
        &.btn-outline-primary:not([disabled]):not(.disabled):active {
            color: $primary-blue;
        }
        &.dropdown-toggle {
            color: $primary-gray;
            font-family: inherit;
            @include rhythm(14, 16);
            margin-top: -1px;
            padding: 0;
            border: 0 none;
            text-align: left;
            width: 100%;
            max-height: #{pxToRem(25)};
            text-transform:none;
            img {
                float: left;
                margin-right: 0.75rem;
                margin-top: 0.25rem;
            }
            .label {
                font-family: inherit;;
                @include rhythm(9, 10);
                color: $primary-gray;
                display: inline-block;
                margin: 0;
                text-transform: uppercase;
            }
            .selected-text {
                width: #{pxToRem(136)};
                text-overflow: ellipsis !important;
                white-space: nowrap;
                display: block;
                overflow: hidden !important;
                .hide-on-selection {
                    display: none;
                }
                &.noLabel {
                    padding-left: 0.45em;
                }

            }
            &:after {
                position: absolute;
                right: 1rem;
                top: 45%;
                border-top-color: $primary-blue;
            }
            &:hover,
            &:focus {
                background-color: transparent;
                border: 0 none;
                box-shadow: none;
            }
        }
    }
    .dropdown-menu {
        -moz-transition: display 5s ease-in;
        -webkit-transition: display 5s ease-in;
        transition: display 5s ease-in;
        border-color: $secondary-gray;
        max-height: 27rem;
        overflow-y: auto;
        &::-webkit-scrollbar-track {
            border: 0;
        }
        &.show {
            border-radius: 0 0 $border-radius $border-radius;
            min-width: 100.35%;
           // margin-left: #{pxToRem(-16.8)};
            margin-top: 11px;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
            top: 32px !important;
            .dropdown-item {
                font-family: $font-family-base;
                @include rhythm(14, 16);
                color: $primary-gray;
                white-space: normal;
                max-width: 96%;
                padding-bottom: 11px;
                padding-top: 11px;
                display: flex;
                word-break: unset;
                &:hover,
                &.selected {
                    color: $primary-blue;
                }
                &.selected {
                    background-color: transparent;
                    position: relative;
                    &:after {
                        font: normal normal normal 10px/8px 'Nalco-additional-icons';
                        content: '\e92c';
                        position: absolute;
                        right: calc(100% - 93%);
                        top: 40%;
                    }
                }
                &:hover,
                &:active,
                &:focus {
                    background-color: transparent;
                    outline: none;
                    cursor: pointer;
                }
                &.disabled {
                    &:hover,
                    &:focus {
                        background-color: #e3e0e0;
                        color: $primary-gray;
                        padding-right: 0;
                    }
                }
            }
        }
    }
    &.show {
        .dropdown-toggle {
            border-radius: $border-radius $border-radius 0 0; 
            background-color: #fff;
            border-color: inherit;
            &:after {
                transform: scaleY(-1);
            }
        }
    }
    &.border-gray {
        border-color: $secondary-gray;
    }
    &.border-dark-gray {
        border-color: $secondary-dark-gray;
    }
    &.after-gray {
        .dropdown-toggle {
            &:after {
                color: $secondary-dark-gray;
            }
        }
    }
    &.after-blue {
        .dropdown-toggle {
            &:after {
                color: $primary-blue;
            }
        }
    }
    &.txt-blue {
        .dropdown-toggle {
            color: $primary-blue;
        }
    }
    &.txt-gray {
        .dropdown-toggle {
            color: $primary-gray;
        }
    }
    &.ws-admin {
        color: #007ac9;
        background-color: #fff;
    }
    .tooltip{
        z-index: 0;
        }

}

//EVOC dropdown CSS
.nalco-customer-division-filter {
    .dropdown-menu {
        min-width: 100.5% !important;
        margin-left: -3px !important;
    }
    .dropdown {
        width: 100% !important;
        .dropdown-toggle {
            .label {
                padding-left: 14px;
            }
        }
    }
    .dropdown-item {
        padding: 0.75rem 1rem;
    }
    .selected-text {
        padding-left: 14px;
    }
}

// EVOC dropdown css ends

// calender icon
[class*='icon-calendar-']:before {
    display: inline-block;
    font-family: 'quality calendar';
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin-right: .5em;
}

.icon-calendar-icon {

  &:before{
    content: '\0041';
    color:  $primary-blue;
    font-size: 1.5rem;
    position: absolute;
    top:12px;
  }

    &.disabled {
      &:before{
        content: '\0041';
        color: $primary-gray;
        font-size: 1.5rem;
        position: absolute;
        top:12px;
      }
    }
}

.icon-division-icon {
    &:before{
      content: '';
      background: url('/assets/images/division-filter.png');
      color:  $primary-blue;
      font-size: 1.5rem;
      position: absolute;
      height: 24px;
      width: 24px;
      background-size: contain;
      background-repeat: no-repeat;
      top: 0.7rem;
    }
}

//My Territory
.filter-icon {
    background-image: url('/assets/images/filter_alt.png');
    background-repeat: no-repeat;
    height: 24px;
    width: 24px;
    position: absolute;
    top: 135px;
    left:12px
  }
.arrow-carret-down-icon {
    &:before{
      content: '';
      background: url('/assets/images/arrow-caret-down-icon.jpg');
      color:  $primary-blue;
      position: absolute;
      height: 24px;
      width: 24px;
      background-size: contain;
      background-repeat: no-repeat;
      top: 10px;
      left: 160px;
    }
}

nalco-date-range-dropdown .dropdown .selected-text {
    color: $primary-blue;
    &.disabled {
      color: $primary-gray !important;
    }

}
nalco-date-range-dropdown .dropdown .drop-btn{
        &.dropdown-toggle {
            margin-top: 4px;
        }
}
nalco-geo-sales-region-filter .dropdown .drop-btn.dropdown-toggle .selected-text{
    width:100% !important;
}
.water-flow-usage-widget-container .dropdown .drop-btn.dropdown-toggle .selected-text {
    width: 95% !important;
  }
  .water-flow-usage-widget-container .dropdown .drop-btn.dropdown-toggle {
    width: 95% !important;
}

.view-favourite-modal, .share-favourite-modal, .annotation-popup-modal {
    .dropdown-menu.show {
        margin-top: -20px!important;
        left: -16px!important
    }
}