/****************************************************/

// This files contains the scss imports that are required for the pills component

/****************************************************/
@import "../../../../styles/variables.scss";
.pills-container {
  border-bottom: 1px solid $secondary-light-gray;
  padding: 0.375rem 1rem;
}
.xyz{
  .pills-container{
    padding:  0.375rem 0rem !important;
  }
}

.pills {
  @include rhythm(12, 16);
  float:left;
  max-height: 2rem;
  border: 1px solid $secondary-dark-gray;
  border-radius: 1rem;
  margin: 0.25rem 0.7rem 0.25rem 0;
  text-align: left;
  width: auto;
  padding: #{pxToRem(6)} 1rem #{pxToRem(6)} 0.5rem;
  vertical-align: middle;
  white-space: nowrap;
  background-color: $primary-white;
  cursor: pointer;
  .ico-exit-in-circle {
    @include rhythm(18, 18);
  }
  &:hover {
    background-color: $secondary-dark-gray !important;
    color:$primary-white;
  }
  &.all-app {
    max-height: 1.96rem;
    padding: #{pxToRem(6)} 1rem #{pxToRem(23)};
    background-color: transparent;
    &.active {
      padding-bottom: 0.5rem;
      padding-top: 0.5rem;
      margin-right:#{pxToRem(7)};
    }
  }
  &.active {
    color: $primary-white;
    background-color: $primary-gray !important;
    .ico-exit-in-circle {
      color: $primary-white;
      background-color: $primary-gray;
    }
  }
  &.add-pills {
    border: 0 none;
    background-color: transparent;
    padding: 0;
    color: $primary-blue;
    &:before {
      content: '\E900';
      font: normal normal normal 2rem/2rem 'nalco-additional-icons';
    }
    &:hover {
      color: $secondary-blue !important;
      background-color: $primary-white !important;
    }
    &:focus {
      color: $primary-blue;
      box-shadow: 0 1px 2px 0 rgba(97, 99, 101, 0.5);
    }
    &.disabled,
    &:disabled {
      color: $secondary-light-gray;
    }
  }
  .pills-text {
    display: inline-block;
    vertical-align: middle;
    margin-left: 0.25rem;
  }
  .line {
    width: 2rem;
    display: inline-block;
    border-bottom: 2px solid;
    vertical-align: middle;
    margin-left: 1rem;
    &.dashed-0 {
      border-bottom-style: dashed;
    }
    &.dashed-1 {
      border-bottom-style: dotted;
    }
    &.dashed-1 {
      border-bottom-style: dotted;
    }
    &.dashed-dotted {
      border: 0 none;
      // background-image: $dashed-dotted-graph-line-url;
      height: #{pxToRem(2)};
    }
  }
  &:last-child{
    &:hover{
      background-color: $primary-white;
    }
  }
}
.ico-exit-round-edge{
  color:#616365
}
.modal .modal-dialog .modal-content *::-webkit-scrollbar-track{
  background-color: white;
  border: 0px;
}
.modal-dialog {
  // min-width: 29rem !important;
  .modal-content {
    background-color: $primary-white !important;
    border:0px;
    // width: 476px;
    .add-asset-to-comp-modal {
      .modal-header {
        padding: 1.5rem 1rem 1.25rem 1rem;
        .close {
          margin-left: unset;
        }
      }
      .modal-body {
        padding: 0;
        overflow-y: hidden;
        max-height: none;
        max-height: 25rem;
        overflow-y: auto;
        min-height: 12rem;
        overflow:hidden;
        ul{
          @include font-size(14);
          color: $primary-gray;
        }
        .pills-bar {
          .pills {
            margin-right: 1rem;
            border: 0 none;
            padding: 0;
            margin-bottom: 0.5rem;
            .ico-exit-in-circle {
              margin-right: 0.2rem;
            }
            a {
              @include rhythm(12, 16);
            }
          }
        }
        .category-filter {
          padding: 0.5rem 1rem;
          background-color: $primary-white;
          border-bottom: 1px solid $secondary-light-gray;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .dropdown {
            max-width: 13rem;
            margin-bottom: 0;
            padding: 0.5rem 1rem;
          }
        }
        .assets-list {
          padding: 0.5rem 1rem 1rem 1rem;
          // min-height: #{pxToRem(304)};;
          // max-height: 19rem;
          min-height: #{pxToRem(300)};
          margin: 0;
          // background-color:white;
          .asset-row {
            padding: 0.5rem 1rem 1rem 1.5rem;
            label{
              &:before{
                // top:#{pxToRem(5)};
                top:#{pxToRem(8)};
              }
              &:after{
                // top:#{pxToRem(5)};
                top:#{pxToRem(8)};
              }
            }
            a {
              span {
                margin: 0;
              }
            }
            .btn-primary {
              @include rhythm(12, 14);
              font-family: $interstate-bold;
              width: auto;
              float: right;
              background: transparent;
              color: $primary-blue;
              padding: 0;
              text-align: right;
              margin-right: 0.5rem;
              visibility: hidden;
            }
            &:hover {
              //background: $nav-link-active-bg;
              .btn-primary {
                visibility: visible;
              }
            }
          }
        }
      }
    }
  }
}
.filter-note{
  padding: 1.625rem 1rem;
  border-bottom: 1px solid $secondary-light-gray;
  span{
      @include font-size(14);
      color:$secondary-dark-gray;
  }
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before{
  background-color: $primary-blue;
}
.custom-control-label{
  padding-left:#{pxToRem(3)};
  padding-top: #{pxToRem(8)};
  vertical-align: middle;
  &:before{
    width:#{pxToRem(18)};
    height:#{pxToRem(18)};
  }
  &:after{
    width: #{pxToRem(18)};
    height: #{pxToRem(18)};
    left:#{pxToRem(-20.75)};
  }
}
.clearButton{
  position:absolute;
  left:16px;
  @include font-size(14);
}

.modal:before{
  content: none ;
}

.pill-2-col{
  width: 50%;
  float: left;
  h3{
    padding-left: 1rem;
    padding-top: 18px;
    text-transform: uppercase;
    font-size: 14px;
    margin-bottom:2px;
  }
}
@media (max-width: 992px) {
    .modal .modal-dialog {
      min-width: inherit;
      width: 88%;
    }
    .clearButton{
      width: 50px;
    }
}

@media only screen and (max-width:550px){
  .add-asset-to-comp-modal{
    .modal-footer{
      .btn-secondary{
        width: auto;
        min-width: auto;
      }

      .btn-primary{
        width: auto;
        min-width: auto;
      }
    }

  }
}
