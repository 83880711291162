@import "../../../../styles/variables.scss";
.alert-dismissible{
  width: 39%;
  position: fixed;
  top: 157px;
  background: black;
  color: white;
  text-align: center;
  z-index: 99999;
  font-size: 14px;
  line-height: 20px;
  margin: 0 auto;
  left:0px;
  right:0px;
  padding-top: 11px 36px 13px 7px;

  @media screen and (max-width:550px){
    width: 85%;
  }

  p{
    margin-bottom: 0px;
  }
  .close{
    color: white !important;
    z-index: 111111111;
    padding: 7px 14px;

    .ico-exit-round-edge{
      color: white !important;
      font-size: 0.65rem !important;
    }
  }
}
.no-header{
  .card.tile{
      margin-bottom: 0px;
  }
}
.nalco-site-details{
  .tooltip{
    &.bs-tooltip-bottom {
        z-index: 500 !important;
  }
 }
}
.across-assets-border{
    padding: 1rem;
    border-bottom: 1px solid #e2e2e2;
}

.k-state-selected{
  color:#000;
}

.k-pager-wrap {
  .k-pager-sizes {
      position: absolute;
      right: 0;
      bottom: 11px;
  }
}

@media  screen and (max-width:550px){
  .k-pager-wrap {
    .k-pager-sizes {
        bottom: 2px;
        select{
          width: auto;
        }
    }
  }
}

@media screen and (max-width:990px){
  .widTh{
    width: inherit;
    padding: 0px !important;
  }
   .mobileSpace{
     padding: 0px !important;
     margin-top: 0px !important;
   }
}

.legionella-growth-risk-site-list{
  min-height: 100px;
}

.downloadText{
  color: #0c5460; 
  background-color: #d1ecf1;    
  border-color: #bee5eb;
  font-size: 15px;
  width: 64%;
  position: fixed;
  font-weight:bold;
  display: block ;
  &.none{
      display: none ;
  }
}


.water-safety-grid-view { 
  .no-padding {
   padding: 0;
   text-align: center;
    .whole-cell {
     display: block;
     background-color:#f3f3f3 !important;
     padding: 15px;    
   }
 } 
}