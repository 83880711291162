/****************************************************/

// This files contains the search component styles

/****************************************************/
input[type="search"] {
  border-radius: 1rem;
  color: $secondary-gray;
  padding: 6px 12px 6px 40px;
  border: 0 none;
  outline: none;
  font-family: $interstate-light;
  @include rhythm(14, 20);
  width: #{pxToRem(194)};
  @include placeholder {
    color: $secondary-gray;
    opacity: 1;
  }
  &:hover {
    color: $secondary-gray;
    &::-webkit-input-placeholder,
    &::-moz-placeholder,
    &:-ms-input-placeholder,
    &:-moz-placeholder {
      color: $secondary-gray;
      opacity: 1;
    }
  }
  &:focus,
  &:active {
    &::-webkit-input-placeholder,
    &::-moz-placeholder,
    &:-ms-input-placeholder,
    &:-moz-placeholder {
      color: $secondary-light-gray;
      opacity: 1;
    }
  }
  &.secondary-search {
    border: 1px solid $secondary-dark-gray;
    width: #{pxToRem(197)};
    padding-bottom: 5px;
    padding-top: 5px;
    &:hover {
      color: $secondary-dark-gray;
      &::-webkit-input-placeholder,
      &::-moz-placeholder,
      &:-ms-input-placeholder,
      &:-moz-placeholder {
        color: $secondary-dark-gray;
        opacity: 1;
      }
    }
  }
  &+.ico-nav-search {
    &::before {
      position: absolute;
      top: 0.5rem;
      left: 1rem;
      @include rhythm(16, 16);
      color: $primary-gray;
    }
  }
}
