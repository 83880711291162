.page_loader {
  position: absoute;
  height: 100%;
  width: 100%;
  background: #f5f5f5;
  z-index: 99;
}
.transparentBG{
  background:  rgba(245,245,245, 0.3);
}
.fit-to-screen{
  position: fixed;
  width: 100%;
  .loader{
    position: relative;
    margin: 0 auto;
    top:50%;
    transform: translateY(-50%);
  }
}
.loader {
  width: 96px;
  height: 96px;
  border-radius: 50%;
  border: 10px solid #007AC9;
  border-top: 10px solid #FFF;
  -webkit-animation: spin 1.5s linear infinite;
  animation: spin 1.5s linear infinite;
  margin: 15% auto;
  box-shadow: inset 0 0 3px 0 rgba(0, 0, 0, 0.2), 0 0 3px 0 rgba(0, 0, 0, 0.3);
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
