@import "../../../styles/variables.scss";

.build-container {
  .btn-primary {
    border-radius: 4px;
  }
}


.property-panel {
  width: 100%;

  .panel-left {
    width: 48%;
    float: left;
    margin-left: 16px;
  }

  .property-title {
    font: $font-family-base;
    @include font-size(10);
    color: $secondary-dark-gray;
    width: 90%;
    float: left;
    margin-bottom: 2px;
  }

  .property-input {
    float: left;
    clear: both;
    border-radius: 0.125rem;
    height: 23px;
    width: 90%;
    padding-left: 10px;
  }
}

.asset-dropdown {
  font: $font-family-base;
  @include font-size(14);
  color: $secondary-dark-gray;
  margin-top: .6rem;
}

.asset-property-right-header {
  width: 64%;
  float: right;

  nalco-dropdown {
    float: right;
  }

  .dropdown {
    width: 141px;
    min-width: 141px;
    padding: 0.45rem .625rem;

    button {
      &.dropdown-toggle {
        &:after {
          right: .625rem;
        }
      }
    }
    .drop-btn{
      &.dropdown-toggle {
        &:after {
          right: .625rem;
        }
      }
    }

    .dropdown-toggle .label {
      font-size: 13px;
      color: $primary-gray;
    }

    .dropdown-menu {
      &.show {
        right: 0;
      }
    }
  }

  .asset-dropdown {
    &.primary-gray {
      color: $primary-gray;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      position: relative;
      margin-left: 4%;
      vertical-align: middle;
      text-transform: initial;
      float: left;
      width: 85%;
      margin-top: 0.5rem;
      cursor: pointer;
      text-align: right;
    }
  }
}

.asset-property-left-header {
  //  width: 70%;
  width: 73%;
  float: right;

  nalco-dropdown {
    float: right;
  }

  .dropdown {
    width: 132px;
    min-width:132px;
    max-width:150px;
    height: 32px;

    .dropdown-toggle .label {
      font-size: 13px;
      color: $primary-gray;
    }
  }

}

.asset-content {
  .site-nav {
    padding-right: 0px !important;

    ul .nav-item {
      @include font-size(14);
      padding-right: 1rem !important;

      .nav-link {
        @include font-size(14);
        outline: none;

      }
    }
  }


}

@media screen and (max-width:1366px){
  .asset-content {
    .site-nav {
      padding-right: 0px !important;
  
      ul .nav-item {
        @include font-size(14);
        padding-right: .5rem !important;
        border-bottom: 1px solid transparent;
        &:nth-child(3),&:nth-child(4){
          width: 93px;
        }
        .nav-link {
          @include font-size(14);
          outline: none;
          height: 100%;

          span{
            overflow: hidden;
            cursor: pointer;
            text-overflow: ellipsis;
            width: 100%;
            display: block;
        
          }
        }
      }
    }
  }
}

.asset-tree-property {
  margin-top: 2.3rem;
  min-height: 75vh;

  .asset-content {
    .center-align {
      @include font-size(18);
      color: $primary-gray;
    }
  }

  .asset-property-right-header {
    nalco-dropdown {
      .dropdown {
        padding: 0.45rem .625rem;

        button {
          &.dropdown-toggle {
            &:after {
              right: .625rem;
            }
          }
        }
        .drop-btn{
          &.dropdown-toggle {
            &:after {
              right: .625rem;
            }
          }
        }
      }
    }
  }

  .asset-status {
    input[type="text"] {
      border-radius: 0.125rem;
      color: $primary-gray;
      width: 100%;
      border: 1px solid $secondary-dark-gray;
      padding: .7rem 1rem;
    }

    .dropdown {
      background-color: $primary-white;
      padding: 0.6875rem 1rem;
      border: 1px solid $secondary-dark-gray;
      border-radius: 0rem;
      margin-bottom: -0.25rem;

      .dropdown-menu {
        padding: 0.375rem 0rem;
      }

      .dropdown-menu.show {
        margin-bottom: 16px;
        margin-left: 0px !important;

        .dropdown-item {
          padding: 0.625rem 1rem;
          max-width: 100%;

          &.selected {
            &:after {
              right: calc(100% - 97%);

            }
          }

        }
      }

      button {
        &.dropdown-toggle {
          .selected-text {
            width: 100%;
          }
        }
      }
      .drop-btn {
        &.dropdown-toggle {
          .selected-text {
            width: 100%;
          }
        }
      }
    }


  }

  // #ngb-tab-0-panel , #limits
  #limits {
    height: auto;
    overflow: none;
  }

  #limits-panel {
    border-bottom: 1px solid $light-gray;
  }


  .asset-status-grid {
    .kendo-grid {
      border: 1px solid $light-gray;
    }

    .dropdown {
      background-color: $primary-white;
      padding: 0.6875rem 1rem;
      margin-top: 0rem;
      border: 1px solid $secondary-dark-gray;
      border-radius: 0rem;
      min-width: 5rem;
      margin-bottom: -0.25rem;
    }

    th,
    td {
      padding: 8px 8px 8px 7px !important;
    }
  }

}

.asset-wrapper {
  min-width: 490px;

  .asset-legend:nth-child(6) .legend-text {
    background: linear-gradient(180deg, #E2E2E2, #BBBCBE);
  }
}

.bs-tooltip-top .arrow {
  bottom: unset;
}

ngb-alert {
  .alert {
    margin-bottom: 0rem;
  }

  .alert-dismissible .close {
    padding: 0.5rem 0.75rem;
    color: $primary-gray;

    &:hover {
      color: $primary-gray;
    }
  }

  .alert-warning {
    color: $validation-red;
    background-color: $btn-group-bg;
    border-color: $btn-group-bg;
  }

}