
.accordiontabletd{
    padding: 0 !important;   
   }
   
   .nalco-assets-details-view {
   
       .no-header{
           .tile-content-wrapper{
               table.limitalltable{
                   .card.tile{
                       .card-header{
                           display: block !important;
                       }
                   }
               }
           }
           }
           
       .no-header{
           .tile-content-wrapper{
               .accordiontable{
                   .card{
                       .card-header{
                           background: #ededed !important;
                           display: block !important;
                       }
                       .card-body{
                            min-height: auto;
                        }  
                    }
                   .btn-link{
                       color:#656565;
                       font-size: 0.875rem;
                       font-weight: 700;
                   }
               }
             .limitalltable  {
                th{
                    height: 47px;
                }
                td,th{
                   border: 1px solid rgba(0, 0, 0, 0.08);
                   padding: 0;   
               }
               td{
                   vertical-align: top;
               }
                kendo-dropdownlist{
                    width: 70px;
                }
             
               }
           }
       }
   }    
   
   
   
   
   