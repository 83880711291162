@import "../../../../styles/variables.scss";

ngb-accordion {
  .highlight {
    background-color: $utilitarian-yellow;
    font-weight: 600;
  }

  .card {
    .card-header {
      border-bottom: 1px solid $secondary-light-gray;
      padding: 5px 10px;
      background-color: $primary-white;
    }

    .card-header[role="tab"] {
      padding: 0.36rem 1rem;
      display:flex;

      a {
        display: inline-block;
        width: 100%;
        text-transform: capitalize;
        text-align: left;
        position: relative;

        &:after {
          position: absolute;
          right: 0;
          top: 30%;
          transform: rotate(-90deg);
        }
      }

      &.active {
        box-shadow: none;

        &:after {
          -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
        }
      }

      &#add-top-header {
        a {
          color: $primary-blue;

          &:after {
            border-color: $primary-blue transparent transparent transparent;
            position: relative;
            display: block;
            float: left;
            top: 5px;
            margin: 0px 10px 0px 0px;
          }
        }
      }

      a {
        font-size: 16px;
        color: $primary-blue;

        &:after {
          position: relative;
          display: block;
          float: left;
          top: 5px;
          margin: 0px 10px 0px 0px;
          border-color: $secondary-blue transparent transparent transparent;
        }
      }

      button.btn-link.collapsed {
        &:after {
          position: absolute;
          border-top-color: #007ac9;
          -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
          top: 20px;
        }
      }

      button.btn-link {
        &:after {
          position: absolute;
          border-top-color: #007ac9;
          -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
          top: 20px;
        }
      }
    }

    .card-body {
      padding: 0rem 1rem;

      .btn.btn-dragged {
        margin-top: 8px;
      }
    }

    .card-body>ngb-accordion a {
      padding-left: 1rem;
      text-transform: none;
    }

    .card-body[role="tabpanel"] {
      background-color: $primary-white;
      padding: 0rem 0.0625rem 0.5rem;
      padding-bottom: 0px;
      display: flex;
      flex-direction: column;
      max-height: 100%;

      .btn.btn-dragged {
        display: inline-block;
        background-image: linear-gradient(180deg,
            $customer-area-legend,
            $customer-area-gradient-legend );
        text-align: left;
        max-width: 90%;
        margin: 0.5rem 1rem 0px 2rem;
        position: relative;
        font-size: 16px;
        margin-right: 1rem;
        padding: 14px;

        &:last-child {
          margin-right: 0;
          text-align: left;
          text-transform: none;
        }

        &:after {
          font: normal normal normal 20px/20px "Nalco-additional-icons";
          content: "\E91E";
          position: absolute;
          right: 1rem;
          opacity: 0.3;
          margin: -2px 0px;
        }

        &:hover,
        &.dnd-drag-start {
          &:after {
            font: normal normal normal 20px/20px "Nalco-additional-icons";
            content: "\E91E";
            position: absolute;
            right: 1rem;
            opacity: 0.3;
          }
        }
      }

      &:nth-child(1) {
        margin-left: 20px;
      }
    }

    .collapse.show[role="tabpanel"] {
      position: relative;
      // top: -40px;
      background-color: $primary-white;
      padding: 0rem 0.0625rem 0.5rem;
      padding-bottom: 0px;
      display: flex;
      flex-direction: column;
      max-height: 100%;

      .btn.btn-dragged {
        display: inline-block;
        background-image: linear-gradient(180deg,
            $customer-area-legend,
            $customer-area-gradient-legend );
        text-align: left;
        max-width: 90%;
        // margin: 0.5rem 1rem 0px 2rem;
        position: relative;
        font-size: 16px;
        margin-right: 1rem;
        padding: 14px;

        &:last-child {
          margin-right: 0;
          text-align: left;
          text-transform: none;
        }

        &:after {
          font: normal normal normal 20px/20px "Nalco-additional-icons";
          content: "\E91E";
          position: absolute;
          right: 1rem;
          opacity: 0.3;
          margin: -2px 0px;
        }

        &:hover,
        &.dnd-drag-start {
          &:after {
            font: normal normal normal 20px/20px "Nalco-additional-icons";
            content: "\E91E";
            position: absolute;
            right: 1rem;
            opacity: 0.3;
          }
        }
      }

      &:nth-child(1) {
        margin-left: 20px;
      }
    }
  }
}

.nomatcherror {
  font-size: 14px;
  padding: 0.5rem 1rem;
  color: $secondary-dark-gray;
}