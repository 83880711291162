@import "../../../../../styles/variables.scss";
.offlineSchedule {
    .dropdownHeading{
        padding-top: 8px;
        padding-left: 16px;
        font-size: 11px;
        color: #616365;
    }
    .selectDropdown{
        font-size: 12px;
        height: 48px;
        padding-top: 18px;
        border: 1px solid #bbb;
        border-radius: 2px;
    }
    .disabled_bg{
        background-color: #e9ecef;
    }
    .float-container {
        border: 1px solid #BBBCBE;
        padding: 0 8px;
        position: relative;
        height: 3rem;
        border-radius: 0.125rem;
        input {
            border: none;
            min-height: 20px !important;
            height: 20px !important;
            margin-left: 5px;
            position: relative;
            bottom: 5px;
            display: inherit;
            color: #616365;
            width: stretch;
            padding-left: 0.375rem;
        }
        label {
            transform: translate(6px, 5px) scale(1);
            font-size: 11px;//0.5625rem;
        }
    }
    .ud-mt{
        margin-top: 2rem!important;
    }
    .dd-container.select-dropdown .heading{
        font-size: 11px;
    }
    .ud-pt{
        padding-top: 30px;
    }
    .d-contents{
        display: contents!important;
    }
    .required {
        color: $utilitarian-red;
    }
    input[type="radio"] {
        appearance: none;
        display: inline-block;
        position: absolute;
        padding: 0.17rem;
        background-clip: content-box;
        border: 1px solid #616365;
        border-radius: 50%;
        height: 0.9125rem;
        width: 0.9125rem;
    }
    input[type="radio"]:checked {
        background-color: #007ac9;
    }

    .dd-container.select-dropdown .dd-control-container .dd-display-item{
        color: #616365;
    }
    .dd-integrated-container.select-integrated .dd-control-container .dd-display-item{
        color: #616365;
        font-size: 13px;
    }
    .ud-width{
        width: 24rem;
    }
    .disableInput{
        background-color: #e9ecef ;
    }
    .disableRadio{
        background-color: #e9ecef !important;
        background-clip: border-box !important;
    }
    .disableRadio-select{
        background-color: #ABA6A5  !important;
    }
    .textTransform{
        text-transform: initial;
    }
    .fontSize{
        font-size: 12px;
    }
    #offline-info .bs-tooltip-bottom .arrow{
        top: -7px;
    }
    // .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow{
    //     top: -10px;
    // }
    .tooltip.bs-tooltip-bottom, .tooltip.bs-tooltip-auto[x-placement^="bottom"]{
        width: max-content;
    }
    #offline-info .tooltip {
        top: 0px !important;
        width: max-content;
        left: 10px !important;
        .tooltip-content {
            font-size: 0.6875rem;
        }
    }
    // table.k-grid-table tr:last-child td .tooltip.bs-tooltip-bottom {
    //     top: 0!important;
    // }
}
