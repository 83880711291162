  .remote-upgrade-list{
    .loader-position{
      margin: -0.5% auto !important;
      position: absolute !important;
      width: 50px !important;
      height: 50px !important; 
    }

    .margine-p{
      margin-left: 2rem !important;
    }
    .target-version-width{
      width:100%;
    }
    .search-box-area{
    .search-label{
      color:#949494;
      font-weight: 300;
      position: relative;
      top:7%;
    }
    .search-box{
      margin-left:2px;
      margin-right: 5px;
      border-radius: 3px;
      color: #434345;
      padding: 6px;
    }
    ::placeholder{
      color:#949494;
    }
  }
    .btn-right{
        display: flex;
        flex-direction: row;
        justify-content: right;
        align-items: center;
        height: 50px;
    }
    .btn-right{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        height: 50px;
    }
    .search{
      display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 50px;
    }
    
    .upgrade-loader{
      position: absolute !important;
      width: 50px !important;
      height: 50px !important;
      margin-left: 13rem;
      margin-top: -0.4rem;
    }
    .min-height {
      min-height: 5rem !important;
    }
    .body-msg{
      line-height: initial;
    }

  .k-grid td.k-state-selected, .k-grid tr.k-state-selected > td {
    background-color: #80b5e9;
  }
  .k-checkbox-label::after , .k-checkbox:checked {
    background-color: #006bd3 ;
    border-color: #767676;
  }
  .k-checkbox-label::before {
    border-color: #767676;
  } 
  .k-grid-content {
    overflow-x: scroll !important;
}
.k-checkbox:indeterminate + .k-checkbox-label::after {
  background-color: #006bd3;
}
.k-filtercell .k-filtercell-operator {
  display: none;
}
.k-filtercell .k-filtercell-wrapper > .k-textbox {
  border-color: rgba(0, 0, 0, 0.3);
}

.k-picker-wrap {
  border-color: #b2b2b2 !important;
  height: 35px !important;
  border-radius: 0.3rem !important;
}
.k-dateinput-wrap{
  border-radius: 0.3rem !important;
}
.k-table-row {
  &.k-selected{
    & .k-table-td{
      background-color: #80b5e9;
    }
  }
}
.k-sort-icon{
  margin-inline-start : -3px !important;
}
.k-filtercell{
  width: 90%;
}
.k-datepicker {
  border-color: rgba(0, 0, 0, 0.3);
  border-radius: 4px !important;
  & kendo-dateinput{
   padding: 0px !important;
 }
}

}

