.search-text-filter{
    .search-icon{
        left: 17px;
        top: 9px;
        background-image: url(/assets/images/search-icon.svg);
        width: 1rem;
        height: 1rem;
        background-repeat: no-repeat;
        position: absolute;
        z-index: 10;
    }

    .searchUser{
        background-color: #fff;
        padding: 0.5rem 1rem 0.5rem 2.8rem;
        border: 1px solid #BBBCBE;
        border-radius: 1rem;
        min-width: 13.8rem;
        margin-bottom: -0.25rem;
        font-size: 14px;
        color: #656565;

        &::placeholder{
            color: #c3c3c3;
        }

    }

    .k-searchbar{
        .k-input{
            max-width: 180px;
        }
            
    }

        .tooltip.show{
            top: -63px !important;
            left: -15px !important;
        }
    }


    .mandatory-field{
        color: red; font-size: 20px; position: absolute; right: -12px;
    }