@import "../../../styles/variables.scss"; 

.audit-popup
{
  .modal-header
  {
    background-color: #fff;
    border-bottom: 1px solid #BBBCBE !important;
    padding:1rem 1.5rem 1.5rem 1rem !important;
    font-family: $interstate-bold;
  }
  .heading , .site-name{
    color:$primary-gray;
    @include rhythm(16, 16);

  

  }
  .site-name{
    @include rhythm(14, 16);
    padding:1rem 1.5rem 1.5rem 1rem !important;
    border-bottom: 1px solid #BBBCBE !important;
  
  }
  .table-wrapper{
    height: 24rem;
    overflow: scroll;
    border-bottom: 1px solid #BBBCBE !important;
  }
  //   .k-grid-content {
  //     overflow: overlay !important;
  //     overflow-x: auto !important;
  //     position: relative;
  //     -ms-flex: 1;
  //     flex: 1;
  //     height:400px !important;
  // }
  .k-grid-header{
    position: fixed;
    z-index: 1;
    // width: calc(100% - 24px);
    max-width: calc(60rem - 16px);
    &:after{
      position: absolute;
      content: '';
      width: 35px;
      height: 48px;
      background: #fff;
      right: -16px;
      border-bottom: 1px solid #dee2e6;
    }
  }
  .k-grid-content {
    margin-top: 47px;
  }
  * {
    ::-webkit-scrollbar {
     width: 1rem;
   }
    ::-webkit-scrollbar-track {
     background-color: $primary-white;
     border-left: none;
   }
    ::-webkit-scrollbar-thumb {
     background-color: $secondary-gray;
     border: none;
     border-radius: 0;
     background-clip: content-box;
     min-height: 2.5rem;
     max-height: 10rem;
   }
 }
}

