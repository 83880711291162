@import "../../../../../styles/variables.scss"; 
ngb-accordion.custom-accordion .card  {
    .card-header[role="tab"]{
        a{
            &::after{
                position: absolute;
                right: 0;
                transform: rotate(0deg);
                top:12px;
                border-color: #007AC9 transparent transparent transparent;
            }
            h4{
                color: $primary-gray;
                margin: 3px 0;
            }
        }
        &.active{
            a{
                &::after{
                    transform: rotate(-180deg);    
                }
            }
        }
    }
    .card-body {
        background-color: #EAE9E8;
        padding: 20px 0 0 0;
        display: block;
        flex-direction: initial;
        -ms-flex-direction: initial;
        min-height: 520px;
        overflow-y: visible;
    }
    .green{
        color: $linear-gradient-end-green;
    }
    .yellow{
       color: $linear-gradient-start-yellow;
    }
    .red{
        color: $utilitarian-red;
    }
    .small-donut {
        padding-top: 3px;
        .DonutChart{
            padding-bottom: 3px;
            .highcharts-title{
                top:55px !important;
            }
        }
        .text-center{
            @include font-size(14);
            }
    }
    .donutTile-header {
        font-size: 1rem;
        margin-top: 3px;
        span{
            vertical-align: top;
            display: inline-block;
            margin-right: 5px;
        }
    }    
}
.parameters-tile{
    .card-header{
    padding-top:15px;
    }
}
ngb-accordion.custom-accordion.admin-accordion .card {
    .card-header[role="tab"]{
        padding:1rem;
        a{
            h4{
               font-size: 16px;
               line-height: 27px;
            }
        }
    }
    .parameters-tile{
        padding-left:51px;
        
        input[type="radio"]{
            display: none;
            
        }
    }
    .card-body {
        background-color: #f7f7f7;
        min-height: auto ;
        padding:0;
        .btn{
            // background-image: none;
            text-align: center;
            margin-top:0;
            margin-left:1rem;
            padding:7px;
            font-size:14px;
            &::after{
                content: "";
            }
            &.btn-primary{
                background-image: linear-gradient(180deg, #1C9AEC, #007ac9);
                &:hover{
                    background-image: linear-gradient(180deg, #5EB6E4, #007ac9); 
                }
            }
            &.btn-secondary{
                background-image: none;
            }
        }
    }
}