@import "../../../../styles/variables.scss";

.nalco-system-assurance-widget {
  .system-dropdown {
    padding: 10px;
    border-bottom: 1px solid #dedede;
  }
  .card-title {
    margin-right: 10px !important;
  }
  .chartGroup {
    #chartcontainer {
      display: inline-block !important;
      cursor: pointer;
      background-color: #f2f2f2;
      width: auto;
      height: 182px !important;
      padding: 10px;

      .highcharts-root {
        height: 180px;
      }

      @media screen and (max-width: 991px) {
        width: 100% !important;
        display: block !important;
      }
    }
    .tile-content-wrapper {
      // min-height: 250px;
      .loading {
        top: 0;
      }
    }
  }
  nalco-sub-system-chart-widget {
    float: left;
    position: relative;
    nalco-donut-chart {
      float: left;
      width: 100%;
    }
    @media screen and (max-width: 991px) {
      width: 50%;
    }
    @media screen and (max-width: 480px) {
      width: 100%;
    }
  }
  .chart-legend {
    background-color: white;
    text-align: center;
    span {
      background-color: white;
      padding: 3px 10px 0px 10px;
    }
  }
  .ledendHyperlink {
    font-size: 0.875rem;
    color: #007ac9;
    cursor: pointer;
    text-decoration: underline;
    margin-bottom: 0;
    padding-top: 5px;
    position: absolute;
    top: 0;
    right: 0;
    padding-right: 4px;
    border: 0;
    background-color: transparent;
    margin-right: 64px !important;
    margin-top: 9px;

    @media screen and (max-width: 990px) {
      margin-top: 1.5rem;
    }
  }
  
  nalco-system-status-table-widget {
    display: block;
    width: 100%;
    overflow: auto;
  }

  kendo-grid {
    table.k-grid-table tbody tr td {
      border-width: 1px 0px 0px 0px;
    }

    thead tr th.k-header {
      border-width: 0px 0px 1px 0px;
    }
  }
  
  .chartGroup {
    .active #chartcontainer {
      background-color: white !important;
      border-bottom-style: solid;
      border-bottom-color: grey !important;
      border-top-style: solid;
      border-top-width: 1px;
      border-top-color: rgb(221, 217, 217) !important;
    }
  }

  .loadder-wrapper {
    width: 100% !important;
    min-height: 17.5rem;
    background-color: $primary-white;
  }
  .loader {
    position: absolute;
    border-radius: 50%;
    left: -10% !important;
    right: 0px;
    width: 50px;
    height: 50px;
    top: 325px !important;
  }
}
.system-assurance-pdf {
  .tile-content-wrapper {
    min-height: 0% !important;
  }
  .asset-measurement-details-wrapper {
    min-height: 0px !important;
    .no-records-msg {
      min-height: 0px !important;
    }
  }
  //For Navigation Link in Table
  span.column-label.link {
    color:$primary-gray !important;
  }
  th.source-header {
    width: 8% !important;
  }
}

// .system-asssurance-pdf-avoid-break {
//   page-break-inside: avoid !important;
// }