/****************************************************/

// This files contains the favorite component styles

/****************************************************/

.customize {
  &:nth-child(1)
  {
    min-width: 6rem;
    padding-top: 1px;
  }
  &:nth-child(2)
  {
    min-width: 5rem;
    padding-top: 1px;
    margin-left: 0.2rem;
  }

  position: relative;
  float: right;
  text-align: center;
  background: transparent;
  border: 0 none;
  @include rhythm(14, 16);
  cursor: pointer;

  &:focus {
    outline: none;
  }
  .ico-customize {
    @include rhythm(20, 17);
    text-align: left;
    position: relative;
    left: 0;
    right: 0;
  }
  .selected-customize {
    position: absolute;
    background-color: $primary-blue;
    color: $primary-white;
    border-radius: 50%;
    @include rhythm(10, 12);
    left: #{pxToRem(10)};
    bottom: #{pxToRem(6)};
  }
}

.modal {
  .modal-dialog {
    .modal-content {
      .filterPopup{
      .modal-body {
        background:$primary-white;
        overflow-y: auto !important;
        .filter-list {
          height: auto; // transition: height 1s;
          padding: 0 1.5rem;
          &:not(:last-child) {
            border-bottom: 1px solid $secondary-light-gray;
          }
          .checkBox {
            &:last-child {
              padding-bottom: 1.5rem;
            }
          }
          &.hide-filter-list {
            height: 0;
            overflow: hidden;
          }
        }
        .modal-body-header{
          float: left;
          background: #f7f7f7;
          margin-top: 0px;
        }
        .dropdown {
          float: left;
          min-width: 5rem;
          padding: 11px 2px;
          margin: 0.5rem 0;
          width: 93%;
          .dropdown-menu{
            &.show{
              min-width: 100% !important;
              margin-left: -0.25rem !important;
            }
          }
      }
      input[type="text"]
      {
        margin: 0.6rem 0;
        width: 92%;
      }
      &>ul li.modal-body-title {
        position: relative;
        clear: both;
        span.filter-label {
          font-size: 0.875rem;
          margin-left: 0;
          text-transform: uppercase;
          cursor: pointer;
          &:after {
            @include triangle(top, 8px, 6px, $primary-blue);
            position: absolute;
            right: 0;
            top: 0.25rem;
          }
          &.collapsed {
            &:after {
              transform: scaleY(-1);
            }
          }
        }
        a.clear {
          float: right;
          margin-right: 2rem;
        }
      }
      &>ul li.modal-body-title-header
      {
        position: relative;
        float:left;
        span.filter-label {
          font-size: 0.875rem;
          margin-left: 0;
          float:left;
          text-transform: uppercase;
          cursor: pointer;
        }
      }
      }
      .modal-footer
      {
        a
        {
          float:left;
        }
      }
    }
  }
}
}