@import "../../../../styles/variables.scss";
.actionLogView {
	.section {
      .select-filter {
        ngb-accordion {
          &.filter-accordion{
            .card .card-header[role="tab"]{
              @include margin-padding(all, null , all , #{pxToRem(16)});
              a{
                &:after{
                  top: 5px !important;
                }
              }
              h4{
                margin: 0;
                @include rhythm(20 ,18);
              }
            }
            .card .card-body{
              background-color: transparent;
              min-height: auto;
              @include margin-padding(all, null , all , #{pxToRem(0)});
            }
          }
          form{
            .col-lg , .col-lg-3{
              @include margin-padding(all, null , right , #{pxToRem(20)});
              @include margin-padding(all, null , left , #{pxToRem(0)});
            }
            .col-lg-3{
             position: relative;
             .k-autocomplete .k-input{
              padding-left: 38px !important;
              min-height: 2.78rem;
             }
             kendo-autocomplete.admin-search {
              width: 100%;
              min-width: 100%;
              border: 1px solid $secondary-gray;
              border-radius: #{pxToRem(2)};
              height: 2.78rem;
              margin-bottom: 0;
            }

          div.admin-icon {
            width: #{pxToRem(15)};
            height: #{pxToRem(19)};
            position: absolute;
            top: #{pxToRem(13)};
            z-index: 10;
            left: #{pxToRem(16)};
            background-image: $autocomplete-account-icon-url;
            background-position-x: no-repeat;
          }
          .dd-display-item{
            height: 2.78rem;
            padding-left: 38px;
            padding-top: 13px;
          }
            }
            div.custom{
              .admin-search , nalco-dropdown .dropdown , .k-datepicker ,
              .data-visual.nalco-date-range-dropdown , kendo-autocomplete.general-currency{
                min-width: 100%;
                width: 100%;
                height: 2.78rem;
                border-radius: #{pxToRem(2)};
                margin-bottom: 0px ;
                .k-picker-wrap.k-state-default{
                  border: 1px solid $secondary-gray;
                  border-radius: #{pxToRem(2)};
                }
                .k-input{
                  @include margin-padding(top,  #{pxToRem(10)}, all , null);
                }
              }
              &:last-child{
                padding-right: 0px !important;
              }
              .start-label{
                color: $primary-gray;
                @include font-size(9);
                left: #{pxToRem(16)};
                display: block;
                z-index: 1;
                position: absolute;
                text-transform: uppercase;
                font-family: $font-family-base;
                color: $primary-gray;
                margin-top: 0.375rem;
                @include margin-padding(bottom,  #{pxToRem(0)}, all , null);
                width: #{pxToRem(100)};
              }
              .k-dateinput-wrap{
                position: relative;
                background: transparent;
                .k-input{

                  color: $primary-blue;
                  &::placeholder{
                    @include font-size(14);
                    margin-top: #{pxToRem(6)};
                    color: $primary-blue !important;
                  }
                  &::-moz-placeholder {
                    @include font-size(14);
                    margin-top: #{pxToRem(3)};
                    color: $primary-blue !important;
                    opacity: 1;
                  }
                }
              }

            }

            .page-datepicker {
              .k-picker-wrap.k-state-default {
                border-color: $secondary-gray !important;
              }
              .start-label {
                color: $primary-gray;
                @include font-size(9);
                left: #{pxToRem(16)};
                display: block;
                z-index: 1;
                position: absolute;
                text-transform: uppercase;
                @include margin-padding(top,  #{pxToRem(6)}, all , null);
              }
              kendo-datepicker {
                .k-dateinput-wrap {
                  .k-input {
                    @include margin-padding(top,  #{pxToRem(4)}, all , null);
                  }
                }
                .k-icon.k-i-calendar {
                  color: $secondary-gray;
                }
              }
            }
            .units{
              .custom{
                &:nth-child(1){
                  @include margin-padding(all, null , right , #{pxToRem(20)});
                }
              }
            }
            hr{
                border-top: 1px solid $light-gray;
            }
          }
          .selectParameter{
            h4{
              @include font-size(20);
              margin-bottom: 0px;
            }
            [class^="icon-"]{
              display: inline-block;
              width: #{pxToRem(17)};
              height: #{pxToRem(17)};
              margin-right:5px;
              background-repeat: no-repeat;

            }

        //   .icon-HighRiskRed{
        //       background: url('../../../assets/images/3d-red.svg');

        //   }
        //   .icon-LowRiskGreenicon {
        //       background: url('../../../assets/images/3d-green.svg');
        //   }
        //   .icon-MediumRiskYellow {
        //       background: url('../../../assets/images/3d-yellow.svg');
        //   }
        //   .icon-trend-NoTest {
        //     background: url('../../../assets/images/3d-grey.svg');
        //   }

            .row.selectParamContent{
              .required {
                color: $utilitarian-red;
              }
              nalco-dropdown{
                .dropdown{
                  margin-bottom: 0px;
                  border-radius: 0.125rem;
                  height: 2.78rem;
                }
              }
            }
          }
          .accfooter{
            button{
              width: #{pxToRem(160)};
              background-image: linear-gradient(180deg, $linear-gradient-starting-blue, $primary-blue);
              background-repeat: repeat-x;
              text-align: center !important;
              @include margin-padding(all,  #{pxToRem(8)}, all , null);
              @include margin-padding(all,  #{pxToRem(0)}, all , null);
              &:after{
                display: none;
              }
            }
         }
        }
      }
     &.portMetrics{
      .card-title{
        @include font-size(20);
      }
      .row{
        @include margin-padding(all, null , all , #{pxToRem(16)});
        .col-lg-3{
          @include margin-padding(all , null , right , #{pxToRem(16)});
          &:last-child{
           padding-right: 0px;
          }
        }
        .card.card-body{
          min-height: 8rem;
          border: 1px solid #dee2e6;
        }
      }
     }
     &.actualFlowRate{
      .card-title{
        @include font-size(20);
      }
     }
    @media (max-width:991px){
      .col-lg , .col-lg-3{
        padding-right: 0px !important;
        margin-bottom: 16px;
      }
    }

  }
  .gridHeight{
    .tile-content-wrapper{
      min-height: 300px;

      .state-wrapper{
        top: 0;
        position: unset;
      }
    }
  }

  .actionLogGridDetails{
    .form-control{
      font-size: 12px;
      overflow: hidden;
      height: auto;
      &:disabled {
        background: #e9ecef !important;
      }
    }

    .heightClass{
      white-space: normal;
      line-height: 14px;
      height: 52px;
    }

    .kendoWidth{
      width: 100%;
      // .k-grid-aria-root{
      //   width: 570px;
      // }
    }
  }

  .text-transform-none{
    text-transform: uppercase !important;
  }
  .actionLogWidget {
    .widgetGridHeight {
      .tile-content-wrapper {
      min-height: 100px;

      .state-wrapper{
        top: 0;
        position: unset;
      }
    }
    }
    nalco-tile {
      div.card {
        margin-bottom: 0px;
      }
    }
  }
  .defaultMsg{
    margin-top:20px;
    span{
        display: flex;
        justify-content: center;
        color: $utilitarian-red;
    }
}
}

.status-toggle-section {
  position: absolute;
  top: 0.95rem;
  right: 19rem;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

  .status-toggle-btn {
    .favorite-toggle {
      width: 4rem;
    }
  }

}

.status-toggle-section-ssd {
  position: absolute;
  top: 0.75rem;
  right: 29.56rem;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  }
  .card.tile{
    box-shadow: none !important;
  }
