// /****************************************************/

// // This files contains the navigation component styles

// /****************************************************/

@import './menu-item/menu-item.component.scss';
@import "../../styles/variables";

.sidebar {
  position: fixed;
  left: 0;
  top: #{pxToRem(64)};
  height: 100%;
  width: 64px;
  background: $secondary-color;
  z-index: 99;
  // transition: all 0.3s ease; // need to add a new class for the tranisitions once body shrink is enabled
}

.sidebar.open {
  width: 190px;

}
.sidebar.display {
 display: none;

}


.sidebar .img-class {
  height: 60px;
  min-width: 50px;
  font-size: 28px;
  text-align: center;
  line-height: 60px;
}

.sidebar .nav-list {
  margin-top: 9px;
  margin-bottom:0rem;
}

.sidebar li {
  position: relative;
  margin-left: -34px;
  list-style: none;
  margin-top: 1px;
}

.sidebar li a {
  display: flex;
  height: 100%;
  width: 90%;
  border-radius: #{pxToRem(8)};
  align-items: center;
  text-decoration: none;
  // transition: all 0.4s ease;  // need to add a new class for the tranisitions once body shrink is enabled
}

.sidebar.open li a {
  width: 96%;
}

.sidebar li .sidebar-hover:hover  {
  .left-sub-nav{
    display: block;
  }
  background: rgba(79, 127, 174, 0.40);
}

.sidebar li .active {
  background: $primary-blue-color;
}

.sidebar li a .links_name {
  color: $default-white;
  font-size: 12px;
  font-weight: 400;
  pointer-events: none;
  opacity: 0;
  width: 0px;
}

.opacity-nav{
  display: none;
}

.sidebar.open li a .links_name {
  pointer-events: auto;
  margin: 1px 5px -1px;
  opacity: 1;
  font-family: $base-font-family;
  width: 100%;
}

.sidebar.open li a .opacity-nav{
  display: block;
}

.sidebar li a:hover .links_name,
.sidebar li a:hover i {
  // transition: all 0.5s ease;  // need to add a new class for the tranisitions once body shrink is enabled
  color: $default-white;
}


.sidebar li .img-class {
  height: 44px;
  line-height: 50px;
  font-size: 18px;
  border-radius: 12px;
}


.transitions-menus {
  transition: all 0.3s ease;
}

.bottom-nav {
  position: fixed;
  bottom: 0;
  width: inherit;
  background: inherit;
}

.expandmenu ::-webkit-scrollbar {
  width: 4px;
}
.collapsmenu ::-webkit-scrollbar {
  width: 4px;
}


.expandmenu ::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px $secondary-color; 
  -webkit-border-radius: 2px;
  border-radius: 2px;
}

.expandmenu ::-webkit-scrollbar-thumb {
  -webkit-border-radius: 2px;
  border-radius: 2px;
  background: $primary-color; 
  -webkit-box-shadow: inset 0 0 6px $secondary-dark-gray; 
}

@mixin left-nav-scroll{
  overflow-x: hidden;
  overflow-y: scroll; 
}

 @media (min-height:465px) and (max-height:655px) {
   .sidebar .overflow-scroll{
     @include left-nav-scroll;  
      height: 275px;
   }
 }
 
  @include media-breakpoint-down(sm){
  .overflow-menu{
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .bottom-nav{
    position: initial;
    margin-bottom: 65px !important;
  }
 }
 
 @media (min-width: 992px) and (max-width: 1500px) {
    .sidebar {
      overflow: scroll;
      overflow-x: hidden;
      height: 90%;
    }

  .bottom-nav{
    position: initial;
    margin-bottom: 65px !important;
    width: inherit;
    background: inherit;
    margin-top: 0px !important;
  }
 }

