@import "../../../../../styles/variables.scss";
.whitePanel
{
    position: relative;
    background:#fff;
    h1{
        .tooltip-info-icon{
        vertical-align:middle;
        @include font-size(20);
        padding-left:0px;
        margin-left:-2px;
        }
        ul{ 
        text-align:left;
        @include font-size(11);
        margin: 10px 0px 10px -17px;
            li{
            list-style-type:disc;
            }
        }
    }
} 
.filterPanel{
    background:#fff;
    padding-left: 1rem !important;
}
.tool-tip-header {font-size: 10px;color: #fff ; text-align: center ; margin-bottom: 0;}
.tooltip-content {
    font-size: 24px;
    color: #fff;
    text-align: center;
    display: block;
}
.showInPng{
    display: none;
}
.more-info{
    padding: 16px 16px 2px 16px;
}