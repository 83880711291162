@import "../../../../styles/variables.scss";

.DeleteTextAlign {
    text-align: left !important;
    margin-left: 0rem;
    padding-left: 0.4rem;
    color: #E00034;

}

.delete-modal-text {
    margin-left: 1rem;
    margin-right: 0.6rem;
    text-align: left !important;
    float: left;
}

.text {
    margin-left: -142px;
    margin-top: 5px;
    color: green;
    font-family: $interstate-regular;
}

.text-view {
    margin-left: -156px;
    margin-top: 5px;
    color: red;
    font-family: $interstate-regular;
}

.featuretable {

    .card.tile {
        box-shadow: none;
    }

    .nalco-asset-details-feature {

        .disabled-form-control {
            background-color: #e9ecef;
            opacity: 1;
            pointer-events: none;
        }

        .required {
            color: #E00034;
            margin-right: 2px;
        }

        .headingClass-NoTransform {
            white-space: normal;
            text-transform: none;

            .required {
                color: $utilitarian-red;
            }
        }

        .col-sm-4.p-0 {
            display: inline-flex;

            .col-form-label.headingClass {
                display: contents;
            }
        }

        .btn {
            width: 10%;
            margin-right: 10px;
            min-height: 32px;
        }

        .btn-width {
            width: 13%;
        }

        .paddingClass {
            padding: 7rem;
        }

        .nalcoDropdown {
            min-height: 1.625rem;

            .dd-container.select-dropdown {
                .drop-input.editable-view.dd-display-item {
                    padding: 0rem 1rem;
                    border: 1px solid #616365;
                    border-radius: 0.25rem;
                    color: #616365;
                    font-size: 12px;
                }

                .dd-list-container {
                    height: 10rem;
                    bottom: 70px;

                    .search-box {
                        border: 1px solid #BBBCBE;
                        border-top: 1px solid #BBBCBE;
                        border-bottom: none;
                    }

                    .list-items {
                        border: 1px solid #BBBCBE;

                        .add-button {
                            margin: 0.3rem;
                            padding-bottom: 0.4rem;
                            padding-top: 0.4rem;
                            font-size: 12px;
                            line-height: 10px;
                            min-height: 22px;
                        }

                        .dd-items-container {
                            height: 6.5rem;
                            border-top: 1px solid #BBBCBE;
                        }

                        .dd-clear-list {
                            padding: 0.4rem 1rem;
                            border-top: 1px solid #BBBCBE;
                        }
                    }
                }
            }
        }

        .questionColumn {
            display: flex;
            flex-flow: column wrap;

            .inputFeildCollection {
                display: flex;

                .inputFeild {
                    margin-right: 1rem;
                    border: 1px solid #616365;
                    border-radius: 0.25rem;
                    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
                }
            }


        }

        .form-control {
            font-size: 12px;
            overflow: hidden;
            min-height: 1.625rem;
        }

        .validation-message {
            color: $utilitarian-red;
            margin-top: 5px;
            margin-left: 5px;
        }

        select,
        .inventory input {
            height: 2rem;
            ;
        }

        .sales-account {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            display: block;
        }

        .salesaccount-custom-tooltip {
            margin-bottom: 1rem;
        }

        .inv-validation-message {
            color: $utilitarian-red;
            text-transform: none;
            margin-top: 5px;
            margin-left: 5px;
        }

        .no-transform {
            text-transform: none;
        }

        .tsnCheckbox{
            cursor: pointer;
            width: 18px;
            z-index: 1000;
        }

        .tsnCheckboxSection{
            display: flex;
            flex-direction: column;
            position: absolute;
            right: -22px;
            top: -10px;
        }
    }

    .form-group {
        &.row {
            white-space: normal;
        }
    }

    .disabledField,
    .inventory input:disabled {
        background: #dddddd;
    }

    .disabledField{
    pointer-events: none;
    }

    .fc-p-left {
        padding-right: 0rem;
    }

    .text-center {
        text-align: center;
    }

    .ico-info-thin{
        font-size: 1rem;
    }
}

.contentCenter {
    height: 57px;
    text-align: center;
    vertical-align: middle;
    line-height: 22px;
    margin-top: 10px;
    margin-bottom: 1px;

}

.highlight-field {
    border: 1px solid #f31700 !important;
}

.form-control:disabled {
    background: #dddddd;
}

.bypassTooltip {
    .tooltip-inner {
        max-width: 21rem !important;
    }
}

.ques-date-picker{
  width: auto;
}

.margin-left-0{
  margin-left: 0px !important;
}

.pl-1{
    margin-left: -5px;
}

.text-validation{
    margin-top: 1px;
}

.disabledIcon{
    opacity: 50%;
    pointer-events: none;
}

.text-overflow-container{
  overflow: hidden !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  .tankserialNumberValue,.tankserialNumberSoldToShipTo{
    display: inline !important;
  }
}
