@import "../../../../styles/variables.scss";
.mapfeatureComponent {
    
    .tile-content-wrapper {
        min-height: 17.35rem;
        box-shadow: none;

        .state-wrapper {
            top: 0;
            position: unset;
        }
    }
    .card-header {
        display: none;
    }
    .form-control {
        font-size: 12px;
        overflow: hidden;
        min-height: 1.925rem;
    }
    .validation-message {
        color: $utilitarian-red;
        margin-top: 5px;
        margin-left: 5px;
    }
    .inputFeild {
        height: 1.9rem;
        border: 1px solid #616365;
    }
    .k-grid .k-detail-cell{
        padding-right: 32px;
    }
    .k-grid .k-hierarchy-cell{
        padding-right: 32px;

    }
    .ud-select{
        display: inline;
        text-overflow: ellipsis;
        overflow: hidden !important;
        white-space: nowrap;
    }
    nalco-select-dropdown-integrated .dd-integrated-container{
        margin-top: 0;

        .dd-control-container .dd-display-item {
            width: 100%;
            padding: 0.375rem 0.75rem;
            font-size: 0.8rem;
            line-height: 1.4;
            color: #616365;
            background-color: #fff;
            background-clip: padding-box;
            border: 1px solid #616365;
            border-radius: 0.25rem;
            transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        }
    }
    .dd-integrated-container.disable{
        opacity: 1;

        .dd-display-item{
            color: #616365 !important;
            background-color: #e9ecef !important;
        }
    }
    .link-note{
        color:$utilitarian-red;
    }
    .reading-hyperlink{
      text-transform: none;
      margin-left: 2px;
      text-decoration: underline;
    }
    .reading-msg{
      text-transform: none;
      margin-left: 2px;
    }
    .dataSource-tooltip{
            line-height: 0.75rem !important;
            padding-left: 5px;
    }
  ::ng-deep {
     .mapMeasurmentToggle {
       nalco-favorite-toggle {
        float: right;

        .favorite-toggle {
          width: 50%;
          position: relative;
          @include margin-padding(all, null, bottom, #{pxToRem(0)});

          input {
            display: none !important;
          }

          .ico-toggle {
            margin-left: 0px !important;
          }

          input:checked+.ico-toggle {
            &:after {
              color: $primary-blue;
              top: 0.2rem;
            }

            &:before {
              color: $secondary-gray;
            }
          }

          .ico-toggle {
            &:after {
              color: $secondary-gray;
            }

            &:before {
              color: $secondary-gray;
            }
          }
        }
      }
    }
  }
}
.overlay-unmap{
  background-color: #fff;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  z-index: 99999;
  opacity: 0.5;
}
.inProgressText {
  color: black;
  font-size: 25px;
  font-weight: bold;
  margin: 200px auto;
  height: 30px;
  width: 500px;
}