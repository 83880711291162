@import "../../../../styles/variables.scss";
.OpScheduleComponent {
    // fieldset legend {
    //     color: #616365b0;
    //     margin: 0 40px 0 40px;
    //     padding: 0 20px 0 20px;
    //     width: auto;
    //     font-size: 14px;
    // }
    fieldset {
        border-color: #616365b0;
        border: 1px solid #616365b0;
        border-radius: 20px;
        min-height: 100px;
        legend {
            color: #616365b0;
            margin: 0 40px 0 40px;
            padding: 0 20px 0 20px;
            width: auto;
            font-size: 14px;
        }
        .row {
            margin-bottom: 0px !important;
        }
    }
    .card.tile{
        padding-bottom: 2rem;
    }
}
