@import "../../../../styles/variables.scss";

/*.quality-header .ico-more {  display: none; }*/

.content {
  .enterpriseTitle {
    width: fit-content;
    max-width: 100%;
    @include multiLineEllipsis(
      $lineHeight: 1.2em,
      $lineCount: 2,
      $bgColor: #eae9e8
    );
  }
}
.select-filter {
  &.disabled-filter-cursor {
    cursor: not-allowed;
  }
  &.enabled-filter-cursor {
    cursor: default;
  }
  & .range-content {
    &.disabled-filter-pointer {
      pointer-events: none;
    }
    &.enabled-filter-pointer {
      pointer-events: all;
    }
  }
}
.viewThreshold {
  float: right;
  font-size: 0.875rem;
  color: #007ac9;
  cursor: pointer;
  text-decoration: underline;
  margin-bottom: 0;
  padding-top: 5px;
}

.k-pager-numbers .k-link,
.k-pager-numbers .k-link:link {
  color: #007ac9;
}

.k-list-item.k-selected {
  background-color: #007ac9;
  color: #ffffff;
  &:hover {
    color: #ffffff;
    background-color: #007ac9;
  }
}

.k-pager-nav.k-button.k-selected {
  background-color: #007ac9 !important;
  color: #ffffff;
  &:hover {
    background-color: #007ac9;
  }
}

.k-pager-nav:hover {
  color: #656565;
  background-color: #ededed;
}
.k-pager-numbers .k-link:hover {
  background-color: #ededed;
}
.k-pager-numbers .k-link.k-state-selected,
.k-pager-numbers .k-link:link.k-state-selected {
  color: #ffffff;
}

// .boxPosition{

//     .showMe{
//         left: 0;
//         top: 3.2rem;
//     }

//     .dropdown-menu{
//        top: 36px !important;
//     }

//     .selected-text{
//         font-family: "Interstate-Regular", sans-serif;
//     }

//     .filterButton{
//         .customerName {
//             font-size: 14px;
//             width: 112px
//         }
//     }
// }
.listHeight {
  .tile-content-wrapper {
    .loading {
      top: 50px;
      left: 50px;
    }
  }

  .no-header {
    .card.tile {
      margin-bottom: 0px;
    }
  }
}

.mapHeight {
  min-height: 250px;
}

.overFlow {
  .showMe {
    top: 3.2rem;
    left: 0;
    padding-left: 0.5rem;
  }

  .tooltip {
    &.bs-tooltip-bottom {
      z-index: 500 !important;
    }
  }

  .disabled {
    .filterButton {
      pointer-events: none;
      opacity: 0.4;
      background-color: transparent;
    }
  }
  // .dateRange{
  //     .dropdown{
  //         padding: 0px;
  //         // height: 44px;
  //             #dropdownBasic1{
  //                 padding: 0.5rem 1rem;
  //                 height: 44px;
  //                 max-height: 44px;
  //                 margin-top: 1px;
  //             }

  //             .dropdown-menu{
  //                 margin-left: 0;
  //                 margin-top: 2px;
  //             }
  //     }
  // }

  div.custom {
    .admin-search,
    nalco-dropdown .dropdown,
    .k-datepicker,
    .data-visual.nalco-date-range-dropdown,
    kendo-autocomplete.general-currency {
      min-width: 100%;
      width: 100%;
      height: 2.78rem;
      border-radius: #{pxToRem(2)};
      margin-bottom: 0px;
      .k-picker-wrap.k-state-default {
        border: 1px solid $secondary-gray;
        border-radius: #{pxToRem(2)};
      }
      .k-input {
        @include margin-padding(top, #{pxToRem(10)}, all, null);
      }
      .selected-text {
        color: $primary-blue;
        top: 0px;
        padding-top: 0.25rem;
      }
      .label {
        display: block !important;
        top: 0px;
        line-height: normal !important;
        padding-top: 0.25rem;
      }
    }
    .start-label {
      color: $primary-gray;
      @include font-size(9);
      left: #{pxToRem(16)};
      display: block;
      z-index: 1;
      position: absolute;
      text-transform: uppercase;
      font-family: $font-family-base;
      color: $primary-gray;
      margin-top: 0.375rem;
      @include margin-padding(bottom, #{pxToRem(0)}, all, null);
      width: #{pxToRem(100)};
      width: 7rem;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .k-input-inner {
        position: relative;
        background: transparent;
        padding: .7rem .5rem 0 1rem;
        color: $primary-blue;
      
        &::placeholder {
          @include font-size(14);
          margin-top: #{pxToRem(6)};
          color: $primary-blue !important;
        }
      
        &::-moz-placeholder {
          @include font-size(14);
          margin-top: #{pxToRem(3)};
          color: $primary-blue !important;
          opacity: 1;
        }
    }

    .k-animation-container-shown {
      z-index: 1002;
    }
  }
  .page-datepicker {
    .k-picker-wrap.k-state-default {
      border-color: $secondary-gray !important;
    }
    .start-label {
      color: $primary-gray;
      @include font-size(9);
      left: #{pxToRem(16)};
      display: block;
      z-index: 1;
      position: absolute;
      text-transform: uppercase;
      @include margin-padding(top, #{pxToRem(6)}, all, null);
    }
    kendo-datepicker {
      .k-dateinput {
          @include margin-padding(top, #{pxToRem(4)}, all, null);
      }
      .k-icon.k-i-calendar {
        color: $secondary-gray;
      }
    }
  }
  nalco-sites-filter .filterButton {
    padding: 0.5rem 0.875rem 0.5rem 0.875rem;
    margin-bottom: 0;
  }
}

.wq-enterprise-view-section {
  .tile-content-wrapper {
    .state-wrapper {
      position: relative;
      &.loading {
        top: 0;
        left: 0;
      }
      .loader {
        position: relative;
      }
    }
  }
}

@media only screen and (max-width: 990px) {
  .mobileWidth {
    width: 100%;
    padding-bottom: 0 !important;
    padding-right: 1rem !important;
  }

  // .mobileSet{
  //     padding-bottom: 0px !important;
  //     padding-top: 0px !important;

  //     .viewThreshold{
  //         padding-top: 0px !important;
  //     }
  // }

  .toolTipPosition {
    .tooltip {
      top: 63px !important;
      left: -7px !important;
    }
  }
}

@media only screen and (max-width: 550px) {
  //  .enterpriseTitle{
  //       width: 78%;
  //  }

  .overFlow {
    overflow-x: auto;
    .section {
      .mapHeight {
        .loaderPosition {
          .card {
            min-height: 166px;
          }
        }
      }

      .listHeight {
        .card {
          min-height: 180px;

          .loader {
            left: -54px;
          }
        }
      }
      .loader {
        top: -18px !important;
      }
    }
  }
}
