@import "../../../../../styles/variables.scss";
.alert-dismissible{
    width: 39%;
    position: fixed;
    top: 157px;
    background: black;
    color: white;
    text-align: center;
    z-index: 99999;
    font-size: 14px;
    line-height: 20px;
    margin: 0 auto;
    left:0px;
    right:0px;
    padding-top: 11px 36px 13px 7px;

    @media screen and (max-width:550px){
        width: 85%;
      }
  
    p{
      margin-bottom: 0px;
    }
    .close{
      color: white !important; 
      z-index: 111111111; 
      padding: 7px 14px;
      
      .ico-exit-round-edge{
        color: white !important; 
        font-size: 0.65rem !important;
      }
    }
  }
.test-count-margin{
    margin-right:-1px;
}

.nalco-legionella-test{
    .tooltip{
        &.bs-tooltip-bottom {
            z-index: 500 !important;
      }
     }
}
.testcount-icon-placement{
    vertical-align: auto;
    line-height: 28px !important;
}
.leg-test-tooltip{
    .tooltip-inner{
        .tooltip-header-bold{
            font-weight: bold;
            margin-top:-15px;
        }
        div:first-child{
            margin-top:-20px;
            strong{
                padding-left: 10px;
            }
        }
    }
    ul{ 
        text-align:left;
        @include font-size(11);
        margin: 10px 0px 10px 0px;
            li{
            list-style-type:disc;
            }
    }
}


@media screen and (max-width:552px){
    .content{
      .siteFilter{
        .showMe{
            left: 8px;
            
          }
      }
  
    }
  }