@import "../../../../styles/variables.scss";
@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';

.nalco-asset-details-widget {
//Asset details widget css 
.loadder-wrapper {
    width: 100% !important;
    min-height: 17.5rem;
    background-color: $primary-white;
  }
  .loader {
    position: absolute;
    border-radius: 50%;
    left:-25px !important;
    right: 0px;
    width: 50px;
    height: 50px;
    top: 325px !important;
  }
  .mat-tab-group{
    margin-bottom: 24px;
}
.mat-tab-label{
    font-family: "Interstate-Bold", sans-serif;
    font-size: 0.75rem;
}
.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
    background-color: $primary-blue;
}
.table-legend {
  background-color: white;
  text-align: center;
  span {
    background-color: white;
    padding: 3px 10px 0px 10px;
  }
}

.custom-icon {
  cursor: all-scroll;
}

.asset-label {
  padding: 0 10px;
}

.asset-label-section {
  display: inherit;
}
}

.nalco-asset-details-widget-container-pdf {
  span.column-label.link {
    cursor: default;
    pointer-events: none;        
    text-decoration: none;
    color:$primary-gray;
    font-size: 0.75rem;
  }

   span.column-label.link {
    color:$primary-gray !important;
  }

  th.source-header {
    width: 8.2% !important;
  }
  .table-legend {
    background-color: $primary-white;
    text-align: center;
    span {
      background-color: $primary-white;
      padding: 3px 10px 0px 10px;
    }
  }
  .tile-content-wrapper{
     .asset-measurement-details-wrapper{
      min-height: 0px !important;
      .no-records-msg {
        min-height: 0px !important;
      }
    }
  }
}


