@import "../../../../styles/variables.scss"; 
.float-right{
  .btn{
      min-width:6rem;
  }  
}
.asset-content{
  .center-align{
      font-size: 26px;
      padding-top: 275px;
      padding-left: 141px;
      padding-right: 141px;
      line-height: 1.75rem;
      color: $primary-blue;
      padding-bottom: 0px;
      
      .panel-info{
          .draggable-btn{
              display: inline-block;
              text-align: center;
              max-width: 100%;
              margin-right: 1rem;
              padding: 7px;
              position: relative;
              font-size: 12px;
              &:last-child {
                  background-image: linear-gradient( 180deg, $opportunity-graph-line-ending-blue, $opportunity-graph-line-starting-blue );
                  margin-right: 0;
                  margin-left: -0.18rem;
                  text-align: left;
                }
              &:after{
                  font: normal normal normal 20px/20px 'Nalco-additional-icons';
                  content: '\E91E' !important;
                  position: absolute;
                  right: 1rem;
                  opacity: 0.3;
                  &.dnd-drag-start {
                      &:after {
                        font: normal normal normal 20px/20px 'Nalco-additional-icons';
                        content: '\E91E';
                        position: absolute;
                        right: 1rem;
                        opacity: 0.3;
                      }
                    }
                  &:hover,
                  &.dnd-drag-start {
                    &:after {
                      font: normal normal normal 20px/20px 'Nalco-additional-icons';
                      content: '\E91E';
                      position: absolute;
                      right: 1rem;
                      opacity: 0.3;
                    }
                  }
              }
          }
      }
  }

}

.btn-sub-customer{        
 
  text-align: left;  
  background : $subcustomer-area-legend;
  width: 100%;
  padding-bottom: 0.875rem;
  padding-top: 0.875rem;
  background-image:none !important;
  &:hover
  {
    background : $subcustomer-area-legend;
    background-image:none !important;
  }
}
.panel-content {
.btn-dragged{
  background-image: linear-gradient(180deg, $subcustomer-area-legend, $primary-blue);
  text-align: left;  
  margin-top: 0px;
  width: 100%;
  padding-bottom: 0.875rem;
  padding-top: 0.875rem;
  
}
.btn-asset{        
  background-image: linear-gradient(180deg, $asset-legend, $asset-gradient-legend);
  text-align: left;  
  margin-top: 0px;
  width: 100%;
  padding-bottom: 0.875rem;
  padding-top: 0.875rem;

  &:hover
  {
    background-image: linear-gradient(180deg, $asset-legend, $asset-gradient-legend);
  }
}
.btnSubasset
{
  background :$subasset-legend;
  text-align: left;  
  margin-top: 0px;
  width: 100%;
  padding-bottom: 0.875rem;
  padding-top: 0.875rem;

  &:hover
  {
    background :$subasset-legend;
  }
}
.btn-system {
  background-image: linear-gradient(180deg, $system-legend , $system-gradient-legend) !important;
  
  &:hover {
    background-image: linear-gradient(180deg, $system-legend , $system-gradient-legend) !important;
  }
}
}
.panel-subody .btn-asset
{
    &:hover
  {
    background: $subasset-legend
  }
}

.btn-dragged{
  text-align: left;
  padding-bottom: 0.875rem;
 padding-top: 0.875rem;
  border-radius: unset;
}

.label:hover {
    cursor: pointer;
  }
  
  .panel-body {
    margin-left: auto;
    margin-right: auto;
       
    .btn
    {
      margin-bottom: 8px;
    }
    div {
    

      > {
        .label {
          display: block;
          margin-bottom: 0px;
          width: 98%;
          position: relative;
        }
        .empty {
          display: block;
          margin-bottom: 0px;
          width: 98%;
          position: relative;
        }
        input {
          display: none;
        }
      }
      .panel-content{
        width: 100%;
        float: left;
        .btn-sub-customer{
          margin-bottom: 8px;
          background: $subcustomer-area-legend;
        }
        div {
          margin-bottom: 0px;
          
          > {
            .label {
              display: block;
              margin-bottom: 0px;
              width: 98%;
              position: relative;
            }
            .empty {
              display: block;
              margin-bottom: 0px;
              width: 98%;
              position: relative;
            }
            input {
              display: none;
            }
          }
       
          .panel-inner{
            width: 94%;
            float: right;
          }
          .btn-subasset{
            display: inline-block !important;
            margin-bottom: 8px;
          }
          .sub-asset{
            width: 90%;
            float: right;
            display: block;
          
          }
          .panel-subody {
            max-height: 0;
            overflow: hidden;
            display: none;                
            margin-bottom: 0px;
           
          }
         
            .panel-inner{
              width: 94%;
              float: right;
            }
          > input {
            &:checked + .label {
              + .panel-subody {
                max-height: 100%;
                display: inline-block;
                  overflow: inherit;
                  width: 100%;
              }
              + .all-sub-asset .sub-asset{
                max-height: 1000px;
                display: block;
              }
              + .btn-all-subasset .btn-subasset{
                max-height: 1000px;
                display: block;
                margin-right: 0rem;
                .btn-asset{
                  background: $subasset-legend !important;
                  max-height: 1000px;
                  display: block;
                  margin-right: 0.6rem;
                }
              }
              &:before {
                content: '';
                display: block;
                width: 0;
                height: 0;
                border-style: solid;
                -moz-transform: scale(.9999);
                border-width: 8px 7px 0 7px;
                 border-color: $primary-white transparent transparent transparent;
                position: absolute;
                left: 2%;
                top: 36%; 
                transform: rotate(0deg);
              }

            }
            + .label:before {
             content: '';
             display: block;
             width: 0;
             height: 0;
             border-style: solid;
             -moz-transform: scale(.9999);
             border-width: 8px 7px 0 7px;
              border-color: $primary-white transparent transparent transparent;
              position: absolute;
              left: 2%;
              top: 33%; 
              transform: rotate(-90deg);
            }
          }
        }
      }
  
      .btn-subasset{
        //float: right;
        display:block;
        margin-bottom: 8px;
      }
      .sub-asset{
        width: 90%;
        float: right;
        display:block;
      
      }
      .panel-subody {
        max-height: 0;
        overflow: hidden;
        display: none;                
        margin-bottom: 8px;
      }
     
        .panel-inner{
          width: 94%;
          float: right;
        }
      > input {
        &:checked + .label {
          + .panel-subody {
            max-height: 100%;
            display: inline-block;
            overflow: inherit;
            width: 100%;
          }
    
          &:before {
            content: '';
            display: block;
            width: 0;
            height: 0;
            border-style: solid;
            -moz-transform: scale(.9999);
            border-width: 8px 7px 0 7px;
             border-color: $primary-white transparent transparent transparent;
            position: absolute;
            left: 2%;
            top: 37%;    
            transform: rotate(0deg);
          }
        }
        + .label:before {
         content: '';
         display: block;
         width: 0;
         height: 0;
         border-style: solid;
         -moz-transform: scale(.9999);
         border-width: 8px 7px 0 7px;
          border-color: $primary-white transparent transparent transparent;
          position: absolute;
          left: 2%;
          top: 36%; 
          transform: rotate(-90deg);
        }
      }
    }
    
  }
  .panel-subody {
    max-height: 0;
    overflow: hidden;
    display: none;                
    margin-bottom: 8px;
    .panel-inner {

      div{
        margin-bottom: 0px;
  > {
    .label {
      display: block;
      margin-bottom: 0px;
      width: 98%;
      position: relative;    
      
  }
  .empty {
    display: block;
    margin-bottom: 0px;
    width: 98%;
    position: relative;    
    
}
    input {
      display: none;
    }
  }
 .btn-subasset{
  // float: right;
   margin-bottom: 8px;
   display: inline-block;
  }
  .btn-asset{
    margin-bottom:8px;
  }
  .all-sub-asset{
    max-height: 1000px;
    display: none;
  }
  .sub-asset{
    width: 90%;
      float: right;
    max-height: 1000px;
    display: none;
  }
  > input {
    &:checked + .label {
      + .panel-inner {
        max-height: 1000px;
        display: none;
      
      }
      + .btn-subasset{
        max-height: 1000px;
        display: block;
      }
      + .all-sub-asset{
        max-height: 1000px;
        display: block;
      }
      + .all-sub-asset .sub-asset{
        max-height: 1000px;
        display: block;
      }
      &:before {
        content: '';
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
        -moz-transform: scale(.9999);
        border-width: 8px 7px 0 7px;
         border-color: $primary-white transparent transparent transparent;
        position: absolute;
        left: 2%;
        top: 36%;
        transform: rotate(0deg);
      }
    }
    + .label:before {
     content: '';
     display: block;
     width: 0;
     height: 0;
     border-style: solid;
     -moz-transform: scale(.9999);
     border-width: 8px 7px 0 7px;
      border-color: $primary-white transparent transparent transparent;
      position: absolute;
      left: 2%;
      top: 35%;
      transform: rotate(-90deg);

      &:nth-last-child(1){
        border-color: $utilitarian-red transparent transparent transparent !important;
      }

      &:last-child {
        border-color: $utilitarian-green transparent transparent transparent !important;
      }
    }
  }
  
      }
      .btn-asset{
        margin-bottom:8px;
        background-image: linear-gradient(180deg, $asset-legend, $asset-gradient-legend) ;
      }
 
    }
  }

.trim-info {
    width: 73%;
   // display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
     white-space: nowrap;      
    position: relative;
    margin: 0;
    margin-left:4%;
    vertical-align: middle;
    text-transform: initial;
    float: left;
    //white-space: pre;
 }
 .info-bottom
 {
   height:100px;
 }

  .addNew-modal{
    .modal-dialog{
      transform: translate(0% , 50%) !important;
      -ms-transform: translate(0% , 50%) !important;
      -webkit-transform: translate(0% , 50%) !important;
      margin: 0px;
    }
  }

  .empty-div
  {
    height:60px;
  }
