/****************************************************/
@import "./variables.scss";
// This files contains the scss imports that are required for the modal

/****************************************************/

.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.2);
}

@media (min-width: 992px){
  .modal.show.cost-saving-modal{
    top: 25%; 
    .modal-dialog{
      min-width: 41rem;
    }
  }  
  .modal.show.multiple-site-popup{
    .modal-dialog{
      min-width: 50rem;
    }
  }
}
@media (min-width:768px) and (max-width:1024px)
{
  .modal.show.chart-setting-modal , .modal.show.View-Detail-modal{
    .modal-dialog{
      min-width: 40rem !important;
    }
  }
} 
@media (min-width:1024px) and (max-width:1334px){
  .modal.show.chart-setting-modal , .modal.show.View-Detail-modal{
    .modal-dialog{
      min-width: 56rem !important;
    }
  }
}
@media (min-width:1334px){
  .modal.show.View-Detail-modal{
    .modal-dialog{
      min-width: 62rem !important;
    }
  }
  .modal.show.chart-setting-modal{
    .modal-dialog{
      min-width: 75rem !important;
    }
  }
}
.modal {
  text-align: center;
  padding: 0;
  z-index: 1200;
  &:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }
  .modal-dialog {
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.7);
    min-width: 35rem;
  display: inline-block;
    text-align: left;
    vertical-align: middle;
    width: auto;
    .modal-content {
      background-color: $modal-body-bg;
      *::-webkit-scrollbar-track {
        background-color: $modal-body-bg;
      }
      .modal-header {
        background-color: $primary-white;
        border-bottom: 1px solid $secondary-light-gray;
        padding: #{pxToRem(14)} 1rem;
        border-radius: .25rem .25rem 0 0;
        .modal-title {
          @include rhythm(16, 20);
          text-transform: capitalize;
          &.delete-txt-icon {
            color: $utilitarian-red;
            &:before {
              font: normal normal normal 16px/16px 'Nalco-additional-icons';
              content: '\E936';
              margin-right: 0.5rem;
            }
          }
          &.save-txt-icon{
            color : $primary-gray;
            @include font-size(16);
          }
        }
        .close {
          cursor: pointer;
          opacity: 1;
          outline: none;
          @include font-size(16);
        }
      }
      .explaination,
      .revert {
        background-color: $primary-white;
      }
      .explaination {
        text-align: center;
        padding: 1rem;
        border-top: 1px solid $secondary-light-gray;
        margin-bottom: 0;
      }
      .revert {
        padding: 0 1.5rem 1.25rem;
        margin: -0.4rem 0 0;
        .mailto {
          color: $primary-blue;
        }
      }
      .modal-body {
        padding: 1rem 1.5rem;
        max-height: 27rem;
        overflow-y:visible;
        overflow-x: visible;
        background-color: #f7f7f7;
        &::-webkit-scrollbar-track {
          border-left: 0;
        }
        &.scrollbar-border {
          &::-webkit-scrollbar-track {
            border-left: 1px solid $secondary-light-gray;
          }
        }
        .pills-bar,
        .search-bar {
          background-color: $primary-white;
        }
        .pills-bar {
          padding: 1rem;
          border-bottom: 1px solid $secondary-light-gray;
        }
        .search-bar {
          border-bottom: 1px solid $secondary-light-gray;
          input[type="search"] {
            width: 100%;
            border-radius: 0;
            padding-bottom: 0.5rem;
            color: $secondary-gray;
          }
        }
        .modal-body-title {
          text-transform: uppercase;
          margin-bottom: 0.5rem;
        }
        .dropdown {
          border-color: $secondary-dark-gray;
          padding-top: #{pxToRem(11)};
          padding-bottom: #{pxToRem(11)};
          margin-bottom: 0;
          border-radius: #{pxToRem(2)};
          .dropdown-toggle {
            color: $primary-gray;
            font-family: $font-family-base;
            &:after {
              color: $primary-blue;
              top: 45%;
            }
          }
          .dropdown-menu {
            border-color: $secondary-dark-gray;
            margin-top: 0.7rem;
            overflow-y: auto;
            -ms-overflow-style : -ms-autohiding-scrollbar;
            max-height: 11rem;
          }
        }
        .textValid {
          display: none;
        }
        input[type="text"],
        input[type="number"],
        .dropdown,
        .textarea,
        .textarea-secondary {
          border-radius: 0.125rem;
          color: $primary-gray;
          padding: 0.6875rem 1rem;
          margin: 0.5rem 0;
          width: 100%;
          border: 1px solid $secondary-dark-gray;
          &::placeholder {
            font-family: $interstate-extra-light;
            @include rhythm(14, 16);
            color: $secondary-gray;
          }
          &.valid {
            background-image: url(../assets/images/checkmark-green-icon.svg);
            background-position-x: 98%;
            background-position-y: center;
            background-repeat: no-repeat;
          }
        }
        .dropdown {
          margin-top: 0;
        }
        input[type="number"] {
          width: 13rem;
          margin: 0 0 2rem;
          -moz-appearance: textfield;
          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
          &.initial {
            background-color: $nav-link-active-bg;
          }
        }
        .textarea,
        .textarea-secondary {
          text-align: justify;
          margin: 0;
        }
        .textarea {
          border: 0 none;
          padding: 1rem 0;
        }
        .textarea-secondary {
          padding: 0.75rem;
        }
        &>.suggestion {
          margin-bottom: 1.5rem;
        }
        .upper-section {
         
          .float-left {
            margin: 0 2rem 1.5rem 0;
            width: 100%;
            &:nth-child(even) {
              margin-right: 0;
            }
            &:empty {
              margin: 0;
            }
            .dropdown,
            input {
              margin: 0;
              width: 100%;
            }
          }
        }
        .lower-dynamic {
          margin-top: 0.75rem;
          .lower-dynamic-row {
            padding: 0.75rem 0;
            .float-left {
              width: 100%;
            }
            .specialcharacters {
              margin-top: 0.5rem;
              input[type="text"] {
                margin: 0;
              }
            }
          }
        }
        p.specialcharacters {
          margin-bottom: 0;
        }
        &>ul {
          margin-bottom: 1rem;
          padding: 0;
          list-style: none;
          &:last-child {
            margin-bottom: 0;
            li {
              &:last-child {
                padding-bottom: 0;
              }
            }
          }
          li {
            padding: 0 0 1rem 0;
            &.modal-body-title {
              font-family: $interstate-bold;
              @include rhythm(14, 16);
              padding-bottom: 0.5rem;
            }
            span {
              @include rhythm(13, 16);
              color: inherit;
              margin-left: #{pxToRem(18)};
            }
          }
        }
      }
      .modal-footer {
        background-color: $primary-white;
        padding: 1rem;
        border-top: 1px solid $secondary-light-gray;
        border-radius: 0 0 .25rem .25rem;
        .btn {
          @include rhythm(14, 16);
          max-width: #{pxToRem(128)};
          &:last-child {
            margin-left: #{pxToRem(12)};
          }
          &.btn-secondary {
            padding: #{pxToRem(7)};
          }
        }
      }
    }
  }
}
.modal-open{
  margin-right: 0px !important;
}
