@import "../../../../styles/variables.scss";

.accessDenied_container {
  height: 68vh;
  justify-content: center;
  align-items: center;
  margin: auto;

  .row {
    margin: auto;
    padding-top:15vh;

    .col-lg-8 {
      margin: auto;
    }
  }
  
    .image {
      position: relative;
      background-image: url('/assets/images/AccessDenied.svg');
      height:#{pxToRem(60)};
      width:#{pxToRem(60)};
      background-repeat: no-repeat;
      margin: auto;
      margin-bottom: 3px;
  
      svg {
        height:#{pxToRem(60)};
        width:#{pxToRem(60)};
        fill: $primary-blue;
      }
    }
  
    .text {
      @include font-size(40);
      font-family: $interstate-regular;
      color: $primary-gray;
      width: 100%;
      line-height: 2.5rem;
      margin-bottom: 8px;
      text-align: center;
    }
   .sub-text{
    @include font-size(16);
    font-family: $interstate-regular;
    line-height: 1.5rem;
    color: $primary-gray;
    width: 100%;
    text-align: center;
   } 
  
  }

.alert-dismissible{
  width: 39%;
  position: fixed;
  top: 157px;
  background: black;
  color: white;
  text-align: center;
  z-index: 99999;
  font-size: 14px;
  line-height: 20px;
  margin: 0 auto;
  left:0px;
  right:0px;
  padding-top: 11px 36px 13px 7px;

  @media screen and (max-width:550px){
    width: 85%;
  }

  p{
    margin-bottom: 0px;
  }
  .close{
    color: white !important;
    z-index: 111111111;
    padding: 7px 14px;

    .ico-exit-round-edge{
      color: white !important;
      font-size: 0.65rem !important;
    }
  }
}
.section
{
  h3{
@include font-size(12);
    margin-top: 0.1rem;
  }
}
.state-wrapper{
  padding:16px;
}
.right-padding-0{
  padding-right: 0;
}

ngb-tabset {
    justify-content: center;
}
//CVOC & EVOC
 // filter icon
 [class^="nalco-"]:before, [class*=" nalco-"]:before {
  font-family: "filter";
  font-style: normal;
  font-weight: normal;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .5em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1.2em;
  margin-left: .2em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.nalco-filtericon:before {
     content: '\e800';
     color:#007ac9;
     font-size: 1.4rem;
  }

.filter{
  .filterButton{
      background-color: #fff;
      padding: 0.5rem 0.875rem;
      border: 1px solid #BBBCBE;
      border-radius: 0.25rem;
      margin-bottom: -0.25rem;
      cursor: pointer;
      .filterBy{
          font-family: "Interstate-Regular", sans-serif;
          font-size: 9px;
          font-size: 0.5625rem;
          line-height: 10px;
          line-height: 0.625rem;
          color: #616365;
          display: block;
          margin: 0;
          text-transform: uppercase;
      }
      .customerName{
          margin-bottom: 0;
          text-overflow: ellipsis;
          white-space: nowrap;
          display:block;
          overflow: hidden;
          color: #007ac9;
      }
  }
}
.activeButton{
  .filterButton{
      background-color: #007ac9;
      .nalco-filtericon:before {
          content: '\e800';
          color:#ffffff;
          font-size: 1.3rem;
      }
      .filterBy{
          color: #ffffff;
      }
      .customerName {
          color: #ffffff;
      }
  }
}
.evoc-safety{
  position: relative;
  .col-sm-2.mr-auto{
    position: static;
    display: block;
  }
  .water-filter-by{
    top: 44px;
    &:hover{
        display:block;
    }
    .innerBox{
        border: 1px solid #BBBCBE;
        background-color: #ffffff;
        .headerActive{
            padding: 13px 8px;
            @include font-size(16);
        }
        .selectedFilter{
            padding: 0 1.125rem 1rem 1.125rem;
            .box1{
                padding: 0.625rem 1rem;
            }
        }
    }
  }
}

.filter:hover + .showMe  {
display: block;
}

.tp-left{
  .tooltip{
    .tooltip-inner{
      margin-left:40px;
    }
  }
}
// CVOC & EVOC ends
// .col-arrow-top{
//   .k-header span:not(.k-icon) {
//     &::before {
//         content: "\E006";
//         color: #cccccc;
//         vertical-align: top;
//         box-sizing: border-box;
//         width: 1em;
//         height: 1em;
//         font-size: 16px;
//         font-family: 'WebComponentsIcons';
//         font-style: normal;
//         font-variant: normal;
//         font-weight: normal;
//         line-height: 1;
//         text-transform: none;
//         text-decoration: none;
//         display: inline-block;
//     }
//   }
// }
.k-state-selected{
  background-color: #007ac9;
}
.k-pager-numbers .k-link, .k-pager-numbers .k-link:link{
  color:#007ac9;
}
.k-pager-wrap .k-state-selected:active{
  background-color: #007ac9;
}
.k-pager-wrap .k-state-selected:hover{
  background-color: #007ac9;
}
.padding-16{
  padding:1rem;
  border-bottom: 1px solid #c3c3c3;
}
.compliance-tile{
    li{
      div{
        padding-top: 2px;
      }
    }
}
.leg-test-col{
  position: relative;
  tr{
     td{
       &:first-child{
        span{
          color:#616365 !important;
        }
      }
    }
  }
}
.site-nav {
    width: 100%;
    display: block;
    &.mapListWrap{
      min-height: 200px;
      background: #fff;
    }
    ul{
      // border-bottom: 1px solid #e2e2e2;
      margin: 0;
      background: $primary-white;
      .nav-item
      {
        padding: 0.75rem 1.75rem 0 #{pxToRem(8)};

        .nav-link{
          padding: 0 0 #{pxToRem(10)} 0;
          @include font-size(16);
          background: $primary-white;
          border: 0;
          color: $secondary-dark-gray;
          &.active{
            color: $primary-gray;
            border-bottom: 0.25rem solid $primary-gray;
            cursor: default;
          }
          &:hover{
            color: $primary-gray;
          }
        }

        &:nth-child(1)
        {
          margin-left: #{pxToRem(7)};
        }

      }

      .nav li{
        @include font-size(24);
        line-height: 1.75rem;
        margin-right: 1rem;
        padding: 1rem 0rem;
        margin-left: 1rem;
      }
  }
  //legionella growth risk css starts
  &.transparent {
    ul{
      background: transparent;
      margin-bottom: 10px;
      &.nav-tabs{
        margin-left: 0px;
        padding-top:10px;
      }
      &:last-child{
        margin-bottom: 0px;
      }
      .nav-item{
       &:first-child{
        margin-left: 0px;
        padding-left: 0px;
       }
        .nav-link{
          background: transparent;
        }

      }

      @media screen and (max-width:550px) {
          .nav-item{
            &:last-child{
              margin-left: 0px;
              padding-left: 0px;
            }
          }
      }
    }
    .tab-content > .tab-pane {
      background:transparent;
    }
    .cleveland-tile{
      background-color: transparent;
      box-shadow: none;
    }
  }

  //legionella growth risk ends
  &.site-sub-nav {
    ul.nav{
      background: #fff !important;
      margin-bottom: 0px;
      .nav-item{
        &:first-child{
         margin-left: 10px;
         padding-left: 10px;
        }
       }
    }
    .tab-content > .tab-pane {
      background: #fff !important;
    }
  }

}

.line-chart-pills{
  ul li{
   &:nth-child(1){
       span:nth-child(2){
               width: #{pxToRem(30)};
               display: inline-block;
               border-bottom: 2px solid;
               vertical-align: middle;
       }
   }
   &:nth-child(2){
       span:nth-child(2){
               width: #{pxToRem(30)};
               display: inline-block;
               border-bottom: 2px dashed;
               vertical-align: middle;
       }
   }
   &:nth-child(3){
       span:nth-child(2){
               width: #{pxToRem(30)};
               display: inline-block;
               border-bottom: 2px dotted;
               vertical-align: middle;
       }
   }
   &:nth-child(4){
       span:nth-child(2){
           content: "";
           width: #{pxToRem(30)};
           vertical-align: middle;
           display: inline-block;
           height: #{pxToRem(2)};
           background-image: linear-gradient(to right, $primary-gray 69%, transparent 40%);
           background-size: 1.5rem 100%;
       }
       &:hover span:nth-child(2){
        background-image: linear-gradient(to right, $primary-white 69%, transparent 40%);
       }
       &:nth-child(4).active{
       span:nth-child(2){
           content: "";
           width: #{pxToRem(30)};
           vertical-align: middle;
           display: inline-block;
           height: #{pxToRem(2)};
           background-image: linear-gradient(to right, $primary-white 69%, transparent 40%);
           background-size: 1.5rem 100%;
       }
   }
}
   }
}
.bottom-10-tooltip{
  .tooltip{
    .tooltip-inner{
      div{
        margin-top:-25px;
      }
    }
  }
}
.watersafety-header
{
  .card.tile{
  .card-header {
    a.card-title {
      font-family: 'Interstate-Bold';
      margin-right: 0.3rem;
      @include font-size(14);
    }
  }
}
.tooltip {
  .tooltip-inner {
    text-align: center;
    white-space: pre-line;
    padding: 25px 0.8rem;
    > div{
      @include font-size(11);
     // margin-top: -20px;
      line-height: 1rem;
      ul{
        text-align:left;
        padding-left: 20px;
        margin-top: -15px;
        margin-bottom: 0;
        ul{
          padding-left: 20px;
          margin-top: 5px;
          li{
            list-style-type: circle;
          }
        }
        li{
          list-style-type: disc;
          margin-bottom: 5px
        }
      }
      p {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  .tooltip-content {
    margin-top: 0;
    display: none;
}
}

}
.distrubution-tile {
  .card.tile .card-header{
    span{
     float:left;
    }
      .card-title{
          @include font-size(16);
          margin-right: 5px;
          text-transform: capitalize;
          line-height: 1;
          max-width: 70%;
          @media screen and ( max-width: 992px) {
            max-width: 70%;
          }
      }
  }
}
.firstTile{
  .card.tile .card-header{
    .card-title{
      max-width: 90%;
      @media screen and ( max-width: 992px) {
        max-width: 90%;
      }
    }
  }
}
.tab-content > .tab-pane {
  background: $primary-white;
}
//KPI Overview
.whitePanel
{
  background: $primary-white;
  h1{
      .tooltip-info-icon{
        vertical-align: bottom;
        padding-left: 0px;
      }
      ul{
        text-align: left;
        li{
          list-style-type: disc;
        }
      }
    }
}

// Sites

.sites-view{

  // .tooltip{
  //  // left: 14px !important;
  // }
  .sites-tooltip{
    vertical-align: top;
    line-height: 31px;
    @include font-size(20);
    padding-left: 0px;
    margin-left: -2px;
    .tooltip.show{
      left: 4% !important;
      z-index: 999;
    }
  }.tooltip-inner{
    @include font-size(11);
    text-align:center;
  }
      ul{
        text-align: left;
        font-size: 11px;
        margin: 10px 0px 10px -17px;
        li{
          list-style-type: disc;
        }
    }
}
.placeMent{
  .tooltip{
    left: 4% !important;
   }
}
.CountbySite {
  .tooltip {
    left: 93px !important;
  }
}
//ICONS
[class*='iconwater-']:before{
	display: inline-block;
  font-family: 'nalco water font';
  @include fonts();
}

[class*='iconwater-'] {
	display: inline-block;
  margin-right:2px;
  background-repeat: no-repeat;
  width:20px;
  height:20px;
}
.iconwater-CVOC icon:before{content:'\0041';}
.iconwater-alert-yellow:before{content:'\0042'; color: #EEAF00; font-size:18px;}
.iconwater-dd-graph:before{content:'\0043';}
.iconwater-NoTestGrey:before{content:'\0046';}
.iconwater-suitcase-with-white-details:before{content:'\0047';}
.iconwater-HighRiskRed{
  background: url( '/assets/images/3d-red.svg');
  background-repeat: no-repeat;
  display: inline-block;
  margin-right: 0px !important;
  width: 20px;
  height: 20px;
}
.iconwater-LowRiskGreen{
  // background: url('/assets/images/3d-green.svg'); 
  width: 19px;
  height: 19px;
  background: #6fb43f;
  margin-top: 2px !important;
}
.iconwater-MediumRiskYellow{
  width: 18px;
  height: 18px;
  background: #eeaf00;
  margin-top: 3px !important;
  // background: url('/assets/images/3d-yellow.svg');
}
.iconwater-NoDataGrey, .icon-trend-NoTest{
  // background: url('/assets/images/3d-grey.svg');
  background: #949494;
  transform: rotate(45deg);
  width:15px;
  height:15px;
  margin-left: 2px;
}
.iconwater-OfflineGray{
  // background: url('/assets/images/3d-grey-offline.svg');
  width: 19px;
  height: 19px;
  background: #949494;
  background-repeat: no-repeat;
  margin-top: 2px !important;
  margin-right: 0px !important;
}
.iconwater-OfflineGray, .iconwater-OfflineGray:after, .iconwater-LowRiskGreen, .iconwater-LowRiskGreen:after{
  border-radius: 50%;
}
.icon-trend-NoTest{
  // background: url('/assets/images/3d-grey.svg');
  display: inline-block;
  background-repeat: no-repeat;
}
.iconwater-NoDataGrey:after{
  height: 0.55rem !important;
    width: 0.55rem !important;
    margin-left: 0.2rem !important;
    margin-top: 0.2rem !important;
}
.iconwater-MediumRiskYellow:after, .iconwater-LowRiskGreen:after, .iconwater-NoDataGrey:after, .iconwater-OfflineGray:after, .icon-trend-NoTest:after{
  content: '';
  height: 60%;
  width: 60%;
  display: block;
  position: relative;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.2) 0%, rgba(255, 255, 255, 0.4) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4d000000', endColorstr='#00000000',GradientType=1 );
  margin-left: 20%;
  margin-top: 20%;
}

.iconwater-alert-red:before{content:'\0042';color:$utilitarian-red ;font-size:18px;}


//admin icons
[class*='icon-admin-']:before{
  display: inline-block;
  font-size:24px;
   font-family: 'admin';
   font-style: normal;
   font-weight: normal;
   line-height: 1;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale
}
.icon-admin-Range:before{content:'\0041'; padding:5px 10px 0px 0px; color:$primary-gray}
.icon-admin-WSIOfferings:before{content:'\0042'; padding:5px 10px 0px 0px;color:$primary-gray}
.icon-admin-WSPlan:before{content:'\0043'; padding:5px 10px 0px 0px;color:$primary-gray}
.icon-admin-CustomerApproval:before{content:'\0044';padding:5px 10px 0px 0px;color:$primary-gray}
.icon-admin-Notification:before{content:'\0045';padding:5px 10px 0px 0px;color:$primary-gray}
.icon-admin-RAGThreshold:before{content:'\0046';padding:5px 10px 0px 0px;color:$primary-gray}

//trend icons

[class*='icon-water-']:before{
	display: inline-block;
   font-family: 'trend-icons';
   @include fonts();
}

.icon-water-Trend-Green, .icon-water-Trend-green {
  background: url('/assets/images/3d-green-plus.svg');
  width:20px;
  height:20px;
}

.icon-water-Trend-Red, .icon-water-Trend-red{
  width:20px;
  height:20px;
  background: url('/assets/images/3d-red-minus.svg');
}

.icon-water-Trend-Yellow , .icon-water-Trend-Ember, .icon-water-Trend-amber, .icon-water-Trend-yellow{
  width:20px;
  height:20px;
  background: url('/assets/images/3d-yellow-equal.svg');
}

//trend icons
[class^="icon-trend-"], [class*=" icon-trend-"] {
  font-family: 'trend-icons' !important;
  font-variant: normal;
  text-transform: none;
  @include fonts();
}
.icon-trend-NoTest:before {
  @include font-size(20);
  @include gradient-icons($linear-gradient-start-grey,$linear-gradient-start-grey);
}

.row-right{
  margin-right: -0.5rem;
}

.DonutChart{
  .highcharts-container {
    margin: auto;
  }
}

.lg-sites .tooltip-inner , .compliance-tab .tooltip-inner , .filterPanel .tooltip-inner {
  ul{
      text-align:center;
      @include font-size(11);
      margin: 10px 0px 10px -17px;
          li{
          list-style-type:disc;
          }
  }
}
.filterPanel   {
  .ico-info-thin {
    display: inline-block;
    margin-top: 19px;
  }
}
.watersafety-header  {
  .tooltip-inner{
    min-width: auto;
  }
}
.progress .tooltip-inner {
  min-width: auto;
}
.recommendations-scroll{
  overflow-y: scroll;
  height: 293px;
  padding-left: 16px;
  ul{
      border-left:1px solid white;
      font-size: 12px;
      padding-left: 0px;
      li{
          position: relative;
          border-bottom:1px solid #E2E2E2;
          list-style-type: none;
          padding:16px 0px;
          ul{
              margin-bottom: 0px;
              li{
                  padding-top:5px;
                  padding-bottom:0px;
                  border:none;
                  padding-left: 16px;
                  &:before{
                      position: absolute;
                      left: 1px;
                      top: 11px;
                      content: '';
                      width: 4px;
                      height: 4px;
                      background-color: #616365;
                      border-radius: 100%;
                  }
              }
          }
      }
  }
}
.asset-parameter-grid .tooltip-inner{
  text-transform: capitalize;
}

.modal .modal-dialog .modal-content .modal-body::-webkit-scrollbar-track{
  background-color: transparent;
}
.k-grid-table{
  td{
    .bs-tooltip-bottom{
      &:first-child{
        left:10px !important;
      }
    }
  }
}
.more-comp-perf{
  position: absolute;
  top: 9px;
  right: 15px;
}
.pop-up-grid-view{
  right: 15px;
    top: 14px;
    position: absolute;
}
.show-more-right{
  position: absolute;
  right: 25px;
  top: 8px;
  &.kpi-graph{
    top:18px;
    right: 15px;
  }
}
.pill-borders .filtertab-list li {
  padding: 7px 60px 7px 16px !important;
}
.kpiOverviewGraph{
  overflow: hidden;
  display: block;
  height: 423px;
  .tooltip{
    left: 109px !important;
  }

  @media screen and (max-width:550px) {
    .tooltip{
      left: 14px !important;
    }
  }
}

.subListView-modal{
  .modal-dialog{
    min-width: 60rem;
    top: 12%;
  }

  .card.tile{
    box-shadow: none;
  }
  .k-grid-header {
      border-right: 1px solid #dedede;
  }
  .k-grid-content {
      border-right: 1px solid #dedede;
  }

  .table-wrapper {
      height: 18rem;
      // overflow-y: scroll;
      background: #fff;
  }
  .tile-content-wrapper {
    height: 20rem;
  }
}

@media (max-width: 1120px) {
    .kpiOverviewGraph{
      overflow: inherit;
      height: 450px;
    }
    .subListView-modal{
      .modal-dialog{
        min-width: auto;
       }
    }
}
//Admin page

.admin-dropdown{
  padding:0 10px 0 10px;
  min-width: 50px;
  .selected-text{
    width:72% !important;
    text-align: center;
    padding: 9px;
  }
  .dropdown{
    padding: 0;
    height: 35px;
    min-width: 60px;
    border-radius: 0;
    border-color: $loader-border;
    .dropdown-menu.show{
      margin-left: -0.19rem;
      width: 53px;
      top: 24px !important;
      left: 3px !important;
    }
    .btn{
      background-image: linear-gradient(180deg, #fff, #fff) !important;
      min-width:20px;
      margin-left:0 !important;
      &:after{
        opacity: 1 !important;
        left: 38px;
        top: 57%;
      }
    }
  }
  &.rag-drop{
    min-width: 97%;
    padding: 0 ;
    margin-top: -2px;
    .selected-text{
      width:100% !important;
      text-align: left;
      padding-left: 4px;
      font-size: 12px;
      font-family: "Interstate-Regular", sans-serif;
    }
    .dropdown{
      height: auto;
      border: 0;
      background: transparent;
      .btn{
        background-image: linear-gradient(180deg,transparent, transparent) !important;
        min-width: 20px;
        margin-left: 0 !important;
        padding: 0 !important;
        height: 15px;
        background-color: transparent;
        &::after{
          left: auto;
        }
      }
      .dropdown-menu{
        max-height: 20rem;
        .dropdown-item{
          padding-left: 15px;
        }
      }
    }
  }
}
.font-14{
font-size:14px;
}
//delete on Admin page
.modal-body{
background-color: #fff !important;
}
.delete-confirmation{
  .modal-body{
    display:none;
  }
  .modal-content{
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.7);
    width:83%;
  }
  .modal-dialog{
    box-shadow: none;
  }
  .modal-header .close {
    margin: -2rem -1rem -1rem auto;
  }
}
.k-pager-wrap{
  background: #fff;
}
.border-grey{
  border:  1px solid #BBBCBE !important
}
.popup-loader{
  .loader {
    margin: 5% auto !important;
  }
}

#ViewTrendGraph{
  width: fit-content;
}
.testResultsTable{
  .loader {
    margin: 2% auto ;
  }
}
// .testResultsTable{
//   .tile-content-wrapper{
//       // min-height: 250px;
//   }
// }
.kpiTile{
  .tile-content-wrapper{
      min-height: 160px;
  }
}
.DistributionWrap{
  .tile-content-wrapper{
      min-height: 356px !important;
  }

}
.LgrowthRsikList {
  .tile-content-wrapper{
      min-height: 300px;
      background: #fff;
  }
  .loader {
    margin: 5% auto ;
  }
}
.KPIGraphTile{
  .tile-content-wrapper{
      min-height: 360px;
      background: #fff;
  }
  .loader {
    margin: 8% auto ;
  }
}
.distrubution-tile {
  .tile-content-wrapper{
    min-height: 329px;
    background: #fff;
    overflow: auto;
    max-height: 206px;

  }
  .loader {
    margin: 90px auto;
  }
}
.riskStatusWrap , .recommendations-tile , .siteCompPerfWrap   {
  .tile-content-wrapper{
    min-height: 330px;
    background: #fff;
  }
  .pop-position , .lg-sites-list , .leg-test-col , .lg-sites-grid  {
    .tile-content-wrapper{
      min-height: 200px;
     }
    }
    .lg-sites-grid{
      background: $primary-white;
    }
  .loader , .leg-test-col .loader {
    margin: 90px auto;
  }
}
.compPerfWrap{
  .tile-content-wrapper{
      overflow: hidden;
      // height: 473px;
      min-height: 473px;
  }
}
.CompPerfListWrap{
  .tile-content-wrapper{
    min-height: 250px;
  }
}
.mapListWrap , .across-tabs{
  .tile-content-wrapper{
    min-height: 200px;
  }
  .loader{
    margin: 30px auto;
  }
}
.k-grid-header .k-header > .k-link > .k-icon.k-i-sort-desc-sm, .k-grid-header .k-header > .k-link > .k-icon.k-i-sort-asc-sm  {
  margin-left: 0;
}

.custom-infobox .bs-tooltip-bottom{
  left: 10px !important;
  margin: 3px;
  width: 98%;
}
.bottom-10-tip .bs-tooltip-bottom {
  left: 10px !important;
  margin: 8px;
  width: 98%;
   .tooltip-inner {
     min-width: 98%;
     padding-top: 2px;
   }
}
.modal-open {
  margin-right: 18px;
}
table.k-grid-table tr:last-child td .tooltip.bs-tooltip-bottom {
  top: inherit !important;
  bottom: 35px;
}
.siteFilter {
  display: block;
  position: relative;


}
.page-filters{
  .selected-text{
    font-family: "Interstate-Regular", sans-serif;
  }
}
.kpiTile {
  .tooltip.bs-tooltip-bottom {
    left: 10px !important;
    font-size: 12px;
  }
  @media screen and (min-width: 992px) {
    &:last-child{
      .tooltip.bs-tooltip-bottom {
        left: inherit !important;
        right: 10px ;
      }
    }
  }

}
.lg-sites.sites-view{
  .tooltip.bs-tooltip-bottom  {
      left: 73px !important;
  }
}
.CompPerfListWrap{
  .loader{
    margin: 4% auto;
  }
}
.k-animation-container * {
  color: #616365 !important;
}

.DonutChart .highcharts-container {
  z-index: 1 !important;
}

@media screen and (max-width:552px){
  .content{
    .siteFilter{
      .showMe{
        left: 8px;
      }
    }

  }
  .sites-view{

    .tooltip{
      left: 14px !important;
    }
  }

  .site-nav {
    ul{
      .nav-item{
        &:nth-child(1)
        {
          margin-left: 0;
        }
        &:nth-child(2){
          border-bottom: 1px solid #dee2e6;
        }
      }
    }


  &.site-sub-nav {
    ul.nav{

      .nav-item{
        &:first-child{
         margin-left: 0px;
         padding-left: 8px;
        }
       }
    }
  }}

  .DistributionWrap{
    .bs-tooltip-bottom{
      left: 7px !important;
    }
  }

}
@media screen and (max-width: 991px) {
  .filters-btn {
    margin: 1em;
  }
  .page-filters{
    height: 0;
    transition: all 0.5s ease-in;
    display: none;
    &.showFilters {
      display: block;
      max-width: 100% !important;
      width: 100%;
      background: #fff;
      padding: 10px;
      margin: 10px;
      box-shadow: 1px 1px 1px 1px #e2e2e2;
      height: auto;
      visibility: visible;
    }
    .calendarFilter , .float-right {
      width: 100%;
      margin:5px 0 !important;
    }
    .filterWidth{
      width: 100%;
    }
  }

}

@media screen and (max-width:770px){
  .content{

    .tile-content-wrapper{
      .loader{
        top: -44px !important;
        position: relative;
      }
    }

    .mapListWrap{
      .tile-content-wrapper{
        .loader{
          top: -40px !important;
          position: relative;
        }
      }
    }
  }

}

@media screen and (max-width:992px){
  .content{

    .tile-content-wrapper{
      .loader{
        position: relative;
          top: 0;
      }
    }

    .mapListWrap{
      .tile-content-wrapper{
        .loader{
          position: relative;
          top: 0;
          }
        }
      }
    }
    .mr-auto.p-2.mw-50 {
      max-width: 72% !important;
    }
    .comperTooltip .tooltip{
      left: 104px !important;
    }
  }

  @media screen and (min-width:1000px) and (max-width:1280px){
    .comperTooltip .tooltip{
      left: 9% !important;
    }
  }

@media screen  and (max-width:767.8px){
  nalco-subheader-left-section {
    min-height: 100px !important;
  }
  .comperTooltip .tooltip{
    left: 12px !important;
  }
}


.nalco-enterprise-overview{
  .tooltip{
    &.bs-tooltip-bottom {
        z-index: 500 !important;
}}
}

// .col-arrow-top .k-header .k-link span:not(.k-icon)::before{
//   content: "\E006";
//     color: #cccccc;
//     vertical-align: top;
//     box-sizing: border-box;
//     width: 1em;
//     height: 1em;
//     font-size: 16px;
//     font-family: 'WebComponentsIcons';
//     font-style: normal;
//     font-variant: normal;
//     font-weight: normal;
//     line-height: 1;
//     text-transform: none;
//     text-decoration: none;
//     display: inline-block;
// }