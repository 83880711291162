@import "../../../../styles/variables.scss";
.mapScalingComponent{
    .dd-container.select-dropdown .heading{
        font-size: 11px;
    }
    .measurementName{
        padding: 10px;
        white-space: break-spaces;
        overflow-wrap: break-word;
    }
    .required {
        color: $utilitarian-red;
      }
    .table {
        border: 1px solid #bbb;
        .table-row{
            display: table;
            width: 100%;
            height: 168px;
            .table-cell{
                display: table-cell;
            }
        }
    }
    .new-col{
        border-right: 1px solid #bbb;
        max-width: 10rem;
        width: 10rem;
        vertical-align: middle;
    }
    .card.tile{
        margin-top: 0rem;
    }
    .dropdownHeading{
        padding-top: 8px;
        padding-left: 16px;
        font-size: 11px;
        color: #616365;
    }
    .selectDropdown{
        height: 48px;
        padding-top: 18px;
        border: 1px solid #bbb;
        border-radius: 2px;
    }
    .disabled_bg{
        background-color: #e9ecef;
    }
    .float-container {
        border: 1px solid #BBBCBE;
        padding: 0 8px;
        position: relative;
        height: 3rem;
        border-radius: 0.125rem;
        input {
            border: none;
            min-height: 20px !important;
            height: 20px !important;
            margin-left: 5px;
            position: relative;
            bottom: 5px;
            display: inherit;
            color: #616365;
            width: stretch;
            padding-left: 0.375rem;
        }
        label {
            transform: translate(6px, 5px) scale(1);
            font-size: 11px;//0.5625rem;
        }
    }
    .nalco-dropdown-scaling {
        overflow: inherit;
        nalco-dropdown .dropdown {
            width: 5.5rem;
            min-height: 2.78rem;
            border-radius: 0.125rem;
            min-width: 100%;
            margin-bottom: 0px;
            .drop-btn{
                &.dropdown-toggle {
                    .selected-text {
                        // color: $primary-blue;
                        position: relative;
                        width: 6rem !important;
                        @include font-size(14);
                        font-family: $font-family-base;
                        left: 0 !important;
                        display: block;
                        top: -5px;
                    }
                    .icon-calendar-icon {
                        &:before {
                            top: 9px;
                            @include margin-padding(right, #{pxToRem(10)}, all, null);
                        }
                    }
                    .label {
                        position: relative;
                        @include font-size(9);
                        line-height: #{pxToRem(2)};
                        top: -3px;
                        display: inline-block;
                        left: 0 !important;
                    }
                    &:after{
                        top: 40% !important;
                    }
                }
            }
            .dropdown-menu {
                &.show {
                    top: 32px !important;
                    -ms-overflow-style: -ms-autohiding-scrollbar;
                    left: 0rem !important;
                    min-width: 100%;
                    margin-left: 0px;
                }
                button {
                    &.dropdown-item {
                        @include font-size(14);
                        min-width: 100%;
                        padding: 0.5rem 1rem;
                    }
                }
            }
        }
        .select-dropdown{
            .tooltip.bs-tooltip-bottom{
                bottom: unset;
            }
        }
        .dd-container.disable{
            background-color: #e9ecef;
            opacity: 1;

            .dd-display-item{
                color: #616365 !important;
                background-color: #e9ecef !important;
            }
        }
    }
    .position-relative {
        position: relative !important;
        .start-label {
            // font-size: 0.5625rem;
            font-size: 11px;
            left: 1rem;
            display: block;
            z-index: 1;
            position: absolute;
            font-family: "Interstate-Regular", sans-serif;
            color: #616365;
            top: -17px;
            margin-top: 0.375rem;
            margin-bottom: 0rem;
            width: 7rem;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }
    kendo-datepicker .k-picker-wrap{
        height: 48px;
        padding: 10px 0px 0px 0px;
        min-width: 160px;
        border-radius: 0.125rem;
        border: 1px solid #bbb;
        font-size: 2px;
        margin-bottom: 5px;
    }
    kendo-datepicker .k-icon{
        height: 27px;
    }
    kendo-datepicker .k-dateinput{
        font-size: 12px;
    }
    .transform{
        text-transform: initial;
    }
    .scaleDate{
        padding-left: 10px;
        width: 80px;
        text-align: center;
    }
    .wrap{
        white-space: break-spaces;
        overflow-wrap: break-word;
    }
    .checkbox{
        margin-top: 5px;
        height: 15px;
        width: 15px;
    }
    .dd-container.select-dropdown .filterByCategory .dropdown {
        .dropdown-menu.show{
            display: contents !important;
        }
        .btn.dropdown-toggle:after{
            top: 12px !important;
        }
    }
    .leftMargin {
        margin-left: 6px;
    }
    .cell-padding{
        padding: 22px 4px;
    }
    .new-checkbox{
        height: 15px;
        width: 15px;
        margin-right: 5px;
        position:relative;
        vertical-align: middle;
    }
    .table-rtd {
        border: 1px solid #bbb;
        margin-bottom: 1rem;
        .table-row{
            display: table;
            width: 100%;
            height: 75px;
            .table-cell{
                display: table-cell;
            }
        }
    }
}