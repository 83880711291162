@import "../../../../../styles/variables.scss";

.nalco-inventory-list {
    .inventory-list-modal {
        .tab-pane.active {
            max-height: #{pxToRem(400)};
            overflow: auto;
        }
        .error-state-message{
            padding-top: 1rem;
            padding-left: 1rem;
        }
    }
}

.inventory-list-modal{
    min-width: fit-content !important;
    max-width: fit-content !important;
    .tile-content-wrapper {
        min-height: #{pxToRem(240)};
        background-color: #FFF;
    }

    .modal-dialog {
        min-width: 60rem !important;
    }

    .modal-body {
        min-height: #{pxToRem(240)};
        padding: 0 !important;
        max-height: fit-content !important;

        .inputFeild {
            width: 4rem;
            height: calc(1.893rem + 2px);
            font-size: 12px;
        }

        .selectDropdown {
            width: 6rem;
            height: calc(1.893rem + 2px);
            font-size: 12px;
            outline: 0px;  
        }

        .serialNumberClass, .productNameClass {
            min-width: 75px;
            max-width: 75px;
            text-align: left;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            cursor: pointer;
        }
    
        .tankTypeClass,.slugFeedClass {
            min-width: 85px;
            max-width: 85px;
            text-align: center;
        }

        .dataSourceClass {
            min-width: 100px;
            max-width: 96px;
            text-align: center;
        }
    
        .slugClass {
            min-width: 90px;
            max-width: 90px;
            text-align: left;
        }
    
        .targetUsageClass {
            min-width: 180px;
            max-width: 180px;
            text-align: left;
        }

        .serialNumberHeading, .productNameHeading {
            min-width: 75px;
            max-width: 75px;
            text-align: left;
        }
    }

    .modal-footer {
        display: flex;
        justify-content: flex-end;
    }

    .assets-list {
        max-height: #{pxToRem(400)};
        padding: 0.5rem 1rem 1rem 1rem !important;
        min-height: #{pxToRem(300)};
        @include margin-padding(all , #{pxToRem(0)}, all ,null);
    }

    .custom-control-label{
        cursor: pointer;
        &:after{
            left:#{pxToRem(2)};
        }
    }
    .custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label {
        cursor: inherit; 
        &::before{
            background-color: $light-gray !important;
        }
    }
    .assets-list .asset-row {
        padding: 0.3rem 1rem 1rem 1.5rem !important;
        @include font-size(14);
    }

    .table-row-header{
        display: table-row;
        font-weight: bold;
        
        .table-cell{
            display: table-cell;
        }
    }
    .table-row-body{
        display: table-row;

        .table-cell{
            display: table-cell;
        }
    }
}
.inventory-modal-checkbox {
    input {
      display: none
    }
    .checkmark {
      position: relative;
      left: 0;
      width: #{pxToRem(18)};
      height: #{pxToRem(18)};
      border: 1px solid $light-gray;
      display: inline-block;
      @include margin-padding(right , #{pxToRem(5)} , all, null);
      vertical-align: top;
      background-color: $light-gray;
      border-radius: #{pxToRem(4)};
    }
    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }
    input:checked~.checkmark:after {
      display: block;
    }
    input:checked~.checkmark {
      background-color: $primary-blue;
      border: 1px solid $primary-blue;
    }
    .checkmark:after {
      left: #{pxToRem(5)};
      top: #{pxToRem(2)};
      width: #{pxToRem(5)};
      height: #{pxToRem(10)};
      border: solid $primary-white;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      border-radius: #{pxToRem(0)};
    }
    input:disabled~.checkmark {
        background-color:$primary-gray;
        border: 1px solid #949494 !important;
    }
    input:disabled~.checkmark:after{
        background: none;
    }
}