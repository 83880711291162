@import "../../../../../styles/variables.scss";

.asset-status-grid {
  height: 70vh;
  min-height: 70vh;
  overflow-y: auto;
  overflow-x: auto;

  input[type="number"],
  .tempTextBox {
    width: 100%;
    text-align: center
  }

  .first-col {
    overflow: hidden;
    text-overflow: ellipsis;
    &::before {
      content: '';
      display: inline-block;
    }
  }

  kendo-dropdownlist {
    width: 100%;
    font-size: 12px;

    .k-dropdown-wrap {
      .k-input {
        @include rhythm(12);
      }
    }
  }

  .k-numerictextbox {
    width: 50px;
    font-size: 12px;
    .k-numeric-wrap{
      color: #656565;
      background-color: #fff;
      border-color: rgba(0, 0, 0, 0.08);
      
    }
  }


  .targetValueWidth{
    width: 100%;
  }

  // &.isError{
  //     border: 1px solid $validation-red;
  // }
  .warningMsg {
    padding-top: 16px;
    padding-left: 12px;
    text-align: left;
    font-size: 12px;
    color: $validation-red;
  }

  .frontEndUnit {
    text-transform: none;
  }

  .inValid {
    border: 1px solid $validation-red;
  }

  .minusBox{
    // display: inline-block;
    background-color: #ececec;
    border-radius: 2px;
    float: left;
    padding: 7px;
    font-size: 19px;
    width: 21px;
    height: 28px;
  }

  .percentageBox{
    background-color: #ececec;
    border-radius: 2px;
    float: left;
    padding: 5px;
    width: 21px;
    height: 28px;
  }
  .plusBox{
    background-color: #ececec;
    border-radius: 2px;
    float: left;
    padding: 6px;
    font-size: 17px;
    width: 21px;
    height: 28px;
  }

  .errorMsgDiv{
    font-size: 1rem;
    line-height: 1.5rem;
  }
}

hr {
  border-top: 0px;
}