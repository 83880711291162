/****************************************************/

// This files contains the styles that are required for the sub-header component

/****************************************************/
header {

  &.sub-header {
    position: absolute;
    top: 4rem;
    margin-left: 0.5rem;
    right: 0;
    left: 0;
    z-index: $default-header-zindex;
    .container-fluid {
      padding: 0.75rem 0;
      margin: 0 auto;
      min-height: 4rem;
    }
    .main-content-collapse {
      background-color: #fff;
      box-shadow: 0 4px 4px -2px #0000004d;
      width: 100%;
     margin-left: 0px;
    }
    .mobile-home-page{
      position: absolute;
    }
    
    .main-content-expand {
      background-color: #fff;
      box-shadow: 0 4px 4px -2px #0000004d;
      margin-left: 130px;
      width: 100%;
    }

    .blue-header-background {
      background-color: $background-light-blue !important;
    }
    
    .no-header-background {
      background-color: transparent;
    }
    
    .white-header-background {
      background-color: #fff;
    }
    @media (min-width:391px) and (max-width: 767px) {
      .main-content-collapse-view {
       width: 95%;
       margin-left: 0;
     }
     }
     @media (min-width: 992px) and (max-width: 1500px) {
      .main-content-collapse {
        width: 120%;
      }
    }
    h3 {
      background: transparent;
      margin-bottom: 0;
      padding-bottom: 0.625rem;
      padding-top: 0.625rem;
      padding-left: 1rem;
    }
    .btn {
      @include rhythm(14, 16);
      padding: 0.75rem 2rem;
      &.btn-secondary {
        padding: 11px 32px;
      }
      &.close-btn{
        width: 129px;
        text-align: center;
        height: 40px;
        padding: 0px;
        line-height: 39px;
        &:before {
          font: normal normal normal 18px/18px 'Nalco-additional-icons';
          content: '\E927';
          margin-right: .2rem;
          vertical-align: text-bottom;
          position: static;
        }
      }
      &.save {
        border: 0 none;
      }
    }
    .btn-group {
      .btn {
        @include rhythm(16, 20);
        padding: 10px 16px;
        width: 13rem;
        min-width: fit-content;
        &:hover{
          z-index:unset;
        }
        
      }

      button.btn {
        &.btn-primary {
          &:after , &:before {
            position: absolute;
            display: block;
            border: 20px solid transparent;
            border-left: 15px solid $primary-blue;
            border-right: 0;
            top: 0px;
            z-index: 10;
            content: '';
            right: -15px;
          }
          &:last-child {
            &:before, &:after {
              display: none;
            }
          }
        }
        &.btn-secondary {
          &:before {
            position: absolute;
            display: block;
            border: 20px solid transparent;
            border-left: 15px solid #7f7a7a;
            border-right: 0;
            top: -1px;
            z-index: 10;
            content: '';
            right: -15px;
          }
          &:after {
            position: absolute;
            display: block;
            border: 20px solid transparent;
            border-left: 15px solid #ffffff;
            border-right: 0;
            top: -1px;
            z-index: 10;
            content: '';
            right: -14px;
          }
          &:last-child {
            &:before, &:after {
              display: none;
            }
          }
        }
      }
    }
    
    .dropdown {
      border-radius: 0;
      .dropdown-menu{
        &.show{
          min-width: 100.7% !important;
          margin-left: -1.05rem;
          margin-top: 0.46rem;
        }
      }
      .dropdown-toggle {
        color: $primary-blue;
        background-color:white; //for blue background color  of dropdown 
      }
    }
    nalco-dropdown+nalco-dropdown>.dropdown {
      border-left: 0;
    }
    nalco-dropdown:first-child .dropdown {
      border-top-left-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem;
    }
    nalco-dropdown:last-child .dropdown {
      border-top-right-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
    }
    .btn-grp{
      display: flex;
      flex-direction: row;
    }
  }
  @include media-breakpoint-down(sm) {
    &.sub-header {
      margin-left:0rem;
      position: relative !important;
      display: inline;
      .container-fluid {
        background-color: initial !important;
        box-shadow: initial !important;
      }

      
    }
  }
}
.searchAutoComp{
  float: right;
  margin-right: 25px;
  margin-top: 2px;
}

.fixed-position{
  position: fixed !important;
}
#Home {
  width: 64px;
  height: 43px;
  // margin-top: -.5rem;
  // position: absolute;
  float: left;
  display: block;
  a {
    background-color: transparent;
    display: block;
    padding: 0.5rem 0.75rem;
    &:hover {
    background-color: transparent;
    display: block;
    padding: 0.5rem 0.75rem;
    }
  }
}

@include media-breakpoint-down(sm) {
#Home {
  display: none;
}
}

@media print{
  #Home a{
    display: none !important;
  }
}