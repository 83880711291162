@import './../../../../styles//variables.scss';

.nalco-home {
    color: $secondary-color;
    font-family: $base-font-family;
    background: $background-light-blue;
    position: relative;
    margin-left: -1.5rem;
    margin-right: -0.75rem;

    .list-text {
        display: inline-block;
    }

    .top-class {
        width: 100%;
        height: max-content;
        height: intrinsic;    
        height: -moz-max-content;   
        height: -webkit-max-content; 
        background: $default-white;
        border-radius: 24px 24px 8px 8px;
        margin-left: 3px;
    }

    .card {
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
        border-radius: 8px 8px 8px 8px;
        margin-bottom: #{pxToRem(20)};
    }

    .card-header {
        color: $default-white;
        background: $secondary-color;
        border-radius: 8px 8px 8px 8px;

    }

    .home-icon-anchor {
        padding: 9px;
        border-radius: 8px 8px 8px 8px;
        background: rgba(255, 255, 255, 0.08);
        cursor: default;
    }

    .header-description {
        font-size: #{pxToRem(10)};
        padding-top: 10px;
        opacity: 80%;
    }

    .card-header-title {
        padding: 12px 0px;
    }

    .label-large {
        color: $secondary-color;
        font-size: #{pxToRem(13)};
        line-height: #{pxToRem(27)};
    }

    .label-large:hover {
        text-decoration: underline;
    }

    .card-list {
        margin-left: -38px;
        transition: all 0.5s ease;
    }

    .green-dot {
        display: inline-block;
        margin-right: 5px;
        width: 8px;
        height: 8px;
        background: #4FC2B0;
        border-radius: 50%;
    }

    .list-text:nth-child(2),.list-text:nth-child(3){
        margin-left: 20px;
    }

    .card-section {
        margin-top: -87px;
    }

    .logo-text {
        font-weight: 900;
    }

    .home-header {
        height: 220px;
    }

    .heading {
        margin-left: -10px;
    }

    .card-body {
        transition: all 0.5s ease;
    }

    .cursor-icon {
        cursor: pointer;
    }

    .title-medium {
        color: $primary-color;
        font-weight: 600;
        font-family: $base-font-family;
    }

    .user-profile-home{
        margin-bottom: 20px;
    }

    .no-content-msg{
        text-align: center;
        font-size: #{pxToRem(13)};
    }

    @media (min-width:321px) and (max-width: 740px) {
        .home-header {
            height: 300px;
        }
        .sub-text{
            margin-top: 0.5rem;
        }
        .list-text{
            width: 100%;
        }
        .core-elements-card{
            margin-top: 1.25rem;
        }
        .list-text:nth-child(2),.list-text:nth-child(3){
            margin-left: 0px;
        }
    }

}