@import "../../../../../styles/variables.scss";
.toggleButton{
  left: -20px;
  float: right;
  top: -16px;
  position: relative;
  display: inline-flex;
  p {
    font-size: 14px;;
  }
  .ico-toggle{
    &:after {
      color: $primary-gray;
    }
  }
  .favorite-toggle input:checked + .ico-toggle:after {
    color: $primary-blue !important;
  }
}
.controller-Repository {
   @include margin-padding(all, null, all, #{pxToRem(20)} #{pxToRem(10)});  
    .div-view{
      text-align: center;
      margin-top: 10px;
    }
    .hr{
     width: 100%;
     border-top: 0.1px solid $light-gray;
     margin-top: 0.2rem;
     margin-bottom: 0.2rem;
    }
  
   .btn-width{
    width: 70%;
   }
   .btn-center{
      display: flex;
      justify-content: center;
      align-items: center;
      height: 50px;
   }
   .controller-image {
    margin-top: 0.5rem;
    margin-left: 24rem;
    height: 7rem;
    width: 7rem;
    background-repeat: no-repeat;
    background-position: center !important;
   }
   .dropdown-item  {
    font-size: 0.75rem;
   }
   
   @media only screen and (max-width: 2600px){.left {margin-left: 13rem;}}
   @media only screen and (max-width: 2200px){.left {margin-left: 10rem;}}
   @media only screen and (max-width: 1900px){.left {margin-left: 8rem;}}
   @media only screen and (max-width: 1600px){.left {margin-left: 7rem;}}
   @media only screen and (max-width: 1500px){.left {margin-left: 6rem;}}
   @media only screen and (max-width: 1400px){.left {margin-left: 5rem;}}

  }