.filterPanel
{
    border-bottom: 1px solid #e2e2e2;
    padding-left: 20px;
    padding-right:10px;

}
.mapPanel
{
    padding: 0px;
}

#EnterpriseKpimapView {
  div.map-container-inner {
    div.MicrosoftMap {
      .letterbox{
        display: none !important;
      }
    }
  }
}


@media screen and (max-width: 992px) {
    .filterPanel{

        .filtertab-checkbox{
            padding-left: 0;
            float: left !important;

            .filter-text{
                width: 100%;
            }
        }
    }

}


    @media screen and (max-width: 550px){
        .filterPanel{

            .filtertab-checkbox{
                li{
                    &:hover{
                        border: solid 1px #616365 !important;
                        background-color: transparent !important;
                        color: #616365 !important;
                    }
                
            }
        li.active{
            border: solid 1px #616365 !important;
            color: #fff !important;
            background-color: #616365 !important;
        }}}
    // .no-header{
    //     .infoBox{
    //         top: 132.233px !important;
    //         }
    //     }
    }
