﻿//****************************************************/
// This files contains the scss for fonts
//****************************************************/
@font-face {
  font-family: 'Interstate-Regular';
  src: url('../assets/fonts/interstate-regular.eot');
  src: url('../assets/fonts/interstate-regular.eot?#iefix') format('embedded-opentype'),
  url('../assets/fonts/interstate-regular.woff') format('woff'),
  url('../assets/fonts/interstate-regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Interstate-Bold';
  src: url('../assets/fonts/interstate-bold.eot');
  src: url('../assets/fonts/interstate-bold.eot?#iefix') format('embedded-opentype'),
  url('../assets/fonts/interstate-bold.woff') format('woff'),
  url('../assets/fonts/interstate-bold.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'icomoon_nav';
  src:  url('../assets/fonts/icomoon_nav.eot?qi3aj4');
  src:  url('../assets/fonts/icomoon_nav.eot?qi3aj4#iefix') format('embedded-opentype'),
    url('../assets/fonts/icomoon_nav.ttf?qi3aj4') format('truetype'),
    url('../assets/fonts/icomoon_nav.woff?qi3aj4') format('woff'),
    url('../assets/fonts/icomoon_nav.svg?qi3aj4#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'icon_Service_Summary';
  src:  url('../assets/fonts/icon_Service_Summary.eot?123sjw');
  src:  url('../assets/fonts/icon_Service_Summary.eot?123sjw#iefix') format('embedded-opentype'),
    url('../assets/fonts/icon_Service_Summary.ttf?123sjw') format('truetype'),
    url('../assets/fonts/icon_Service_Summary.woff?123sjw') format('woff'),
    url('../assets/fonts/icon_Service_Summary.svg?123sjw#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'icon-TrainingIcon';
  src:  url('../assets/fonts/icon-TrainingIcon.eot');
  src:  url('../assets/fonts/icon-TrainingIcon.eot?k06xt3#iefix') format('embedded-opentype'),
    url('../assets/fonts/icon-TrainingIcon.ttf?k06xt3') format('truetype'),
    url('../assets/fonts/icon-TrainingIcon.woff?k06xt3') format('woff'),
    url('../assets/fonts/icon-TrainingIcon.svg?k06xt3') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Interstate-Light';
  src: url('../assets/fonts/interstate-light.eot');
  src: url('../assets/fonts/interstate-light.eot?#iefix') format('embedded-opentype'),
  url('../assets/fonts/interstate-light.woff') format('woff'),
  url('../assets/fonts/interstate-light.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Interstate-Extra-Light';
  src: url('../assets/fonts/interstate-extra-light.eot');
  src: url('../assets/fonts/interstate-extra-light.eot?#iefix') format('embedded-opentype'),
  url('../assets/fonts/interstate-extra-light.woff') format('woff'),
  url('../assets/fonts/interstate-extra-light.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'NALCO-Nav-Icons';
  src:  url('../assets/fonts/NALCO-Nav-Icons.eot?ckw4cx');
  src:  url('../assets/fonts/NALCO-Nav-Icons.eot?ckw4cx#iefix') format('embedded-opentype'),
    url('../assets/fonts/NALCO-Nav-Icons.ttf?ckw4cx') format('truetype'),
    url('../assets/fonts/NALCO-Nav-Icons.woff?ckw4cx') format('woff'),
    url('../assets/fonts/NALCO-Nav-Icons.svg?ckw4cx#NALCO-Nav-Icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Nalco-additional-icons';
  src:  url('../assets/fonts/Nalco-additional-icons.eot?2acw8a');
  src:  url('../assets/fonts/Nalco-additional-icons.eot?2acw8a#iefix') format('embedded-opentype'),
    url('../assets/fonts/Nalco-additional-icons.ttf?2acw8a') format('truetype'),
    url('../assets/fonts/Nalco-additional-icons.woff?2acw8a') format('woff'),
    url('../assets/fonts/Nalco-additional-icons.svg?2acw8a#Nalco-additional-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'nalco-asset-icon';
  src: url('../assets/fonts/nalco-asset-icon.eot?59685721');
  src: url('../assets/fonts/nalco-asset-icon.eot?59685721#iefix') format('embedded-opentype'),
       url('../assets/fonts/nalco-asset-icon.woff?59685721') format('woff'),
       url('../assets/fonts/nalco-asset-icon.ttf?59685721') format('truetype'),
       url('../assets/fonts/nalco-asset-icon.svg?59685721#nalco-asset-icon') format('svg');
  font-weight: normal;
  font-style: normal;
}


//Trend Icon
@font-face {
  font-family: 'nalco';
  src: url('../assets/fonts/nalco.eot');
  src: url('../assets/fonts/nalco.eot?#iefix') format('embedded-opentype'),
       url('../assets/fonts/nalco.woff') format('woff'),
       url('../assets/fonts/nalco.ttf') format('truetype'),
       url('../assets/fonts/nalco.svg#nalco') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'nalco water font';
  src: url('../assets/fonts/nalco-water-font.eot');
  src: url('../assets/fonts/nalco-water-font.eot?#iefix') format('embedded-opentype'),
       url('../assets/fonts/nalco-water-font.woff') format('woff'),
       url('../assets/fonts/nalco-water-font.ttf') format('truetype'),
       url('../assets/fonts/nalco-water-font.svg#nalco-water-font') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'map-icon';
  src:  url('../assets/fonts/map-icon.eot?kpmu74');
  src:  url('../assets/fonts/map-icon.eot?kpmu74#iefix') format('embedded-opentype'),
    url('../assets/fonts/map-icon.ttf?kpmu74') format('truetype'),
    url('../assets/fonts/map-icon.woff?kpmu74') format('woff'),
    url('../assets/fonts/map-icon.svg?kpmu74#map-icon') format('svg');
  font-weight: normal;
  font-style: normal;
}
//Risk, No Test and Trend icons
@font-face {
  font-family: 'trend-icons';
  src:  url('../assets/fonts/trend-icons.eot?v64l39');
  src:  url('../assets/fonts/trend-icons.eot?v64l39#iefix') format('embedded-opentype'),
    url('../assets/fonts/trend-icons.ttf?v64l39') format('truetype'),
    url('../assets/fonts/trend-icons.woff?v64l39') format('woff'),
    url('../assets/fonts/trend-icons.svg?v64l39#trend-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'asset-gridlist-icon';
  src: url('../assets/fonts/asset-gridlist-icon.eot?26468244');
  src: url('../assets/fonts/asset-gridlist-icon.eot?26468244#iefix') format('embedded-opentype'),
       url('../assets/fonts/asset-gridlist-icon.woff?26468244') format('woff'),
       url('../assets/fonts/asset-gridlist-icon.ttf?26468244') format('truetype'),
       url('../assets/fonts/asset-gridlist-icon.svg?26468244#asset-gridlist-icon') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'asset-edit-delete-icon';
  src: url('../assets/fonts/asset-edit-delete-icon.eot?36057272');
  src: url('../assets/fonts/asset-edit-delete-icon.eot?36057272#iefix') format('embedded-opentype'),
       url('../assets/fonts/asset-edit-delete-icon.woff?36057272') format('woff'),
       url('../assets/fonts/asset-edit-delete-icon.ttf?36057272') format('truetype'),
       url('../assets/fonts/asset-edit-delete-icon.svg?36057272#asset-edit-delete-icon') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'data-attachment-icon';
  src: url('../assets/fonts/data-attachment-icon.eot?60431598');
  src: url('../assets/fonts/data-attachment-icon.eot?60431598#iefix') format('embedded-opentype'),
       url('../assets/fonts/data-attachment-icon.woff?60431598') format('woff'),
       url('../assets/fonts/data-attachment-icon.ttf?60431598') format('truetype'),
       url('../assets/fonts/data-attachment-icon.svg?60431598#data-attachment-icon') format('svg');
  font-weight: normal;
  font-style: normal;
}



//Water Quality Fonts

@font-face {
  font-family: 'quality calendar';
  src: url('../assets/fonts/quality-calendar.eot');
  src: url('../assets/fonts/quality-calendar.eot?#iefix') format('embedded-opentype'),
       url('../assets/fonts/quality-calendar.woff') format('woff'),
       url('../assets/fonts/quality-calendar.ttf') format('truetype'),
       url('../assets/fonts/quality-calendar.svg#quality-calendar') format('svg');
  font-weight: normal;
  font-style: normal;
}

//Admin Icons

@font-face {
  font-family: 'admin';
  src: url('../assets/fonts/admin.eot');
  src: url('../assets/fonts/admin.eot?#iefix') format('embedded-opentype'),
       url('../assets/fonts/admin.woff') format('woff'),
       url('../assets/fonts/admin.ttf') format('truetype'),
       url('../assets/fonts/admin.svg#admin') format('svg');
  font-weight: normal;
  font-style: normal;
}
//export icon
@font-face {
  font-family: 'export-icon';
  src: url('../assets/fonts/export-icon.eot?15288833');
  src: url('../assets/fonts/export-icon.eot?15288833#iefix') format('embedded-opentype'),
       url('../assets/fonts/export-icon.woff?15288833') format('woff'),
       url('../assets/fonts/export-icon.ttf?15288833') format('truetype'),
       url('../assets/fonts/export-icon.svg?15288833#export-icon') format('svg');
  font-weight: normal;
  font-style: normal;
}

//filter icon
@font-face {
  font-family: 'filter';
  src: url('../assets/fonts/filter.eot?803409');
  src: url('../assets/fonts/filter.eot?803409#iefix') format('embedded-opentype'),
       url('../assets/fonts/filter.woff?803409') format('woff'),
       url('../assets/fonts/filter.ttf?803409') format('truetype'),
       url('../assets/fonts/filter.svg?803409#filter') format('svg');
  font-weight: normal;
  font-style: normal;
}
//value admin icon


@font-face {
  font-family: 'value_admin';
  src: url('../assets/fonts/value_admin.eot?86824605');
  src: url('../assets/fonts/value_admin.eot?86824605#iefix') format('embedded-opentype'),
       url('../assets/fonts/value_admin.woff?86824605') format('woff'),
       url('../assets/fonts/value_admin.ttf?86824605') format('truetype'),
       url('../assets/fonts/value_admin.svg?86824605#value_admin') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'shared-admin';
  src:  url('../assets/fonts/omini-admin.eot?cf3okg');
  src:  url('../assets/fonts/omini-admin.eot?cf3okg#iefix') format('embedded-opentype'),
    url('../assets/fonts/omini-admin.ttf?cf3okg') format('truetype'),
    url('../assets/fonts/omini-admin.woff?cf3okg') format('woff'),
    url('../assets/fonts/omini-admin.svg?cf3okg#omini-admin') format('svg');
  font-weight: normal;
  font-style: normal;
}
//waste category icons
@font-face {
  font-family: 'waste-treatment-icon';
  src: url('../assets/fonts/waste-treatment-icon.eot');
  src: url('../assets/fonts/waste-treatment-icon.eot#iefix') format('embedded-opentype'),
       url('../assets/fonts/waste-treatment-icon.woff') format('woff'),
       url('../assets/fonts/waste-treatment-icon.ttf') format('truetype'),
       url('../assets/fonts/waste-treatment-icon.svg#waste-treatment-icon') format('svg');
  font-weight: normal;
  font-style: normal;
}

//project overview additional icons

@font-face {
  font-family: 'category-icons';
  src: url('../assets/fonts/category-icons.eot?91085235');
  src: url('../assets/fonts/category-icons.eot?91085235#iefix') format('embedded-opentype'),
       url('../assets/fonts/category-icons.woff?91085235') format('woff'),
       url('../assets/fonts/category-icons.ttf?91085235') format('truetype'),
       url('../assets/fonts/category-icons.svg?91085235#category-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}
 // value list view icons
@font-face {
  font-family: 'value-list-view-icons';
  src: url('../assets/fonts/value-list-view-icons.eot?82307454');
  src: url('../assets/fonts/value-list-view-icons.eot?82307454#iefix') format('embedded-opentype'),
       url('../assets/fonts/value-list-view-icons.woff?82307454') format('woff'),
       url('../assets/fonts/value-list-view-icons.ttf?82307454') format('truetype'),
       url('../assets/fonts/value-list-view-icons.svg?82307454#value-list-view-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}
//value-customize icon
@font-face {
  font-family: 'value-customize-icon';
  src: url('../assets/fonts/value-customize-icon.eot?94691364');
  src: url('../assets/fonts/value-customize-icon.eot?94691364#iefix') format('embedded-opentype'),
       url('../assets/fonts/value-customize-icon.woff?94691364') format('woff'),
       url('../assets/fonts/value-customize-icon.ttf?94691364') format('truetype'),
       url('../assets/fonts/value-customize-icon.svg?94691364#value-customize-icon') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'dv-drag-icon';
  src: url('../assets/fonts/dv-drag-icon.eot?97062402');
  src: url('../assets/fonts/dv-drag-icon.eot?97062402#iefix') format('embedded-opentype'),
       url('../assets/fonts/dv-drag-icon.woff?97062402') format('woff'),
       url('../assets/fonts/dv-drag-icon.ttf?97062402') format('truetype'),
       url('../assets/fonts/dv-drag-icon.svg?97062402#dv-drag-icon') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Glyphter';
  src: url('../assets/fonts/Glyphter.eot');
  src: url('../assets/fonts/Glyphter.eot?#iefix') format('embedded-opentype'),
       url('../assets/fonts/Glyphter.woff') format('woff'),
       url('../assets/fonts/Glyphter.ttf') format('truetype'),
       url('../assets/fonts/Glyphter.svg#Glyphter') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'roboto';
  src: url('../assets/fonts/Roboto-Regular.ttf')format('truetype');
  font-weight: normal;
  font-style: normal;
}





