@import "../../../../styles/variables.scss"; 
.asset-library {
  h3{

    font-size: 1rem;
    line-height: 1.25rem;
    font-family: $interstate-bold;
  }
  h6{
    font-size: 13px;
    /* line-height: 2px; */
    margin-bottom: 10px;
  }
}


.mapdatadropdown {
  margin: 10px 0px 20px 0px;

  label
  {
    font-size: 14px;
    text-transform: uppercase;
  }

  .dropdown {
    padding: 0.8rem 1rem 0.9rem 1rem;
    border-radius: 0px;
    .dropdown-menu{
      &.show{
        max-width:100.4% !important;
        .dropdown-item{
          &.selected{
            &:after{
              right: calc(100% - 100%) ;
            top: 30% ;
            }
          }
        }
      }
    }
    button{
        .selected-text {
          width: #{pxToRem(422.4)};
          text-overflow: ellipsis;
          white-space: nowrap;
          display: inline-block;
          overflow: hidden;
          vertical-align: bottom;
        }
    }
    .drop-btn{
      .selected-text {
        width: #{pxToRem(422.4)};
        text-overflow: ellipsis;
        white-space: nowrap;
        display: inline-block;
        overflow: hidden;
        vertical-align: bottom;
      }
  }
}
}
.asset-header.search-header
{
  display: flex;
  padding: 0.1rem 1rem;
  margin: 6px 0px;
  .float-left
  {
    width: 84%;

    span{
    color: $secondary-dark-gray;
    font-family: '$interstate-regular';
    font-size:14px;
    }
  }

  .float-right
  {
    font-size: 10px;
    cursor: pointer;
    line-height: 1.25rem;
    color: $secondary-dark-gray;
    font-family: '$interstate-regular';
    margin-top: 0.1rem;

    button{
      margin-left: 10px;
      color: $secondary-dark-gray;

      img {
        vertical-align: top;
    margin-top: 5px;
    height: 10px;
    width: 10px;
      }
    }
  
  }
}
.searchlabel
{
  width: 29%;
  float: right;
  text-align: right;
  margin-right: 10px;
  font-size: 10px;
  font-family: '$interstate-regular';
  color: $secondary-dark-gray;
}
.search-result-content
{
  height: 100%;
  overflow-y: scroll;
  height: 100%;
  position: relative;
  overflow-y: auto;
  height: 43rem;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
}
