.site-search-results{
    .card.tile {
    .card-header .card-title {
        text-transform: uppercase;
    }
    .card-body {
        min-height: 17.2rem !important;
        max-height: 17.2rem !important;
        &.asset-tree
        {
            .table {
                .card-left {
                    padding: 0 .8rem 0 0 !important;
    
                    .ico-info-thin {
                        float: right !important;
                     }

                     .tooltip.bs-tooltip-bottom {
                        left: 23px !important;
                        right: 10px !important;
                    }
                }
            }
        }

    }

}
.tooltip.bs-tooltip-bottom, .tooltip.bs-tooltip-auto[x-placement^="bottom"] {
    margin-left: -7px !important;
    left: 13px !important;
    right: 4px !important;
}



}

.tooltip{
    z-index: 2050 !important;
}

@media only screen and (max-width:990px){
    .tooltip{
        z-index: 1070;
    }
}

.harvester, .fsqs-manage-sites{
    padding-top: 5px;
}

.site-search-results{
    .asset-tree{
        .total-assets-count{
            font-size: 0.8875rem;
        }
        .text-capitalize{
            font-size: 0.6875rem;
        }
        .table{
            display: table;
            margin-bottom: 0.5rem !important;
            background: none;
            min-height: 7rem;
            max-height: 7rem;
            .row{
                .tableStyle{
                    border: none; 
                    padding: 0 0 0.3rem .75rem; 
                    font-size: 0.625rem;
                    display: table-cell;
                }
            }
        }
            
    }
}

.height-datasource{
    margin-top: 0.8rem !important;
}