// /****************************************************/

// // This files contains the header component styles

// /****************************************************/

.page-title {
    color: $default-white;
    margin: 1px;
}

.heading-small{
    font-size: #{pxToRem(18)};
}

.span-round {
    border-radius: 25px;
    height: 41px;
    background-color: $secondary-color;
    width: 42px;
    padding: 12px;
    text-transform: uppercase;
}
.subheader-hidden{
  margin-top: 60px;
}

.notification-class{
  margin-bottom: 10px;
  margin-right: -10px;
}

.profile-label {
    margin-bottom: 0;
    margin-left: -10px;
    margin-right: -10px;
}

.role-label{
    font-size: #{pxToRem(10)};
}

.profile-class {
    position: relative;
    margin: 10px;
    color: $default-white;
}

.hamburger-class-img{
    height: 33px;
}
      
/* navbar */
.navbar {
    position: fixed;
    top: 0;
    width: 100%;
    left: 0;
    @include gradient-directional($primary-blue-color, $primary-blue-color, 180deg);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 16px;
    z-index: calc($default-header-zindex + 130);
    height: #{pxToRem(64)};
  }
  .logo_item {
    display: flex;
    align-items: center;
    column-gap: 10px;
    font-size: 22px;
    font-weight: 500;
    color: var(--blue-color);
    .margin-left{
      margin-left: 0.5rem;
    }
  }
  .navbar .logo_img {
    height: 15px;
    margin-bottom: 4px;
  }
  .search_bar {
    height: 47px;
    max-width: 430px;
    width: 100%;
  }
  .search_bar input {
    height: 100%;
    width: 100%;
    border-radius: 25px;
    font-size: 18px;
    outline: none;
    background-color: var(--white-color);
    color: var(--grey-color);
    border: 1px solid var(--grey-color-light);
    padding: 0 20px;
  }
  .navbar_content {
    display: flex;
    align-items: center;
    column-gap: 21px;
    color: $default-white;

  }
  .margin-right{
    margin-right: 0.25rem;
    }

  /* DropDown Menu */

  .custon-dropdown {
    position: relative;
  }
  
  .custon-dropdown a{
    text-decoration: none;
    display: block;
  }
  
  .custom-dropdown-menu{
    list-style: none;
    margin: 9px;
    position: absolute;
    right: 0;
    top: 100%;
    color: $secondary-color;
    background-color: $default-white;
    border-radius: 8px;
    display: block;
  }

  .custom-dropdown-menu::before{
    content: '';
    position: absolute;
    top: -44px;
    right: 0px;
    border: 20px solid transparent;
    border-bottom-color: $secondary-color;
  }
  
  .custom-dropdown-menu::after{
    content: '';
    position: absolute;
    top: -40px;
    right: 0px;
    border: 20px solid transparent;
    border-bottom-color: $secondary-color;
  }
  .display-none{
    display: none;
  }

  @mixin responsive-display {
    display: none;
  }
  @mixin responsive-css{
    .logo_item .header-divider{ hr{
      height: 15px !important;
    }}
  .profile-details{
    @include responsive-display; 
  }
  .dropdown .drop-btn.dropdown-toggle img {
    width: 16px;
  }
  .d-inline-block {
    margin-top: 10px !important;
  }

 .page-title{
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis; 
  font-size: 12px;
 }
 .threedot-logo{
  position: absolute;
    top: 50%;
    left: 93%;
    transform: translate(-50%, -50%);
 }
 .threedot-class-img{
  height: 20px;
 }
  .img-fluid-small{
    width: 90px;
    height: 12.439px;
  }
  .img-fluid{
    max-width: 100%;
    height: auto;
    position: absolute;
    top: 50%;
    left: 47%;
    transform: translate(-50%, -50%);
  }
  .custome-threedot-menu{
    background-color: #ffffff;
    position: absolute;
    top: 85%;
    left: 62%;
    border-color: transparent;
    border-style: solid;
    border-radius: 5px;
    border-radius: 7px;
    padding: 7px 30px 6px 5px;
    box-shadow: 0px 0px 4px 1px #6498C6;
    .dropdown-item{
      padding: 0.25rem 0.5rem;
      color: #034E9F;
      font-weight: bold;
      background-color: $default-white
    }
  }
}

@include media-breakpoint-down(sm){
  .navbar{  
    @include responsive-css;
        .margin-right{
          margin-right: -1.2rem;
        }
        .page-title{
          width: 128px;
        }
    } 
    .main-content {
      width: 95%;
    }
}
@media (min-width:300px) and (max-width: 359px) {
  .navbar {
    .page-title{
    width: 70px;
  }
}
}
  .navbar-globalsearch{
    position: relative;
    bottom: 5px;
    left: 897px;
  }
  

