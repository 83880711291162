@import "../../../../styles/variables.scss";

.nalco-update-data-source {
    .template{
        background-color: transparent;
    }
    .k-input{
        font-size: 12px;
    }
    .k-list-optionlabel.k-state-selected{
        background-color: transparent;
    }
    .ud-column{
        white-space: normal;
        overflow: unset;
    }
    .k-dropdown .k-dropdown-wrap, .k-dropdowntree .k-dropdown-wrap{
        background-color: #f6f6f600;
        border: 1px solid #616365;
    }
    .btn {
        width: 8%;
        margin-right: 10px;
        min-height: 32px;
    }
    .paddingClass {
        padding: 7rem;
    }

    .form-control {
        font-size: 12px;
        overflow: hidden;
        min-height: 1.625rem;
    }

    .validation-message {
        color: $utilitarian-red;
        margin-top: 5px;
        margin-left: 5px;
    }
    .inputFeild{
        height: 1.9rem;
        border: 1px solid #616365;
    }
    body .k-grid th, body .k-grid td{
        padding: 0.4375rem 0.5rem;
    }
    .requiredClass{
        color: red;
    }
}

.align-datasource-modal {
    display: flex !important;
    align-items: center;
    justify-content: center;
        .modal-dialog{
            min-width:27rem !important;
    }
    .modal-title{
      text-transform: none !important;
    }
}
