/****************************************************/

// This files contains the dropdown component styles
@import "../../../styles/variables.scss"; 
/****************************************************/
.modal-body {
    padding: 1rem 1.5rem;
    max-height: 27rem;
    overflow-y: unset !important;
    min-height: 12rem;
    .validateInput{       
        margin-bottom:5px !important;     
        margin: 0.5rem 0;
        width: 100%; 
        border-radius: 0.125rem;
        color: #616365;
    }
.validation-message{
    color: $utilitarian-red;
    margin-top:5px;

    }
 
.asset-title{
    float: left;
    width: 100%;
}
.Newcustomer-dropdown{
    .dropdown{   
        max-width: 100%;
        display: block;
    }
}
.k-combobox .k-dropdown-wrap .k-select .k-icon{
    display: block;
}
.dropdown button.dropdown-toggle .selected-text {
    width: 9.4rem;
}
.dropdown .drop-btn.dropdown-toggle .selected-text {
    width: 9.4rem;
}
} 
.reorder-content{
 padding-top: 0px !important;
 padding-bottom: 24px !important;
 padding-left:24px !important;
    background: $primary-white;
}
.modal-sm {
    min-width: 360px !important;
}
// nalco-build-dialog > .modal-header{
//     padding-top: 24px !important;
//     padding-left: 24px !important;
// }
#modal-restricted{
    border-bottom: none;
}
.astrik:after{
    content:"*" ;
    color:$utilitarian-red;
    margin-left: 3px;
    }
.exportArea{
 margin-top: 24px;

 textarea {
    resize: none;
}
}
.popup-footer-section>:not(.clear-all) {
    margin: 0rem;
}
.clear-all
{
    margin-right: -.7rem;
    outline: none;
}
