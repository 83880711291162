.notifications-by-user {

  .nameUser {
    position: absolute;
    top: 0;
    white-space: nowrap;
    left: 29%;
    padding-top: 14px;

      .displayName {
      margin-left: 20px;
      margin-bottom: .5rem;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      line-height: 1.3em;
      z-index: 10;
      max-height: 2.6em;
      width: 77%;
      overflow: hidden;
    }

    @media screen and (max-width: 1120px) {
      left: 45%;
    }

    @media screen and (max-width: 800px) {
      left: 90%;
    }

    @media screen and (max-width: 600px) {
      left: 90%;
    }
  }

  #Notifications-header {
    .card-body {
      padding-left: 54px;
    }
  }

  .parentDivision {
    position: absolute;
    left:559px;

    @media screen and (max-width: 1120px) {
      left: 45%;
    }

    @media screen and (max-width: 800px) {
      left: 90%;
    }

    @media screen and (max-width: 600px) {
      left: 90%;
    }

    .checkBoxClass {
      position: absolute;
      top: 46px;
      left: 0px;
      width: 100%;
      height: 38px;
      border-bottom: 1px solid #E2E2E2;
    }


  }

  .accordianClass {
    padding-top: 43px;
    float: left;
    width: 100%;

    .panelClass {
      width: 100%;

      .panelAccordian {
        padding-top: 5px;
        float: left;
        width: 100%;

        .contentDiv {
          width: 100%;

          .contentAccordian {
            padding-top: 5px;
            float: left;
            width: 100%;

            .childDiv {
              margin-left: 12px;

              .custom-control {
                border-bottom: 1px solid #E2E2E2;

                .custom-control-label {

                  &::before {
                   // left: 489px;

                    @media screen and (max-width: 1120px) {
                      left: 41.2%;
                    }

                    @media screen and (max-width: 800px) {
                      left: 91%;
                    }

                    @media screen and (max-width: 600px) {
                      left: 91%;
                    }
                  }

                  &:after {
                  //  left:491px;

                    @media screen and (max-width: 1120px) {
                      left: 41.5%;
                    }

                    @media screen and (max-width: 800px) {
                      left: 91.8%;
                    }

                    @media screen and (max-width: 600px) {
                      left: 91.8%;
                    }
                  }
                }
              }


            }

            .childAccordian {
              padding-top: 5px;
              float: left;
              width: 100%;

              .card-body {
                padding-left: 14px;
              }

              .card-header {
                a {
                  overflow: hidden;
                  white-space: nowrap;
                  padding-left: 16px;
                  width: 40%;
                  text-overflow: ellipsis;


                  &:after {
                    position: absolute;
                    display: block;
                    float: left;
                    top: 8px;
                    margin: 0px 10px 0px 0px;
                    border-color: #5EB6E4 transparent transparent transparent;
                    LEFT: 0%;
                    right: auto;
                  }
                }
              }

              .custom-control {
                border-bottom: 1px solid #E2E2E2;

                .custom-control-label {
                  &::before {
                    left: 462px;

                    @media screen and (max-width: 1120px) {
                      left: 41.2%;
                    }

                    @media screen and (max-width: 800px) {
                      left: 91%;
                    }

                    @media screen and (max-width: 600px) {
                      left: 91%;
                    }
                  }

                  &::after {
                    left: 464px;

                    @media screen and (max-width: 1120px) {
                      left: 41.5%;
                    }

                    @media screen and (max-width: 800px) {
                      left: 91.8%;
                    }

                    @media screen and (max-width: 600px) {
                      left: 91.8%;
                    }
                  }

                }
              }
            }
          }

          .sideBox {
            position: absolute;
            top: 8px;
            left: 525px;

            @media screen and (max-width: 1120px) {
              left: 43.5%;
            }

            @media screen and (max-width: 800px) {
              left: 91.5%;
            }

            @media screen and (max-width: 600px) {
              left: 91.5%;
            }

            // .custom-control-label{

            //     &:before {
            //         left:30%;

            //         @media screen and (max-width: 1120px){
            //             left: 45%;
            //         }
            //         @media screen and (max-width: 800px){
            //             left: 55%;
            //         }
            //         @media screen and (max-width: 600px){
            //             left: 94%;
            //         }
            //     }

            //     &:after{
            //         left: 39%;


            //         @media screen and (max-width: 1120px){
            //             left: 45.1%;
            //         }
            //         @media screen and (max-width: 800px){
            //             left: 55.1%;
            //         }
            //         @media screen and (max-width: 600px){
            //             left: 94.1%;
            //         }

            //     }

            // }

          }

          .individualSite {
            height: auto;
            overflow: hidden;

            .checkboxStyle {
              position: absolute;
              left: 476px;
              top: 8px;

              @media screen and (max-width: 1120px) {
                left: 40.3%;
              }

              @media screen and (max-width: 800px) {
                left: 90.6%;
              }

              @media screen and (max-width: 600px) {
                left: 90.6%;
              }
            }

            .classForTooltip {
              position: absolute;
              top: 14px;
              z-index: 100;
              left: 32px;
              width: 22%;
              height: 16px;

              @media screen and (max-width: 1120px) {
                width: 27%;
              }

              @media screen and (max-width: 800px) {
                width: 37%;
              }

              @media screen and (max-width: 600px) {
                width: 47%;
              }
            }
          }
        }

      }

    }
  }

  .panelCheckbox {
    position: absolute;
    left:550px;
    top: 8px;

    @media screen and (max-width: 1120px) {
      left: 45%;
    }

    @media screen and (max-width: 800px) {
      left: 91.5%;
    }

    @media screen and (max-width: 600px) {
      left: 91.5%;
    }
  }

  .custom-checkbox .custom-control-input:indeterminate~.custom-control-label::before {
    background-color: #007ac9 !important;
  }

  .custom-checkbox .custom-control-input:checked~.custom-control-label::before {
    background-color: #007ac9 !important;
  }

  .custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    background-color: #007ac9 !important;
  }


  .notificationPage {
    .noData {
      font-size: 16px;
      padding: 16px;

      justify-content: center;
    }
  }

  .custom-checkbox-input {
    display: block;
    min-height: 1.5rem;
    padding-top: 50px;
  }

  .child-control-input {
    position: absolute;
  }

  label.custom-control-label {
    // width: 35%;
    // white-space: nowrap;
    // overflow: hidden;
    // text-overflow: ellipsis;
    .select-label-inner{
      overflow: hidden;
      white-space: nowrap;
      max-width: 150px;
      text-overflow: ellipsis;
      padding: 5px 4px 4px 4px;
      width: 100%;
  }
    
  }
}