@import "../../../../../styles/variables.scss";

.sub-header-cleveland {
    .cleveland-tile {
        padding: 0 0 0 16px;

        .font-12 {
            font-size: 12px;
        }

        .row-padding {
            padding: 40px 40px 43px 28px;
            text-align: left;
            // border-right: 1px solid #e2e2e2;

            .high-padding {
                padding-bottom: 24px;
                padding-top: 35px;
                @include font-size(32);

                .ico-padding {
                    position: relative;
                    top: -5px;
                    left: -4px;
                }

                .bigIcon {
                    &:before {
                        @include font-size(27);
                    }
                }
            }

            .risk-padding {
                font-size: 14px;
                padding-bottom: 34px;
            }
        }

        .graph-padding {
            padding: 0;
            padding-bottom: 11px;
        }

        .pills-section {
            margin-bottom: 10px;
            margin-right: 10px;
            display: flex;
            overflow: auto;
            height: auto;
            max-height: 88px;
        }
        .graph-section {
            margin-right: 10px;
            .line-chart-wrap {
                overflow-x: hidden;
            }
        }
    }

    .card.tile {
        .card-header {
            .card-title {
                font-size: 16px;
            }
        }
    }
}

.recommendations-tile {
    .card.tile {
        .card-header {
            padding: 13px 16px;

            .card-title {
                margin-right: 5px;
                font-size: 16px;
            }
        }
    }

    .tile-content-wrapper {
        min-height: 430px;
    }

    .recommendation-tile-height {
        height: 430px;
        .recommendations-scroll {
            height: 380px;
        }
    }
    .error-state-message {
        padding: 1rem;
    }
}

.white-bg {
    background-color: white;
}

.filterPanel {
    padding-bottom: 10px;
    border-bottom: 1px solid #E2E2E2;
}

.ml-10 {
    margin-left: 10px;
}

body * ::-webkit-scrollbar-track {
    border: none !important;
}

ul {
    li {
        list-style-type: disc;
    }
}

.risk-col {
    height:330px;
}

.risk-distribution-tile-content {
    padding: 20px 16px 0px 16px;
    overflow-y: scroll;
    .corporateLabel {
        font-size: 16px;
    }

    .progress-padding {
        margin-bottom: 13px;
    }

    div {
        @include font-size(14);
    }

    .risk-lists {
        color: $primary-gray;

        li {
            margin: 10px 0;
            display: block;
            @include font-size(14);

            &:first-child {
                min-height: 67px;
                margin-bottom: 19px;

                p {
                    margin-bottom: 8px;
                    padding-top: 4px;
                }
            }

            &:last-child {
                border-bottom: 1px solid $secondary-light-gray;
                padding-bottom: 15px;

                h2 {
                    padding-top: 2px;
                }
            }

            span {
                margin-right: 5px;
                vertical-align: middle;
                display: inline-block;
            }

            h2 {
                @include font-size(32);
                padding-top: 10px;
            }

            .trend-text {
                display: table-cell;
                padding-top: 2px;
                text-transform: capitalize;
            }

            .progress {
                height: 10px;
                padding-top: 0px !important;
            }

            .progress-bar {
                width: 80%;
                border-radius: 21px;
                height: 10px;

                &.red {
                    background-color: $utilitarian-red;
                }

                &.yellow {
                    background-color: $linear-gradient-end-yellow;
                }

                &.green {
                    background-color: $linear-gradient-end-green;
                }
            }
        }
    }
}

.graph-left-padding {
    padding: 0 0 12px 0;
    border-left: 1px solid #e2e2e2;
}

//media query for equal length columns

@media only screen and (min-width: 949px) {
    .row-padding {
        padding: 20px 20px 30px 14px !important;
    }
}


.compPerfIcon {
    z-index: 1;
    top: 15px;
    right: 16px;
}

.riskStatusWrap {
    overflow: hidden;
    height: 480px;
    .tile-content-wrapper {
        min-height: 430px;
    }
}

.siteCompPerfWrap {
    overflow: hidden;
    height: 389px;
}

.across-tabs {
    .tab-pane {
        min-height: 200px;
    }

}

@media (max-width: 991px) {
    .riskStatusWrap {
        overflow: inherit;
        height: auto;
    }

    .siteCompPerfWrap {
        overflow: inherit;
        height: auto;
    }
}

@media screen and (max-width:550px) {
    #Industry {
        padding-left: 10px;
    }
}