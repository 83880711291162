@import "../../../../styles/variables.scss";
.nalco-charge-balance{
  .modal-dialog{
    position: relative;
    top: 50% !important;
    transform: translateY(-50%)  !important;
  }
  .modal-header {
      background-color: $primary-blue !important;
      color: white !important
  }
  .modal-footer {
    background-color: $light-gray !important;
  }
}
.outerbody {
    padding: 10px;
    background-color: $secondary-light-gray !important;
}
.label-class {
    display: flex;
    align-items: center;
    margin: 2px 0;
    font-weight: bold;
}
.selectRow {
    height: 48px;
}
.dropDown {
    height: 35px;
    margin: auto 0;
    border-radius: 2px;
}
.span-class {
    display: flex;
    align-items: center;
    background-color: $secondary-light-gray !important;
    color: $primary-blue !important;
    margin: 10px 2px;
    padding: 5px;
}
.disabled {
  background-color:#e9ecef ;
}

.error-modal{
  .modal-content {
    width: 300px;
  }
  p {
    margin: 1.5rem;
  }
}
  