@import "../../../../styles/variables.scss";
.actonLogMetrics{
    .card-header{
      display: none;
    }
    .row{
      @include margin-padding(all, null , all , #{pxToRem(16)});
      .col-lg-3{
        @include margin-padding(all , null , right , #{pxToRem(16)});
        &:last-child{
         padding-right: 0px;
        }
      }
      .card.card-body{
        min-height: 8rem;
        border: 1px solid #dee2e6;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 5px;

        .labelBoxWidth{
          display: flex;
          align-items: center;
          flex-direction: column;
        }
      }
    }
    .nameClass{
      font-size: 16px;
    }
    .numberClass{
      font-size: 40px;    
      margin: 15px;
    }
    .siteTile{
      padding: 16px;
      display: grid;
      justify-content: center;
    }
    .card.card-body-ssd{
      min-height: 5.5rem;
      border: 1px solid #dee2e6;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 6px;
      
      .labelBoxWidth{
        display: flex;
        align-items: center;
        flex-direction: column;
      }
    }
    .nameClass-ssd{
      font-size: 14px;
    }
    .numberClass-ssd{
      font-size: 25px;    
      margin: 8px;
    }
    .al-avoid-page-break {
      pageX-breakX-inside: avoid;
    }
   }