/****************************************************/

// This files contains the scss imports that are required for the application

/****************************************************/

/** common scss **/
@import "variables.scss";
$icon-font-path: '../../node_modules/bootstrap-sass/assets/fonts/bootstrap/';
@import "fonts.scss";
@import "../../node_modules/bootstrap/scss/bootstrap";
@import "typography.scss";
@import "button.scss";
@import "kendo.scss";
@import "form-controls.scss";
@import "modal.scss";
@import "accordion.scss";
@import "tab.scss";
@import "three-dots-loader";
@import "pdf-table.scss";

/** main app component scss **/
@import "../app/app.component.scss";

/* core component scss*/
@import "../app//core/components/home/home.component.scss";

/** asset tree component scss **/
@import "../app/asset-tree/asset-tree.component.scss";
@import "../app/asset-tree/customize/customize.component.scss";
// @import "../../node_modules/@progress/kendo-theme-default/scss/all";
@import "../../node_modules/@progress/kendo-theme-default/dist/all.css";
@import "../app/asset-tree/add-properties/asset-properties/limits/limits.component.scss";
@import "../app/asset-tree/add-properties/asset-properties/contextpoint-limits/contextpoint-limits.component.scss";


/** water safety component scss **/
@import "../app/water-safety/intelligence/enterprise-overview/enterprise-overview.component.scss";
@import "../app/water-safety/intelligence/enterprise-overview/water-safety-gridview/water-safety-gridview.component.scss";
@import "../app/water-safety/intelligence/enterprise-overview/enterprise-kpi-overview/enterprise-kpi-overview.component.scss";
@import "../app/water-safety/intelligence/enterprise-overview/water-safety-mapview/water-safety-mapview.component.scss";
@import "../app/water-safety/intelligence/site-details/legionella-growth-risk-site-list/legionella-growth-risk-site-list.component.scss";
@import "../app/water-safety/intelligence/site-details/compliance-performance-graph/compliance-performance-graph.component.scss";
@import "../app/water-safety/intelligence/enterprise-details/legionella-growth-risk/legionella-growth-risk.component.scss";
@import "../app/water-safety/intelligence/enterprise-details/water-safety-compliance/water-safety-compliance.component.scss";
@import "../app/water-safety/intelligence/enterprise-details/legionella-test/legionella-test.component.scss";
@import "../app/water-safety/intelligence/site-details/site-details.component.scss";
@import "../app/water-safety/admin/legionella-distribution-group/legionella-distribution-group.component.scss";
@import "../app/water-safety/intelligence/enterprise-details/legionella-test/legionella-test-list/asset-level-test-details/asset-level-test-details.component.scss";

/** shared component scss **/
// @import "../app/shared/components/header/header.component.scss";
@import "./../app/shared-ui-refresh/app-header/app-header.component.scss"; // for the new header Css
@import "../app/shared/components/loader/loader.component.scss";
@import "../app/menu/menu.component.scss"; // for the new Navigation Css
// @import "../app/shared/components/navigation/navigation.component.scss";
@import "../app/shared/components/sub-header/sub-header.component.scss";
@import "../app/shared/components/dropdown/dropdown.component.scss";
@import "../app/shared/components/favorite/favorite.component.scss";
@import "../app/shared/components/favorite-toggle/favorite-toggle.component.scss";
@import "../app/shared/components/more-option/more-option.component.scss";
@import "../app/shared/components/tile/tile.component.scss";
@import "../app/shared/components/search/search.component.scss";
@import "../app/shared-ui-refresh/breadcrumb/breadcrumb.component.scss";
@import "../app/shared/components/logger/toaster.scss";
@import "../app/shared/components/pills/pills.component.scss";
@import "../app/shared-action-log/components/action-log-metrics/action-log-metrics.component.scss";
@import "../app/shared-action-log/components/action-log-container/action-log-container.component.scss";
@import "../app/shared-action-log/components/filter-section/filter-section.component.scss";
@import "../app/shared-action-log/components/action-log-grid-details/action-log-grid-details.component.scss";
@import "../app/shared/components/asset-count/asset-count.component.scss";

/** build asset scss **/
@import "../app/asset-tree/asset-tree-listview/asset-tree-list-item/asset-tree-list-item.component.scss";
@import "../app/asset-tree/build/build.component.scss";
@import "../app/asset-tree/build-dialog/build-dialog.component.scss";
@import "../app/asset-tree/build/build-area-source/build-area-source.component.scss";
@import "../app/asset-tree/build/build-tree-target/build-tree-target.component.scss";
@import "../app/asset-tree/build/build-target-asset-area/build-target-asset-area.component.scss";
@import "../app/asset-tree/build/build-asset-source/build-asset-source.component.scss";

/** map asset container scss**/
@import "../app/asset-tree/map-asset/map-asset.component.scss";
@import "../app/asset-tree/map-asset/map-asset-source/map-asset-source.component.scss";

/** water safety component scss **/
@import "../app/water-safety/intelligence/site-details/important-parameters/important-parameters.component.scss";

/** Asset property **/
@import "../app/asset-tree/add-properties/add-properties.component.scss";

/** Audit Dialog **/
@import "../app/asset-tree/audit-dialog/audit-dialog.component.scss";


/** Water quality **/
@import "../app/quality/common/quality-assets/quality-assets.component.scss";
@import "../app/shared-water-quality/components/site-list-view-widget/site-list-view-widget.component.scss";
@import "../app/quality/dashboards/enterprise-view/enterprise-view.component.scss";
@import "../app/quality/dashboards/system-view/sub-system-chart/sub-system-chart.component.scss";
@import "../app/quality/dashboards/enterprise-view/water-quality-map/water-quality-map.component.scss";

/** Notification userstory **/
@import "../app/notifications-admin/notifications-filter/filter-by-service/filter-by-service.component.scss";
@import "../app/notifications-admin/notifications-filter/filter-by-type-of-user/filter-by-type-of-user.component.scss";
@import "../app/notifications-admin/notifications-admin.component.scss";
@import "../app/notifications-admin/select-view-type/select-view-type.component.scss";
@import "../app/notifications-admin/user-notification-container/notifications-by-user/notifications-by-user.component.scss";
@import "../app/notifications-admin/notifications-filter/search-text-filter/search-text-filter.component.scss";

/** Action Log **/
@import "../app/action-log/action-log.component.scss";
@import "../app/shared-action-log/components/action-log-grid/action-log-grid.component.scss";


/** Water flow intelegent  **/
@import "../app/water-flow-intelligence/site-overview/water-usage/types-and-units/types-and-units.component.scss";

/** Asset Dashboard **/
@import "../app/asset-tree/dashboard/asset-dashboard.component.scss";

/** Asset Harvester **/
@import "../app/asset-harvester/assets-details-view/assets-details-view.component.scss";
@import "../app/asset-harvester/assets-details-view/asset-details-grid/asset-details-grid.component.scss";
@import "../app/asset-harvester/assets-details-view/asset-details-feature/asset-details-feature.component.scss";
@import "../app/asset-harvester/assets-details-view/assets-properties/assets-properties.component.scss";
@import "../app/asset-harvester/assets-details-view/asset-inventory-fields/asset-inventory-fields.component.scss";
@import "../app/asset-harvester/assets-details-view/asset-inventory-fields/inventory-calibrate/inventory-calibrate.component.scss";
@import "../app/asset-harvester/manage-measurements/edata-tour-configuration/edata-tour-configuration.component.scss";

/** Shared Limit Config **/
@import "../app/shared-limit-config/limits-configuration/limits-configuration.component.scss";

/** Shared datasource details **/
@import "../app/shared-datasource-details/datasource-details/datasource-details.component.scss";
@import "../app/shared-datasource-details/datasource-details/update-datasource/update-datasource.component.scss";

/** Shared map measurement details **/
@import "../app/shared-map-measurements/map-measurement-view/map-measurement-view.component.scss";
@import "../app/shared-map-measurements/map-measurement-view/map-measurement-feature/map-measurement-feature.component.scss";
@import "../app/shared-map-measurements/map-measurement-view/map-measurement-scaling/map-measurement-scaling.component.scss";
@import "../app/shared-map-measurements/map-measurement-view/map-measurement-grid/delete-popup-component/delete-popup.component.scss";

/** Financial Impact **/
@import "../app/shared-financial-impact/components/date-range-dropdown/date-range-dropdown.component.scss";

/** My Documents **/
@import "../app/shared-my-documents/_components/my-documents-filter/my-documents-filter.component.scss";

/** operating Schedule **/
@import "../app/shared-operating-schedule/components/operating-schedule-tab/operating-schedule-tab.component.scss";
@import "../app/shared-operating-schedule/components/operating-schedule-tab/schedule-section/schedule-section.component.scss";
@import "../app/shared-operating-schedule/components/operating-schedule-tab/offline-section/offline-section.component.scss";

/** inventory **/
@import "../app/shared-ssd/components/inventory/inventory/inventory.component.scss";
@import "../app/shared/components/inventory_list/inventory-list/inventory-list.component.scss";


/** edata **/
@import "../app/shared-ssd/components/edata-widget/edata-mapping-customize/edata-mapping-customize.component.scss";

/** system assurance**/
@import "../app/shared-system-assurance/components/system-assurance-widget/system-assurance-widget.component.scss";


/** asset deatils**/
@import "../app/shared-asset-details/components/asset-details-widget/asset-details-widget.component.scss";
@import "../app/shared-asset-details/components/asset-details-customize/asset-details-customize.component.scss";
@import "../app/shared-asset-details/components/asset-details-customize/asset-selection/asset-selection.component.scss";

@import "../app/shared-ro/components/charge-balance/charge-balance.component.scss";

/** Controller Management**/
@import "../app/controller-management/_components/controller-repository/controller-repository.component.scss";
@import "../app/controller-management/_components/controller-repository/provisioned-controllers/provisioned-controllers.component.scss";
@import "../app/controller-management/_components/controller-upgrade/remote-upgrade/remote-upgrade.component.scss";

@import "../app/admin-alarm/admin-alarm.component.scss";
@import "../app/alarm-user-mgmt/alarm-user-mgmt.component.scss";

@import "../app/crude-flex/crude-flex.component.scss";

/** error page**/
@import "../app/core/fallback-modules/error-page/error-page.component.scss";
.global-search-set-maxheight{
    max-height: 30rem !important;
}