$primary-white: #fff;
$primary-black: #000;
$primary-blue: #007ac9;
$primary-blue-three: #192D4E;
$primary-blue-dark: #3C5B96;
$secondary-blue: #5eb6e4;
$primary-gray: #616365;
$secondary-dark-gray: #584141;
$secondary-gray: #bbbcbe;
$secondary-light-gray: #e2e2e2;
$calendar-hover-background: #0275d8;
$secondary-dark-blue: #004d71;

// -----grid customization for Edata----
.admin-grid-container {
  &:hover td {
    background-color: $primary-white;

  }

  .k-grid .k-grid-header .k-header {
    white-space: normal;
    background-color: $linear-gradient-starting-blue;
    color: $primary-white !important;
    font-family: $interstate-regular !important;
    font-size: 12px !important;
  }

  ::-webkit-scrollbar {
    width: 1.5rem;
  }

  ::-webkit-scrollbar-track-piece {
    background-color: $primary-white !important;
    height: 2rem;
  }

  ::-webkit-scrollbar-thumb {
    background-color: $secondary-gray !important;
    border: .5rem solid transparent;
    border-radius: .75rem;
    background-clip: content-box;
    min-height: 2.5rem;
    max-height: 10rem;
  }

  ::-webkit-scrollbar-corner {
    background-color: $primary-white !important;

  }

  ::-webkit-scrollbar:horizontal {
    width: 1em;
    height: 1.5rem;
  }


  & .k-grid td {
    border: 0.5px solid $secondary-gray !important;
    text-overflow: clip;
    white-space: nowrap;
    word-wrap: break-word;;
    overflow: hidden;
    & .k-svg-i-none{
      &.k-treelist-toggle{
        margin:0;
        padding: 0;
      }
    }
  }
  .header-bottom {
    border-bottom: 1px solid $secondary-gray !important;
    border-left: 1px solid $secondary-gray !important;
    border-right: 1px solid $secondary-gray !important;
    border-top: 0px solid $secondary-gray !important;

  }

  .header-top {
    border-top: 0px solid $secondary-gray !important;
    border-left: 1px solid $secondary-gray !important;
    border-right: 1px solid $secondary-gray !important;
    border-bottom: 0px solid $secondary-gray !important;
  }

  .header-bottom-odd {
    border-bottom: 1px solid $secondary-gray !important;
    border-left: 1px solid $secondary-gray !important;
    border-right: 0px solid $secondary-gray !important;
    border-top: 0px solid $secondary-gray !important;

  }

  .header-bottom-even {
    border-bottom: 1px solid $secondary-gray !important;
    border-left: 0px solid $secondary-gray !important;
    border-right: 1px solid $secondary-gray !important;
    border-top: 0px solid $secondary-gray !important;

  }

  .grid-wrapper {
    border: 1px solid $secondary-gray !important;
    border-left: 0px solid $secondary-gray !important;
    border-top: 0px solid $secondary-gray !important;
  }

  .k-grid-header-locked table {
    height: 110px !important;
  }

  .k-grid-header-wrap table {
    height: 110px !important;
  }

  .k-virtual-content {
    overflow-y: scroll !important;
    position: relative !important;
}

.k-virtual-content .k-grid-table .colorChangedRow td{
  background-color: #FFF45F !important;
  color: #000000 !important;
}

.grid-loading-icon {
  border: 9px solid $primary-blue !important;
  border-top: 9px solid #ffffff !important;
}

}

// notification-container customization
.notification-container {
  border: 0px !important;

  .search-icon {
    left: 20px !important;
    position: absolute !important;
    font-size: 15px !important;
    top: 0px !important;
  }

  .search-bar {
    margin-left: 0px !important;
    padding-left: 32px !important;
    color: #BBBCBE !important;
  }
  input[type="search"] {
    padding: 4px 12px 4px 40px !important;
  }

  [type="search"]::-webkit-search-cancel-button {
    -webkit-appearance: auto !important;
  }

  .mt3 {
    margin-top: -3px;
  }

  .reset-btn {
    margin-top: 35px !important;
  }

  .fa {
    display: inline-block;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .SearchFilterContainer {
    font-size: 11px !important;
    .page-loader {
      border: 7px solid $primary-blue !important;
      border-top: 7px solid #ffffff !important;
    }
    input {
      padding: 6px 12px 6px 10px !important;
    }
  }

  lib-common-drop-down .datePickerContainer 
  {
  .dropdown {
    border: unset;
    padding-left: 0px !important;
    padding-right: 0px !important;

    .btn {
      color: #616365;
      border-color: grey;
      background-color: unset;
    }

    .btn-outline-primary:hover {
      background-color: unset;
      color: #616365;
      border-color: grey;
    }

    .dropdown-menu {
      width: 192px !important;
      transform: translate(0px, 30px) !important;
    }

  }
  .disable-drop-down .btn{
    color: #fff !important;
  }
}

  lib-configuration-grid {
    .k-checkbox {
      border-color: rgba(0, 0, 0, 0.08);
      color: #656565 !important;
      background-color: lightgrey !important;
      position: unset !important;
      opacity: unset !important;
      -webkit-appearance: auto;
      pointer-events: auto;

    }

    .k-grid-content {
      overflow: auto !important;
      background-color: $primary-white !important;
      position: relative !important;
    }

    kendo-datepicker {
      .k-datepicker {
        width: 8rem !important;
      }
    }

    .admin-grid-container .k-grid .k-grid-header .k-header {
      font-size: 11px !important;
      // margin-bottom:15px !important;
    }
  }

  .btn.disabled, .btn:disabled {
    background: transparent;
    color: #bbbcbe !important;
    border: 1px solid #bbbcbe !important;
    opacity: 1;

    &:hover {
      background: transparent;
    }
  }

  .btn.btn-outline-primary:disabled.active {
    background: #E2E2E2;
    color: $primary-white !important;
    border: 1px solid #E2E2E2 !important;

    &:hover {
      background: #E2E2E2;
      border: 1px solid #E2E2E2 !important;
      color: $primary-white !important;
    }
  }

  .reset-btn.disabled {
    background-color: transparent !important;
  }

  .k-grid td, .k-grid th {
    padding: 5px 8px !important;
  }

  .collapse-btn, i.fa.fa-caret-right.fa-lg {
    background: transparent;
    color: white;
    font-size: 1.2rem;
  }
}

body .main-content .notification-container .settings-text {
  color: #007ac9 !important;
  font-size: 0.95rem !important;

  &:last-child {
    padding-right: 0px !important;
  }
}

.filterDialog {
  .modal-dialog {
    transform: none !important
  }

  .multiselect-dropdown {
    .dropdown-list {
      margin-top: 0px !important;

      .item2 {
        max-height: 142px !important;
      }
    }

    .dropdown-btn {
      color: #656565 !important;
      padding-top: 14px !important;
      background-color: $secondary-dark-blue !important;

      .selected-item :hover {
        box-shadow: none !important;
      }
    }

  }

  .modal-header .close-btn {
    border: none !important;
  }

  .configuration-grid-filter {
    width: 40rem !important;
  }
}
.toast-top-full-width{
  top: 10% !important;
  right: 0 !important; 
  width: 100% !important;
}

.modal.show .modal-dialog {
  transform: none;
  .persistent-settings-dialog {
    table {
      thead {
        tr {
          th {
            background: $primary-white;
            top: -18px !important;
          }
        }
      }
    }
    .modal-body {
      overflow-y: auto !important;
    } 
  }    

  
}

.notification-container .filters .text-md-right button.btn {
  
  background: #fff;
  border: 1px solid #949494;
  box-shadow: none;
  color: #616365;
  text-transform: capitalize;
  
  &:hover {
    
      color: #949494;
      border: 1px solid #BBBCBE;
  
  }

}

.notification-container .filters .text-md-right button.btn.active
{
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;

    &:hover{
      background-image: linear-gradient(180deg, #1C9AEC, #007ac9);
      background-repeat: repeat-x;
    }

    &:disabled {
      background: #E2E2E2;
      color: #fff !important;
      border: 1px solid #E2E2E2 !important;
    }
}
