﻿/****************************************************/

// This files contains the scss for typography

/****************************************************/

body {
  @include rhythm(13, 16);
  font-smooth: always;
  font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  -webkit-font-smoothing: subpixel-antialiased;
}

h1 {
  @include rhythm(26, 28);
}

h2 {
  @include rhythm(26, 28);
  font-family: $interstate-light;
  margin-bottom: 0.75rem;
}

h3 {
  @include rhythm(16, 20);
}

.font-40 {
  @include rhythm(40, 44);
  margin-bottom: 0;
}

.font-32 {
  @include rhythm(32, 35);
  margin-bottom: 0;
}

.font-14 {
  @include rhythm(14, 16);
  margin-bottom: 0;
}

.font-12 {
  @include rhythm(12, 16);
  margin-bottom: 0;
}

.font-16 {
  @include rhythm(16, 18);
  margin-bottom: 0;
}

.font-bold {
  font-family: $interstate-bold;
}

// New Ecolab 3d Design Css //

// Fonts //

@mixin text-styles($size, $weight, $line-height, $letter-spacing) {
  // color: $secondary-color;
  font-family: $base-font-family;
  font-size: $size;
  font-weight: $weight;
  line-height: $line-height;
  letter-spacing: $letter-spacing;
}

.heading-small {
  @include text-styles(pxToRem(24), 400, 2rem, pxToRem(1.7));
}

.title-large {
  @include text-styles(pxToRem(22), 400, 1.75rem, pxToRem(1.1));
}

.title-small {
  @include text-styles(pxToRem(14), 400, 1.25rem, pxToRem(0.7));
}

.display-medium {
  @include text-styles(2.813rem, 400, 3.313rem, -0.007rem);
}

.display-small {
  @include text-styles(2.25rem, 400, 2.75rem, -0.006rem);
}

.heading-large {
  @include text-styles(2rem, 400, 2.5rem, -0.02rem);
}

.heading-medium {
  @include text-styles(1.75rem, 400, 2.25rem, -0.018rem);
}

.title-medium {
  @include text-styles(1rem, 500, 1.5rem, -0.01rem);
}

.body-large {
  @include text-styles(1rem, 400, 1.375rem, -0.003rem);
}

.body-medium {
  @include text-styles(0.875rem, 400, 1.25rem, -0.002rem);
}

.body-small {
  @include text-styles(0.75rem, 400, 1rem, -0.002rem);
}

.label-large {
  @include text-styles(0.875rem, 500, 1.25rem, -0.009rem);
}

.label-medium {
  @include text-styles(0.75rem, 400, 1rem, -0.008rem);
}

.label-small {
  @include text-styles(0.688rem, 400, 1rem, -0.007rem);
}