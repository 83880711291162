﻿/****************************************************/

// This files contains the scss for button

/****************************************************/

.btn {
  min-width: #{pxToRem(128)};
  cursor: pointer;
  &.btn-primary {
    @include gradient-directional($linear-gradient-starting-blue, $primary-blue, 180deg);
    @include rhythm(16, 16);
    border: 0 none;
    border-radius: 0.25rem;
    box-shadow: none;
    width: 100%;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
    text-transform: capitalize;
    &:hover {
      @include gradient-directional($secondary-blue, $primary-blue, 180deg);
    }
    &.disabled,
    &:disabled {
      opacity: 1;
    }
    &:not([disabled]) {
      &:not(.disabled) {
        &.active,
        &:active {
          border: 0 none;
          background-color: $primary-blue;
          box-shadow: none;
        }
      }
    }
  }
  &.save {
    @include gradient-directional($linear-gradient-starting-green, $utilitarian-green, 180deg);
    color: $primary-white;
  }
  &.remove {
    @include gradient-directional($remove-btn-start-color, $remove-btn-end-color, 180deg);
    &:hover {
      @include gradient-directional($remove-btn-start-color, $remove-btn-end-color, 180deg);
    }
  }
  &.btn-secondary {
    @include rhythm(14, 16);
    background: $primary-white;
    border: 1px solid $secondary-dark-gray;
    box-shadow: none;
    color: $primary-gray;
    width: 100%;
    text-transform: capitalize;
    &:hover {
      color: $secondary-dark-gray;
      border: 1px solid $secondary-gray;
    }
    &:active {
      color: $primary-gray;
      border: 1px solid $primary-gray;
    }
    &:not([disabled]) {
      &:not(.disabled) {
        &.active,
        &:active {
          color: $primary-gray;
          border: 1px solid $primary-gray;
          background-color: $primary-white;
          box-shadow: none;
        }
      }
    }
    &.disabled {
      color: $secondary-light-gray;
      border: 1px solid $secondary-light-gray;
    }
    &.close-btn {
      position: relative;
      text-align: right;
      &:before {
        font: normal normal normal 18px/18px 'Nalco-additional-icons';
        content: '\E927';
        position: absolute;
        left: 2rem;
        top: 0.6875rem;
      }
      &:hover {
        color: $secondary-dark-gray;
      }
    }
  }
  &.btn-large {
    padding: 16px 0;
  }
  &.active {
    background: $primary-blue;
    color: $primary-white;
  }
  &.disabled,
  &:disabled {
    cursor: default;
    background: $secondary-light-gray;
    &:hover {
      background: $secondary-light-gray;
    }
  }
}

.btn-outline-primary {
  &:not([disabled]) {
    &:not(.disabled) {
      &:active {
        color: $primary-gray;
        background-color: $primary-white;
      }
    }
  }
}

a.btn.save {
  color: $primary-white;
  &:hover {
    color: $primary-white;
  }
}
