@import "../../../../styles/variables.scss";
.gridComponent{
  .k-grid tbody tr:hover {
    background-color: transparent;
  }
  .nalco-asset-details-grid{
    .min-height{
        min-height: 10rem;
        .noData{
            padding: 6% 10% 0 44%;
        }

        .card.tile{
          box-shadow: none;
        }
    }
    .drag-delete {
        font: normal normal normal 18px/18px 'asset-edit-delete-icon';
        float: right;
      
        &:before {
          content: '\e801';
          float: right;
          margin-left: 10px;
          margin-top: 0px;
        }
      }
}

  .assetKendoTable{
    .card.tile{
      box-shadow: none;
    }
    kendo-grid-list { // To freeze the column header
      .k-grid-content {
        overflow:auto !important;
      }
    }
    & .k-table-td{
      border-left-width: 0;
    }
    
  }

  .card.tile{
    box-shadow: none;
  }
}

//Icons
[class*='speedDialIcon-'] {
  width:33px;
  height:33px;
  cursor: pointer;
}

.speedDialIcon-Green{
  background: url('/assets/images/speed_dial_icon-green.svg');  
  background-repeat: no-repeat;
}

.speedDialIcon-Blue{
  background: url("/assets/images/speed_dial_icon-blue.svg"); 
  background-repeat: no-repeat;
}

.speedDialIcon-Red{
  background: url("/assets/images/speed_dial_icon-red.svg"); 
  background-repeat: no-repeat;
}

.AH-limit-modal{
  .nalco-limits-configuration{
      kendo-grid-list{
          .k-grid-content{
              overflow: auto !important;
          }
      }
  }
}
.grid_StaticHeight { // Static Grid Height 
  height: 577px;
}
.grid_AutoHeight { // Auto Grid Height
  height: auto;
}

.WW-Icon {
  color: $primary-blue;
  font-weight: bold;
}
.button-border{
 border:0px !important;
 background: transparent;
  
}

.confirm-item-modal {
  top: 58%;
  transform: translateY(-50%);

  .modal-dialog {
      width: #{pxToRem(753)};
  }

  .modal-header {
      flex-direction: column-reverse;
  }

  .modal-title {
      color: $secondary-color;
      font-family: $base-font-family;
      font-size: #{pxToRem(24)} !important;
      font-style: normal;
      font-weight: 400;
      line-height: #{pxToRem(40)} !important;
      letter-spacing: -0.32px;
      text-transform: none !important;
  }

  .modal-content{
      font-size: 0.8125rem !important;
      color: $secondary-color !important;
  }

  .modal-footer {
      border-top: none !important;
  }

  .modal-content {
      font-family: $base-font-family;
      font-size: #{pxToRem(14)} !important;
      font-style: normal;
      font-weight: 400;
      line-height: #{pxToRem(22)};
      letter-spacing: -0.04px;
      color: $secondary-color !important;
  }

  .btn {
      border-radius: 0.5rem !important;
      font-family: $base-font-family !important;
      line-height: 1.25rem !important;
      max-width: 10rem !important;
      height: 2.6rem !important;
  }

  .btn-primary {
      background-color: $primary-color !important;
      background-image: none !important;
  }

  .btn-secondary {
      color: $primary-color !important;
      border: 1px solid $primary-color !important;
  }

}

.new-label-badge {
  background: #006Bd3;
    width: #{pxToRem(35)};
    height: #{pxToRem(18)};
    font-size: #{pxToRem(10)};
    border-top-left-radius: #{pxToRem(4)};
    border-bottom-right-radius: #{pxToRem(4)};
    color: $default-white;
    font-weight: 500;
    // position: relative;
    display: inline-block;
    text-align: center;
}