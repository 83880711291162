/****************************************************/

// This files contains the scss imports that are required for the tab

/****************************************************/

.tab {
  &.list-inline {
    float: left;
    margin-bottom: 0;
    .list-inline-item {
      &:not(:last-child) {
        margin-right: 1.5rem;
      }
      @include rhythm(14, 16);
      color: $secondary-dark-gray;
      padding-bottom: 0.75rem;
      cursor: pointer;
      text-transform: capitalize;
      &.active {
        color: $primary-gray;
        border-bottom: 0.25rem solid $primary-gray;
      }
    }
  }
}
