/****************************************************/

// This files contains the more-option component styles

/****************************************************/

.more-option {
  padding: 0;
  border: 0 none;
  min-width: 0;
  margin: 0;
  margin-top: 3px;
  @include font-size(14);
  .dropdown-content {
    width: auto;
    height: 18px;
    .btn {
      &.dropdown-toggle {
        margin: 0 #{pxToRem(-6)};
        background-color: transparent;
        width: auto;
        min-width: 0;
        @include rhythm(18, 4);
        &:after {
          content: none;
        }
      }
    }
  }
  .dropdown-menu {
    .dropdown-item {
      max-width: 100%;
      padding: 10px 16px;
      white-space: break-spaces;
    }
    &.show {
      min-width: 0;
      margin: 5px 0;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
      padding:10px 0px;
      width: 10rem !important;
      top:0 !important;
    }
  }

  .more-options-list {
    width: max-content;

     .disableExport{
      cursor: not-allowed;
    }
  }
}
.more-info{
  padding: 16px 16px 2px 16px;
  font-family: "Interstate-Regular", sans-serif;
  color: #616365;
  background: #fff;

  p{
    margin-bottom: 5px;
  }
}