/****************************************************/
@import "../styles/variables.scss";
// @import "../mobile-drag-drop/default.css";
// This files contains the styles that are required for the application.

/****************************************************/
html, body {
  height: 100%;
  margin: 0;
}

body {
  margin: 0 auto;
}

a:not([href]):not([tabindex]) {
  cursor: pointer;
}

button,
label,
img,
a {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.left-nav {
  width: 64px;
  display: inline-block;
}

#navbarSupportedContent{
  li{
    outline-color: transparent;
  }

} 

.main-home-page{
  padding-top: 55px;
  background-color:  $background-light-blue;
  @include media-breakpoint-down(sm) {
    padding-top: 0px;
    padding-left: 20px;
  }
}

.main-page-layout{
  padding-top: 130px;
  @include media-breakpoint-down(sm) {
    padding-top: 15px;
  }
}

.main-content {
  display: inline-block;
  min-height: 420px;
  padding: 1em 1em 1em 2em;
  margin-left: 5em;
  transition: all 0.3s ease;
  @include media-breakpoint-down(sm) {
    padding: 0em 0em 1em 1em;
    margin-left: 0em;
    width: 100%;
    .OmniEnterprise-dashboard , .nalco-home, .SGIEnterprise-dashboard, .WBIEnterprise-dashboard {
      margin-top:40px;
    }
  }
  &.hide-layout {
    width: 100%;
    min-height: 100%;
    padding: 0px;
    margin-left: 1em;
  }

  .content {
    padding: 24px 12px 10px 8px;
  }

  .section {
    // margin-bottom: 40px;

    .ico-info-thin {
      cursor: pointer;
      @include rhythm(inherit, 16);

    }
  }
  @media screen  and (max-width: 991px) {
    width: 90%;
     padding: 1em 0em 1em 2em;
    margin-left: 4em;
  }
  @media screen  and (max-width: 767px) {
    width: 100%;
    margin-left: 0;
    padding: 1em 0em 1em 1em;   
   }
}

.main-content-collapse {
  width: 95%;
  margin-left: 72px;
}

.main-content-expand {
  margin-left: 200px;
  width: calc(95% - 130px);
}

.home-background-color{
 background-color: $background-light-blue;
}

.whatsnewpopup{
margin-left: 260px;
position: absolute;
width: 57%; z-index: 11;
  @media screen  and (max-width: 991px) {
  margin-top: 20px;
    margin-left: 90px;
    position: absolute;
  width: 57%; z-index: 11;
  }
}
.wrapper-position{
  position:relative
}
.btn-align{
float: right;
  width: 90px !important;
  @media screen  and (max-width: 991px) {
    width: 90px !important;
  }
}
.icon-TrainingIconNew{
  font-size: 28px;
  height: 28px;
  width: 16px;
  float: left;
  position: absolute;
  margin-top: -13px; 
  margin-left: -17px;
   .path1:before {
    content: "\e400";
    top: 0px;
    left: 18px;
    position: absolute;
    color: #007ac9;
  }
   .path2:before {
    content: "\e401";
    top: 0px;
    left: 18px;
    position: absolute;
    color: #007ac9;
  }
  .path3:before {
    content: "\e402";
    top: 0px;
    left: 18px;
    position: absolute;
    color: #007ac9;
  }
   .path4:before {
    content: "\e403";
    top: 0px;
    left: 18px;
    position: absolute;
    color: #007ac9;
  }
}

.row {
  margin-right: 0;
}

.bg-white {
  background-color: $primary-white;
}

.compliance-tab .tooltip .tooltip-inner {
  text-align: left;
  @include font-size(11);
}

.lg-sites {
  .tooltip-inner {
    @include font-size(11);
    text-align: left;
  }

}

.tooltip {
  .tooltip-inner {
    max-width: 20rem;
    font-size: 0.75rem;
  }

  .tooltip-content {
    font-family: $font-family-base;
    @include rhythm(14, 16);
    padding: #{pxToRem(8)} #{pxToRem(12)};
    margin-bottom: 0;
  }

  &.bs-tooltip-bottom {
    margin-left: -1px;
    margin-top: 0.5rem;

  }
}



.suggestion {
  font-family: $interstate-extra-light;
  @include rhythm(13, 16);
}

.error-text {
  font-family: $interstate-bold;
  @include rhythm(12, 14);
  color: $utilitarian-red;
  text-align: right;
  text-transform: none;
}

virtual-scroll {
  margin-top: 0;
}

.display-none {
  display: none;
}

ul {
  li {
    list-style: none;
  }
}

.disabled-txt {
  color: $secondary-gray;
  user-select: none;
  pointer-events: none;
}

.top-right-triangle-black {
  position: relative;

  &:after {
    position: absolute;
    top: 0.25rem;
    right: 0.25rem;
    @include triangle(top, 4px, 4px, $primary-gray);
    transform: rotate(45deg);
  }
}

nalco-sub-header.fixed-top {
  margin-top: 62px;
  box-shadow: 0px 1px 1px #ccc;
}

.left-nav.fixed-top {
  margin-top: 130px;
}

.left-nav.fixed-top+.main-content {
  margin-left: 80px;
  margin-top: 140px;
}

.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.primary-blue-color-no-link {
  color: $primary-blue !important
}

//*****************font icons additional
[class^="ico-"],
[class*=" ico-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Nalco-additional-icons' !important;

  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  vertical-align: middle;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'nalco-asset-icon' !important;

  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  vertical-align: middle;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


[class^="asseticon-"]:before,
[class*="asseticon-"]:before {
  font-family: "asset-gridlist-icon";
  font-style: normal;
  font-weight: normal;


  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;


  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

[class^="drag-"]:before,
[class*=" drag-"]:before {
  font-family: "asset-edit-delete-icon" !important;
  font-style: normal;
  font-weight: normal;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  margin-left: .2em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

//export icon
[class^="export-"]:before,
[class*=" export-"]:before {
  font-family: "export-icon";
  font-style: normal;
  font-weight: normal;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  margin-left: .2em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

//admin Icons
[class^="admin-"]:before,
[class*=" admin-"]:before {
  font-family: "shared-admin";
  font-style: normal;
  font-weight: normal;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .3em;
  font-variant: normal;
  text-transform: none;
  line-height: 2rem;
  color: #616365;
  vertical-align: middle;
  font-size: 24px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

//water safety view by icon
[class^="trendiconwater-"]:before,
[class*=" iconwater-"]:before {
  font-family: "nalco-view-by";
  font-style: normal;
  font-weight: normal;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;

  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.iconwater-dd-graph:before {
  content: '\e800';
}

//water safety view by icon ends

//attachment icon for map data parameter in left section

[class^="attach-"]:before,
[class*=" attach-"]:before {
  font-family: "data-attachment-icon";
  font-style: normal;
  font-weight: normal;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  margin-left: .2em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

//value-waste treatment savings icons


[class^="saving-"]:before,
[class*=" saving-"]:before {
  font-family: "waste-treatment-icon";
  font-style: normal;
  font-weight: normal;
  font-size: 65px;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-bottom: 10px;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
[class^="dv-drag-"]:before, [class*=" dv-drag-"]:before {
  font-family: "dv-drag-icon";
  font-style: normal;
  font-weight: normal;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  margin-left: .2em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}
[class*='-NA']:before{
   font-family: 'Glyphter';
   font-style: normal;
  font-weight: normal;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  }
  .circlebar-NA:before
  {
    content:'\004b';
    @include font-size(16);
  }
.dv-drag-ico:before {
  content: '\e800';
}

.saving-productivity:before {
  content: '\e801';
  color: $saving-productivity;
}

.saving-assetpreservation:before {
  content: '\e802';
  color: $saving-assetpreservation;
}

.saving-chemistry:before {
  content: '\e803';
  color: $saving-chemistry;
}

.saving-co2-reduction:before {
  content: '\e804';
  color: $saving-co2;
}

.saving-cost:before {
  content: '\e805';
  color: $saving-cost;
}

.saving-energy:before {
  content: '\e806';
  color: $saving-energy;
}

.saving-waste:before {
  content: '\e807';
  color: $saving-waste;
}

.saving-water:before {
  content: '\e808';
  color: $saving-water;
}

//default-icon for value
[class^="category-"]:before,
[class*="category-"]:before {
  font-family: "category-icons";
  font-style: normal;
  font-weight: normal;
  font-size: 65px;
  display: inline-block;
  text-decoration: inherit;
  margin-bottom: 10px;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.category-profitability:before {
  content: '\e801';
  color: $saving-profitability;
}

.category-food-safety:before {
  content: '\e802';
  color: $saving-food;
}

.category-product-quality:before {
  content: '\e803';
  color: $saving-quality;
}

//value list view icons


[class^="listview-"]:before,
[class*=" listview-"]:before {
  font-family: "value-list-view-icons";
  font-style: normal;
  font-weight: normal;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $primary-gray;
  @include font-size(16);
}

.listview-money:before {
  content: '\e802';

}

.listview-graph:before {
  content: '\e803';
}

 //value customize icon
[class^="custom-"]:before, [class*=" custom-"]:before {
  font-family: "value-customize-icon";
  font-style: normal;
  font-weight: normal;


  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;

  font-variant: normal;
  text-transform: none;

  line-height: 1em;

  margin-left: .2em;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}
.custom-icon:before {
  content: '\e800';
 }
.attach-parameter{
  display: inline-block;
  right: 0;
  position: absolute;

  &:before {
    content: '\E800';
    color: #007ac9;
  }
}

.ico-plus-button {
  &:before {
    content: "\e900";
  }
}

.ico-status-square {
  &:before {
    content: "\e901";
  }
}

.ico-status-hexa {
  &:before {
    content: "\e902";
  }
}

.ico-status-circle {
  &:before {
    content: "\e903";
  }
}

.ico-status-donut {
  &:before {
    content: "\e907";
  }
}

.ico-radio-on-circle {
  &:before {
    content: "\e908";
  }
}

.ico-zoom-out {
  &:before {
    content: "\e909";
  }
}

.ico-zoom-in {
  &:before {
    content: "\e90a";
  }
}

.ico-expand {
  &:before {
    content: "\e90b";
  }
}

.ico-collapse {
  &:before {
    content: "\e90c";
  }
}

.ico-edit-pencil {
  &:before {
    content: "\e90d";
  }
}

.ico-reports {
  &:before {
    content: "\e90e";
  }
}

.ico-toggle {
  &:before {
    content: '\e912';
    color: $primary-gray;
  }

  &:after {
    content: '\e903';
    @include rhythm(11, 11);
    color: $primary-blue;
    position: absolute;
    top: 3px;
    right: 1.05rem;
  }
}


.ico-watchlist-eye {
  &:before {
    content: "\e911";
  }
}

.ico-switch-shape {
  &:before {
    content: "\e912";
  }
}

.ico-water {
  &:before {
    content: "\e914";
  }
}

.ico-lock {
  &:before {
    content: "\e915";
  }
}

.ico-favorites-star {
  @include rhythm(15, 15);

  &:before {
    content: "\e917";
  }
}

.ico-unfavorites-star {
  @include rhythm(15, 15);

  &:before {
    content: "\e918";
  }
}

.ico-arrow-drag {
  @include rhythm(31, 8);
  color: $secondary-dark-gray;

  &:before {
    content: "\e919";
  }
}

.ico-refresh {
  &:before {
    content: "\e91a";
  }
}

.ico-refresh-filled {
  &:before {
    content: "\e91b";
  }
}

.ico-waste {
  &:before {
    content: "\e91c";
  }
}

.ico-global-graph {
  &:before {
    content: "\e91d";
  }
}

.ico-grip {
  @include rhythm(20, 20);
  color: $primary-white;
  @include font-size(18);

  &:before {
    content: "\e91e";
    float: right;
    opacity: 0.3;
  }

  &.dark {
    color: $secondary-gray;
    opacity: 1;
  }
}

.ico-more {
  &:before {
    content: "\e91f";
  }

}

.drag-edit {
  float: right;
  @include font-size(18);

  &:before {
    content: '\e800';
    float: right;
    margin-right: 10px;
    margin-top: 0px;
  }
}

.drag-delete {
  float: right;
  @include font-size(18);

  &:before {
    content: '\e801';
    float: right;
    margin-right: 1px;
    margin-top: -1px;
  }
}

.asseticon-List {
  padding-right: 5px;

  &:before {
    content: '\e801';
  }
}

.export-icon {
  padding-right: 5px;

  &:before {
    content: '\e800';
  }
}

//admin icons



.admin-performance:before {
  content: "\e900";
}

.admin-commitmentDetails:before {
  content: "\e901";
}

.admin-contractDetails:before {
  content: "\e902";
  margin-top: -2px;
}

.admin-generalSetting:before {
  content: "\e908";
}

.admin-additionalDetails:before {
  content: "\e909";
}


.customize.selected {
  .asseticon-List:before {
    color: $primary-blue;
  }
}

.asseticon-Grid {
  padding-right: 5px;

  &:before {
    content: '\e800';


  }
}

.customize.selected {
  .asseticon-Grid:before {
    color: $primary-blue;
  }
}

.customize.selected {
  .ico-more:before {
    color: $primary-blue;
  }
}

.customize.selected {
  .ico-more:before {
    color: $primary-blue;
  }
}

.ico-info-thick {
  color: $primary-blue;
  @include rhythm(22, 22);

  &:before {
    content: "\e920";
  }
}

.ico-info-thin {
  color: $primary-blue;
  @include rhythm(20, 16);
  vertical-align: text-top;
  cursor: pointer;

  &:before {
    content: "\e921";
    // vertical-align: text-top;
  }
}

.ico-locations {
  &:before {
    content: "\e922";
  }
}

.ico-customize {
  padding-right: 5px;

  &:before {
    content: "\e923";
  }
}

.ico-delete {
  &:before {
    content: "\e924";
  }
}

.ico-exit-round-edge {
  @include rhythm(12, 12);

  &:before {
    content: "\e925";
  }
}

.ico-exit-flat-edge {
  &:before {
    content: "\e926";
  }
}

.ico-exit-in-circle {
  &:before {
    content: "\e927";
  }
}

.ico-calendar {
  &:before {
    content: "\e928";
  }
}

.ico-checkbox {
  &:before {
    content: "\e929";
  }
}

.ico-checkbox-pressed {
  &:before {
    content: "\e92a";
  }
}

.ico-checkbox-checkmark {
  &:before {
    content: "\e92b";
  }
}

.ico-checkmark {
  &:before {
    content: "\e92c";
  }
}

.ico-arrow-up-tooltip {
  &:before {
    content: "\e92d";
  }
}

.ico-arrow-right-tooltip {
  &:before {
    content: "\e92e";
  }
}

.ico-asset-tree {
  &:before {
    content: "\e932";
  }
}

.ico-asset-life {
  &:before {
    content: "\e933";
  }
}

.ico-air {
  &:before {
    content: "\e934";
  }
}

.ico-energy {
  &:before {
    content: "\e935";
  }
}

.ico-alerts {
  &:before {
    content: "\e936";
  }
}



.page-content {
  min-width: 80vw;
  min-height: 60vw;
}

.mw-50 { max-width: 50% !important; }

.mw-25 { max-width: 25% !important; }

.w-50 { width: 50% !important; }

.w-25 { width: 25% !important; }

/*idle timeout */
 .idle-modal-cont {
  position: relative;
  z-index: 5000;
  & .idle-time-out-text {
    line-height: 2rem;
    margin-top: 10px;
    margin-bottom: 1px;
    margin-right: 0.6rem;
    text-align: left !important;
    float: left;
    font-family: $interstate-regular;
  }
  & .idle-modal-header {
    text-align: left !important;
    margin-left: 0rem;
    padding-left: 0.4rem;
    color: #E00034;
  }
  & .modal-backdrop.idle-modal-backdrop {
    z-index: 1200 !important;
    background-color: #000 !important;
  }

  & .modal-body{
    min-height: fit-content!important;
    border-radius: 10px;
  }
  & .modal-content{
    min-width: 450px !important;
  }
  & .modal-title{
    font-size: 1.2rem;
    color: #007ac9;
    font-family: $interstate-regular;
    // &::before{
    //   content: '\231B';
    //   padding-right: 5px;
    // }
  }
}

.chinese-font-family {
  font-family: "Interstate-Regular", sans-serif,SimSun !important;
}
.bgColorFSQS{
  // background-color: #F5FAFD !important;
}

.overlay-body{
  overflow: hidden;
}



@media (min-width:391px) and (max-width: 767px) {
 .main-content-collapse-view {
  width: 95%;
  margin-left: 0;
}
}