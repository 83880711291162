@import "../../../../../styles/variables.scss";

.alert-dismissible{
  width: 39%;
  position: fixed;
  top: 157px;
  background: black;
  color: white;
  text-align: center;
  z-index: 99999;
  font-size: 14px;
  line-height: 20px;
  margin: 0 auto;
  left:0px;
  right:0px;
  padding-top: 11px 36px 13px 7px;

  @media screen and (max-width:550px){
    width: 85%;
  }

  p{
    margin-bottom: 0px;
  }
  .close{
    color: white !important; 
    z-index: 111111111; 
    padding: 7px 14px;
    
    .ico-exit-round-edge{
      color: white !important; 
      font-size: 0.65rem !important;
    }
  }
}
.padding-3{
    padding-bottom:3px;
    .sites-tooltip{
        line-height: #{pxToRem(28)} !important;
        padding-left: 5px;
    }
}

.nalco-water-safety-compliance{
  .tooltip{
    &.bs-tooltip-bottom {
        z-index: 500 !important;
  }
 }
}


@media screen and (max-width:552px){
    .content{
      .siteFilter{
        .showMe{
          left:8px;
        }
      }
  
      // .calendarFilter{
      //   padding:0.5rem;
      // }
    }
   
  }
  // @media screen and (max-width:750px){
  //   .content{
  //     .calendarFilter{
  //       padding:0.5rem;
  //     }
  //   }
  // } 
