/****************************************************/

// This files contains the asset tree component styles
@import "../../../styles/variables.scss"; 
/****************************************************/

ul {
  li {
    list-style: none;
  }
}

.center-align{
  text-align:center;
  margin-top: -30px;
  span.site-name{
    font-size:26px;
    color:$asset-tree-icon;
  }
  h3.d-inline{
    font-size:12px;
    color:$d-inline;
    position: relative;
    top: 5px;
  }
  .ico-asset-tree {
    &::before {
      font-size: 1.5625rem;
      line-height: 1.5625rem;
      color: $asset-tree-icon;
    }
  }
}

.map-asset-container {
  position: relative;
  *[draggable='true'],
  .dnd-drag-start {
    cursor: all-scroll !important;
  }
  .section
  {
    max-width: 46.3%;
    width: 46.3%;
    margin-bottom:0px;

    @media (max-width: 1200px) {
      max-width: 46.3%;
      width: 44%;
      margin-bottom: 0px;
    }
  }
  .asset-library {
    margin-top: 0.5rem;
    &>p {
      margin-bottom: 0.75rem;
    }

    .asset-content {
      display: flex;
      flex-direction: column;
      position: relative;

      .asset-content-title {

        .card-body
        {
          overflow-y: auto;
          height: 13.3rem;
        }
    
        .btn {
          display: inline-block;
          @include rhythm(16, 20);
          text-align: center;
          max-width: 100%;
          margin-right: 1rem;
          padding: 14px;
          position: relative;
          font-size: 16px;
          &:last-child {
            background-image: linear-gradient(180deg, $primary-white, $body-bg);
            margin-right: 0;
            margin-left: -0.18rem;
            text-align: left;
            border: 1px solid $primary-gray;
            margin-bottom: 0.5rem;
            color: $primary-gray;
            padding: 12px;
            background-color: transparent;
    
            li{
              list-style: none;
              padding: 0;
            }
          }
            &:after {
             display: none;
            }
            &.dnd-drag-start {
              &:after {
                font: normal normal normal 20px/20px 'Nalco-additional-icons';
                content: '\E91E';
                position: absolute;
                right: 1rem;
                opacity: 0.3;
              }
            }
          &:hover,
          &.dnd-drag-start {
            &:after {
              font: normal normal normal 20px/20px 'Nalco-additional-icons';
              content: '\E91E';
              position: absolute;
              right: 1rem;
              opacity: 0.3;
            }
          }
        }
      }
    

      .ico-grip {
        float: right;
      }
    }
  }
  
  .asset-tree {
  
    margin-top: 2.3rem;
    max-height: 95vh;
    .ico-asset-treeico {
      @include rhythm(25, 25);
      color: $asset-tree-icon;
    }
    .site-name{
      font-size:20px;
    }
    &>p {
      margin-bottom: 0.75rem;
    }
    .btn-secondary
    {
      width: 100px;
      margin-right:8px;
      height:32px;
      white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0.375rem 0.65rem;
    }
    span {
      &.site-name {
        @include rhythm(26, 28);
        margin-left: #{pxToRem(14)};
        vertical-align: bottom;
      }
    }
    .customer-name {
      @include rhythm(14, 16);
      color: $secondary-dark-gray;
      margin-top: 0.75rem;
      text-transform: uppercase;
    }
    .asset-body{

      .asset-content {
        position: relative;
        overflow-y: auto;
        height: 85vh;
        .asset-content-title {
      .card{
        .card-body
        {
          width: 93%;
          float: right;
          margin-left: 32px;
          padding: 0rem 0rem 0rem .2rem;
          overflow: hidden;
          .btn {
            display: inline-block;
            @include rhythm(16, 20);
            text-align: center;
            max-width: 100%;
            margin-right: 1rem;
            padding: 14px;
            position: relative;
            font-size: 16px;
            &:last-child {
              background-image: linear-gradient(180deg, $primary-white, $body-bg);
              margin-right: 0;
              margin: 0rem 0rem 0.5rem -0.18rem;
              text-align: left;
              border: 1px solid $primary-gray;
              color: $primary-gray;
              padding: 14px;
              background-color: transparent;
              width: 99.6%;
      
              li{
                list-style: none;
                padding: 0rem;
              }
            }
              &:after {
               display: none;
              }
          }
        }
      }

        }
      
  
      }
    }

  }
 
  .asset-body {
    background-color: $primary-white;
    height: 95vh;
  }
  .asset-subheader{
    border-bottom: 1px solid $secondary-light-gray;
    padding: 0.1rem 1rem;

    .asset-Subtitle{
      margin: 6px 0;
    }
  }
  .asset-header {
    border-bottom: 1px solid $secondary-light-gray;
    padding: 0.5rem 1rem;
    .asset-title {
      font-size:16px;
      margin-top: 8px;
      margin-bottom: 4px;
      
    }
    .asset-Subtitle
    {
      font-size: 14px;
    }
   
    .float-right {
      &.checkBox {
        width: #{pxToRem(170)};
        padding-top: #{pxToRem(7)};
        label {
          text-align: right;
        }
      }
    }
  }
  .devider {
    width: 5rem;
    display: inline-block;
    margin-top: 2.4rem;
    p {
      margin-bottom: 0.75rem;
      &.dashed {
        border-right: 2px dashed $secondary-dark-gray;
        min-height: 42%;
        max-height: 42%;
        width: 2.5rem;
      }
      &.drag-right {
        text-align: center;
        color: $secondary-dark-gray;
        line-height: 28px;
        font-weight: 700;
        padding-left: 0px;
      }
    }
  }


  ngb-accordion .card
  {
    .card-header[role="tab"] {
      padding: .875rem;
      background: linear-gradient($customer-area-legend, $customer-area-gradient-legend);
      margin-bottom: 0.5rem;
      width: 98%;    
    }
 
   
    ngb-accordion .card {
      .card-header[role="tab"] {
        background: linear-gradient($asset-legend, $asset-gradient-legend);
        width: 99%; 
      }

      ngb-accordion .card {
        .card-header[role="tab"] {
          background-color: $port-legend;
          margin-bottom: 0.5rem;
          width: 99.5%; 
        }
    }
  }
     a {
      color: $primary-white !important;
      padding-left: 0rem !important;

      span {
      width:94%;
      }
      &:after {
        border-color: $primary-white transparent transparent transparent !important;
        position: relative;
        display: block;
        float: left;
        top: 5px;
        margin: 0px 10px 0px 0px;
    }  
    }  
  }
}


.legend-wrapper{
  float: right;
  max-width: 97%;
  margin-top: 20px;
  font-size: 0.75rem;
  font-family: $interstate-regular;
.legend-align{
  float: left;
  margin-right: 11px;
  margin-bottom:5px;
}

.legend-align:nth-child(1){
  float: left;
  margin-left: 0px;
}
.legend-align:nth-child(7){
  margin-left: 0px;

}
.legend-text{
  width: 10px;
  height: 10px;
  margin-right: 4px;
  float: left;
  margin-top: 2px;
}
.indicator-text{
  //margin-top: 5px;
}
.indicator{
  color: $primary-blue;
  margin-left: -2px;
  margin-right: 5px;
}

.legend-align:nth-child(1) .legend-text{
  background:linear-gradient($customer-area-legend,$customer-area-gradient-legend);
}
.legend-align:nth-child(2) .legend-text{

  background:$subcustomer-area-legend;
}
.legend-align:nth-child(3) .legend-text{

  background-image: linear-gradient( $deg-system, $system-legend, $system-gradient-legend );
}
.legend-align:nth-child(4) .legend-text{

  background:linear-gradient($asset-legend,$asset-gradient-legend) ;
}

.legend-align:nth-child(5) .legend-text{
  background: $port-legend;
  
}
.legend-align:nth-child(6) .legend-text{
 background: linear-gradient ($parameter , $parameter-gradient);
 border:1px solid $secondary-gray;
}

}
@-moz-document url-prefix() {
  .legend-wrapper{
  .legend-align{
    float: left;
    margin-left: 8px;
  }
}
}
.legend-attachment{
  font-family: $interstate-regular;
  .legend-align{
    float: left;
    margin-top: 20px
  }
  .legend-text{
    color:$primary-gray;
    float:left;
    margin-right: 5px;
    &:before{
      font: normal normal normal 16px/16px 'data-attachment-icon';
      content: '\e800';
      color: $primary-blue;
    }
  }

}

.tooltip.bs-tooltip-bottom {
  margin-left: -7px !important;
}

.btn-secondary:disabled+.tooltip {
  display: none !important;
}

.btn-secondary:not(:disabled):not(.disabled)+.tooltip {
  display: none !important;
}

.btn-secondary:not(:disabled):not(.disabled):hover +.tooltip {
    display: block !important;
}