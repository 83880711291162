/****************************************************/

// This files contains the tiles component styles
/****************************************************/

.admin-tile {
    .card.tile {
        margin-bottom: 0;
        .tile-content-wrapper {
            background-color: #fbfbfb;
        }
    }
}

.errorClass {
    color: $primary-blue;
    cursor: pointer;
}

.card.tile {
    border: 0 none;
    border-radius: 0;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
    margin-bottom: 1rem;
    p {
        &.label {
            @include rhythm(14, 16);
        }
    }
    .card-header,
    .card-footer {
        background: none;
    }
    .card-header {
        border-bottom: 1px solid $secondary-light-gray;
        min-height: 2.75rem;
        padding: 0.75rem 1rem #{pxToRem(15)};
        .card-title {
            float: left;
            margin: 0;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 70%;
        }
        #card-tooltip {
            &::after {
                content: '';
                display: block;
            }
        }
        a.card-title {
            @include rhythm(16, 20);
            color: $primary-blue !important;
        }
        a.card-title-pdf {
            @include rhythm(16, 20);
            color: #616365 !important;
        }
        .card-subtitle {
            @include rhythm(12, 20);
            float: left;
            clear: none;
        }
        .moreActionIcons{
            position: absolute;
            right: 30px;
            top: 14px;
            display: flex;
            .drag-delete {
                cursor: pointer;
            }           
            .ico-grip:before {
                color: #616365;
                opacity: 0.9 !important;
            }
        }
        .right-aligned {
            float: right;
        }
        
        .accordion-caret {
            position: relative;
            &:before {
                @include triangle(top, 10px, 9px, $primary-blue);
                position: absolute;
                right: 20px;
                top: 5px;
            }
            &.collapsed {
                &:before {
                    transform: rotate(180deg)
                }
            }
        }
    }
    .defaultDataText{
        display: flex;
        justify-content: center;
        color: $primary-gray;
        font-size: 13px;
    }
    .tile-content-wrapper {
        transition: height .5s;
        &.collapsed {
            height: 0;
            overflow: hidden;
        }
    }
        .card-body {
        min-height: 10rem;
        padding: 0rem;
        .customer-name {
            @include rhythm(12, 14);
            text-transform: uppercase;
        }
        .table {
            display: table;
            p {
                margin-bottom: 0;
            }
            .card-left,
            .card-right {
                display: table-cell;
                vertical-align: top;
            }
            .card-left {
                min-width: #{pxToRem(80)};
                text-align: right;
                padding: 0 1rem 0 0;
                .total-assets {
                    @include rhythm(40, 44);
                    margin: 0;
                }
                .text-capitalize {
                    @include rhythm(14, 16);
                    margin-right: 2px;
                }
            }
            .card-right {
                padding: 0 0 0 1rem;
                text-align: left;
                border-left: 1px solid $secondary-light-gray;
                p {
                    margin-bottom: 0.25rem;
                }
            }
        }
        .accounted-for {
            margin-bottom: 0.75rem;
            font-size: 1rem;
            .ico-checkmark {
                @include rhythm(10, 8);
                color: $utilitarian-green;
                margin-right: 0.5rem;
            }
        }
        .last-edited-by {
            font-family: $interstate-bold;
            @include rhythm(10, 12);
        }
        .ico-info-thin {
            cursor: pointer;
        }
        .locked {
            .ico-lock {
                @include rhythm(12, 9);
                margin-right: 0.3rem;
                vertical-align: baseline;
            }
        }
    }
    .card-footer {
        border-top: 1px solid $secondary-light-gray;
        min-height: 3.5rem;
        padding-bottom: 1rem;
        padding-top: 1rem;
        .buildConfigure {
            .btn {
                display: inline-block;
                width: auto;
                margin-right:#{pxToRem(22)};
                &:last-child {
                    margin-right: 0;
                }
            }
        }
        .locked {
            margin: 0.5rem 0;
            text-align: center;
            img {
                margin-right: 0.5rem;
                vertical-align: top;
            }
        }
    }
}

.watersafety-header {
    .card.tile {
        .card-header {
            border-bottom: 1px solid #E2E2E2;
            min-height: 2.55rem;
            padding: 0.75rem 1rem 0.175rem !important;
            .right-aligned {
                float: right;
                margin-top: -1rem;
            }
            .card-subtitle {
                font-size: 9px;
                line-height: 1rem;
                float: left;
                clear: both;
                margin-top: 0.1rem;
                min-height: 0.9rem;
                height: 0.9rem;
            }
        }
    }
}

.watersafety-header.distrubution-tile {
    .card {
        .card-header {
            padding-top: 13px !important;
            padding-bottom: 13px !important;
            h3 {
                .right-aligned {
                    padding-top: 13px;
                }
            }
            .right-aligned {
                padding-top: 0.75rem;
            }
            .card-subtitle {
                display: none;
            }
        }
    }
}

.no-header {
    .card-header {
        display: none;
    }
}

.display-header {
    .card-header {
        display: block;
    }
}

.state-wrapper {
    padding: 16px;
    &.loading {
        width: 100%;
        position: absolute;
        left: 0;
        right: 0;
        top: 30px;
    }
    .loader {
        display: block;
        width: 70px;
        height: 70px;
    }
}


.quality-header{
    &:focus{
        outline: none;
    }
    &.asset-tiles{
       .tooltip{
            position: absolute;
            top: 20% !important;
            left: 0% !important;
       
            .tooltip-inner div p{
                margin-bottom:0;
            }
        }
     
    }

    .card.tile .card-header {
        h3.card-title{
            font-size: 1rem;
            margin-right:0.3rem;
        }
        a.card-title{
            margin-right: 0.3rem;
        }

       .right-aligned {
            margin-top: 0rem;
        }
    } 
   
    .tooltip {
        top: 0.5rem !important;
        padding: 0.5rem !important;
        .tooltip-inner {
            padding: 0.5rem 0.5rem;
            max-width: 20rem !important;
            white-space: normal;
            @media screen and (max-width:550px){
                min-width: 50%;
            }
            .tooltip-content {
                padding: 2px;
            }
            div {
                @include font-size(11);
                line-height: 1rem;
            }
        }
    }
    
}

.AssetPerformanceTrendLineChart {
    .card.tile .card-header {
        h3.card-title {
            font-size: 1rem;
            margin-right: 0.3rem;
        }

        a.card-title {
            margin-right: 0.3rem;
        }

        .right-aligned {
            margin-top: -1.7rem;
            margin-bottom: -1.7rem;
        }
    }
    .line-chart-pills {
        ul li {
                background-color: $primary-white !important;
                border: 1px solid $secondary-dark-gray;
                cursor: default !important;
                color: $primary-gray !important;
                pointer-events: none;
              }
    }

    .pill-borders .filtertab-list li {
        padding: 7px 60px 5px 0px !important;
        margin-right: 5px;
        margin-top: 23px !important;
      }
    
}

.card-title-content {
    width: 70%;
    display: inline;
}

.tooltip-msg {
    max-width: 12rem !important;
}
