@import "../../../../styles/variables.scss";

.TourConfigTile {
    .mat-mdc-chip {
        font-size: 0.8125rem;
        margin: 4px;
    }

    .mat-mdc-chip.mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
        background-color: $primary-gray;
        color: $primary-white;
    }

    .chipRow {
        border-bottom: 1px solid #e2e2e2;
        padding: 0.5rem 1rem 0.5rem;
        display: flex;
        width: auto;
        overflow-x: auto;
        height: 85px;
    }

    .card.tile .tile-content-wrapper {
        min-height: 300px !important;
    }

    .msgContentTile {
        padding: 100px;
        .msgContent {
            text-align: center;
        }
    }

    .k-button{
      background-color: #fff !important;
      border: none;
      color: #007AC9;
  }

  }
  .add-edit-sub-configuration-modal {
    display: flex !important;
    align-items: center;
    justify-content: center;
        .modal-dialog{
            min-width:42rem !important;
      }
  }

  .mappingsuccessDialog {
    .modal-dialog {
      top: 25%;
      min-width: 25rem;
    }

    .modal-body {
      padding: 40px 60px !important;
      min-height: 11rem;

      img {
        width: 40px;
        border-radius: 40px;
      }

      label {
        font-size: 16px;
        margin-top: 21px;
      }
    }
  }
  .custom-msr-dialog {
    .modal-dialog {
      top: 25%;
      min-width: 35rem;
    }

    .modal-body {
      min-height: 5rem;
    }
  }
  .drag-edit{
    margin-top: 5px;
    margin-left: 15px;
    cursor: pointer;
    background: none;
    border: 0px;
    padding: 0;
    &:before{
      margin: 0px !important;
    }
  }
