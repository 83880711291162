/****************************************************/

// This files contains the scss imports that are required for the accordion

/****************************************************/

ngb-accordion {
  .card {
    .card-header[role="tab"] {
      min-height: 0;
      padding: 1.375rem 1.5rem 1.375rem 1rem;
      a {
        @include rhythm(16, 20);
        color: $primary-gray;
        display: inline-block;
        width: 100%;
        text-transform: capitalize;
        text-align: left;
        position: relative;
        &:after {
          @include triangle(bottom, 8px, 7px, $primary-blue);
          position: absolute;
          right: 0;
          top: 30%;         
          transform: rotate(-90deg);
        }
      }
      &.active {
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
        a {
          &:after {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
          }
        }
      }
    }
    .card-body {
      // background-color: $modal-body-bg;
      // padding: 1.5rem #{pxToRem(17)} 1rem;
      // max-height: 43rem;
      overflow-y: auto;
      &::-webkit-scrollbar-track {
        background-color: $modal-body-bg;
      }
    }
  }

  &.transparent-bg {
    .card {
      &,
      .card-body {
        background-color: transparent;
      }
    }
  }

  &.header-white-bg {
    .card {
      .card-header {
        background-color: $white;
      }
    }
  }

  &.content-no-padding {
    .card {
      .card-body {
        padding: 0;
        margin-top: 10px;

        nalco-tile {
          padding: 0px;
        }
      }
    }
  }
}

ngb-accordion .card .card-header button:after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  -moz-transform: scale(0.9999);
  border-width: 8px 7px 0 7px;
  border-color: #007ac9 transparent transparent transparent;
  position: absolute;
  right: 0;
  top: 25px;
  transform: rotate(
-90deg
);
}

ngb-accordion{
  .card{
      .card-header{
          
          button {
            padding-left: 25px;
            background: none;
            text-align: left;
            flex: 0 0 100%;
          }
          button:after{
              transform: rotate(180deg);
              left: 10px;
              top: 25px;
          }
        button.btn-link.collapsed:after {
          position: absolute;      
          border-top-color: #007ac9;
          transform: rotate(0deg);
          }
      }
  }
}

ngb-accordion.custom-accordion.admin-accordion .card .card-header[role="tab"] button h4{
  font-size: 16px;
  line-height: 27px;
  color: #616365;
}

ngb-accordion.custom-accordion.admin-accordion .card .card-header[role="tab"] button:after{
  left: auto;
  right: 25px;
}
