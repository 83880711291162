/****************************************************/

// This files contains the asset tree component styles

/****************************************************/
@import "../../../styles/variables.scss";

ul {
  li {
    list-style: none;
  }
}

.center-align {
  text-align: center;
  margin-top: -30px;

  span.site-name {
    font-size: 26px;
    color: $asset-tree-icon;
  }

  h3.d-inline {
    font-size: 12px;
    color: $d-inline;
    position: relative;
    top: 5px;
  }

  .ico-asset-tree {
    &::before {
      font-size: 1.5625rem;
      line-height: 1.5625rem;
      color: $asset-tree-icon;
    }
  }
}

.asset-wrapper {
  min-width: 447px;
  margin: 0 auto;
  margin-top: 30px;
  font-size: 0.75rem;

  .asset-legend {
    float: left;
    margin-left: 11px;
  }

  .legend-text {
    width: 10px;
    height: 10px;
    margin-right: 4px;
    margin-top: 2px;
    float: left;

  }

  .asset-legend:nth-child(1) {
    margin-left: 0px;
  }

  .asset-legend:nth-child(1) .legend-text {
    background: linear-gradient($customer-area-legend, $customer-area-gradient-legend);
  }

  .asset-legend:nth-child(2) .legend-text {
    background: $subcustomer-area-legend;
  }

  .asset-legend:nth-child(4) .legend-text {
    background: linear-gradient($asset-legend, $asset-gradient-legend);
  }

  .asset-legend:nth-child(5) .legend-text {
    background: $subasset-legend;
  }

  .asset-legend:nth-child(3) .legend-text {
    background-image: linear-gradient($deg-system, $system-legend, $system-gradient-legend);
  }
}

.build-container {
  position: relative;

  *[draggable='true'],
  .dnd-drag-start {
    cursor: all-scroll !important;
  }

  .section {
    max-width: 46.3%;
    width: 46.3%;
    margin-bottom: 0px;

    @media (max-width: 1200px) {
      max-width: 46.3%;
      width: 44%;
      margin-bottom: 0px;
    }
  }

  &>div {
    max-width: 41rem;
  }

  .asset-library {
    margin-top: 0.5rem;

    &>p {
      margin-bottom: 0.75rem;
    }

    .asset-content {
      height: 85vh;
      display: flex;
      flex-direction: column;

      .asset-content-title {
        border-bottom: 2px solid $secondary-light-gray;

        .btn {
          display: inline-block;
          @include rhythm(16, 20);
          text-align: center;
          max-width: 100%;
          margin-right: 1rem;
          padding: 14px;
          position: relative;
          font-size: 16px;

          &:last-child {
            background-image: linear-gradient(180deg, $subcustomer-area-legend, $customer-area-gradient-legend);
            margin-right: 0;
            margin-left: -0.18rem;
            text-align: left;
          }

          &:after {
            font: normal normal normal 20px/20px 'Nalco-additional-icons';
            content: '\E91E';
            position: absolute;
            right: 1rem;
            opacity: 0.3;
          }

          &.dnd-drag-start {
            &:after {
              font: normal normal normal 20px/20px 'Nalco-additional-icons';
              content: '\E91E';
              position: absolute;
              right: 1rem;
              opacity: 0.3;
            }
          }

          &:hover,
          &.dnd-drag-start {
            &:after {
              font: normal normal normal 20px/20px 'Nalco-additional-icons';
              content: '\E91E';
              position: absolute;
              right: 1rem;
              opacity: 0.3;
            }
          }
        }
      }

      .asset-tree-content {
        height: 100%;
        overflow-y: auto;
        padding-bottom: 30px;

        .asset-accordion {
          .asset-accordion-title {
            border-bottom: 1px solid $secondary-light-gray;
            width: 100%;
            padding: 6px 0px 6px 16px;
            margin-bottom: 0px;
          }

          .card {
            border-top: 1px solid $secondary-light-gray;

            &:first-child {
              border-top: 0 none;
            }

            &:last-child {
              border-bottom: 1px solid $secondary-light-gray;
            }

            &.hidden {
              display: none;
            }

            .card-header {
              background-color: $primary-white;
              padding: 15px 16px 15px 16px;
              position: relative;

              &:after {
                @include triangle(bottom, 8px, 6px, $primary-blue);
                position: absolute;
                right: 1rem;
                top: 45%;
              }

              &.active {
                &:after {
                  transform: scaleY(-1);
                }

                border-bottom: 1px solid $secondary-light-gray;
              }

              a {
                color: $primary-blue;
                @include rhythm(16, 18);
                display: inline-block;
                width: 100%;
              }
            }

            .card-body {
              display: none;
            }

            &.isExpanded {
              .card-header {
                border-bottom: 1px solid $secondary-light-gray;

                &:after {
                  transform: scaleY(-1);
                }
              }

              .card-body {
                display: block;
              }
            }
          }

          .card-body {
            padding: 1rem 2rem;

            .btn.btn-secondary {
              background-image: linear-gradient(180deg, $opportunity-graph-line-ending-blue, $opportunity-graph-line-starting-blue) !important;

              padding: 13px 16px;
              border-radius: 0.25rem;
              border: 1px solid $secondary-dark-gray;
              margin-bottom: 0.5rem;
              text-align: left;

              span {
                @include rhythm(14, 16);
              }

              .ico-grip {
                visibility: hidden;
              }

              &:hover {
                color: $primary-gray;
              }

              &:hover,
              &.dnd-drag-start {
                .ico-grip {
                  visibility: visible;
                }
              }

              &.dnd-drag-start {
                background-image: none;
                border: 0 none;
                background-color: $secondary-light-gray;
                color: $secondary-light-gray;
              }
            }
          }
        }
      }

      .ico-grip {
        float: right;
      }
    }
  }

  .asset-tree {
    margin-top: 2.3rem;
    min-height: 75vh;

    .ico-asset-treeico {
      @include rhythm(25, 25);
      color: $primary-blue;
    }

    .site-name {
      font-size: 20px;
    }

    &>p {
      margin-bottom: 0.75rem;
    }

    .btn-secondary {
      width: 100px;
      margin-right: 8px;
      height: 32px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 0.375rem 0.65rem;

      &.button-active {
        color: $primary-white !important;
        background-image: linear-gradient(180deg, $linear-gradient-starting-blue, $primary-blue);
        border: none !important;
      }
    }

    .btn-secondary:disabled+.tooltip {
      display: none !important;
    }

    .btn-secondary:not(:disabled):not(.disabled)+.tooltip {
      display: none !important;
    }

    .btn-secondary:not(:disabled):not(.disabled):hover +.tooltip {
        display: block !important;
    }

    span {
      &.site-name {
        @include rhythm(26, 28);
        margin-left: #{pxToRem(14)};
        vertical-align: bottom;
      }
    }

    .customer-name {
      @include rhythm(14, 16);
      color: $secondary-dark-gray;
      margin-top: 0.75rem;
      text-transform: uppercase;
    }

    .asset-content {
      position: relative;
      overflow-y: auto;
      height: 100%;
      display: flex;
      flex-direction: column;

      .empty-asset-tree {
        //display: none;
        font-family: $interstate-extra-light;
        @include rhythm(26, 28);
        text-align: center;
        color: $primary-blue;
        width: #{pxToRem(348)};
        position: absolute;
        top: 18rem;
        left: 50%;
        margin-left: #{pxToRem(-174)};
      }

      &>div {
        padding: 0rem;
      }

      .area-container {
        padding-bottom: 0.5rem;

        .row {
          .assets-btn-group {
            visibility: hidden;
            position: absolute;
            right: 2.5rem;
            top: 0.875rem;

            img {
              height: 18px;
            }

            .assets-btn {
              cursor: pointer !important;

              &.ico-delete {
                @include rhythm(19, 19);
              }

              &.ico-edit-pencil {
                @include rhythm(20, 20);
              }

              &.ico-delete,
              &.ico-edit-pencil {
                color: $primary-white;
              }
            }

            .assets-btn,
            .ico-grip {
              display: inline-block;
              margin-left: #{pxToRem(14)};
              margin-right: 2px;
              vertical-align: middle;
            }
          }

          &:hover {
            .assets-btn-group {
              visibility: visible;
            }
          }
        }

        h3 {
          color: $primary-white;
          margin: 0;
          padding: 0;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .area-name,
        .unit-name {
          position: relative;

          &:after {
            @include triangle(top, 8px, 6px, $primary-white);
            position: absolute;
            right: 1rem;
            top: 1.25rem;
            cursor: pointer;
          }

          &.isCollapsed {
            &:after {
              transform: scaleY(-1);
            }
          }
        }

        .area-name {
          margin: 0;
          @include gradient-directional($linear-gradient-starting-blue, $primary-blue, 180deg);
          padding: #{pxToRem(14)} 1rem;
        }

        .unit-container {
          .unit-name {
            @include gradient-directional($new-unit-button-starting-blue, $new-unit-button-ending-blue, 180deg);
            border-radius: 0;
            margin: 0.5rem 0 0.5rem 1.25rem;
            padding: #{pxToRem(15)} 1rem;

            h3 {
              @include rhythm(16, 18);
            }
          }

          &:last-child {
            .unit-name {
              &.isCollapsed {
                margin-bottom: 0;
              }
            }
          }

          .asset-container {
            .assets {
              padding-left: #{pxToRem(33)};
              margin-bottom: 0;

              .asset-name {
                border: 1px solid $secondary-dark-gray;
                border-radius: 0.25rem;
                padding: 15px 16px;
                @include gradient-directional($primary-white, $modal-body-bg, 180deg);
                margin: 0 0 0.5rem 0;
                position: relative;

                h3 {
                  @include rhythm(14, 16);
                  color: $primary-gray;
                }

                .assets-btn-group {
                  right: 1rem;

                  .assets-btn {

                    &.ico-delete,
                    &.ico-edit-pencil {
                      color: $primary-gray;
                    }
                  }
                }
              }

              &:last-child {
                .asset-name {
                  margin-bottom: 0;
                }
              }
            }
          }

          .asset-container.emptySortAsset {
            min-height: 3rem !important;
            border: 1px dashed $secondary-dark-gray;
            border-radius: 0.25rem;
            margin-left: #{pxToRem(35)};

          }
        }

        .unit-container.emptySortUnit {
          min-height: 3rem !important;
          border: 1px dashed $secondary-blue;
          border-radius: 0.25rem;
          margin: 0.5rem 0 0.5rem 1.25rem;

        }
      }
    }
  }

  .asset-body {
    background-color: $primary-white;
    height: 95vh;
    overflow: hidden;
    padding-bottom: 60px;
  }

  .asset-subheader {
    border-bottom: 1px solid $secondary-light-gray;
    padding: 0.1rem 1rem;

    .asset-Subtitle {
      margin: 6px 0;
    }
  }

  .asset-header {
    border-bottom: 1px solid $secondary-light-gray;
    padding: 0.5rem 1rem;

    .asset-title {
      font-size: 16px;
      margin-top: 8px;
      margin-bottom: 4px;

    }

    .asset-Subtitle {
      font-size: 14px;
    }

    .float-right {
      &.checkBox {
        width: #{pxToRem(170)};
        padding-top: #{pxToRem(7)};

        label {
          text-align: right;
        }
      }
    }
  }

  .devider {
    width: 5rem;
    display: inline-block;
    margin-top: 2.4rem;

    p {
      margin-bottom: 0.75rem;

      &.dashed {
        border-right: 2px dashed $secondary-dark-gray;
        min-height: 45%;
        max-height: 45%;
        width: 2.5rem;
      }

      &.drag-right {
        text-align: center;
        color: $secondary-dark-gray;
        line-height: 28px;
        font-weight: 700;
        padding-left: 0px;
      }
    }
  }

  .gray-area {
    background: $secondary-light-gray;
    background-image: none !important;
  }

  .in {
    display: block;
  }
}



.empty-container {
  visibility: hidden;
}

.empty-area-container {
  border: 1px dashed $secondary-blue;
  display: none;
  height: 3rem;
  margin-left: #{pxToRem(20)};
  margin-top: 0.5rem;
}

.empty-unit-container {
  height: 3rem;
  border: 1px dashed $secondary-dark-gray;
  border-radius: 0.25rem;
  display: none;
  margin-left: #{pxToRem(35)};
  margin-top: 0.5rem;
}

.unit-container.dnd-drag-over .empty-unit-container,
.empty-unit-container.dnd-drag-enter {
  display: block;
}

.area-container-inner.dnd-drag-over .empty-area-container,
.empty-area-container.dnd-drag-enter {
  display: block;
}

.asset-content.dnd-drag-over .empty-container,
.empty-container.dnd-drag-enter {
  border: 1px dashed $secondary-blue;
  visibility: visible;
  height: 3rem;
  margin: 0.5rem 1rem;
}

.btn-dragged.dnd-drag-start {
  background-image: none !important;
  border: none;
  background-color: $secondary-light-gray !important;
  color: $secondary-light-gray !important;
}

#AssetSetupComplete {
  margin-right: 8px;
  vertical-align: middle;
}

.saveExit-modal,
.sessiontime-modal,
.timeout-modal,
.saved-modal,
.audit-modal {
  text-align: center;
  padding: 0 !important;

  .modal-dialog {
    transform: none;
    display: inline-block;
    vertical-align: middle;
    min-width: auto;
  }

  &:before {
    display: inline-block;
    vertical-align: middle;
    content: " ";
    height: 100%;
    margin-right: -4px;

  }

}

.delete-modal {
  text-align: center;
  padding: 0 !important;

  .modal-dialog {
    transform: none;
    display: inline-block;
    vertical-align: middle;
    min-width: 35rem;
  }

  &:before {
    display: inline-block;
    vertical-align: middle;
    content: " ";
    height: 100%;
    margin-right: -4px;

  }
}

.saveExit-modal {
  &:before{
    height: 30%;
  }
  .modal-dialog{
    display: flex;
    width: fit-content;
  }
  .modal .modal-dialog .modal-content .modal-footer {
    padding: 1rem 1rem 1rem 1.6rem;
  }
}

.audit-modal {

  .modal-dialog,
  .modal-content {
    min-width: 60rem;
  }
}


.saved-modal {
  .modal-dialog {
    min-width: 360px !important;

    .modal-content {
      max-width: 360px !important;
    }
  }
}

.acceptRecommendation-modal {
  .modal-dialog {
    min-width: 60% !important;
  }
  .modal-content {
    min-width: 60% !important;
    text-align: center;
    padding: 10px 20px 0px 20px;
  }
  .modal-title {
    min-width: 80% !important;
    width: 80%;
    text-align: center;
    box-shadow: 0 1px 4px 0 #bbbcbe;
    border-radius: 100px;
    height: 48px;
    margin-bottom: 10px;
    margin: auto;
    text-transform: none !important;
}
  
  .modal-footer {
    justify-content: center;
    border-top: none !important;
  }
}
