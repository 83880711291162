@import "../../../../styles/variables.scss";
.my-documents-filters {
    .filter-accordion {
      .select-Dvheading {
          @include margin-padding(all, null, all, #{pxToRem(16)});
          @include font-size(16);
          background-color: $primary-white;
          position: relative;
          cursor: pointer;

          p.upArrow {
            &:after {
              display: inline-block;
              vertical-align: #{pxToRem(4)};
              content: "";
              border-top: 0.3em solid;
              border-right: 0.3em solid transparent;
              border-bottom: 0;
              border-left: 0.3em solid transparent;
              position: absolute;
              right: #{pxToRem(16)};
              border-top-color: $primary-blue;
              @include font-size(20);
              top: 45%;
              border-width: 8px 7px 0 7px;
            }
          }
          p.downArrow {
            &:after {
              transform: scaleY(-1);
              display: inline-block;
              vertical-align: #{pxToRem(4)};
              content: "";
              border-top: 0.3em solid;
              border-right: 0.3em solid transparent;
              border-bottom: 0;
              border-left: 0.3em solid transparent;
              position: absolute;
              right: #{pxToRem(16)};
              border-top-color: $primary-blue;
              @include font-size(20);
              top: 45%;
              border-width: 8px 7px 0 7px;
            }
          }
      }
      .selectFilterContent {
          nalco-sites-filter .filterButton {
              padding: 0.50rem 0.875rem 0.50rem 0.875rem;
              margin-bottom: 0;

              &.activeButton {
                  background-color: $primary-blue;
                  border: 1px solid $primary-blue;
                }
          }
      }
      .primary-filter-search {
        kendo-dateinput ::placeholder {
          color: #007ac9;
        }
        kendo-dateinput :-ms-input-placeholder { /* Internet Explorer 10-11 */ 
          color: #007ac9;
        }
        kendo-dateinput ::-ms-input-placeholder { /* Microsoft Edge */
          color: #007ac9;
        }
        .k-autocomplete .k-input {
          color: #007ac9;
        }
        .search-container {
          padding-left: 0rem;
          padding-bottom: 0rem;
          padding-top: 1.4rem;
          padding-right: 0rem;
        }
        div > div.search-container {
          padding-left: 0px;
        }
        input[type="search"].primary-search {
          border-radius: 0.125rem;
          height: 2.78rem;
          border: 1px solid #BBBCBE;
          font-family: "Interstate-Regular", sans-serif;
          font-weight: 400;
          width: 100%;
        }
        input[type="search"].primary-search::placeholder{
          font-size: 14px;
          color: #BBBCBE;
        }
        label.ico-nav-search::before {
          top: 0.9rem;
          color:#007ac9;
          font-weight: 400;
        }
      }
      .ico-nav-document-type {
        &:after {
          content: "\e92f";
       }
       font-family: 'NALCO-Nav-Icons' !important;
       font-style: normal;
       font-weight: 400;
       font-variant: normal;
       text-transform: none;
       font-size: 1.5rem;
       line-height: 30px;
       color: #007ac9;
      }
      .document-type-icon{
        position: absolute;
        z-index: 10;
        margin-left: 10px;
        margin-top: 2px;
      }
      .ico-nav-file-icon {
        &:after {
          content: "\e922";
          font-size: smaller;
       }
      font-family: 'NALCO-Nav-Icons' !important;
      font-style: normal;
      font-weight: 400;
      font-variant: normal;
      text-transform: none;
      font-size: 1.5rem;
      line-height: 30px;
      color: #007ac9;
      }
      .label-align{
        display: inline-block;
        margin-bottom: .5rem;
      }
      .required{
        color: #E00034;
     }
     .disabled {
     .dd-container.select-dropdown
     .dd-list-container .list-items
     .dd-items-container
     .dd-list-item:nth-child(n+2){
       opacity: 0.5;
       pointer-events: none;
     }
    }

      .document-filter-date-picker
      {
        width:100%;
          & kendo-dateinput{
           padding: 5px !important;
           border: 1px solid $secondary-gray;
            border-radius: 0.125rem;
            &:hover{
           border: 1px solid $secondary-gray;
           box-shadow: none !important
            }
         }
      }
      form{
        .col-lg , .col-lg-3{
          @include margin-padding(all, null , left , #{pxToRem(0)});
        }
        .col-lg-3{
          position: relative;
          .k-autocomplete .k-input{
          padding-left: 38px !important;
          min-height: 2.78rem;
        }
        kendo-autocomplete.admin-search {
          width: 100%;
          min-width: 100%;
          border: 1px solid $secondary-gray;
          border-radius: #{pxToRem(2)};
          height: 2.78rem;
          margin-bottom: 0 ;
        }

        div.admin-icon {
          width: #{pxToRem(15)};
          height: #{pxToRem(19)};
          position: absolute;
          top: 2.20rem !important;
          z-index: 10;
          left: #{pxToRem(16)};
          background-image: $autocomplete-account-icon-url;
          background-position-x: no-repeat;
        }
        .dd-display-item{
          height: 2.78rem;
          padding-left: 36px;
          padding-top: 0.7rem;
        }
      }
    }
    .range-content {
        border-top: 1px solid $secondary-gray;
        background-color: $primary-white;

        .mydocuments-secondary-filters {
          kendo-dateinput ::placeholder {
            color: #007ac9;
          }
          kendo-dateinput :-ms-input-placeholder { /* Internet Explorer 10-11 */
            color: #007ac9;
          }
          kendo-dateinput ::-ms-input-placeholder { /* Microsoft Edge */
            color: #007ac9;
          }
          .k-autocomplete .k-input {
            color: #007ac9;
          }
          .search-container {
            padding-left: 0rem;
            padding-bottom: 0rem;
            padding-top: 1.4rem;
            padding-right: 0rem;
          }
          div > div.search-container {
            padding-left: 0px;
          }
          input[type="search"].secondary-search {
            border-radius: 0.125rem;
            height: 2.6rem;
            border: 1px solid #BBBCBE;
            font-family: "Interstate-Regular", sans-serif;
            font-weight: 400;
            width: 100%;
          }
          input[type="search"].secondary-search::placeholder{
            font-size: 14px;
            color: #BBBCBE;
          }
          label.ico-nav-search::before {
            top: 0.9rem;
            color:#007ac9;
            font-weight: 400;
          }
          .dd-container.select-dropdown .dd-control-container .dd-display-item {
            padding-left: 2.0rem;
            padding-top: 0.7rem;
            padding-bottom: 0.7rem;
          }
        }
    }
    .disabled {
        .customDateRange{
            pointer-events: none;
            opacity: 0.4;
            background-color: transparent;
        }
    }
    .showMe {
        left : 0px;
    }
    div.custom{
      .admin-search , nalco-dropdown .dropdown , .k-datepicker ,
      .data-visual.nalco-date-range-dropdown , kendo-autocomplete.general-currency{
        min-width: 100%;
        width: 100%;
        height: 2.78rem;
        border-radius: #{pxToRem(2)};
        margin-bottom: 0px ;
        .k-picker-wrap.k-state-default{
          border: 1px solid $secondary-gray;
          border-radius: #{pxToRem(2)};
        }
        .k-input{
          @include margin-padding(top,  #{pxToRem(10)}, all , null);
        }
        .selected-text{
          color: $primary-blue;
          top: 0px;
        }
        .label{
          display: block !important;
          top: 0px;
          line-height: normal !important;
        }
      }
      .start-label{
        color: $primary-gray;
        @include font-size(9);
        left: #{pxToRem(16)};
        display: block;
        z-index: 1;
        position: absolute;
        text-transform: uppercase;
        font-family: $font-family-base;
        color: $primary-gray;
        margin-top: 0.375rem;
        @include margin-padding(bottom,  #{pxToRem(0)}, all , null);
        width: #{pxToRem(100)};
        width: 7rem;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .k-dateinput-wrap{
        position: relative;
        background: transparent;
        .k-input{

          color: $primary-blue;
          &::placeholder{
            @include font-size(14);
            margin-top: #{pxToRem(6)};
            color: $primary-blue !important;
          }
          &::-moz-placeholder {
            @include font-size(14);
            margin-top: #{pxToRem(3)};
            color: $primary-blue !important;
            opacity: 1;
          }
        }
      }

    }

    .page-datepicker {
      .k-picker-wrap.k-state-default {
        border-color: $secondary-gray !important;
      }
      .start-label {
        color: $primary-gray;
        @include font-size(9);
        left: #{pxToRem(16)};
        display: block;
        z-index: 1;
        position: absolute;
        text-transform: uppercase;
        @include margin-padding(top,  #{pxToRem(6)}, all , null);
      }
      kendo-datepicker {
        .k-dateinput-wrap {
          .k-input {
            @include margin-padding(top,  #{pxToRem(4)}, all , null);
          }
        }
        .k-icon.k-i-calendar {
          color: $secondary-gray;
        }
      }
    }
    .units{
      display: flex;
    }
    @media screen and (max-width: $break-sml-large) {
      .units {
        flex-wrap: wrap;
      }
      .custom {
        padding-left: 0.5rem !important;
        padding-right: 0.5rem !important;
      }
    }
    hr{
        border-top: 1px solid $light-gray;
    }
  }

  .view-details-button {
    width: auto;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 13rem !important;
  }
}

