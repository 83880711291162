/****************************************************/

// This files contains the asset-list-item component styles

/****************************************************/
@import "../../../../../styles/variables.scss";
.accounted-for{
    color:#6FB43F;
    margin-bottom:0px;
  }
 .pop-up-styling{
    right: 15px;top:9px;position: absolute;
  }
  .asset-level-pop-up{
    right: 15px;top: -43px;position: absolute;
  }
  .legionella-test-list-pop-up{
    right: 15px;
    top: 14px;
    position: absolute;
  }
  .pop-up-across-sites{
    right: 23px;
    top: 9px;
    position: absolute;
  }
  .pop-up-across-assets{
    right: 15px;top:-38px;position: absolute;
  }
  .growth-risk-pop-up{
    right: 23px;
    top: 8px;
    position: absolute;
  }

  kendo-grid.k-widget.k-grid{
    border-style: none;
  }

  body .k-grid .k-grid-header .k-header {
    border-left-width:0px;
  }
  body .k-grid td {
    @include font-size(12);
    border-width: 1px 0px 0px 0px;
    text-transform: capitalize;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    .tooltip .tooltip-inner{
      @include font-size(12);
    }
}
body .k-grid tr:first-child td{
    border-top-width: 0;

}
body .k-grid th {
  @include font-size(12);
  &:first-child{
    border-left: 0;
  }
}

.k-grouping-row td, tbody .k-group-cell{
  background-color: #ededed;
}

.k-grid-header {
  padding-right: 0 !important;
  .k-header:first-child {
    border-left-width: 0px;
  }
}
.k-grid-header-wrap {
  border-width: 0;
}

.k-pager-numbers .k-link, .k-pager-numbers .k-link:link {
  color: #007ac9;
}

.k-state-selected{
  background-color:#007ac9;
}

.k-pager-wrap .k-state-selected:hover{
  background-color:#007ac9
}

.k-pager-wrap {
  .k-pager-sizes {
      position: absolute;
      right: 0;
      bottom: 11px;
  }
}

.water-safety-grid-view {
  .first-col {
        overflow: hidden;
        text-overflow: ellipsis;
        text-transform: none;
        &::before {
            content: '';
            display: block;
        }
    }

  .k-grid-aria-root {
    overflow-x: auto !important;
    tbody tr.k-alt{
      background-color: #fff;
    }
  }

}

@media only screen and (max-width: 768px) {
  .k-grid {
    max-height:350px;
  }
  .k-grid-aria-root {
    overflow-y: scroll !important;
  }
}

@media only screen and (max-width:550px){
  .k-pager-wrap {
    .k-pager-sizes {
      bottom: 2px;
        select{
          width: auto;
        }
    }
  }

  .pop-up-across-assets{
    top: -73px; 
  }
}
