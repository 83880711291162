.select-view-type{
  max-width: inherit;
  ul {
  float:left;
    li{
      list-style-type: none;
    }
  }
  table{
    width: auto !important;
  }

  .header_border{
    border-bottom: 1px solid #E2E2E2;
  }  .nalco-tree-column {
      ngb-accordion .card {
        .card-header {
          position: relative;
          top: -40px;
          padding: 10px 10px 15px;
          height: 100px;
          // overflow: hidden;
          // text-overflow: ellipsis;
          // white-space: nowrap;

          .btn.btn-link {
            white-space: break-spaces;
            &:after{
              transform: rotate(0deg);
            }
            &.collapsed {
              &:after {
                transform: rotate(270deg);
              }
            }
            
          }

          a {
            height: 20px;
            white-space: nowrap;
            width: 90%;
            text-overflow: ellipsis;
            overflow: hidden;
            padding-left: 24px;
          &:after{
            position: absolute ;
            left: 0;
          }
        }
      }
      .card-body {
        position: relative;
        top: -40px;
        padding: 0px;
      }
    }

    .custom-control{
      height: 39px;
      border-bottom: 1px solid #E2E2E2;
      margin-top: 12px;
      margin-left: 31px;

      .custom-control-label{
        overflow: hidden;
        white-space: nowrap;
        max-width: 150px;
        text-overflow: ellipsis;
        padding: 5px 4px 4px 4px;
        width: 100%;

        // display: -webkit-box;
        // -webkit-box-orient: vertical;
        // -webkit-line-clamp: 2;
        // line-height: 1.3em;
        // max-height: 2.6em;
        // /* position: absolute; */
        // text-align: center;
        // width: 160px;
        // /* margin-top: 7px; */
        // z-index: 10;
        // /* left: 0px; */

        @media screen and (max-width:990px){
          width: 100%;
      }
      }
    }
  }

  .nalco-simple-column{

    .custom-control{
      padding-bottom: 12px;
      height: 39px;
    }

    .checkBoxStyle{
      .custom-control{
        border-bottom: 1px solid #E2E2E2;
        margin-top: 12px;
      }
    }
  }
  .noData{
    font-size: 16px;
    padding: 16px;
    justify-content: center;
  }
  .custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
    background-color: #007ac9 !important;
  }

  .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
    background-color: #007ac9 !important;
  }

  .custom-control-input:checked ~ .custom-control-label::before{
      color: #fff;
      background-color: #007ac9 !important;
  }
}

